import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatTabGroup, MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { FieldQueryParamMap } from "../../_shared/app-objects/field-query-param-map.ao";
import { dtoKeyValue } from "../../_shared/business-objects/dto-int-key-value.bo";
import { dtoLoadingDocket } from "../../_shared/business-objects/dto-loading-docket.bo";
import { LoadingDocket } from "../../_shared/business-objects/loading-docket.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { AlertService } from "../../_shared/services/alert.service";
import { FormService } from "../../_shared/services/form.service";
import { LoadingDocketService } from "../../_shared/services/loading-docket.service";
import { dtoLoadingDocketService } from "../../_shared/services/dto-loading-docket.service";
import { SharedService } from "../../_shared/services/shared.service";
import { dtoLoadingDocketDeliveryService } from "../../_shared/services/dto-loading-docket-delivery.service";
import { MatTableDataSource } from "@angular/material/table";
import { share } from "rxjs/operators";
import { UserPositionService } from "../../_shared/services/user-position.service";
import { AuthService } from "../../account/auth.service";
import { MatSelectionList } from "@angular/material/list";
import { MatSelect } from "@angular/material/select";
import { dtoLoadingDocketDelivery } from "../../_shared/business-objects/dto-loading-docket-delivery.bo";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TruckTrailerSelectionDialogComponent } from "../truck-trailer-selection-dialog/truck-trailer-selection-dialog.component";
import { MobilePlantCategory } from "../../_shared/business-objects/mobile-plant-category.bo";
import { HaulingCompany } from "../../_shared/business-objects/hauling-company.bo";
import { HaulingCompanyDriver } from "../../_shared/business-objects/hauling-company-driver.bo";
import { MobilePlantEquipment } from "../../_shared/business-objects/mobile-plant-equipment.bo";
import { MobilePlantCategoryService } from "../../_shared/services/mobile-plant-category.service";
import { HaulingCompanyDriverService } from "../../_shared/services/hauling-company-driver.service";
import { HaulingCompanyService } from "../../_shared/services/hauling-company.service";
import { dtoProductType } from "../../_shared/business-objects/dto-product-type.bo";
import { dtoProductTypeService } from "../../_shared/services/dto-product-type.service";

export enum enLoadingDocketTabs {
  LoadingDockets = 0,
  SearchOptions = 1,
  CreateLoadingDocket = 2,
  UpdateLoadingDocket = 3,
  DocketAddItems = 4,
}

@Component({
  selector: "app-loading-docket-search",
  templateUrl: "./loading-docket-search.component.html",
  styleUrls: ["./loading-docket-search.component.css"],
  animations: [
    trigger("displaySpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingDocketSearchComponent implements OnInit {
  @ViewChild("tabLoadingDockets", { static: false })
  tabLoadingDockets: MatTabGroup;
  //@ViewChild('loadingDocketAddItems', { static: false }) loadingDocketAddItems: LoadingDocketAddItemsComponent;

  form: FormGroup;
  public eLoadingDocketTabs = enLoadingDocketTabs;

  selectedTab: number;
  showTable: boolean = false;
  showSpinner: boolean = false;
  loadingDockets: MatTableDataSource<dtoLoadingDocket> =
    new MatTableDataSource();
  loadingDocket: LoadingDocket;
  loadingDocketAddItem: dtoLoadingDocket;
  loadingStatuses: dtoKeyValue[] = [];
  loadingSections: dtoKeyValue[] = [];
  yardSections: dtoKeyValue[] = [];
  selectedStatuses: number[] = [];

  yardSectionLoadFilter: string;
  yardSection: SharedService.enYardSection;
  yardSectionsSelected: SharedService.enYardSection[] = [];
  treatmentPlantAdmin: boolean = false;
  crossarmAdmin: boolean = false;
  treatmentPlantYardSection: boolean = false;
  boardPlantYardSection: boolean = false;
  crossarmYardSection: boolean = false;
  millYardSection: boolean = false;

  productTypes: dtoProductType[];
  selectedProd: dtoProductType[];

  totalTonne: number = 0;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  truckTrailerSelectionDialogRef: MatDialogRef<TruckTrailerSelectionDialogComponent>;
  searchHaulingCompany: HaulingCompany;
  searchDriver: HaulingCompanyDriver;
  searchTrailer: MobilePlantEquipment;

  searchDockets: boolean = false;

  constructor(
    private dtoLoadingDocketService: dtoLoadingDocketService,
    private loadingDocketService: LoadingDocketService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private formService: FormService,
    private dtoLoadingDocketOrderItemService: dtoLoadingDocketDeliveryService,
    private userPositionService: UserPositionService,
    private authService: AuthService,
    private mobilePlantCategoryService: MobilePlantCategoryService,
    private haulingCompanyDriverService: HaulingCompanyDriverService,
    private haulingCompanyService: HaulingCompanyService,
    private dialog: MatDialog,
    private productTypeService: dtoProductTypeService
  ) {
    this.form = this.fb.group({
      txtSearchLoadingDocketNo: [""],
      txtSearchDeliveryDocketNo: [""],
      dteSearchLoadingDateFrom: [""],
      dteSearchLoadingDateTo: [""],
      txtSearchCustomer: [""],
      txtSearchHaulingCompanyName: [""],
      intStatus: [""],
      intYardSection: [""],
      blnSearchUploadInvoice: [""],
      txtSearchLocation: [""],
      txtSearchOrderNumber: [""],
      txtSearchFinalNumber: [""],
      loadingSectionList: [""],
      dteSearchDispatchedDateFrom: [""],
      dteSearchDispatchedDateTo: [""],
      txtDateSelection: [""],
    });

    this.loadingStatuses = this.sharedService.getEnumObject2(
      LoadingDocket.enLoadingDocketStatus
    );
    this.yardSections = this.sharedService.getEnumObject2(
      SharedService.enYardSection
    );
    this.loadingSections = this.sharedService
      .getEnumObject2(SharedService.enYardSection, true)
      .sort((a, b) => {
        return a.txtValue > b.txtValue ? 1 : -1;
      });

    this.filterToQueryParamMap.push({
      txtField: "intStatus",
      txtQueryParam: "status",
      intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple,
      intValueType: FieldQueryParamMap.enValueType.Int,
    });
    this.filterToQueryParamMap.push({
      txtField: "intYardSection",
      txtQueryParam: "yardsection",
      intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple,
      intValueType: FieldQueryParamMap.enValueType.Int,
    });
    this.filterToQueryParamMap.push({
      txtField: "txtSearchHaulingCompanyName",
      txtQueryParam: "hauler",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.String,
    });
    this.filterToQueryParamMap.push({
      txtField: "txtSearchCustomer",
      txtQueryParam: "customer",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.String,
    });
    this.filterToQueryParamMap.push({
      txtField: "txtSearchLocation",
      txtQueryParam: "location",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.String,
    });
    this.filterToQueryParamMap.push({
      txtField: "txtSearchOrderNumber",
      txtQueryParam: "orderno",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.String,
    });
    this.filterToQueryParamMap.push({
      txtField: "txtSearchFinalNumber",
      txtQueryParam: "finalno",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.String,
    });
    this.filterToQueryParamMap.push({
      txtField: "dteSearchLoadingDateFrom",
      txtQueryParam: "datefrom",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.Date,
    });
    this.filterToQueryParamMap.push({
      txtField: "dteSearchLoadingDateTo",
      txtQueryParam: "dateto",
      intFieldType: FieldQueryParamMap.enFieldType.Textbox,
      intValueType: FieldQueryParamMap.enValueType.Date,
    });
    this.filterToQueryParamMap.push({
      txtField: "blnSearchUploadInvoice",
      txtQueryParam: "uploadinvoice",
      intFieldType: FieldQueryParamMap.enFieldType.SlideToogle,
      intValueType: FieldQueryParamMap.enValueType.Boolean,
    });

    //this.form.controls['txtSearchLoadingDocketNo'].setValue("36461");
  }

  ngOnInit(): void {
    document.title = "Loading Dockets";

    this.showTable = false;

    this.selectedTab = this.eLoadingDocketTabs.LoadingDockets;

    this.loadData();

    this.selectedStatuses.length = 0;

    if (Array.isArray(this.form.controls["intStatus"].value)) {
      for (
        let i = 0;
        i <= this.form.controls["intStatus"].value.length - 1;
        i++
      ) {
        this.selectedStatuses.push(this.form.controls["intStatus"].value[i]);
        //console.log("status: " + this.form.controls['intStatus'].value[i]);
      }
    }

    //this.form.controls['txtSearchLoadingDocketNo'].setValue("36577");
  }

  ngAfterViewInit(): void {}

  async loadData() {
    this.route.queryParams.subscribe(async (params) => {
      //console.log("search: ", params);
      if (params != null) {
        let tab: number = params["tab"];
        let docketNo: number = params["docketno"];

        if (tab != null) {
          this.selectedTab = tab;
        }

        this.searchDockets = params["search"] == "true" && docketNo == null;

        if (docketNo != null) {
          let loadingDocket: dtoLoadingDocket =
            await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseByDocketNo(
              docketNo.toString()
            );
          if (loadingDocket != null) {
            loadingDocket = loadingDocket;

            if (params["search"] == "true") {
              console.log("params", params);

              this.loadingDockets.data = [loadingDocket];
            }
          }
        }
      }

      this.formService.setFieldsFromQueryParams(
        this.filterToQueryParamMap,
        params,
        this.form
      );
    });

    this.formService.setAutoFocusTimeout("txtSearchLoadingDocketNo");

    this.productTypeService.getdtoProductTypes().then((productTypes) => {
      this.productTypes = productTypes;
    });

    // Only one of these should exist other than office staff.
    this.crossarmAdmin = await this.userPositionService.isInPosition(
      "crossarm admin",
      this.authService.getAppUserEmployeeId()
    );
    this.treatmentPlantAdmin = await this.userPositionService.isInPosition(
      "tp admin",
      this.authService.getAppUserEmployeeId()
    );
    this.treatmentPlantYardSection =
      await this.userPositionService.isInPosition(
        "tp supervisor",
        this.authService.getAppUserEmployeeId()
      );
    this.boardPlantYardSection = await this.userPositionService.isInPosition(
      "board plant sales staff",
      this.authService.getAppUserEmployeeId()
    );
    this.crossarmYardSection = await this.userPositionService.isInPosition(
      "crossarms",
      this.authService.getAppUserEmployeeId()
    );
    this.millYardSection = await this.userPositionService.isInPosition(
      "mill",
      this.authService.getAppUserEmployeeId()
    );

    this.yardSection = SharedService.enYardSection.All;
    this.yardSectionLoadFilter = "All";

    if (
      this.treatmentPlantYardSection == true ||
      this.treatmentPlantAdmin == true
    ) {
      this.yardSection = SharedService.enYardSection.TreatmentPlant;
      this.yardSectionLoadFilter = "Treatment Plant";
      this.yardSectionsSelected.push(this.yardSection);

      this.form.controls["intYardSection"].setValue(this.yardSectionsSelected);
    }

    if (this.boardPlantYardSection == true) {
      this.yardSection = SharedService.enYardSection.BoardPlant;
      this.yardSectionLoadFilter = "Board Plant";
      this.yardSectionsSelected.push(this.yardSection);
      this.form.controls["intYardSection"].setValue(this.yardSectionsSelected);
    }

    if (this.crossarmYardSection == true || this.crossarmAdmin == true) {
      this.yardSection = SharedService.enYardSection.XArms;
      this.yardSectionLoadFilter = "Crossarms";
      this.yardSectionsSelected.push(this.yardSection);
      this.form.controls["intYardSection"].setValue(this.yardSectionsSelected);
      //console.log(this.yardSectionsSelected);
    }

    if (this.millYardSection == true) {
      this.yardSection = SharedService.enYardSection.Mill;
      this.yardSectionLoadFilter = "Mill";
      this.yardSectionsSelected.push(this.yardSection);
      this.form.controls["intYardSection"].setValue(this.yardSectionsSelected);
    }

    if (this.searchDockets == true) {
      console.log("this.searchDockets", this.searchDockets);

      this.searchLoadingDockets("");
    }
  }

  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eLoadingDocketTabs.SearchOptions);

    if (e.index == this.eLoadingDocketTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchCustomer");
    }

    if (e.index == this.eLoadingDocketTabs.LoadingDockets) {
      this.formService.setAutoFocusTimeout("txtSearchLoadingDocketNo");
    }
  }

  selectedProductTypes(productTypes: dtoProductType[]) {
    this.selectedProd = productTypes;
  }

  searchReadyForLoading() {
    this.form.controls["intStatus"].setValue([
      LoadingDocket.enLoadingDocketStatus.ReadyForLoading,
    ]);
    this.searchLoadingDockets("");
  }

  searchScheduled() {
    this.form.controls["intStatus"].setValue([
      LoadingDocket.enLoadingDocketStatus.Scheduled,
    ]);
    this.searchLoadingDockets("");
  }

  searchLoaded() {
    this.form.controls["intStatus"].setValue([
      LoadingDocket.enLoadingDocketStatus.Loaded,
    ]);
    this.searchLoadingDockets("");
  }

  searchDispatched() {
    this.form.controls["intStatus"].setValue([
      LoadingDocket.enLoadingDocketStatus.Dispatched,
    ]);
    this.searchLoadingDockets("dispatched");
  }

  async searchLoadingDockets(searchType: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let docketNo: string = this.form.controls["txtSearchLoadingDocketNo"].value;
    let deliveryDocketNo: string =
      this.form.controls["txtSearchDeliveryDocketNo"].value;
    let statusDateFrom: string =
      this.form.controls["dteSearchLoadingDateFrom"].value;
    let statusDateTo: string =
      this.form.controls["dteSearchLoadingDateTo"].value;
    let dispatchedDateFrom: string =
      this.form.controls["dteSearchDispatchedDateFrom"].value;
    let dispatchedDateTo: string =
      this.form.controls["dteSearchDispatchedDateTo"].value;

    let haulingCompanyName: string =
      this.form.controls["txtSearchHaulingCompanyName"].value;
    let customer: string = this.form.controls["txtSearchCustomer"].value;
    let location: string = this.form.controls["txtSearchLocation"].value;
    let orderno: string = this.form.controls["txtSearchOrderNumber"].value;
    let finalno: string = this.form.controls["txtSearchFinalNumber"].value;
    let status: number[] = this.form.controls["intStatus"].value;
    let yardSection: number[] = this.form.controls["intYardSection"].value;
    const datepipe: DatePipe = new DatePipe("en-AU");
    let criteriaSelected: boolean = false;
    let scheduled: boolean = false;
    let readyForLoading: boolean = false;
    console.log("yardSection: " + Array.isArray(yardSection));

    //if (yardSection.length == 0 && this.yardSectionsSelected.length > 0) {
    //  yardSection = [];
    //  yardSection = this.yardSectionsSelected;
    //}

    if (searchType == "readyforloading") {
      status = [];
      status.push(LoadingDocket.enLoadingDocketStatus.ReadyForLoading);
    }

    if (searchType == "dispatched") {
      status = [];
      status.push(LoadingDocket.enLoadingDocketStatus.Dispatched);
    }

    if (searchType == "scheduled") {
      status = [];
      status.push(LoadingDocket.enLoadingDocketStatus.Scheduled);
    }

    if (searchType == "complete") {
      status = [];
      status.push(LoadingDocket.enLoadingDocketStatus.Complete);
    }

    //if (this.selectedStatuses.length > 0) {
    //  for (let i = 0; i <= this.selectedStatuses.length - 1; i++) {
    //    aParam.push(new SQLParamArray("intStatus", this.selectedStatuses[i].toString()))
    //  }
    //  aParamList.push(aParam);
    //}

    if (status.length > 0 || (status != null && status.toString() != "")) {
      criteriaSelected = true;
      aParam = [];
      if (Array.isArray(status) == true) {
        for (let i = 0; i <= status.length - 1; i++) {
          aParam.push(new SQLParamArray("intStatus", status[i].toString()));
          if (status[i] == LoadingDocket.enLoadingDocketStatus.Scheduled) {
            scheduled = true;
          }

          if (
            status[i] == LoadingDocket.enLoadingDocketStatus.ReadyForLoading
          ) {
            readyForLoading = true;
          }

          console.log("aParam.push: " + status[i].toString());
        }
        aParamList.push(aParam);
        //console.log("intStatus - parameter Array", aParam);
      }

      if (Array.isArray(status) == false) {
        aParam.push(new SQLParamArray("intStatus", status.toString()));
        aParamList.push(aParam);
        if (Number(status) == LoadingDocket.enLoadingDocketStatus.Scheduled) {
          scheduled = true;
        }

        if (
          Number(status) == LoadingDocket.enLoadingDocketStatus.ReadyForLoading
        ) {
          readyForLoading = true;
        }

        //console.log("intStatus - parameter", aParam);
      }
    }

    if (
      yardSection.length > 0 ||
      (yardSection != null && yardSection.toString() != "")
    ) {
      aParam = [];
      if (Array.isArray(yardSection) == true) {
        for (let i = 0; i <= yardSection.length - 1; i++) {
          aParam.push(
            new SQLParamArray("intYardSection", yardSection[i].toString())
          );
        }
        aParamList.push(aParam);
        console.log("intYardSection - parameter Array", aParam);
      }

      if (Array.isArray(yardSection) == false) {
        aParam.push(
          new SQLParamArray("intYardSection", yardSection.toString())
        );
        aParamList.push(aParam);
        console.log("intYardSection - parameter", aParam);
      }
    }

    if (docketNo != null && docketNo.toString() != "") {
      criteriaSelected = true;
      aParam = [];
      if (docketNo.toString().indexOf(",")) {
        let aDocketNos: string[] = docketNo.toString().split(",");
        for (let i = 0; i <= aDocketNos.length - 1; i++) {
          aParam.push(new SQLParamArray("intDocketNo", aDocketNos[i]));
        }
      } else {
        aParam.push(new SQLParamArray("intDocketNo", docketNo));
      }
      aParamList.push(aParam);
    }

    if (deliveryDocketNo != "" && deliveryDocketNo != null) {
      criteriaSelected = true;
      aParam = [];
      if (deliveryDocketNo.indexOf(",")) {
        let aDeliveryDocketNos: string[] = deliveryDocketNo.split(",");
        for (let i = 0; i <= aDeliveryDocketNos.length - 1; i++) {
          aParam.push(
            new SQLParamArray(
              "intDeliveryDocketNo",
              aDeliveryDocketNos[i],
              SQLParamArray.enSQLOperator.Equals,
              SQLParamArray.enSQLLogicalOperator.AND,
              null,
              "",
              SQLParamArray.enSQLStatementType.SubQuerySQL
            )
          );
        }
      } else {
        aParam.push(
          new SQLParamArray(
            "intDeliveryDocketNo",
            deliveryDocketNo,
            SQLParamArray.enSQLOperator.Equals,
            SQLParamArray.enSQLLogicalOperator.AND,
            null,
            "",
            SQLParamArray.enSQLStatementType.SubQuerySQL
          )
        );
      }
      aParamList.push(aParam);
    }

    if (statusDateFrom != "" && statusDateFrom != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(
        new SQLParamArray(
          "dteExpectedLoadingDate",
          datepipe.transform(statusDateFrom, "yyyy-MM-dd"),
          SQLParamArray.enSQLOperator.GreaterThanOrEqualTo,
          SQLParamArray.enSQLLogicalOperator.AND
        )
      );
      aParamList.push(aParam);
    }

    if (statusDateTo != "" && statusDateTo != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(
        new SQLParamArray(
          "dteExpectedLoadingDate",
          datepipe.transform(statusDateTo, "yyyy-MM-dd"),
          SQLParamArray.enSQLOperator.LessThanOrEqualTo,
          SQLParamArray.enSQLLogicalOperator.AND
        )
      );
      aParamList.push(aParam);
    }

    if (dispatchedDateFrom != "" && dispatchedDateFrom != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(
        new SQLParamArray(
          "dteDateDispatched",
          datepipe.transform(dispatchedDateFrom, "yyyy-MM-dd"),
          SQLParamArray.enSQLOperator.GreaterThanOrEqualTo,
          SQLParamArray.enSQLLogicalOperator.AND
        )
      );
      aParamList.push(aParam);
    }

    if (dispatchedDateTo != "" && dispatchedDateTo != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(
        new SQLParamArray(
          "dteDateDispatched",
          datepipe.transform(dispatchedDateTo, "yyyy-MM-dd"),
          SQLParamArray.enSQLOperator.LessThanOrEqualTo,
          SQLParamArray.enSQLLogicalOperator.AND
        )
      );
      aParamList.push(aParam);
    }

    if (haulingCompanyName != "" && haulingCompanyName != null) {
      criteriaSelected = true;
      aParam = [];
      if (haulingCompanyName.indexOf(",")) {
        let aHaulingCompanyNames: string[] = haulingCompanyName.split(",");
        for (let i = 0; i <= aHaulingCompanyNames.length - 1; i++) {
          aParam.push(
            new SQLParamArray(
              "txtHaulingCompanyName",
              aHaulingCompanyNames[i],
              SQLParamArray.enSQLOperator.Like,
              SQLParamArray.enSQLLogicalOperator.AND
            )
          );
        }
      } else {
        aParam.push(
          new SQLParamArray(
            "txtHaulingCompanyName",
            haulingCompanyName,
            SQLParamArray.enSQLOperator.Like,
            SQLParamArray.enSQLLogicalOperator.AND
          )
        );
      }
      aParamList.push(aParam);
    }

    if (customer != "" && customer != null) {
      criteriaSelected = true;
      aParam = [];
      if (customer.indexOf(",")) {
        let aCustomers: string[] = customer.split(",");
        for (let i = 0; i <= aCustomers.length - 1; i++) {
          aParam.push(
            new SQLParamArray(
              "txtCustomerName",
              aCustomers[i],
              SQLParamArray.enSQLOperator.Like,
              SQLParamArray.enSQLLogicalOperator.AND,
              null,
              "",
              SQLParamArray.enSQLStatementType.SubQuerySQL
            )
          );
        }
      } else {
        aParam.push(
          new SQLParamArray(
            "txtCustomerName",
            customer,
            SQLParamArray.enSQLOperator.Like,
            SQLParamArray.enSQLLogicalOperator.AND,
            null,
            "",
            SQLParamArray.enSQLStatementType.SubQuerySQL
          )
        );
      }
      aParamList.push(aParam);
    }

    if (orderno != "" && orderno != null) {
      criteriaSelected = true;
      aParam = [];
      if (orderno.indexOf(",") > -1) {
        let aOrderNos: string[] = orderno.split(",");
        for (let i = 0; i <= aOrderNos.length - 1; i++) {
          aParam.push(
            new SQLParamArray(
              "txtOrderNo",
              aOrderNos[i],
              SQLParamArray.enSQLOperator.Like,
              SQLParamArray.enSQLLogicalOperator.AND,
              null,
              "",
              SQLParamArray.enSQLStatementType.SubQuerySQL,
              2
            )
          );
        }
      } else {
        aParam.push(
          new SQLParamArray(
            "txtOrderNo",
            orderno.toString(),
            SQLParamArray.enSQLOperator.Like,
            SQLParamArray.enSQLLogicalOperator.AND,
            null,
            "",
            SQLParamArray.enSQLStatementType.SubQuerySQL,
            2
          )
        );
      }
      aParamList.push(aParam);
    }

    if (finalno != "" && finalno != null) {
      criteriaSelected = true;
      aParam = [];
      if (finalno.indexOf(",") > -1) {
        let aFinalNos: string[] = finalno.split(",");
        for (let i = 0; i <= aFinalNos.length - 1; i++) {
          aParam.push(
            new SQLParamArray(
              "intFinalNo",
              aFinalNos[i],
              SQLParamArray.enSQLOperator.Like,
              SQLParamArray.enSQLLogicalOperator.AND,
              null,
              "",
              SQLParamArray.enSQLStatementType.SubQuerySQL,
              3
            )
          );
        }
      } else {
        aParam.push(
          new SQLParamArray(
            "intFinalNo",
            finalno.toString(),
            SQLParamArray.enSQLOperator.Like,
            SQLParamArray.enSQLLogicalOperator.AND,
            null,
            "",
            SQLParamArray.enSQLStatementType.SubQuerySQL,
            3
          )
        );
      }
      aParamList.push(aParam);
    }

    if (location != "" && location != null) {
      criteriaSelected = true;
      aParam = [];
      if (location.indexOf(",") > -1) {
        let aLocations: string[] = location.split(",");
        for (let i = 0; i <= aLocations.length - 1; i++) {
          aParam.push(
            new SQLParamArray(
              "txtLocation",
              aLocations[i],
              SQLParamArray.enSQLOperator.Like,
              SQLParamArray.enSQLLogicalOperator.AND
            )
          );
        }
      } else {
        aParam.push(
          new SQLParamArray(
            "txtLocation",
            location.toString(),
            SQLParamArray.enSQLOperator.Like,
            SQLParamArray.enSQLLogicalOperator.AND
          )
        );
      }
      aParamList.push(aParam);
    }

    if (this.searchHaulingCompany != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(
        new SQLParamArray("guHaulerId", this.searchHaulingCompany.rowguid)
      );
      aParamList.push(aParam);
    }

    if (this.searchDriver != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(new SQLParamArray("guDriverId", this.searchDriver.rowguid));
      aParamList.push(aParam);
    }

    if (this.searchTrailer != null) {
      criteriaSelected = true;
      aParam = [];
      aParam.push(new SQLParamArray("guTrailerId", this.searchTrailer.rowguid));
      aParamList.push(aParam);
    }

    if (this.selectedProd != null) {
      let productTypeIds: string[] =
        this.selectedProd != null
          ? this.selectedProd.map((a) => {
              return a.rowguid;
            })
          : [];
      aParam = [];
      for (let p = 0; p <= productTypeIds.length - 1; p++) {
        aParam.push(
          new SQLParamArray(
            "guProductTypeId",
            productTypeIds[p].toString(),
            SQLParamArray.enSQLOperator.Equals,
            SQLParamArray.enSQLLogicalOperator.AND,
            null,
            "",
            SQLParamArray.enSQLStatementType.SubQuerySQL,
            2
          )
        );
      }
      aParamList.push(aParam);
      //this.sqlParamService.addParameter(this.aQuoteParamList, productTypeIds, "guProductTypeId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    }

    this.showTable = false;
    this.showSpinner = true;

    if (criteriaSelected == false) {
      this.alertService.openSnackBar(
        "Please select some search crieteria before searching",
        "Close",
        "center",
        "bottom",
        "centerSnackbar",
        4000
      );
      this.showSpinner = false;
      return;
    }

    console.log("params: ", aParamList);

    this.totalTonne = 0;

    this.dtoLoadingDocketService
      .getdtoLoadingDocketParamArray(aParamList)
      .subscribe(
        (loadDockets) => {
          //console.log("loadingDockets", loadDockets);

          if (scheduled == false) {
            this.loadingDockets.data = loadDockets.sort((a, b) => {
              if (a.txtCustomerLocation != null && b.txtCustomerLocation) {
                return String(a.txtCustomerLocation).substr(
                  0,
                  a.txtCustomerLocation.indexOf("-")
                ) <
                  String(b.txtCustomerLocation).substr(
                    0,
                    b.txtCustomerLocation.indexOf("-")
                  ) || a.txtOrderNo < b.txtOrderNo
                  ? -1
                  : 1;
              }
            });
          }

          //console.log("search type: ", searchType);

          if (searchType == "dispatched") {
            this.loadingDockets.data = loadDockets.sort((a, b) => {
              if (a.dteDateDispatched != null) {
                return a.dteDateDispatched < b.dteDateDispatched ? 1 : -1;
              } else {
                if (a.dteExpectedLoadingDate != null) {
                  return a.dteExpectedLoadingDate < b.dteExpectedLoadingDate
                    ? 1
                    : -1;
                }
              }
            });
          }

          if (scheduled == true) {
            this.loadingDockets.data = loadDockets;
            this.loadingDockets.data = loadDockets.sort((a, b) => {
              return a.txtHaulingCompanyDriverFirstName != null
                ? a.txtHaulingCompanyDriverFirstName.localeCompare(
                    b.txtHaulingCompanyDriverFirstName
                  )
                : false ||
                    (a.dteExpectedDeliveryDate > b.dteExpectedDeliveryDate
                      ? 1
                      : -1);
            });
          }

          if (searchType == "complete") {
          }

          loadDockets.forEach((ld) => {
            this.totalTonne += ld.fltActualTonne;
          });
          this.totalTonne = Number(this.totalTonne.toFixed(1));

          this.showTable = true;
          this.tabLoadingDockets.selectedIndex = 0;
          this.showSpinner = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  async editLoadingDocket(loadingDocket: dtoLoadingDocket) {
    this.loadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );

    this.tabLoadingDockets.selectedIndex =
      this.eLoadingDocketTabs.UpdateLoadingDocket;
  }

  async removeLoadingDocket(loadingDocket: dtoLoadingDocket) {
    //Check if delivery dockets are attached.
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guLoadingDocketId", loadingDocket.rowguid));
    aParamList.push(aParam);

    let loadingDeliveryDockets: dtoLoadingDocketDelivery[] =
      await this.dtoLoadingDocketOrderItemService.getdtoLoadingDocketDeliveryParamArrayPromise(
        aParamList
      );

    if (loadingDeliveryDockets != null && loadingDeliveryDockets.length > 0) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Remove Loading Docket",
        "There are delivery dockets attached to this Loading Docket.\nPlease remove the delivery dockets before removing this Loading Docket",
        "Ok",
        "",
        "center",
        "bottom",
        "",
        0,
        false
      );
      return;
    }

    let loadDocketRemoved: boolean =
      await this.loadingDocketService.deleteLoadingDocketPromise(
        loadingDocket.rowguid
      );
    if (loadDocketRemoved == true) {
      this.alertService.openSnackBarDefault(
        "The loading docket has been removed"
      );
    }

    for (let i = this.loadingDockets.data.length - 1; i >= 0; i--) {
      //console.log("remove loading docket", this.loadingDockets.data[i].intDocketNo);
      if (this.loadingDockets.data[i].rowguid == loadingDocket.rowguid) {
        this.loadingDockets.data.splice(i, 1);
        this.loadingDockets.data = this.loadingDockets.data;
        break;
      }
    }
  }

  updatedLoadingDocket(loadingDocket: dtoLoadingDocket) {}

  async loadingDocketCreated(loadingDocketCreatedObj: [LoadingDocket, string]) {
    if (loadingDocketCreatedObj == null) {
      return;
    }

    if (loadingDocketCreatedObj[1] == "create") {
      this.tabLoadingDockets.selectedIndex =
        this.eLoadingDocketTabs.LoadingDockets;
      this.form.controls["txtSearchLoadingDocketNo"].setValue(
        loadingDocketCreatedObj[0].intDocketNo.toString()
      );
      this.searchLoadingDockets("");
    }

    //if (loadingDocketCreatedObj[1] == "createanditem") {
    //  this.loadingDocketAdd = await this.dtoLoadingDocketService.getdtoLoadingDocketPromise(loadingDocketCreatedObj[0].rowguid);
    //  this.tabLoadingDockets.selectedIndex = this.eLoadingDocketTabs.LoadingDockets;
    //  this.form.controls['txtSearchFinalNumber'].setValue(loadingDocketCreatedObj[0].intFinalNo);
    //  console.log("before - this.autoAdd: " + this.autoAdd);
    //  await this.searchLoadingDockets();
    //  console.log("after - this.autoAdd: " + this.autoAdd);

    //  this.appLoadingDocketList.addLoadingDocketItem(this.loadingDocketAdd);

    //}
  }

  async loadingDocketUpdated(loadingDocket: LoadingDocket) {
    if (loadingDocket == null) {
      return;
    }

    this.tabLoadingDockets.selectedIndex =
      this.eLoadingDocketTabs.LoadingDockets;

    this.form.controls["txtSearchLoadingDocketNo"].setValue(
      loadingDocket.intDocketNo
    );

    this.searchLoadingDockets("");
  }

  finalInspectionCancelled() {
    this.tabLoadingDockets.selectedIndex =
      this.eLoadingDocketTabs.LoadingDockets;
  }

  //updateLoadingDocket(loadingDocket: dtoLoadingDocket, eStatus: LoadingDocket.enLoadingStatus, index: number) {

  //  console.log(loadingDocket);

  //  this.loadingDocketService.getLoadingDocket(loadingDocket.intDocketNo).subscribe(dd => {

  //    dd.intStatus = eStatus;
  //    this.loadingDocketService.updateLoadingDocket(dd).subscribe(ddUpdated => {

  //      this.loadingDockets.splice(index, 1);
  //      //this.tblLoadingDocket.renderRows();

  //    }, err => {
  //      console.log(err);

  //    });

  //  }, err => {
  //    console.log(err);
  //  });
  //}

  loadingDocketItemsAdded(added: boolean) {}

  loadingDocketCancelled(e) {
    this.tabLoadingDockets.selectedIndex =
      this.eLoadingDocketTabs.LoadingDockets;
  }

  addLoadingDocketItems(loadingDocket: dtoLoadingDocket) {
    this.loadingDocketAddItem = loadingDocket;

    this.tabLoadingDockets.selectedIndex =
      this.eLoadingDocketTabs.DocketAddItems;
  }

  async openEquipmentDialog() {
    if (this.truckTrailerSelectionDialogRef != null) {
      this.truckTrailerSelectionDialogRef.close();
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("txtName", "Trailer"));
    aParamList.push(aParam);

    let mobilePlantCategories: MobilePlantCategory[] =
      await this.mobilePlantCategoryService.getMobilePlantCategoryParamArrayPromise(
        aParamList
      );
    let categoryId: string = "";

    if (mobilePlantCategories != null && mobilePlantCategories.length > 0) {
      categoryId = mobilePlantCategories[0].rowguid;
    }

    //let trailerId: string = null;
    //if (this.searchTrailer != null) {
    //  trailerId = this.searchTrailer.rowguid;
    //}

    this.truckTrailerSelectionDialogRef = this.dialog.open(
      TruckTrailerSelectionDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
        data: {
          equipmentCategoryId: categoryId,
          haulingCompany: this.searchHaulingCompany,
          haulingCompanyDriver: this.searchDriver,
          trailerEquipment: this.searchTrailer,
          independentSelection: true,
        },
      }
    );

    this.truckTrailerSelectionDialogRef.backdropClick().subscribe(() => {
      this.truckTrailerSelectionDialogRef.close();
    });

    this.truckTrailerSelectionDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          haulingCompany: HaulingCompany;
          haulingCompanyDriver: HaulingCompanyDriver;
          trailerEquipment: MobilePlantEquipment;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            console.log("data.trailerEquipment: " + data.trailerEquipment);

            this.searchHaulingCompany = data.haulingCompany;
            this.searchDriver = data.haulingCompanyDriver;
            this.searchTrailer = data.trailerEquipment;
          } else {
            // We have cancellled
          }
        }
      );
  }

  clearTruckSelection() {
    this.searchHaulingCompany = null;
  }

  clearTrailerSelection() {
    this.searchTrailer = null;
  }

  clearDriverSelection() {
    this.searchDriver = null;
  }

  async setDispatchedDate(frequency: number, frequencyType: string) {
    var someDate = new Date();
    let numberOfDaysToAdd: number;

    if (frequencyType == "w") {
      numberOfDaysToAdd = frequency * 7;
      var result = someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      this.form.controls["dteSearchDispatchedDateFrom"].setValue(
        new Date(result)
      );
      this.form.controls["dteSearchDispatchedDateTo"].setValue(
        new Date(Date.now())
      );
    }

    if (frequencyType == "m") {
      numberOfDaysToAdd = frequency * 4 * 7;
      var result = someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      this.form.controls["dteSearchDispatchedDateFrom"].setValue(
        new Date(result)
      );
      this.form.controls["dteSearchDispatchedDateTo"].setValue(
        new Date(Date.now())
      );
    }

    console.log(new Date(result));
  }

  clearDispatchDateSelection() {
    this.form.controls["dteSearchDispatchedDateFrom"].setValue("");
    this.form.controls["dteSearchDispatchedDateTo"].setValue("");
    this.form.controls["txtDateSelection"].setValue([]);
  }

  clearTruckTrailerDriver() {
    this.clearTruckSelection();
    this.clearTrailerSelection();
    this.clearDriverSelection();
  }

  async searchDispatchedLoadingDockets() {
    if (
      (this.form.controls["dteSearchDispatchedDateFrom"].value == null ||
        this.form.controls["dteSearchDispatchedDateFrom"].value == "") &&
      (this.form.controls["dteSearchDispatchedDateTo"].value == null ||
        this.form.controls["dteSearchDispatchedDateTo"].value == "")
    ) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Despatch Dates",
        "Please select at least one dispatch date",
        "Ok"
      );
      return;
    }

    this.searchLoadingDockets("dispatched");
  }
}
