import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  animateChild,
} from "@angular/animations";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { dtoOrderItem } from "../../../_shared/business-objects/dto-order-item.bo";
import { dtoOrders } from "../../../_shared/business-objects/dto-orders.bo";
import { TruncateStringPipe } from "../../../../pipes/truncateString.pipe";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomerDeliveryAddressDialogComponent } from "../../../customer-delivery-address/customer-delivery-address-dialog/customer-delivery-address-dialog.component";
import { AlertService } from "../../../_shared/services/alert.service";
import { SQLParamArray } from "../../../_shared/business-objects/sql-param-array";
import { dtoCustomerDeliveryAddress } from "../../../_shared/business-objects/dto-customer-delivery-address.bo";
import { dtoCustomerDeliveryAddressService } from "../../../_shared/services/dto-customer-delivery-address.service";
import { ClientDeliveryAddress } from "../../../_shared/business-objects/client-delivery-address.bo";
import { OrdersService } from "../../../_shared/services/orders.service";
import { Orders } from "../../../_shared/business-objects/orders.bo";
import { dtoOrdersService } from "../../../_shared/services/dto-orders.service";
import { SharedService } from "../../../_shared/services/shared.service";
import { dtoOrderItemService } from "../../../_shared/services/dto-order-item.service";
import { AppPreferenceService } from "../../../_shared/services/app-preference.service";
import { AppUserPreferenceService } from "../../../_shared/services/app-user-preference.service";
import { AppPreference } from "../../../_shared/business-objects/app-preference.bo";
import { AppUserPreference } from "../../../_shared/business-objects/app-user-preference.bo";

@Component({
  selector: "app-quote-list",
  templateUrl: "./quote-list.component.html",
  styleUrl: "./quote-list.component.scss",
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", [
        animate("425ms cubic-bezier(0.4, 0.0, 0.2, 1)"), //225
        query("@*", animateChild(), { optional: true }),
      ]),
    ]),
  ],
})
export class QuoteListComponent implements OnInit {
  @Input() datasource: MatTableDataSource<dtoOrders>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  @ViewChild("tblQuotes", { static: false }) tblQuotes: MatTable<dtoOrders>;

  showTick: boolean;
  showInlineSpinner: boolean;
  showTOCs: boolean;
  quotePDFId: boolean;
  loadedComponent: boolean;

  quoteExpanded: dtoOrders;
  quoteItems: dtoOrderItem[];

  orderStatusComplete: Orders.enStatus.Complete = Orders.enStatus.Complete;
  orderStatusActive: Orders.enStatus.Active = Orders.enStatus.Active;

  selectedRow: string = "";

  viewType: number;

  customerDeliveryAddressDialogRef: MatDialogRef<CustomerDeliveryAddressDialogComponent>;

  selectedDueDate: string = "";
  selectedAskForSaleReminderDate: string = "";

  //displayedColumns: string[] = ['txtCustomerName', 'txtCustomerContactName', 'txtCustomerContactPhone', 'txtCustomerContactMobile', 'txtDeliveryAddress', 'txtDeliveryTown', 'btnEditDeliveryAddress', 'txtQuoteNo', 'txtOrderNo', 'dteCreated', 'dteDueDate', 'btnEditDueDate', 'txtAvailabilityNo', 'txtAvailabilityUnit', 'txtAvailability', 'txtPaymentType', 'txtInvoiceReply', 'txtComments', 'txtStatus', 'txtQuotedBy', 'dteDateEntered', 'txtEnteredBy', 'dteCompleted', 'dteLastEmailed', 'txtInternalComments', 'blnAskForSale', 'dteAskForSaleReminder', 'btnEditAskForSaleReminder', 'txtAskForReason', 'txtAskForReasonComments', 'fltAskForReasonPercentage', 'blnOptional', 'txtAdminNotes', 'fltM3', 'fltTonne', 'btnSetAsComplete', 'btnConvertToOrder', 'btnEditQuote', 'btnRemoveQuote', 'btnPrintQuote', 'btnEmailQuote', 'btnViewQuoteItems'];
  displayedColumns: string[] = [
    "txtCustomerName",
    "txtCustomerContactName",
    "txtCustomerContactPhone",
    "txtCustomerContactMobile",
    "txtDeliveryAddress",
    "txtDeliveryTown",
    "btnEditDeliveryAddress",
    "txtQuoteNo",
    "txtOrderNo",
    "dteCreated",
    "dteDueDate",
    "btnEditDueDate",
    "txtAvailabilityNo",
    "txtAvailabilityUnit",
    "txtAvailability",
    "txtPaymentType",
    "txtInvoiceReply",
    "txtComments",
    "txtStatus",
    "txtQuotedBy",
    "dteDateEntered",
    "txtEnteredBy",
    "dteCompleted",
    "dteLastEmailed",
    "txtInternalComments",
    "blnAskForSale",
    "dteAskForSaleReminder",
    "btnEditAskForSaleReminder",
    "txtAskForReason",
    "txtAskForReasonComments",
    "fltAskForReasonPercentage",
    "blnOptional",
    "txtAdminNotes",
    "fltM3",
    "fltTonne",
    "btnSetAsComplete",
    "btnConvertToOrder",
    "btnEditQuote",
    "btnRemoveQuote",
    "btnPrintQuote",
    "btnEmailQuote",
    "btnViewQuoteItems",
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService,
    private dialog: MatDialog,
    private ordersService: OrdersService,
    private dtoOrdersService: dtoOrdersService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef,
    private dtoQuoteItemService: dtoOrderItemService,
    private appPreferenceService: AppPreferenceService,
    private appUserPreferenceService: AppUserPreferenceService
  ) {}

  ngOnInit(): void {
    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }

    if (this.columnBarPage == null || this.columnBarPage == "") {
      this.columnBarPage = "Quotes";
    }

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "quoteContainer";
    }

    //console.log("columnBarPage: ", this.columnBarPage);
    this.getAppPreferences();

    this.loadedComponent = true;
    this.viewType = 0;
  }

  async getAppPreferences() {
    let appPreference: AppPreference =
      await this.appPreferenceService.getAppPreferenceByName(
        "QuoteListViewType"
      );
    if (appPreference == null) {
      //Create New App Preference.
      appPreference = await this.appPreferenceService.createAppPreferenceByName(
        "QuoteListViewType"
      );
    }

    if (appPreference == null) {
      //Something went wrong
      this.alertService.openSnackBarCustomDefaultPromise(
        "No App User Preference",
        "There is no app user preference",
        "Ok"
      );
      return;
    }

    let appUserPreference: AppUserPreference =
      await this.appUserPreferenceService.getAppUserPreferenceByPreferenceId(
        appPreference.rowguid
      );
    if (appUserPreference == null) {
      appUserPreference =
        await this.appUserPreferenceService.createAppUserPreferenceByPreferenceId(
          appPreference.rowguid
        );
      appUserPreference.intValue = 0;
      appUserPreference =
        await this.appUserPreferenceService.updateAppUserPreferencePromise(
          appUserPreference
        );
    }

    if (appUserPreference != null) {
      this.viewType = appUserPreference.intValue;
      return appUserPreference;
    }
  }

  updatedComponentColumnsEvent(columns: string[]) {
    //this.displayedColumns = columns;
    console.log("app user colums");
    //this.ref.detectChanges();
  }

  async changeView(quote: dtoOrders, type: number) {
    let appUserPreference: AppUserPreference = await this.getAppPreferences();

    appUserPreference.intValue = type;
    await this.appUserPreferenceService.updateAppUserPreferencePromise(
      appUserPreference
    );

    this.viewType = type;
  }

  async setAsComplete(quote: dtoOrders) {
    let quoteUpdate: Orders = await this.ordersService.getOrdersPromise(
      quote.rowguid
    );
    if (quoteUpdate != null) {
      quoteUpdate.intStatus = Orders.enStatus.Complete;
      await this.ordersService.updateOrdersPromise(quoteUpdate);
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Complete Quote",
        "The quote has been set to Compete"
      );
    }
  }

  async setAsActive(quote: dtoOrders) {
    let quoteUpdate: Orders = await this.ordersService.getOrdersPromise(
      quote.rowguid
    );
    if (quoteUpdate != null) {
      quoteUpdate.intStatus = Orders.enStatus.Active;
      await this.ordersService.updateOrdersPromise(quoteUpdate);
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Complete Quote",
        "The quote has been set to Compete"
      );
    }
  }

  convertToOrder(quote: dtoOrders) {}

  editQuote(quote: dtoOrders) {}

  removeQuote(quote: dtoOrders) {}

  printQuote(quote: dtoOrders) {}

  emailQuote(quote: dtoOrders, emailButtonTD: HTMLTableDataCellElement) {}

  openDropbox(quote: dtoOrders) {}

  async expandElement(quote: dtoOrders, index: number) {
    this.quoteExpanded = this.quoteExpanded === quote ? null : quote;

    if (this.quoteExpanded != null) {
      await this.showQuoteItems(quote.rowguid);
    }
  }

  expandElementAndSetColor(quote: dtoOrders, idx: number) {
    //let log = document.getElementById("consoleOutput");

    //log.style.zIndex = "100000";
    //log.style.display = "absoulte";
    //log.style.top = "500px";
    //log.style.left = "100px";

    //log.innerHTML = "ev: \n" + JSON.stringify(quote);

    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(quote.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(quote, idx);
      this.selectedRow = quote.rowguid; //Always highlight row when double clicking.

      //window.alert('ondouble');
    }
  }

  // touchstart handler
  process_touchstart(ev) {
    let log = document.getElementById("consoleOutput");
    log.innerHTML = "ev: \n" + JSON.stringify(ev);
    console.log("ev: ", ev);
    // Use the event's data to call out to the appropriate gesture handlers
    switch (ev.touches.length) {
      case 1:
        this.handle_one_touch(ev);
        break;
      case 2:
        this.handle_two_touches(ev);
        break;
      case 3:
        this.handle_three_touches(ev);
        break;
      default:
        this.gesture_not_supported(ev);
        break;
    }
  }

  handle_one_touch(ev) {
    console.log("one touch", ev);
  }

  handle_two_touches(ev) {
    console.log("two touch", ev);
  }

  handle_three_touches(ev) {
    console.log("three touch", ev);
  }

  gesture_not_supported(ev) {
    console.log("not supported touch", ev);
  }

  setRowColor(deliveryDocketId: string) {
    if (
      String(this.selectedDueDate) != "" ||
      String(this.selectedAskForSaleReminderDate) != ""
    ) {
      return;
    }

    if (this.selectedRow == "" || deliveryDocketId != this.selectedRow) {
      this.selectedRow = deliveryDocketId;
      return;
    }

    if (deliveryDocketId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }
  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  setCommentsAttribute(div: HTMLDivElement, comments: string) {
    if (comments == null || comments.length < 50) {
      return;
    }

    let ariaExpanded = div.getAttribute("aria-expanded");
    console.log(ariaExpanded);

    if (ariaExpanded == "true" || ariaExpanded == null) {
      div.setAttribute("aria-expanded", "false");
    }

    if (ariaExpanded == "false") {
      div.setAttribute("aria-expanded", "true");
    }

    console.log(ariaExpanded);
  }

  getCommentsAttribute(div: HTMLDivElement, comments: string) {
    let ariaExpanded = div.getAttribute("aria-expanded");

    if (ariaExpanded == "true" || ariaExpanded == null) {
      return comments;
    }

    if (ariaExpanded == "false") {
      div.setAttribute("aria-expanded", "true");
      //let domSan: DomSanitizer;
      let trunPipe: TruncateStringPipe = new TruncateStringPipe(this.sanitizer);
      return trunPipe.transform(comments, 50);
    }
  }

  async openDeliveryAddressDialog(quote: dtoOrders) {
    this.rowColorPropagation(quote.rowguid);

    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (quote == null) {
      this.alertService.openSnackBarDefault("There is no quote selected.");
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("guOrderId", quote.rowguid));
    aParamList.push(aParam);

    let dtoDelAddress: dtoCustomerDeliveryAddress = null;
    dtoDelAddress =
      await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressPromise(
        quote.guClientDelAddressId
      );

    this.customerDeliveryAddressDialogRef = this.dialog.open(
      CustomerDeliveryAddressDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
        data: {
          customerId: quote.guCustomerId,
          customerName: quote.txtCustomerName,
          selectedCustomerDeliveryAddress: dtoDelAddress,
        },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.customerDeliveryAddressDialogRef.backdropClick().subscribe(() => {
      this.customerDeliveryAddressDialogRef.close();
    });

    this.customerDeliveryAddressDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          customerDeliveryAddress: ClientDeliveryAddress;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            if (data.customerDeliveryAddress == null) {
              this.alertService.openSnackBar(
                "There is no delivery address selected.",
                "Close",
                "center",
                "bottom",
                "",
                5000
              );
              return;
            }

            if (data.customerDeliveryAddress != null) {
              let quoteUpdate: Orders =
                await this.ordersService.getOrdersPromise(quote.rowguid);
              if (quoteUpdate != null) {
                quoteUpdate.guClientDelAddressId =
                  data.customerDeliveryAddress.rowguid;
                await this.ordersService.updateOrdersPromise(quoteUpdate);
              }

              // Get updated delivery docket.
              let dtoQuote: dtoOrders =
                await this.dtoOrdersService.getdtoOrdersPromise(quote.rowguid);
              if (dtoQuote != null) {
                for (let i = 0; i <= this.datasource.data.length - 1; i++) {
                  //console.log("update del docket: ");
                  if (this.datasource.data[i].rowguid == dtoQuote.rowguid) {
                    this.datasource.data[i] = dtoQuote;
                    this.tblQuotes.renderRows();
                    break;
                  }
                }
              }

              //for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {

              //}

              this.alertService.openSnackBarDefault(
                "Delivery address has been updated."
              );
            }
          } else {
            // We have cancellled
          }
        }
      );
  }

  openDueDateSelector(quote: dtoOrders, index: number) {
    let selectorDiv: HTMLDivElement = document.getElementById(
      "dueDateSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "due-date-selector-location" + index
    ) as HTMLDivElement;

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 210;
    var left = offsets.left + window.scrollX;

    selectorDiv.style.top = top.toString() + "px";
    selectorDiv.style.left = left.toString() + "px";

    if (
      this.selectedDueDate == "" ||
      this.selectedDueDate == null ||
      quote.rowguid != this.selectedDueDate
    ) {
      this.selectedDueDate = quote.rowguid;
      this.selectedAskForSaleReminderDate = "";
      //this.selectedExpectedDeliveryDate = "";

      this.selectedRow = quote.rowguid;
      return;
    }

    if (this.selectedDueDate != "") {
      this.selectedDueDate = "";
      window.event.stopPropagation();
      return;
    }
  }

  async setDueDate(dtoQuote: dtoOrders, dueDate: Date) {
    let d: Date = new Date();
    console.log("d: ", d);
    d.setHours(0, 0, 0, 0);

    dueDate = this.sharedService.convertDateAEST(dueDate);

    if (dueDate != null && dueDate < d) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Past Date",
        "The date is in the past is this correct?",
        "Yes",
        "No",
        "center",
        "bottom"
      );
      if (msg != "Yes") {
        return;
      }
    }

    let quote: Orders = await this.ordersService.getOrdersPromise(
      dtoQuote.rowguid
    );
    if (quote == null) {
      return;
    }

    quote.dteDueDate = dueDate;

    await this.ordersService.updateOrdersPromise(quote);

    dtoQuote = await this.dtoOrdersService.getdtoOrdersPromise(quote.rowguid);

    if (this.datasource.data != null) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == dtoQuote.rowguid) {
          this.datasource.data[i] = dtoQuote;
          //console.log("setexpectedLoadingDate: ", this.datasource.data[i]);
          this.tblQuotes.renderRows();
          break;
        }
      }
    }

    this.alertService.openSnackBarDefault("The due date has been updated");

    console.log("dueDate: " + dueDate);

    this.selectedDueDate = "";
  }

  closeDueDate() {
    this.selectedDueDate = "";
  }

  openAskForSaleReminderSelector(quote: dtoOrders, index: number) {
    let selectorDiv: HTMLDivElement = document.getElementById(
      "askForSaleReminderSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "ask-for-sale-reminder-selector-location" + index
    ) as HTMLDivElement;

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 210;
    var left = offsets.left + window.scrollX;

    selectorDiv.style.top = top.toString() + "px";
    selectorDiv.style.left = left.toString() + "px";

    if (
      this.selectedAskForSaleReminderDate == "" ||
      this.selectedAskForSaleReminderDate == null ||
      quote.rowguid != this.selectedAskForSaleReminderDate
    ) {
      this.selectedAskForSaleReminderDate = quote.rowguid;
      this.selectedDueDate = "";
      //this.selectedExpectedDeliveryDate = "";

      this.selectedRow = quote.rowguid;
      return;
    }

    if (this.selectedAskForSaleReminderDate != "") {
      this.selectedAskForSaleReminderDate = "";
      window.event.stopPropagation();
      return;
    }
  }

  async setAskForSaleReminder(
    dtoQuote: dtoOrders,
    askForSaleReminderDate: Date
  ) {
    let d: Date = new Date();
    d.setHours(0, 0, 0, 0);

    askForSaleReminderDate = this.sharedService.convertDateAEST(
      askForSaleReminderDate
    );

    if (askForSaleReminderDate != null && askForSaleReminderDate < d) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Past Date",
        "The date is in the past is this correct?",
        "Yes",
        "No",
        "center",
        "bottom"
      );
      if (msg != "Yes") {
        return;
      }
    }

    let quote: Orders = await this.ordersService.getOrdersPromise(
      dtoQuote.rowguid
    );
    if (quote == null) {
      return;
    }

    quote.dteAskForSaleReminder = askForSaleReminderDate;

    await this.ordersService.updateOrdersPromise(quote);

    dtoQuote = await this.dtoOrdersService.getdtoOrdersPromise(quote.rowguid);

    if (this.datasource.data != null) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == dtoQuote.rowguid) {
          this.datasource.data[i] = dtoQuote;
          //console.log("setexpectedLoadingDate: ", this.datasource.data[i]);
          this.tblQuotes.renderRows();
          break;
        }
      }
    }

    this.alertService.openSnackBarDefault(
      "The ask for sale reminder date has been updated"
    );

    this.selectedAskForSaleReminderDate = "";
  }

  closeAskForSaleReminder() {
    this.selectedAskForSaleReminderDate = "";
  }

  async showQuoteItems(quoteId: string) {
    if (quoteId == null || quoteId == "") {
      this.alertService.openSnackBarDefault(
        "There is no quote to view quote items"
      );
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", quoteId));
    aParamList.push(aParam);

    this.quoteItems =
      await this.dtoQuoteItemService.getdtoOrderItemParamArrayPromise(
        aParamList
      );

    console.log("quoteItems: ", this.quoteItems);
  }
}
