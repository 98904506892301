import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "../account/auth.service";
import { PrinterSelectionComponent } from "../utilities/printers/printer-selection/printer-selection.component";
import { dtoStockItemDisplay } from "../_shared/business-objects/dto-stock-item-display.bo";
import { dtoStockItem } from "../_shared/business-objects/dto-stock-item.bo";
import { AlertService } from "../_shared/services/alert.service";
import { dtoAppPrinterService } from "../_shared/services/dto-app-printer.service";
import { BoardTallyRecordService } from "../_shared/services/board-tally-record.service";
import { FormService } from "../_shared/services/form.service";
import { SharedService } from "../_shared/services/shared.service";
import { StockContainerService } from "../_shared/services/stock-container.service";
import { dtoAppPrinter } from "../_shared/business-objects/dto-app-printer.bo";
import { Printer } from "../_shared/business-objects/printer.bo";
import { HttpClient } from "@angular/common/http";
import { PrinterService } from "../_shared/services/printer.service";
import { FileService } from "../_shared/services/app-services/file.service";
import { PhotoViewerDialogComponent } from "../utilities/photo-viewer-dialog/photo-viewer-dialog.component";
import { StockItem } from "../fetch-data/fetch-data.component";
import {
  animate,
  animateChild,
  query,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { dtoBoardTallyRecordHistoryService } from "../_shared/services/dto-board-tally-record-history.service";
import { dtoBoardTallyRecordHistory } from "../_shared/business-objects/dto-board-tally-record-history.bo";
import { BoardTallyRecordHistoryListComponent } from "../board-tally-record-history/board-tally-record-history-list/board-tally-record-history-list.component";
import { StockItemBarcode } from "../_shared/app-objects/StockItemBarcode";
import { PrintService } from "../_shared/services/app-services/print.service";
import { dtoStockItemService } from "../_shared/services/dto-stock-item.service";
import { UtilityService } from "../_shared/services/app-services/utility.service";
import { dtoAppUserPrinterDefault } from "../_shared/business-objects/dto-app-user-printer-default.bo";
import { AppUserPrinterDefaultService } from "../_shared/services/app-user-printer-default.service";
import { UserPositionService } from "../_shared/services/user-position.service";
import { BoardTallyRecord } from "../_shared/business-objects/board-tally-record.bo";
import { BoardTallyRecordHistory } from "../_shared/business-objects/board-tally-record-history.bo";
import { FileAttachment } from "../_shared/app-objects/file-attachment";
import { BoardTallyRecordPhotoService } from "../_shared/services/board-tally-record-photo.service";
import { BoardTallyRecordPhoto } from "../_shared/business-objects/board-tally-record-photo.bo";
import { SQLParamArray } from "../_shared/business-objects/sql-param-array";

enum enStockItemColumns {
  "txtProductName",
  "txtProfileName",
  "intWidth",
  "intThickness",
  "txtSpeciesName",
  "txtGradeName",
  "fltQuantity",
  "txtQuantityUoM",
  "fltLength",
  "fltDia",
  "txtLocation",
  "intIdentifier",
  "dteDateCreated",
  "txtTradePrice",
  "txtRetailPrice",
  "txtApprovedPrice",
  "txtStockKeepingUnit",
  "chkReceiveStock",
}

@Component({
  selector: "app-stock-item-list",
  templateUrl: "./stock-item-list.component.html",
  styleUrls: ["./stock-item-list.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", [
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"), //225
        query("@*", animateChild(), { optional: true }),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class StockItemListComponent implements OnInit, AfterViewInit {
  //@Input() data: dtoStockItem[];
  //@Input() datasource: MatTableDataSource<dtoStockItem>;
  @Input() datasource: MatTableDataSource<dtoStockItemDisplay>;
  @Output() editStockItemEvent = new EventEmitter<dtoStockItemDisplay>();
  @Output() addStockItemEvent = new EventEmitter<dtoStockItemDisplay>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("boardTallyRecordHistoryApp")
  boardTallyRecordHistoryApp: BoardTallyRecordHistoryListComponent;

  stockItemExpanded: dtoStockItemDisplay;
  boardTallyRecordHistory: MatTableDataSource<dtoBoardTallyRecordHistory>;
  printerSelectionDialogRef: MatDialogRef<PrinterSelectionComponent>;
  currentHistoryAll: dtoBoardTallyRecordHistory[];
  currentHistorySpliced: dtoBoardTallyRecordHistory[];
  boardPlantPrinter2: Printer;

  selectedRow: string = "";
  bpFloor: boolean = false;
  officeAdmin: boolean = false;
  salesStaff: boolean = false;
  coffsSalesStaff: boolean = false;

  myDefaultMobilePrinter: dtoAppUserPrinterDefault;

  deletedPhotoSub = this.fileService.deletedPhotosEvent;
  photoSub = this.fileService.deletedAllPhotosEvent;
  viewPhotoSub = this.fileService.viewPhotosEvent;
  viewThumbnailPhotoSub = this.fileService.viewThumbnailPhotosEvent;

  selectedStockItem: dtoStockItemDisplay;

  displayedStockItems: string[] = [
    "txtProductName",
    "txtProfileName",
    "intWidth",
    "intThickness",
    "txtSpeciesName",
    "txtGradeName",
    "fltQuantity",
    "txtQuantityUoM",
    "intTotalLM",
    "fltDia",
    "txtLocation",
    "intIdentifier",
    "dteDateCreated",
    "txtTradePrice",
    "txtRetailPrice",
    "txtApprovedPrice",
    "txtStockKeepingUnit",
    "blnSLOBStock",
    "blnDamaged",
    "btnEditStockItem",
    "btnAddStockItem",
    "btnTakePhoto",
    "btnViewPhotos",
    "btnTakeThumbnailPhoto",
    "btnViewThumbnailPhotos",
    "btnViewStockItemHistory",
  ];

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private stockContainerService: StockContainerService,
    private boardTallyRecordService: BoardTallyRecordService,
    private sharedService: SharedService,
    private formService: FormService,
    private printerService: PrinterService,
    private printService: PrintService,
    private dtoAppPrinterService: dtoAppPrinterService,
    private http: HttpClient,
    private fileService: FileService,
    private dialog: MatDialog,
    private dtoBoardTallyRecordHistoryService: dtoBoardTallyRecordHistoryService,
    private dtoStockItemService: dtoStockItemService,
    private utils: UtilityService,
    private appUserPrinterDefault: AppUserPrinterDefaultService,
    private userPositionService: UserPositionService,
    private boardTallyRecordPhotoService: BoardTallyRecordPhotoService
  ) {}

  ngOnInit(): void {
    // Take out unwanted columns. Perhaps come up with a user defined visible columns state stored in database.
    //this.displayedStockItems.splice(enStockItemColumns.txtStockKeepingUnit, 1);
    //this.displayedStockItems.splice(enStockItemColumns.dteDateCreated, 1);
    //test saving

    this.loadColumns();

    //if (this.authService.getAppUserId().toUpperCase() == "E6041C0E-162B-4B18-A838-2F29FFC5C242" || this.authService.getAppUserId().toUpperCase() == "E604130F-022C-4C68-A886-4BE037F8B6A9" || this.authService.getAppUserId().toUpperCase() == "E30C020F-0416-4921-86B2-F33B4ECE86FF") {  // lawson@coffshardwoods.com.au or office@coffshardwoods.com.au or martin@coffshardwoods.com.au or bporders@coffshardwoods.com.au - web app users
    //  this.displayedStockItems = ['txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'intTotalLM', 'fltDia', 'txtLocation', 'intIdentifier', 'dteDateCreated', 'txtTradePrice', 'txtRetailPrice', 'txtApprovedPrice', 'txtStockKeepingUnit', 'btnEditStockItem', 'btnAddStockItem', 'btnPrintSKUBarcode', 'btnPrintSKUBarcodeSmall', 'btnPrintSKUBarcodeExtraLarge', 'btnPrintSKUBarcodeExtraLargeWithQty', 'btnTakePhoto', 'btnViewPhotos', 'btnViewStockItemHistory'];
    //}

    //if (this.authService.getAppUserId().toUpperCase() == "E80B0109-1900-46C3-B72C-79F4BE09B4D9" || this.authService.getAppUserId().toUpperCase() == "E8021A0D-292D-49ED-93A4-79C569327FCB") {  // bporders@coffshardwoods.com.au || will@coffshardwoods.com.au - web app users
    //  this.displayedStockItems = ['txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'intTotalLM', 'fltDia', 'txtLocation', 'intIdentifier', 'dteDateCreated', 'txtStockKeepingUnit', 'btnEditStockItem', 'btnAddStockItem', 'btnPrintSKUBarcodeExtraLarge', 'btnPrintSKUBarcodeExtraLargeWithQty', 'btnTakePhoto', 'btnViewPhotos', 'btnViewStockItemHistory'];
    //}

    this.getBoardPlantPrinter2();
    this.getMyDefaultMobilePrinter();

    //console.log("subscribing to default printer");

    this.appUserPrinterDefault.setDefaultPrinter.subscribe((appUserPrinter) => {
      this.getMyDefaultMobilePrinter();
      //console.log("appUserPrinter", appUserPrinter);
    });

    //NUMBER OF PHOTOS ON FILE. THIS IS TO MAKE DISPLAYING PHOTOS IN THE PHOTO VIEWER MORE EFFECIENT.
    //I.E SO WE CAN LOAD THE FIRST NUMBER OF PHOTOS EACH CALL AT A TIME.
    this.viewPhotoSub.subscribe(async (fileAttachments) => {
      //console.log("in viewPhotoSub: ", fileAttachments.length);

      await this.updateBoardTallyRecordPhotos(
        this.selectedStockItem,
        fileAttachments,
        false
      );
    });

    this.viewThumbnailPhotoSub.subscribe(async (fileAttachments) => {
      //console.log("in viewThumbnailPhotoSub: ", fileAttachments.length);

      await this.updateBoardTallyRecordPhotos(
        this.selectedStockItem,
        fileAttachments,
        true
      );
    });

    this.photoSub.subscribe(async (val) => {
      //console.log("val: ", val);

      if (val == true) {
        let boardTallyRecord: BoardTallyRecord =
          await this.boardTallyRecordService.getBoardTallyRecordPromise(
            this.selectedStockItem.guBoardTallyRecordId
          );
        boardTallyRecord.blnHasThumbnailImage = false;
        await this.boardTallyRecordService.updateBoardTallyRecordPromise(
          this.authService.getAppUserEmployeeId(),
          false,
          BoardTallyRecordHistory.enChangeReason.Created,
          boardTallyRecord
        );
        //console.log("about to unsubscribes: ", boardTallyRecord);

        //console.log("updated and unsubscribes: ", boardTallyRecord);
      }
    });
  }

  ngOnDestroy() {
    this.photoSub.unsubscribe();
    this.viewPhotoSub.unsubscribe();
    this.viewThumbnailPhotoSub.unsubscribe();
    this.deletedPhotoSub.unsubscribe();
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;

    this.datasource.sort = this.sort;

    //console.log(this.datasource.data);
    //console.log("length: " + this.datasource.data.length);
  }

  async loadPositions() {
    this.bpFloor = await this.userPositionService.isInPosition(
      "board plant floor",
      this.authService.getAppUserEmployeeId()
    );
    this.salesStaff = await this.userPositionService.isInPosition(
      "sales staff",
      this.authService.getAppUserEmployeeId()
    );
    this.coffsSalesStaff = await this.userPositionService.isInPosition(
      "coffs sales staff",
      this.authService.getAppUserEmployeeId()
    );
    this.officeAdmin = await this.userPositionService.isInPosition(
      "office admin",
      this.authService.getAppUserEmployeeId()
    );
  }

  async loadColumns() {
    await this.loadPositions();

    if (this.salesStaff || this.officeAdmin || this.coffsSalesStaff == true) {
      //console.log("salesStaff: ", this.salesStaff);
      //console.log("officeAdmin: ", this.officeAdmin);
      //console.log("coffsSalesStaff: ", this.coffsSalesStaff);

      this.displayedStockItems = [
        "txtProductName",
        "txtProfileName",
        "intWidth",
        "intThickness",
        "txtSpeciesName",
        "txtGradeName",
        "fltQuantity",
        "txtQuantityUoM",
        "intTotalLM",
        "fltDia",
        "txtLocation",
        "intIdentifier",
        "dteDateCreated",
        "txtTradePrice",
        "txtRetailPrice",
        "txtApprovedPrice",
        "txtStockKeepingUnit",
        "blnSLOBStock",
        "blnDamaged",
        "btnEditStockItem",
        "btnAddStockItem",
        "btnPrintSKUBarcode",
        "btnPrintSKUBarcodeSmall",
        "btnPrintSKUBarcodeExtraLarge",
        "btnPrintSKUBarcodeExtraLargeWithQty",
        "btnTakePhoto",
        "btnViewPhotos",
        "btnTakeThumbnailPhoto",
        "btnViewThumbnailPhotos",
        "btnSetAsReadyForPublish",
        "btnSetAsPublished",
        "btnSetAsNotPublished",
        "btnViewStockItemHistory",
      ];
    }

    if (this.bpFloor == true) {
      this.displayedStockItems = [
        "txtProductName",
        "txtProfileName",
        "intWidth",
        "intThickness",
        "txtSpeciesName",
        "txtGradeName",
        "fltQuantity",
        "txtQuantityUoM",
        "intTotalLM",
        "fltDia",
        "txtLocation",
        "intIdentifier",
        "dteDateCreated",
        "txtStockKeepingUnit",
        "blnSLOBStock",
        "blnDamaged",
        "btnEditStockItem",
        "btnAddStockItem",
        "btnPrintSKUBarcodeExtraLarge",
        "btnPrintSKUBarcodeExtraLargeWithQty",
        "btnTakePhoto",
        "btnViewPhotos",
        "btnTakeThumbnailPhoto",
        "btnViewThumbnailPhotos",
        "btnSetAsReadyForPublish",
        "btnViewStockItemHistory",
      ];
      console.log("bpFloor: ", this.bpFloor);
    }
  }

  async getMyDefaultMobilePrinter() {
    setTimeout(async () => {
      //this.authService.getAppUser();
      //console.log(this.authService.user);
      if (this.authService.user != null) {
        this.myDefaultMobilePrinter =
          await this.printService.myDefaultMobilePrinter(
            this.authService.user.rowguid
          );
        //console.log(this.myDefaultMobilePrinter);
      }
    }, 800);
  }

  async getBoardPlantPrinter2() {
    this.boardPlantPrinter2 = await this.printService.getPrinterByNamePromise(
      "WEB-APP-BP2",
      Printer.enType.Mobile
    );
    //console.log("boardPlantPrinter2: ", this.boardPlantPrinter2);
  }

  addStockItem(stockItem: dtoStockItemDisplay) {
    this.addStockItemEvent.emit(stockItem);
  }

  editStockItem(stockItem: dtoStockItemDisplay) {
    this.editStockItemEvent.emit(stockItem);
  }

  copyToClipboard(value: string) {
    this.formService.copyToClipboard(value);
    this.alertService.openSnackBar(
      "The value " + value + " has been copied to the clipboard",
      "Close",
      "center",
      "bottom",
      "",
      3000
    );
  }

  async printSKUBarcodeExtraLargeWithoutQty(stockItem: dtoStockItem) {
    await this.printSKUBarcodeExtraLarge(stockItem, false);
  }

  async printSKUBarcodeExtraLargeWithQty(stockItem: dtoStockItem) {
    await this.printSKUBarcodeExtraLarge(stockItem, true);
  }

  async printSKUBarcodeExtraLarge(
    stockItem: dtoStockItem,
    printQuantity: boolean
  ) {
    if (stockItem == null) {
      this.alertService.openSnackBarError(
        "There was no stock item to print from",
        "Close",
        "center",
        "bottom",
        2000,
        false,
        ""
      );
      return false;
    }

    if (this.myDefaultMobilePrinter == null) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "No Default Mobile Printer",
        "You do not have a default mobile printer set",
        "Ok"
      );
      return;
    }

    let mobilePrinter: Printer = await this.printerService.getPrinterPromise(
      this.myDefaultMobilePrinter.rowguid
    );
    if (mobilePrinter == null) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "There was a problem getting the printer.",
        "Ok"
      );
      return;
    }

    console.log("stockItem", stockItem);

    let identifier: string = stockItem.intIdentifier.toString();

    var dataURL: string = await this.fileService.getImageDataURL(
      "/assets/img/BlackLogo.bmp",
      "image/bmp"
    );

    let stockItemBarcode: StockItemBarcode = new StockItemBarcode();
    stockItemBarcode.Printer = mobilePrinter;
    stockItemBarcode.txtIdentifier = identifier;
    //stockItemBarcode.txtDescription = stockItem.intWidth.toString() + " " + stockItem.intThickness.toString() + " " + stockItem.intTotalLM.toString()
    stockItemBarcode.txtProductName = this.utils.CString(
      stockItem.txtProductName
    );
    stockItemBarcode.txtProductShortName = this.utils.CString(
      stockItem.txtProductShortName
    );
    stockItemBarcode.txtWidth = this.utils.CString(stockItem.intWidth);
    stockItemBarcode.txtThickness = this.utils.CString(stockItem.intThickness);
    stockItemBarcode.txtLength = this.utils.CString(stockItem.intTotalLM);
    stockItemBarcode.txtQuantity = "";
    stockItemBarcode.txtUOM = "";
    if (printQuantity == true) {
      stockItemBarcode.txtQuantity = this.utils.CString(stockItem.fltQuantity);
      stockItemBarcode.txtUOM = this.utils.CString(stockItem.txtQuantityUoM);
    }

    stockItemBarcode.txtSpecies = this.utils.CString(stockItem.txtSpeciesCode);
    stockItemBarcode.txtRunNos = this.utils.CString(stockItem.txtMachineRuns);
    stockItemBarcode.txtVisualGrade = this.utils.CString(
      stockItem.txtGradeAbbreviation
    );
    stockItemBarcode.txtStructuralGrade = this.utils.CString(
      stockItem.txtStructureGrade
    );

    if (stockItemBarcode.txtVisualGrade.toLowerCase() == "none") {
      stockItemBarcode.txtVisualGrade = "";
    }

    if (stockItemBarcode.txtStructuralGrade.toLowerCase() == "none") {
      stockItemBarcode.txtStructuralGrade = "";
    }

    if (stockItem.blnToolHandle == true) {
      stockItemBarcode.txtWidth = "";
      stockItemBarcode.txtThickness = "";
      stockItemBarcode.txtLength = "";
      stockItemBarcode.txtSpecies = "";
      stockItemBarcode.txtRunNos = "";
      stockItemBarcode.txtVisualGrade = "";
      stockItemBarcode.txtStructuralGrade = "";
    }

    stockItemBarcode.txtLogoBase64 =
      this.fileService.base64WithoutPrefix(dataURL);

    console.log("stockItemBarcode", stockItemBarcode);
    //this.dtoStockItemService.printStockItemBarcode(stockItemBarcode);

    this.dtoStockItemService.printStockItemBarcodeDetail(stockItemBarcode);
  }

  async printSKUBarcode(stockItem: dtoStockItem, size: string) {
    if (this.printerSelectionDialogRef != null) {
      this.printerSelectionDialogRef.close();
    }

    //let printer: Printer = await this.printerService.getPrintersByAppNameAndTypePromise("WEB-APP-COFFS-SKU", Printer.enType.Mobile);
    let printer: Printer;

    // TODO: Remove hardcoded printers
    if (
      this.authService.getAppUserId().toUpperCase() ==
      "E6041C0E-162B-4B18-A838-2F29FFC5C242"
    ) {
      // lawson@coffshardwoods.com.au - user
      printer = await this.printerService.getPrintersByAppNameAndTypePromise(
        "WEB-APP-COFFS-SKU",
        Printer.enType.Mobile
      );
    }

    if (
      this.authService.getAppUserId().toUpperCase() ==
        "E604130F-022C-4C68-A886-4BE037F8B6A9" ||
      this.authService.getAppUserId().toUpperCase() ==
        "E30C020F-0416-4921-86B2-F33B4ECE86FF"
    ) {
      // office@coffshardwoods.com.au or martin@coffshardwoods.com.au - user
      printer = await this.printerService.getPrintersByAppNameAndTypePromise(
        "WEB-APP-BP",
        Printer.enType.Mobile
      );
      console.log("printing to WEB-APP-BP");
    }

    //ZDesigner ZQ630 (CPCL) (1)
    //console.log("printer - ", printer);

    if (
      printer != null &&
      stockItem.txtStockKeepingUnit != null &&
      stockItem.txtStockKeepingUnit != ""
    ) {
      let path: string = this.sharedService.LocalServerURI;
      if (location.hostname.indexOf("localhost") == -1) {
        path = "https://" + location.hostname;
      }

      console.log(
        "encodeURI: ",
        encodeURIComponent(stockItem.txtStockKeepingUnit)
      );
      var windowUrl =
        path +
        "/barcode-sticker?data=" +
        encodeURIComponent(stockItem.txtStockKeepingUnit) +
        "&hidenav=yes";
      var uniqueName = new Date();
      var windowName = "_blank";
      var printWindow = window.open(
        windowUrl,
        windowName,
        "left=400,top=200,width=750,height=600"
      );

      //let path: string = this.sharedService.PrintServerURI;
      ////path = "https://localhost:44358/";

      ////https://localhost:44358
      ////this.sharedService.PrintServerURI
      //this.http.post(path + 'api/Print/PrintSKUBarcode/' + stockItem.txtStockKeepingUnit + '/' + size, printer, { responseType: 'blob' }).subscribe(pdf => {
      //  //let mimeType: string = 'application/pdf';
      //  //let blob: any = new Blob([pdf], { type: mimeType });
      //  //const url = window.URL.createObjectURL(blob);
      //  //console.log(blob);
      //  //window.open(url);

      //  //saveAs(blob, 'testFile.txt');
      //  //saveAs(blob, 'testFile.jpg');
      //  //saveAs(blob, 'testPDF.pdf');

      //}, err => {
      //  console.log(err);
      //});;
    }

    //let deliveryDocket: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(this.deliveryDocket.rowguid);
    //let orderId: string = "";
    //let deliveryDocketOrderItems: dtoDeliveryDocketOrderItem[] = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemByDocketIdPromise(deliveryDocket.rowguid);
    //let loadingDocket: LoadingDocket;

    ////console.log("deliveryDocketOrderItems", deliveryDocketOrderItems);
    //if (deliveryDocketOrderItems != null && deliveryDocketOrderItems.length > 0) {
    //  orderId = deliveryDocketOrderItems[0].guOrderId;
    //}

    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
    //aParamList.push(aParam);

    //let loadingDocketDelivery: LoadingDocketDelivery[] = await this.loadingDocketDeliveryService.getLoadingDocketDeliveryParamArrayPromise(aParamList);

    ////console.log("loadingDocketDelivery", loadingDocketDelivery);
    //if (loadingDocketDelivery != null && loadingDocketDelivery.length > 0) {
    //  loadingDocket = await this.loadingDocketService.getLoadingDocketPromise(loadingDocketDelivery[0].guLoadingDocketId);
    //}

    //this.deliveryDocketUpdateDialogRef = this.dialog.open(DeliveryDocketUpdateDialogComponent, {
    //  height: 'auto',
    //  maxWidth: '90vw',
    //  data: { deliveryDocket: deliveryDocket, loadingDocket: loadingDocket, orderId: orderId }
    //});

    //// -------------------------------------------------------------------------------
    //// COULD USE componentInstance FOR SOMTHING.
    ////this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    //// -------------------------------------------------------------------------------

    //this.deliveryDocketUpdateDialogRef.backdropClick().subscribe(() => {
    //  this.deliveryDocketUpdateDialogRef.close();
    //});

    //this.deliveryDocketUpdateDialogRef
    //  .afterClosed()
    //  .subscribe(async (
    //    data: { status: string, deliveryDocket: DeliveryDocket }) => {
    //    if (data == null || data == undefined) {
    //      this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
    //      return;
    //    }

    //    console.log(data);
    //    if (data.status != "Cancel") {
    //      console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

    //      let deliveryDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(data.deliveryDocket.rowguid);

    //      this.deliveryDocket = deliveryDocket;
    //      this.loadDeliveryDocketData();

    //    }
    //    else {
    //      // We have cancellled

    //    }

    //  });
  }

  RoundQuantity(val: number) {
    if (val == null) {
      return 0;
    }

    let num: string = "";
    let returnNum: number = 0;
    num = val.toString();

    //console.log(num);

    if (num.indexOf(".") == -1) {
      return val;
    }

    if (num.indexOf(".") > -1) {
      return parseFloat(val.toFixed(2));
    }
  }

  async savePhoto(ev, stockItem: dtoStockItemDisplay) {
    this.selectedStockItem = stockItem;

    this.fileService.savePhoto(
      ev,
      "mtech\\BoardPlant\\StockItemPhotos\\" + stockItem.guBoardTallyRecordId
    );

    window.event.stopPropagation();
  }

  async viewPhotos(stockItem: dtoStockItemDisplay) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("blnThumbnail", "0"));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(
      new SQLParamArray("guBoardTallyRecordId", stockItem.guBoardTallyRecordId)
    );
    aParamList.push(aParam);

    let boardTallyRecordPhotos: BoardTallyRecordPhoto[] =
      await this.boardTallyRecordPhotoService.getBoardTallyRecordPhotoParamArrayPromise(
        aParamList
      );

    let attachments: FileAttachment[] = await this.fileService.viewPhotos(
      "mtech\\BoardPlant\\StockItemPhotos\\",
      stockItem.guBoardTallyRecordId,
      "",
      false,
      boardTallyRecordPhotos
    );

    this.selectedStockItem = stockItem;

    window.event.stopPropagation();
  }

  async removeAllBoardTallyRecordPhotos(
    stockItem: dtoStockItemDisplay,
    thumbnail: boolean
  ) {
    console.log("removeBoardTallyRecordPhotos - stockItem: ", stockItem);

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(
      new SQLParamArray("guBoardTallyRecordId", stockItem.guBoardTallyRecordId)
    );
    aParamList.push(aParam);

    aParam = [];
    if (thumbnail == false) {
      aParam.push(new SQLParamArray("blnThumbnail", "0"));
      aParamList.push(aParam);
    }

    if (thumbnail == true) {
      aParam.push(new SQLParamArray("blnThumbnail", "1"));
      aParamList.push(aParam);
    }

    let btPhotos: BoardTallyRecordPhoto[] =
      await this.boardTallyRecordPhotoService.getBoardTallyRecordPhotoParamArrayPromise(
        aParamList
      );

    if (btPhotos != null && btPhotos.length > 0) {
      for (let p = 0; p <= btPhotos.length - 1; p++) {
        console.log("delete btPhotos: ", btPhotos[p]);
        await this.boardTallyRecordPhotoService.deleteBoardTallyRecordPhotoPromise(
          btPhotos[p].rowguid
        );
      }
    }
  }

  async removeBoardTallyRecordPhotos(
    stockItem: dtoStockItemDisplay,
    attachments: FileAttachment[]
  ) {
    console.log("removeBoardTallyRecordPhotos - stockItem: ", stockItem);
    if (attachments != null && attachments.length > 0) {
      let btPhotos: BoardTallyRecordPhoto[] =
        await this.boardTallyRecordPhotoService.getBoardTallyRecordPhotosPromise(
          stockItem.guBoardTallyRecordId
        );

      for (let i = 0; i <= attachments.length - 1; i++) {
        let btPhoto: BoardTallyRecordPhoto;
        console.log("btPhotos: ", btPhotos);
        if (btPhotos != null && btPhotos.length > 0) {
          for (let p = 0; p <= btPhotos.length - 1; p++) {
            if (btPhotos[p].txtFileName == attachments[i].fileName) {
              await this.boardTallyRecordPhotoService.deleteBoardTallyRecordPhotoPromise(
                btPhotos[p].rowguid
              );
              break;
            }
          }
        }
      }
    }
  }

  async updateBoardTallyRecordPhotos(
    stockItem: dtoStockItemDisplay,
    attachments: FileAttachment[],
    thumbnail: boolean
  ) {
    //console.log("updateBoardTallyRecordPhotos - stockItem: ", stockItem);
    //Remove all BoardTallyRecordPhotos first
    await this.removeAllBoardTallyRecordPhotos(stockItem, thumbnail);

    if (attachments != null && attachments.length > 0) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(
        new SQLParamArray(
          "guBoardTallyRecordId",
          stockItem.guBoardTallyRecordId
        )
      );
      aParamList.push(aParam);

      aParam = [];
      if (thumbnail == false) {
        aParam.push(new SQLParamArray("blnThumbnail", "0"));
        aParamList.push(aParam);
      }

      if (thumbnail == true) {
        aParam.push(new SQLParamArray("blnThumbnail", "1"));
        aParamList.push(aParam);
      }

      let btPhotos: BoardTallyRecordPhoto[] =
        await this.boardTallyRecordPhotoService.getBoardTallyRecordPhotoParamArrayPromise(
          aParamList
        );
      //console.log("updateBoardTallyRecordPhotos - attachments: ", attachments);

      for (let i = 0; i <= attachments.length - 1; i++) {
        let bGotPhoto: boolean = false;
        //console.log("btPhotos: ", btPhotos);
        if (btPhotos != null && btPhotos.length > 0) {
          for (let p = 0; p <= btPhotos.length - 1; p++) {
            if (btPhotos[p].txtFileName == attachments[i].fileName) {
              bGotPhoto = true;
              break;
            }
          }
        }

        if (bGotPhoto == false) {
          let btPhoto: BoardTallyRecordPhoto = new BoardTallyRecordPhoto();
          btPhoto.blnActive = true;
          btPhoto.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
          btPhoto.guBoardTallyRecordId = stockItem.guBoardTallyRecordId;
          btPhoto.intOrder = i + 1;
          btPhoto.txtFileName = attachments[i].fileName;
          btPhoto.blnThumbnail = thumbnail;
          await this.boardTallyRecordPhotoService.createBoardTallyRecordPhotoPromise(
            btPhoto
          );
          //console.log("btPhoto: ", btPhoto);
        }
      }

      ////Run through and make sure all BoardTallyRecordPhotos exist with photos on file.
      //let bGotAttachment: boolean = false;
      //if (btPhotos != null && btPhotos.length > 0) {
      //  for (let p = 0; p <= btPhotos.length - 1; p++) {
      //    bGotAttachment = false;
      //    for (let i = 0; i <= attachments.length - 1; i++) {
      //      if (btPhotos[p].txtFileName == attachments[i].fileName) {
      //        bGotAttachment = true;
      //        break;
      //      }
      //    }

      //    if (bGotAttachment == false) {
      //      await this.boardTallyRecordPhotoService.deleteBoardTallyRecordPhotoPromise(btPhotos[p].rowguid);
      //    }
      //  }

      //}

      //let bt: BoardTallyRecord = await this.boardTallyRecordService.getBoardTallyRecordPromise(stockItem.guBoardTallyRecordId);
      //if (bt != null) {
      //  bt.intNumberOfPhotos = attachments.length;
      //  await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), false, BoardTallyRecordHistory.enChangeReason.Location, bt);
      //}
    }
  }

  async saveThumbnailPhoto(ev, stockItem: dtoStockItemDisplay) {
    window.event.stopPropagation();
    this.selectedStockItem = stockItem;

    await this.fileService.savePhoto(
      ev,
      "mtech\\BoardPlant\\StockItemThumbnailPhotos\\" +
        stockItem.guBoardTallyRecordId,
      "thumbnail",
      true
    );
    let boardTallyRecord: BoardTallyRecord =
      await this.boardTallyRecordService.getBoardTallyRecordPromise(
        stockItem.guBoardTallyRecordId
      );
    boardTallyRecord.blnHasThumbnailImage = true;
    await this.boardTallyRecordService.updateBoardTallyRecordPromise(
      this.authService.getAppUserEmployeeId(),
      false,
      BoardTallyRecordHistory.enChangeReason.Created,
      boardTallyRecord
    );

    window.event.stopPropagation();
  }

  async viewThumbnailPhotos(stockItem: dtoStockItemDisplay) {
    await this.fileService.viewPhotos(
      "mtech\\BoardPlant\\StockItemThumbnailPhotos\\",
      stockItem.guBoardTallyRecordId,
      "thumbnail",
      true
    );

    this.selectedStockItem = stockItem;

    //console.log("about to subscribe: ");
    this.photoSub.subscribe(async (val) => {
      //console.log("val: ", val);

      if (val == true) {
        let boardTallyRecord: BoardTallyRecord =
          await this.boardTallyRecordService.getBoardTallyRecordPromise(
            stockItem.guBoardTallyRecordId
          );
        boardTallyRecord.blnHasThumbnailImage = false;
        await this.boardTallyRecordService.updateBoardTallyRecordPromise(
          this.authService.getAppUserEmployeeId(),
          false,
          BoardTallyRecordHistory.enChangeReason.Created,
          boardTallyRecord
        );
        //console.log("about to unsubscribes: ", boardTallyRecord);

        //console.log("updated and unsubscribes: ", boardTallyRecord);
      }
    });

    window.event.stopPropagation();
  }

  async expandElement(stockItem: dtoStockItemDisplay, index: number) {
    //console.log("in expand element: ");

    if (this.stockItemExpanded === stockItem) {
      this.stockItemExpanded = null;
      return;
    }

    if (this.stockItemExpanded != stockItem) {
      this.stockItemExpanded =
        this.stockItemExpanded === stockItem ? null : stockItem;

      if (this.boardTallyRecordHistory == null) {
        this.boardTallyRecordHistory =
          new MatTableDataSource<dtoBoardTallyRecordHistory>();
      }

      this.currentHistoryAll = [];
      this.currentHistorySpliced = [];
      this.boardTallyRecordHistory.data = [];

      setTimeout(async () => {
        await this.loadStockItemHistory(stockItem, 0, 10, index);
        //console.log("this.currentHistoryAll:", this.currentHistoryAll);
      }, 1200);
    }
  }

  async loadStockItemHistory(
    stockItem: dtoStockItemDisplay,
    nextPage: number,
    pageSize: number,
    rowIndex: number
  ) {
    if (this.stockItemExpanded == stockItem) {
      this.boardTallyRecordHistoryApp.showSpinner = true;

      //let boardTallyRecordHistoryApps = this.boardTallyRecordHistoryApp.toArray();
      //let boardTallyRecordHistoryApp: BoardTallyRecordHistoryListComponent;
      //for (let i = 0; i <= boardTallyRecordHistoryApps.length - 1; i++) {
      //  console.log("i == rowIndex" + i + " == " + rowIndex);
      //  if (i == rowIndex) {
      //    console.log("boardTallyRecordHistoryApps[i]", boardTallyRecordHistoryApps[i]);
      //    boardTallyRecordHistoryApp = boardTallyRecordHistoryApps[i];
      //    boardTallyRecordHistoryApp.showSpinner = true;
      //    break;
      //  }
      //}

      this.currentHistoryAll =
        await this.dtoBoardTallyRecordHistoryService.getdtoBoardTallyRecordHistoryByBoardTallyIdPromise(
          stockItem.guBoardTallyRecordId
        );
      //this.currentHistoryAll = this.currentHistoryAll.sort((a, b) => { return a.dteCreated < b.dteCreated ? 1 : -1 });
      this.currentHistoryAll = this.currentHistoryAll.sort((a, b) => {
        return a.dteDateComputerChanged < b.dteDateComputerChanged ? 1 : -1;
      });
      this.nextPage(0, 10);

      //console.log("about to set paginator");
      //console.log("this.boardTallyRecordHistoryApp", this.boardTallyRecordHistoryApp);

      this.boardTallyRecordHistoryApp.setPaginator(
        this.currentHistoryAll.length
      );
      this.boardTallyRecordHistoryApp.showSpinner = false;
    }
  }

  turnNextPage(nextPage: { nextPage: number; pageSize: number }) {
    this.nextPage(nextPage.nextPage, nextPage.pageSize);
  }

  async nextPage(nextPage: number, pageSize: number) {
    //console.log("this.currentHistoryAll:", this.currentHistoryAll);

    //this.currentHistorySpliced = [...this.currentHistoryAll];

    //console.log("nextPage:", nextPage);
    //console.log("pageSize:", pageSize);
    //console.log("this.currentHistorySpliced.length - 11 > pageSize:", this.currentHistorySpliced.length - 11);

    //let nextIndex = (nextPage * pageSize) + 1;

    //if ((this.currentHistorySpliced.length - nextIndex) < pageSize) {
    //  pageSize = (this.currentHistorySpliced.length - nextIndex)
    //  console.log("pageSize modified:", pageSize);
    //}

    //this.currentHistorySpliced.splice(nextIndex, this.currentHistorySpliced.length - pageSize);
    //console.log("currentHistorySpliced:", this.currentHistorySpliced);

    //console.log("btHistory.length", this.currentHistorySpliced.length);
    ////this.boardTallyRecordHistory.data = this.currentHistorySpliced;

    this.boardTallyRecordHistory.data = this.currentHistoryAll;
  }

  expandElementAndSetColor(e, stockItem: dtoStockItemDisplay, idx: number) {
    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    //console.log("e", e);
    //console.log("e.target.type", e.target.type);
    //console.log("expandElementAndSetColor");

    if (
      e.target != null &&
      e.target.type != null &&
      e.target.type.toString() == "file"
    ) {
      return;
    }

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(stockItem.guBoardTallyRecordId);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(stockItem, idx);
      this.selectedRow = stockItem.guBoardTallyRecordId; //Always highlight row when double clicking.

      //window.alert('ondouble');
    }
  }

  setRowColor(stockItemId: string) {
    //console.log("setRowColor");

    if (this.selectedRow == "" || stockItemId != this.selectedRow) {
      this.selectedRow = stockItemId;
      return;
    }

    if (stockItemId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }
  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  doubleclick(idx: number) {
    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(function () {
        if (div.getAttribute("data-dblclick") == "1") {
          window.alert("single");
        }
        div.removeAttribute("data-dblclick");
      }, 300);
    } else {
      div.removeAttribute("data-dblclick");
      window.alert("ondouble");
    }
  }

  async setReadyForPublish(stockitem: dtoStockItemDisplay, index: number) {
    if (
      stockitem == null ||
      this.datasource == null ||
      this.datasource.data == null
    ) {
      return;
    }

    let bt: BoardTallyRecord;
    bt = await this.boardTallyRecordService.getBoardTallyRecordPromise(
      stockitem.guBoardTallyRecordId
    );
    bt.intWebPublishStatus =
      BoardTallyRecord.enWebPublishStatus.ReadyForPublish;
    this.datasource.data[index].intWebPublishStatus =
      BoardTallyRecord.enWebPublishStatus.ReadyForPublish;
    await this.boardTallyRecordService.updateBoardTallyRecordPromise(
      this.authService.getAppUserEmployeeId(),
      true,
      BoardTallyRecordHistory.enChangeReason.WebPublishStatus,
      bt
    );

    this.alertService.openSnackBarDefault(
      "The status has been set to Ready For Publish"
    );
  }

  async setAsPublished(stockitem: dtoStockItemDisplay, index: number) {
    if (
      stockitem == null ||
      this.datasource == null ||
      this.datasource.data == null
    ) {
      return;
    }

    let bt: BoardTallyRecord;
    bt = await this.boardTallyRecordService.getBoardTallyRecordPromise(
      stockitem.guBoardTallyRecordId
    );
    bt.intWebPublishStatus = BoardTallyRecord.enWebPublishStatus.Published;
    this.datasource.data[index].intWebPublishStatus =
      BoardTallyRecord.enWebPublishStatus.Published;
    await this.boardTallyRecordService.updateBoardTallyRecordPromise(
      this.authService.getAppUserEmployeeId(),
      true,
      BoardTallyRecordHistory.enChangeReason.WebPublishStatus,
      bt
    );

    this.alertService.openSnackBarDefault(
      "The status has been set to Published"
    );
  }

  async setAsNotPublished(stockitem: dtoStockItemDisplay, index: number) {
    if (
      stockitem == null ||
      this.datasource == null ||
      this.datasource.data == null
    ) {
      return;
    }

    let bt: BoardTallyRecord;
    bt = await this.boardTallyRecordService.getBoardTallyRecordPromise(
      stockitem.guBoardTallyRecordId
    );
    bt.intWebPublishStatus = BoardTallyRecord.enWebPublishStatus.NotPublished;
    this.datasource.data[index].intWebPublishStatus =
      BoardTallyRecord.enWebPublishStatus.NotPublished;
    await this.boardTallyRecordService.updateBoardTallyRecordPromise(
      this.authService.getAppUserEmployeeId(),
      true,
      BoardTallyRecordHistory.enChangeReason.WebPublishStatus,
      bt
    );

    this.alertService.openSnackBarDefault(
      "The status has been set to Not Published"
    );
  }
}
