import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { AlertService } from "../alert.service";
import { SharedService } from "../shared.service";
import jsPDF, { jsPDFOptions } from "jspdf";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { FileAttachment } from "../../app-objects/file-attachment";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PhotoViewerDialogComponent } from "../../../utilities/photo-viewer-dialog/photo-viewer-dialog.component";
import { dtoStockItemDisplay } from "../../business-objects/dto-stock-item-display.bo";
import { SendMailDialogComponent } from "../../../email/send-mail-dialog/send-mail-dialog.component";
import { AuthService } from "../../../account/auth.service";
import { error, Key } from "protractor";
import {
  dtoKeyValue,
  dtoStrKeyValue,
} from "../../business-objects/dto-int-key-value.bo";
import { dtoFileAttachment } from "../../app-objects/dto-file-attachment";
import { PhotoViewerZoomComponent } from "../../../utilities/photo-viewer-zoom/photo-viewer-zoom.component";
import { BoardTallyRecordPhoto } from "../../business-objects/board-tally-record-photo.bo";
import { PhotoViewerComponent } from "../../../utilities/photo-viewer/photo-viewer.component";

@Injectable()
export class FileService {
  @Output() deletedAllPhotosEvent = new EventEmitter<boolean>();
  @Output() deletedPhotosEvent = new EventEmitter<FileAttachment[]>();
  @Output() viewPhotosEvent = new EventEmitter<FileAttachment[]>();
  @Output() viewThumbnailPhotosEvent = new EventEmitter<FileAttachment[]>();

  public _baseUrl: string;
  photoViewerDialogRef: MatDialogRef<PhotoViewerDialogComponent>;
  photoViewerZoomRef: MatDialogRef<PhotoViewerZoomComponent>;
  sendMailDialogRef: MatDialogRef<SendMailDialogComponent>;

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private sharedService: SharedService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this._baseUrl = baseUrl;
  }

  getImageDataURL(src: string, type: string) {
    return new Promise<string>((resolve) => {
      var dataURL: string = "";
      var image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        context.drawImage(image, 0, 0);
        dataURL = canvas.toDataURL(type);
        //console.log("in image load", dataURL);
        resolve(dataURL);
      };
      image.src = src;
    });
  }

  async fileToBase64(file): Promise<String | ArrayBuffer> {
    return await this.readImage(file);
  }

  readImage = (file) => {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target?.result);
      };

      reader.onerror = (e) => {
        reject(`couldn't read image`);
      };

      reader.readAsDataURL(file);
    });
  };

  arrayBufferToBase64(arrBuffer: ArrayBuffer) {
    let TYPED_ARRAY = new Uint8Array(arrBuffer);
    //const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
    const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, "");
    return btoa(STRING_CHAR);
  }

  base64ToArrayBuffer(base64): string | ArrayBuffer {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public async createPDFFileAttachment(fileName, arrBuffer: ArrayBuffer[]) {
    const mergedPdfFile = await this.mergePDFs(arrBuffer);
    let bs64 = await this.arrayBufferToBase64(mergedPdfFile);

    return await new FileAttachment(
      fileName,
      "",
      "application/pdf",
      bs64.toString(),
      null,
      mergedPdfFile.byteLength,
      new Date(Date.now())
    );
  }

  public async mergePDFs(arrBuffer: ArrayBuffer[]) {
    const mergedPdf = await PDFDocument.create();
    for (let i = 0; i <= arrBuffer.length - 1; i++) {
      const pdf = await PDFDocument.load(arrBuffer[i]);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      await copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    return await mergedPdf.save();
  }

  public async mergedPDFsBase64(arrBuffer: ArrayBuffer[]) {
    const mergedPdfFile = await this.mergePDFs(arrBuffer);
    return await this.arrayBufferToBase64(mergedPdfFile);
  }

  public async HTMLToPdfArrayBuffer(
    element,
    orientation: "p" | "portrait" | "l" | "landscape" = "p"
  ) {
    var pdf = new jsPDF(orientation, "pt", "A4");

    return await new Promise<ArrayBuffer>(async (resolve) => {
      await pdf.html(element, {
        html2canvas: {
          scale: 0.5,
        },
        callback: (pdf) => {
          resolve(pdf.output("arraybuffer"));
        },
      });
    });
  }

  public async HTMLToPdfDataURL(
    element,
    orientation: "p" | "portrait" | "l" | "landscape" = "p",
    unit: "pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc" = "pt",
    format: string | number[] = "A4"
  ) {
    var pdf = new jsPDF(orientation, unit, format);
    return await new Promise<string>(async (resolve) => {
      await pdf.html(element, {
        html2canvas: {
          scale: 0.5,
        },
        callback: (pdf) => {
          resolve(pdf.output("dataurlstring", { filename: "test.pdf" }));
        },
      });
    });
  }

  base64WithoutPrefix(base64: string) {
    let base64Index: number = base64.indexOf("base64,");
    let base64WithoutPrefix: string;
    if (base64Index > -1) {
      base64WithoutPrefix = base64.substring(base64Index + 7, base64.length);
    }
    if (base64Index == -1) {
      base64WithoutPrefix = base64;
    }
    return base64WithoutPrefix;
  }

  getBase64ContentType(base64: string) {
    let base64Index: number = base64.indexOf("base64,");
    let dataIndex: number = base64.indexOf("data:");

    if (base64Index > -1 && dataIndex > -1) {
      return base64.substring(dataIndex + 5, base64Index - 1);
    }

    return "";
  }

  getBase64ContentExtensionFileType(base64: string) {
    let contentType: string = this.getBase64ContentType(base64);
    let slashIndex: number = contentType.indexOf("/");

    console.log("contentType", contentType);

    if (slashIndex > -1 && contentType.length > 0) {
      return contentType.substring(slashIndex + 1, contentType.length);
    }

    return "";
  }

  base64toBlob(base64: string, contentType: string) {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //let contentBytes: string = fileAttachment.Bytes.toString();
    //let base64Index: number = contentBytes.indexOf("base64,");
    let base64Index: number = base64.indexOf("base64,");
    let base64WithoutPrefix: string;
    if (base64Index > -1) {
      base64WithoutPrefix = base64.substring(base64Index + 7, base64.length);
    }
    if (base64Index == -1) {
      base64WithoutPrefix = base64;
    }

    //console.log("base64Index", base64Index)
    //console.log("base64WithoutPrefix", base64WithoutPrefix)

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    //return new Blob([out], { type: 'application/pdf' });
    return new Blob([out], { type: contentType });
    //return base64toBlob
  }

  downloadAttachment(fileAttachment: FileAttachment) {
    const base64toBlob = this.base64toBlob(
      fileAttachment.bytes.toString(),
      fileAttachment.contentType
    );

    // Have to work out how to view email messages.
    if (
      fileAttachment.fileName.indexOf(".msg") == -1 &&
      fileAttachment != null
    ) {
      const blob = base64toBlob;

      //let mimeType: string = 'application/pdf';
      //let blob: any = new Blob([pdf], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      //console.log("opening widow");
      window.open(url);
    }
  }

  public dropboxFile(fileAttachments: FileAttachment[]) {
    return new Promise<boolean>((resolve) => {
      //console.log("path", encodeURIComponent(path));

      //let log = document.getElementById("consoleLogMessage");

      //log.innerHTML = "before</br>" + file.toString();

      //const base64toBlob = this.base64toBlob(file.toString(), "");

      //log.innerHTML = "after</br>" + file.toString();

      //let fileAttachment: FileAttachment = new FileAttachment(fileName, path, contentType, file.toString(), null, 0, new Date(Date.now()));
      //let fileAttachments: FileAttachment[] = [];
      //fileAttachments.push(fileAttachment);

      //console.log(fileAttachments);

      //let fileAttachment: dtoFileAttachment = new dtoFileAttachment(fileName, path, contentType, "", base64toBlob, 0, new Date(Date.now()));
      //let fileAttachments: dtoFileAttachment[] = [];
      //fileAttachments.push(fileAttachment);

      //let keyValue: dtoKeyValue = new dtoKeyValue();
      //keyValue.txtValue = fileAttachment.base64WithoutPrefix();

      //try {
      this.http
        .post<true>(this._baseUrl + "api/FileService/Dropbox", fileAttachments)
        .subscribe(
          (data) => {
            //log.innerHTML = "in subscribe";

            console.log("data: ", data);
            resolve(true);
          },
          (err: HttpErrorResponse) => {
            //let errObjectString = "error: " + err.error + "<br>message: " + err.message + "<br>statusText: " + err.statusText;
            //errObjectString += "<br>headers : " + JSON.stringify(err.headers) + "<br>url: " + err.url
            //log.innerHTML = errObjectString;
          }
        );
      //} catch (err) {
      //  log.innerHTML = err
      //}

      //this.http.post<true>(this._baseUrl + 'api/FileService/Dropbox/' + encodeURIComponent(path) + '/' + encodeURIComponent(fileName), base64toBlob, {
      //  headers: { "Content-Type": "application/octet-stream" }

      //}).subscribe(data => {

      //  log.innerHTML = "in subscribe";

      //  console.log("data: ", data);
      //  resolve(true);
      //  if (data == true) {
      //  }
      //});;
    });
  }

  async savePhoto(
    ev,
    dropboxLocation: string,
    nameOverride: string = "",
    isThumbnail: boolean = false
  ) {
    return new Promise<boolean>(async (resolve) => {
      let log = document.getElementById("consoleLogMessage");
      //console.log("log: ", log);
      try {
        const file = ev.target.files[0];
        let fileByteArray: String | ArrayBuffer;
        fileByteArray = await this.readImage(file);
        //log.innerHTML = fileByteArray.toString();

        const base64toBlob = this.base64toBlob(fileByteArray.toString(), "");
        let fileName: string = file.name.toString();
        if (nameOverride != "") {
          let fileExt: string = fileName.substring(
            fileName.lastIndexOf("."),
            fileName.length
          );
          fileName = nameOverride + fileExt;
          console.log("nameOverride: ", nameOverride);
          console.log("fileExt: ", fileExt);
        }

        let fileAttachments: FileAttachment[] = [];
        fileAttachments.push(
          new FileAttachment(
            fileName,
            dropboxLocation,
            file.type,
            this.base64WithoutPrefix(fileByteArray.toString()),
            null,
            file.size,
            new Date(Date.now())
          )
        );

        await this.dropboxFile(fileAttachments);

        if (isThumbnail == false || isThumbnail == null) {
          this.viewPhotosEvent.emit(fileAttachments);
        }

        if (isThumbnail == true) {
          this.viewThumbnailPhotosEvent.emit(fileAttachments);
        }

        resolve(true);
      } catch (error) {
        this.alertService.openSnackBarError(
          "Error saving photo",
          "Close",
          "center",
          "bottom",
          4000,
          true,
          error.message
        );

        log.innerHTML = error;
      }
    });
  }

  async viewPhotos(
    path: string,
    folderName: string,
    fileNameOverride: string = "",
    isThumbnail: boolean = false,
    boardTallyRecordPhotos: BoardTallyRecordPhoto[] = null
  ): Promise<FileAttachment[]> {
    if (this.photoViewerDialogRef != null) {
      this.photoViewerDialogRef.close();
    }

    this.photoViewerDialogRef = this.dialog.open(PhotoViewerDialogComponent, {
      height: "auto",
      maxWidth: "90vw",
      data: {
        path: path,
        folderName: folderName,
        fileNameOverride: fileNameOverride,
        boardTallyRecordPhotos: boardTallyRecordPhotos,
      },
    });

    this.photoViewerDialogRef.backdropClick().subscribe(() => {
      this.photoViewerDialogRef.close();
    });

    let updatePhotosSub =
      this.photoViewerDialogRef.componentInstance.updatePhotosEvent;
    updatePhotosSub.subscribe((fileAttachments) => {
      //console.log("fileAttachments ", fileAttachments);
      this.viewPhotosEvent.emit(fileAttachments);
    });

    this.photoViewerDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; fileAttachments: FileAttachment[] }) => {
          updatePhotosSub.unsubscribe();

          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return null;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            if (data.status == "emailphotos") {
              // Open Send Mail dialog with photos attached.

              this.emailFiles(
                "",
                "",
                "",
                data.fileAttachments,
                "Photos Attached",
                "Please find attached photos"
              );
            }

            console.log("data.status ", data.status);
            console.log("isThumbnail ", isThumbnail);

            if (data.status == "deletedphotos") {
              // Open Send Mail dialog with photos attached.
              console.log("deleted photos");

              this.deletedPhotosEvent.emit(data.fileAttachments);
            }

            if (data.status == "deletedallphotos" && isThumbnail == true) {
              // Open Send Mail dialog with photos attached.
              console.log("deleted all photos");

              this.deletedAllPhotosEvent.emit(true);
            }

            //return data.fileAttachments;

            //this.alertService.openSnackBarDefault("The loading docket has been updated with a truck and trailer");
          } else {
            // We have cancellled
          }

          if (
            data != null &&
            data.fileAttachments != null &&
            data.fileAttachments.length > 0
          ) {
            console.log("isThumbnail " + isThumbnail);
            if (isThumbnail == false || isThumbnail == null) {
              this.viewPhotosEvent.emit(data.fileAttachments);
            }

            if (isThumbnail == true) {
              this.viewThumbnailPhotosEvent.emit(data.fileAttachments);
            }
          }
        }
      );

    return null;
  }

  viewPhotoZoom(fileAttachment: FileAttachment) {
    if (this.photoViewerZoomRef != null) {
      this.photoViewerZoomRef.close();
    }

    let header: HTMLElement = document.getElementById("stockForSaleHeader");
    header.classList.toggle("header");
    header.classList.toggle("header-not-sticky");
    console.log("headedr: ", header);

    this.photoViewerZoomRef = this.dialog.open(PhotoViewerZoomComponent, {
      maxWidth: "99vw",
      panelClass: "photo-zoom-dialog",
      data: { fileAttachment: fileAttachment },
    });

    this.photoViewerZoomRef.backdropClick().subscribe(() => {
      this.photoViewerZoomRef.close();
      header.classList.toggle("header");
      header.classList.toggle("header-not-sticky");
    });

    this.photoViewerZoomRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; fileAttachments: FileAttachment[] }) => {
          header.classList.toggle("header");
          header.classList.toggle("header-not-sticky");

          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
          } else {
            // We have cancellled
          }
        }
      );
  }

  async getFileNames(path: string) {
    return new Promise<string[]>((resolve) => {
      let keyValue: dtoKeyValue = new dtoKeyValue();
      keyValue.txtValue = path;

      this.http
        .post<string[]>(
          this._baseUrl + "api/FileService/GetFileNames",
          keyValue
        )
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  async getFiles(paths: string[]): Promise<FileAttachment[]> {
    return new Promise<FileAttachment[]>((resolve) => {
      if (paths == null || paths.length == 0) {
        resolve(null);
        return;
      }

      let fileAttachments: FileAttachment[] = [];
      let fileAttachment: FileAttachment;
      let keyValues: dtoStrKeyValue[] = [];
      paths.forEach((s) => {
        keyValues.push(new dtoStrKeyValue("", paths[s]));
      });

      this.http
        .post<FileAttachment[]>(
          this._baseUrl + "api/FileService/GetFiles",
          paths
        )
        .subscribe((data: FileAttachment[]) => {
          data.forEach(async (f) => {
            fileAttachment = new FileAttachment(
              f.fileName,
              f.filePath,
              f.contentType,
              f.bytes,
              f.byteArr,
              f.size,
              f.fileDate
            );
            fileAttachments.push(fileAttachment);
          });

          resolve(fileAttachments);
        });
    });
  }

  async getFile(path: string, fileName: string): Promise<FileAttachment[]> {
    return new Promise<FileAttachment[]>((resolve) => {
      let fileAttachments: FileAttachment[] = [];
      let fileAttachment: FileAttachment;
      let keyValue: dtoKeyValue = new dtoKeyValue();
      keyValue.txtValue = path;
      keyValue.txtValue2 = fileName;

      this.http
        .post<FileAttachment[]>(
          this._baseUrl + "api/FileService/GetFile",
          keyValue
        )
        .subscribe((data: FileAttachment[]) => {
          data.forEach(async (f) => {
            fileAttachment = new FileAttachment(
              f.fileName,
              f.filePath,
              f.contentType,
              f.bytes,
              f.byteArr,
              f.size,
              f.fileDate
            );
            fileAttachments.push(fileAttachment);
          });

          resolve(fileAttachments);
        });
    });
  }

  async deleteFiles(fileAttachments: FileAttachment[]) {
    console.log("fileAttachments:", fileAttachments);

    return new Promise<boolean>((resolve) => {
      this.http
        .delete<boolean>(this._baseUrl + "api/FileService/DeleteFiles", {
          body: fileAttachments,
        })
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  async downloadFiles(path: string) {
    return new Promise((resolve) => {
      this.http
        .get(
          this._baseUrl +
            "api/FileService/DownloadFile/" +
            encodeURIComponent(path),
          { responseType: "blob" }
        )
        .subscribe((data) => {
          console.log("data: ", data);

          let mimeType: string = "image/jpg";
          let blob: any = new Blob([data], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          //console.log("opening widow");
          window.open(url);

          //resolve(data);
        });
    });
  }

  async createPDF(
    elementId: string,
    fileName: string,
    orientation: "p" | "portrait" | "l" | "landscape" = "p"
  ) {
    var pdf = new jsPDF(orientation, "pt", "A4");

    let htmlElement: HTMLElement = document.getElementById(elementId);

    if (htmlElement == null) {
      console.log("null element");
      return;
    }

    console.log("htmlElement", htmlElement);

    const headers = new HttpHeaders({
      "Content-Type": "application/octet-stream",
      Accept: "application/octet-stream",
    });

    pdf.html(htmlElement, {
      html2canvas: {
        // insert html2canvas options here, e.g.
        scale: 0.5,
      },
      callback: (pdf) => {
        pdf.save(fileName + ".pdf");
      },
    });
  }

  async createPDF2(
    elementId: string,
    fileName: string,
    orientation: "p" | "portrait" | "l" | "landscape" = "p"
  ) {
    var pdf = new jsPDF(orientation, "pt", "A4");

    let htmlElement: HTMLElement = document.getElementById(elementId);

    if (htmlElement == null) {
      console.log("null element");
      return;
    }

    console.log("htmlElement", htmlElement);

    const headers = new HttpHeaders({
      "Content-Type": "application/octet-stream",
      Accept: "application/octet-stream",
    });

    pdf.html(htmlElement, {
      html2canvas: {
        // insert html2canvas options here, e.g.
        scale: 0.5,
      },
      callback: (pdf) => {
        pdf.save(fileName + ".pdf");
      },
    });
  }

  async emailFiles(
    to: string,
    cc: string,
    bcc: string,
    fileAttachments: FileAttachment[],
    subject: string = "",
    message: string = ""
  ) {
    if (fileAttachments == null || fileAttachments.length == 0) {
      this.alertService.openSnackBarDefault("There are no files to email.");
      return;
    }

    //console.log(fileAttachments);

    this.sendMailDialogRef = this.dialog.open(SendMailDialogComponent, {
      height: "auto",
      maxWidth: "90vw",
      data: {
        toAddresses: to,
        ccAddresses: cc,
        bcc: "",
        emailSubject: subject,
        emailMessage: message,
        fileAttachements: fileAttachments,
      },
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.sendMailDialogRef.backdropClick().subscribe(() => {
      this.sendMailDialogRef.close();
    });

    this.sendMailDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);
          //if (data.customerDeliveryAddress == null) {
          //  this.alertService.openSnackBar("There is no delivery address selected.", "Close", "center", "bottom", "", 5000);
          //  return;
          //}
          //if (data.customerDeliveryAddress != null) {
          //  this.alertService.openSnackBarDefault("Delivery address has been updated.");
          //}
        } else {
          // We have cancellled
        }
      });
  }

  //public async generatePdfList(elementArray: HTMLDivElement[], type: string, page = 1) {
  //  elementArray.forEach(a => {
  //    console.log("elem: ", a);
  //  });
  //  console.log('STEP 1:', new Date());
  //  //const elements = document.querySelectorAll('.staff-list-receipt');
  //  //const elementArray = Array.from(elements);
  //  const bufferPromises: Promise<any>[] = elementArray
  //    .filter(element => !!element)
  //    .map(element => this.elementToPdfBuffer(type, element, page));
  //  const pdfArrayBuffers = await Promise.all(bufferPromises);
  //  console.log('STEP 2:', new Date());
  //  const mergedPdf = await this.mergePdfs(pdfArrayBuffers);
  //  console.log('STEP 3:', mergedPdf);
  //  const pdfUrl = URL.createObjectURL(
  //    new Blob([mergedPdf], { type: 'application/pdf' }),
  //  );

  //  window.open(pdfUrl);
  //}

  //async elementToPdfBuffer(type, element, page) {
  //  // option 1:
  //  // const pdf: jsPDF = html2pdf().from(element).toPdf().get("pdf");
  //  // option 2:
  //  let arrBuffer: ArrayBuffer;
  //  var pdf = new jsPDF('p', 'pt', 'A4');
  //  await pdf.html(element, {
  //    html2canvas: {
  //      scale: 0.5
  //    },
  //    callback: async (pdf) => {
  //      arrBuffer = await pdf.output('arraybuffer');
  //      console.log("toc PDF: ", pdf);
  //    }
  //  });

  //  return arrBuffer;
  //}

  //async mergePdfs(pdfsToMerges: ArrayBuffer[]) {
  //  const mergedPdf = await PDFDocument.create();
  //  const actions = pdfsToMerges.map(async pdfBuffer => {
  //    const pdf = await PDFDocument.load(pdfBuffer);
  //    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  //    copiedPages.forEach((page) => {
  //       //console.log('page', page.getWidth(), page.getHeight());
  //      //page.setWidth(210);
  //      mergedPdf.addPage(page);
  //    });
  //  });
  //  await Promise.all(actions);
  //  const mergedPdfFile = await mergedPdf.save();
  //  //console.log('mergedPdfFile', mergedPdfFile);
  //  return mergedPdfFile;
  //}
}
