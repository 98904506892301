import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../account/auth.service';
import { dtoKeyValue } from '../../../_shared/business-objects/dto-int-key-value.bo';
import { Pole } from '../../../_shared/business-objects/pole.bo';
import { RoyaltyRate } from '../../../_shared/business-objects/royalty-rate.bo';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../../_shared/services/alert.service';
import { FormService } from '../../../_shared/services/form.service';
import { PoleService } from '../../../_shared/services/pole.service';
import { RoyaltyRateService } from '../../../_shared/services/royalty-rate.service';
import { SharedService } from '../../../_shared/services/shared.service';

@Component({
  selector: 'app-royalty-rate-update',
  templateUrl: './royalty-rate-update.component.html',
  styleUrls: ['./royalty-rate-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class RoyaltyRateUpdateComponent implements OnInit {

  form: FormGroup;

  @Output() royaltyRateUpdated = new EventEmitter<RoyaltyRate>();
  @Output() royaltyRateCancelled = new EventEmitter<boolean>();
  @Input() royaltyRate: RoyaltyRate;

  public polesFilterCtrl: FormControl = new FormControl();
  public poleContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public poles: Pole[];


  public filteredPoles: ReplaySubject<Pole[]> = new ReplaySubject<Pole[]>(1);

  private _onDestroy = new Subject<void>();

  durabilityClasses: dtoKeyValue[] = [];

  public firstNameMaxWidth: number;

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private alertService: AlertService, private authService: AuthService, private sharedService: SharedService
    , private poleService: PoleService, private royaltyRateService: RoyaltyRateService) {

    this.durabilityClasses = this.sharedService.getEnumObject2(RoyaltyRate.enDurabilityClass, true);

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      dteDateCreated: ['', Validators.required],
      guPoleId: ['', Validators.required],
      dteExpiry: [''],
      intDurabilityClass: ['', Validators.required],
      fltRoyaltyRate: [''],
      fltCHHRoyaltyRate: [''],
      txtComments: [''],
      chkCurrent: ['']
    });

    this.showSpinner = false;

    this.loadData();

  }

  async loadData() {

    const datepipe: DatePipe = new DatePipe('en-AU');

    await this.getPoles();

    this.form.controls['dteDateCreated'].setValue(this.royaltyRate.dteDateCreated);
    this.form.controls['guPoleId'].setValue(this.royaltyRate.guPoleId);
    this.form.controls['dteExpiry'].setValue(this.royaltyRate.dteDateExpires);
    this.form.controls['intDurabilityClass'].setValue(this.royaltyRate.intDurabilityClass);
    this.form.controls['fltRoyaltyRate'].setValue(this.royaltyRate.fltForestryRoyaltyRate);
    this.form.controls['fltCHHRoyaltyRate'].setValue(this.royaltyRate.fltCHHRoyaltyRate);
    this.form.controls['txtComments'].setValue(this.royaltyRate.txtComments);
    this.form.controls['chkCurrent'].setValue(this.royaltyRate.blnCurrent);


  }

  async getPoles() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnForestryRoyalty", "1"));
    aParamList.push(aParam);

    let poles = await this.poleService.getPoleParamArrayPromise(aParamList);
    if (poles == null) {
      return;
    }

    this.poles = poles;
    this.filteredPoles.next(this.poles);

    this.polesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPoles();
      });
  }

  private filterPoles() {

    if (!this.poles) {
      return;
    }

    // get the search keyword
    let search = this.polesFilterCtrl.value;
    if (!search) {
      this.filteredPoles.next(this.poles);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the Poles
    this.filteredPoles.next(
      this.poles.filter(c => (c.intLength.toString() + '/' + c.intKn.toString()).startsWith(search, 0) == true)
    );
  }



  async updateRoyaltyRate() {

    let dateCreated: Date = this.form.controls['dteDateCreated'].value;
    let pole: string = this.form.controls['guPoleId'].value;
    let expiry: Date = this.form.controls['dteExpiry'].value;
    let durabilityClass: number = this.form.controls['intDurabilityClass'].value;
    let royaltyrate: number = Number(this.form.controls['fltRoyaltyRate'].value);
    let chhRoyaltyRate: number = Number(this.form.controls['fltCHHRoyaltyRate'].value);
    let comments: string = this.form.controls['txtComments'].value;
    let current: boolean = this.form.controls['chkCurrent'].value == true;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (pole == null || pole.toString() == "") {
      this.alertService.openSnackBar("Please select a pole.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (durabilityClass == null || durabilityClass.toString() == "") {
      this.alertService.openSnackBar("Please select a durability class.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select a date created.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (expiry == null || expiry.toString() == "") {
      this.alertService.openSnackBar("Please select an expiry date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (this.royaltyRate.blnCurrent == true && current == false) {
      //We are deactivating royalty rate so set dteDateDeactivated.
      this.royaltyRate.dteDateDeactivated = new Date(datepipe.transform(expiry, 'yyyy-MM-dd'));
    }

    if (this.royaltyRate.blnCurrent == false && current == true) {
      //We are reactivating royalty rate so set dteDateDeactivated to null.
      //Perhaps should do this if we are accidentally ticking current on a non current record.
      let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Set as current", "Are you sure you want to set this Royalty Rate back to current?", "Yes", "No");
      if (msg != "Yes") {
        return;
      }
      this.royaltyRate.dteDateDeactivated = null;
    }

    this.royaltyRate.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
    this.royaltyRate.dteDateExpires = new Date(datepipe.transform(expiry, 'yyyy-MM-dd'));
    this.royaltyRate.guPoleId = pole;
    this.royaltyRate.intDurabilityClass = durabilityClass;
    this.royaltyRate.blnCurrent = current;
    this.royaltyRate.fltForestryRoyaltyRate = royaltyrate;
    this.royaltyRate.fltCHHRoyaltyRate = chhRoyaltyRate;
    this.royaltyRate.txtComments = comments;

    
    this.showSpinner = true;

    console.log(this.royaltyRate);

    this.royaltyRate = await this.royaltyRateService.updateRoyaltyRatePromise(this.royaltyRate);

    if (this.royaltyRate != null) {
      this.alertService.openSnackBar("The royalty rate has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.royaltyRateUpdated.emit(this.royaltyRate);
    }


    if (this.royaltyRate == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem updating the royalty rate", "Close", "center", "bottom", 3000, true, "There was a problem updating the royalty rate");
    }



  }

  cancelRoyaltyRate() {
    this.royaltyRateCancelled.emit(true);
  }

}
