import {
  animate,
  animateChild,
  query,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import { AfterViewInit, ElementRef } from "@angular/core";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSelectTrigger } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "../../account/auth.service";
import { DeliveryDocketCreateDialogComponent } from "../../delivery-docket/delivery-docket-create-dialog/delivery-docket-create-dialog.component";
import { DeliveryDocketUpdateDialogComponent } from "../../delivery-docket/delivery-docket-update-dialog/delivery-docket-update-dialog.component";
import { FinalInspectionItemCreateComponent } from "../../final-inspection-item/final-inspection-item-create/final-inspection-item-create.component";
import { FinalInspectionItemUpdateComponent } from "../../final-inspection-item/final-inspection-item-update/final-inspection-item-update.component";
import { ContractCutSawLog } from "../../_shared/business-objects/contract-cut-saw-log.bo";
import { DeliveryDocket } from "../../_shared/business-objects/delivery-docket.bo";
import { dtoDeliveryDocket } from "../../_shared/business-objects/dto-delivery-docket.bo";
import { dtoOrdersDeliveryRate } from "../../_shared/business-objects/dto-orders-delivery-rate.bo";
import { dtoOrders } from "../../_shared/business-objects/dto-orders.bo";
import { dtoTPPoleInspectionItemSummary } from "../../_shared/business-objects/dto-tppole-inspection-item-summary.bo";
import { dtoTPPoleInspectionItem } from "../../_shared/business-objects/dto-tppole-inspection-item.bo";
import { dtoTPPoleInspection } from "../../_shared/business-objects/dto-tppole-inspection.bo";
import { Orders } from "../../_shared/business-objects/orders.bo";
import { Printer } from "../../_shared/business-objects/printer.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { TPPoleInspectionItem } from "../../_shared/business-objects/tppole-inspection-item.bo";
import { TPPoleInspection } from "../../_shared/business-objects/tppole-inspection.bo";
import { AlertService } from "../../_shared/services/alert.service";
import { AppPrinterService } from "../../_shared/services/app-printer.service";
import { DeliveryDocketService } from "../../_shared/services/delivery-docket.service";
import { dtoTPPoleInspectionItemSummaryService } from "../../_shared/services/dto-tppole-inspection-item-summary.service";
import { dtoTPPoleInspectionItemService } from "../../_shared/services/dto-tppole-inspection-item.service";
import { dtoTPPoleInspectionLoadingDocketService } from "../../_shared/services/dto-tppole-inspection-loading-docket.service";
import { FormService } from "../../_shared/services/form.service";
import { LoadingDocketService } from "../../_shared/services/loading-docket.service";
import { OrderService } from "../../_shared/services/order.service";
import { SharedService } from "../../_shared/services/shared.service";
import { TPPoleInspectionItemService } from "../../_shared/services/tppole-inspection-item.service";
import { TPPoleInspectionService } from "../../_shared/services/tppole-inspection.service";
import { dtoOrdersDeliveryRateService } from "../../_shared/services/dto-orders-delivery-rate.service";
import { dtoDeliveryDocketOrderItem } from "../../_shared/business-objects/dto-delivery-docket-order-item.bo";
import { dtoDeliveryDocketOrderItemService } from "../../_shared/services/dto-delivery-docket-order-item.service";
import { LoadingDocket } from "../../_shared/business-objects/loading-docket.bo";
import { LoadingLocation } from "../../_shared/business-objects/loading-location.bo";
import { LoadingLocationService } from "../../_shared/services/loading-location.service";
import { LoadingDocketDelivery } from "../../_shared/business-objects/loading-docket-delivery.bo";
import { LoadingDocketDeliveryService } from "../../_shared/services/loading-docket-delivery.service";
import { FinalInspectionItemListComponent } from "../../final-inspection-item/final-inspection-item-list/final-inspection-item-list.component";
import { dtoTPPoleInspectionService } from "../../_shared/services/dto-tppole-inspection.service";
import { dtoOrdersCustomerDeliveryContactService } from "../../_shared/services/dto-orders-customer-delivery-contact.service";
import { dtoOrdersCustomerDeliveryContact } from "../../_shared/business-objects/dto-orders-customer-delivery-contact.bo";
import { dtoDeliveryDocketCustomerDeliveryContact } from "../../_shared/business-objects/dto-delivery-docket-customer-delivery-contact.bo";
import { DeliveryDocketCustomerDeliveryContact } from "../../_shared/business-objects/delivery-docket-customer-delivery-contact.bo";
import { DeliveryDocketCustomerDeliveryContactService } from "../../_shared/services/delivery-docket-customer-delivery-contact.service";
import { dtoTPChargeItem } from "../../_shared/business-objects/dto-tpcharge-item.bo";
import { TPChargeItem } from "../../_shared/business-objects/tpcharge-item.bo";
import { TPChargeItemService } from "../../_shared/services/tpcharge-item.service";
import { FileService } from "../../_shared/services/app-services/file.service";
import { FinalInspectionItemPrintComponent } from "../../final-inspection-item/final-inspection-item-print/final-inspection-item-print.component";
import { FileAttachment } from "../../_shared/app-objects/file-attachment";

@Component({
  selector: "app-final-inspection-list",
  templateUrl: "./final-inspection-list.component.html",
  styleUrls: ["./final-inspection-list.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", [
        animate("325ms cubic-bezier(0.4, 0.0, 0.2, 1)"), //225
        query("@*", animateChild(), { optional: true }),
      ]),
    ]),
  ],
})
export class FinalInspectionListComponent implements OnInit, AfterViewInit {
  @Input() datasource: MatTableDataSource<dtoTPPoleInspection>;
  @Input() finalInspectionAdd: dtoTPPoleInspection;
  @Input() isSupervisor: boolean;
  @Input() elementRef: ElementRef;
  @Output() editFinalInspectionEvent = new EventEmitter<dtoTPPoleInspection>();
  @Output() removeFinalInspectionEvent =
    new EventEmitter<dtoTPPoleInspection>();
  @Output() addFinalInspectionEvent = new EventEmitter<dtoTPPoleInspection>();
  @Output() changeViewEvent = new EventEmitter<number>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("appfinalinspectionitemlist")
  appfinalinspectionitemlist: FinalInspectionItemListComponent;
  @ViewChild("finalInspectionItemPrintApp")
  finalInspectionItemPrintApp: FinalInspectionItemPrintComponent;

  deliveryDocketCreateDialogRef: MatDialogRef<DeliveryDocketCreateDialogComponent>;

  supervisorfinalInspections: string[] = [
    "intFinalNo",
    "dteDateInspected",
    "txtCustomerLocation",
    "txtOrderNo",
    "intTotalPoleQuantity",
    "fltTotalActualM3",
    "fltTotalActualTonne",
    "txtStatus",
    "txtDeliveryStatus",
    "btnEditFinalInspection",
    "btnSetStatus",
    "btnAddFinalInspectionItem",
    "btnCreateLoadingDocket",
    "btnAddToDeliveryDocket",
    "btnCreateDeliveryDocket",
  ]; //, 'btnViewLoadingDocket'
  displayedfinalInspections: string[] = [
    "intFinalNo",
    "dteDateInspected",
    "txtCustomerLocation",
    "txtOrderNo",
    "intTotalPoleQuantity",
    "fltTotalActualM3",
    "fltTotalActualTonne",
    "txtStatus",
    "txtDeliveryStatus",
    "intLoadNo",
    "txtSeparator",
    "intMaxLoads",
    "intMinPoleLength",
    "intMaxPoleLength",
    "btnEditFinalInspection",
    "btnSetStatus",
    "btnAddFinalInspectionItem",
    "btnRemoveFinalInspection",
    "btnCreateLoadingDocket",
    "btnAddToDeliveryDocket",
    "btnCreateDeliveryDocket",
    "btnCreateDiscCSV",
  ];

  finalInspectionExpanded: dtoTPPoleInspection;

  finalInspectionItems: dtoTPPoleInspectionItem[] = [];
  finalInspectionItemSummary: dtoTPPoleInspectionItemSummary[] = [];
  finalInspectionItemSummaryS1QtyTotal: number;
  finalInspectionItemSummaryS2QtyTotal: number;
  finalInspectionItemSummaryS3QtyTotal: number;
  finalInspectionItemSummaryBBTQtyTotal: number;
  finalInspectionItemSummaryQtyTotal: number;
  finalInspectionItemSummaryM3Total: number;
  finalInspectionItemSummaryTonneTotal: number;

  finalItemRef: MatDialogRef<FinalInspectionItemCreateComponent>;
  finalItemUpdateRef: MatDialogRef<FinalInspectionItemUpdateComponent>;
  deliveryDocketUpdateDialogRef: MatDialogRef<DeliveryDocketUpdateDialogComponent>;

  selectedRow: string = "";

  viewType: number = 0;
  finalInspectionPDFId: string = "";

  constructor(
    private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService,
    private dtoTPPoleInspectionService: dtoTPPoleInspectionService,
    private tpPoleInspectionItemService: TPPoleInspectionItemService,
    private tpChargeItemService: TPChargeItemService,
    private dialog: MatDialog,
    private tpPoleInspectionService: TPPoleInspectionService,
    private alertService: AlertService,
    private loadingDocketService: LoadingDocketService,
    private dtoTPPoleInspectionLoadingDocketService: dtoTPPoleInspectionLoadingDocketService,
    private appPrinterService: AppPrinterService,
    private dtoTPPoleInspectionItemSummaryService: dtoTPPoleInspectionItemSummaryService,
    private formService: FormService,
    private sharedService: SharedService,
    private deliveryDocketService: DeliveryDocketService,
    private orderService: OrderService,
    private authService: AuthService,
    private dtoOrdersDeliveryRateService: dtoOrdersDeliveryRateService,
    private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService,
    private loadingLocationService: LoadingLocationService,
    private loadingDocketDelivery: LoadingDocketDeliveryService,
    private dtoOrdersCustomerDeliveryContactService: dtoOrdersCustomerDeliveryContactService,
    private deliveryDocketCustomerDeliveryContactService: DeliveryDocketCustomerDeliveryContactService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.finalInspectionItemPrintApp.printPDFMode = true;
    }, 300);
  }

  ngAfterViewInit() {
    //this.datasource.sort = this.sort;
  }

  setIsSupervisor(isSupervisor: boolean) {
    this.isSupervisor = isSupervisor;

    //if (this.isSupervisor == true) {
    //  this.displayedfinalInspections = this.supervisorfinalInspections;
    //}
  }

  customerLocation(finalInspection: dtoTPPoleInspection) {
    if (
      finalInspection.txtCustomer == null ||
      finalInspection.txtLocation == null
    ) {
      return;
    }

    return finalInspection.txtCustomer.indexOf(",") > -1 &&
      finalInspection.txtLocation.indexOf(",") > -1
      ? finalInspection.txtCustomerLocation
      : finalInspection.txtCustomer + " - " + finalInspection.txtLocation;
  }

  editFinalInspection(finalInspection: dtoTPPoleInspection, rowIdx: number) {
    this.editFinalInspectionEvent.emit(finalInspection);
  }

  removeFinalInspection(finalInspection: dtoTPPoleInspection) {
    this.removeFinalInspectionEvent.emit(finalInspection);
  }

  printFinalInspection(finalInspection: dtoTPPoleInspection) {
    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf("localhost") == -1) {
      path = "https://" + location.hostname;
    }

    var windowUrl =
      path +
      "/final-inspection-item-print?finalinspectionid=" +
      finalInspection.rowguid +
      "&hidenav=yes&openprintdialog=yes";
    var uniqueName = new Date();
    var windowName = "_blank";

    var printWindow = window.open(windowUrl, windowName).focus();
  }

  //editFinalInspectionItem(finalInspectionItem: dtoTPPoleInspectionItem) {

  //  this.editFinalInspectionEvent.emit(finalInspectionItem);

  //}

  expandElementAndSetColor(finalInspection: dtoTPPoleInspection, idx: number) {
    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(finalInspection.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(finalInspection, idx);
      this.selectedRow = finalInspection.rowguid; //Always highlight row when double clicking.

      //window.alert('ondouble');
    }
  }

  async expandElement(finalInspection: dtoTPPoleInspection, index: number) {
    //console.log(this.finalInspectionExpanded === finalInspection);

    this.finalInspectionExpanded =
      this.finalInspectionExpanded === finalInspection ? null : finalInspection;

    //console.log(this.finalInspectionExpanded === finalInspection);

    //this.viewType = 0;

    await this.loadFinalInspectionItems(finalInspection);
    await this.loadFinalInspectionItemSummary(finalInspection);
  }

  async loadFinalInspectionItems(finalInspection: dtoTPPoleInspection) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(
      new SQLParamArray("guTPPoleInspectionId", finalInspection.rowguid)
    );
    aParamList.push(aParam);

    this.finalInspectionItems =
      await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(
        aParamList
      );

    //this.loadFinalInspectionItemSummary(finalInspection);
    if (this.appfinalinspectionitemlist != null) {
      this.appfinalinspectionitemlist.datasource = this
        .finalInspectionItems as unknown as MatTableDataSource<dtoTPPoleInspectionItem>;
      this.appfinalinspectionitemlist.calcTotals();
    }

    //console.log(this.finalInspectionItems);
  }

  async loadFinalInspectionItemSummary(finalInspection: dtoTPPoleInspection) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(
      new SQLParamArray("intFinalNo", finalInspection.intFinalNo.toString())
    );
    aParamList.push(aParam);

    this.finalInspectionItemSummary =
      await this.dtoTPPoleInspectionItemSummaryService.getdtoTPPoleInspectionItemSummaryParamArrayPromise(
        aParamList
      );

    this.finalInspectionItemSummaryS1QtyTotal = 0;
    this.finalInspectionItemSummaryS2QtyTotal = 0;
    this.finalInspectionItemSummaryS3QtyTotal = 0;
    this.finalInspectionItemSummaryBBTQtyTotal = 0;
    this.finalInspectionItemSummaryQtyTotal = 0;
    this.finalInspectionItemSummaryM3Total = 0;
    this.finalInspectionItemSummaryTonneTotal = 0;

    if (this.finalInspectionItemSummary != null) {
      this.finalInspectionItemSummary.forEach((finalSum, index, arr) => {
        this.finalInspectionItemSummaryS1QtyTotal += finalSum.intS1;
        this.finalInspectionItemSummaryS2QtyTotal += finalSum.intS2;
        this.finalInspectionItemSummaryS3QtyTotal += finalSum.intS3;
        this.finalInspectionItemSummaryBBTQtyTotal += finalSum.intBBT;
        this.finalInspectionItemSummaryQtyTotal += finalSum.intTotalQuantity;
        this.finalInspectionItemSummaryM3Total += finalSum.fltActualM3;
        this.finalInspectionItemSummaryTonneTotal += finalSum.fltActualTonne;
      });

      this.finalInspectionItemSummaryTonneTotal =
        Math.round(
          (this.finalInspectionItemSummaryTonneTotal + Number.EPSILON) * 100
        ) / 100;
      this.finalInspectionItemSummaryM3Total =
        Math.round(
          (this.finalInspectionItemSummaryM3Total + Number.EPSILON) * 100
        ) / 100;
    }
    //console.log("finalInspectionItemSummaryTotal: " + this.finalInspectionItemSummaryTotal);
  }

  refreshFinalInspectionItems(
    update: boolean,
    finalInspection: dtoTPPoleInspection
  ) {
    //console.log("refreshFinalInspectionItems:");
    //console.log("update: " + update);

    if (update == true) {
      //console.log("inside update: " + update);
      this.loadFinalInspectionItems(finalInspection);
    }
  }

  async addFinalInspectionItem(
    finalInspection: dtoTPPoleInspection,
    rowIdx: number
  ) {
    if (this.finalItemRef != null) {
      this.finalItemRef.close();
    }

    if (this.finalInspectionExpanded != finalInspection) {
      await this.expandElement(finalInspection, rowIdx);
    }

    this.finalItemRef = this.dialog.open(FinalInspectionItemCreateComponent, {
      position: { top: "20px" },
      data: {
        finalInspection: finalInspection,
        positionRelativeToElement: this.elementRef,
      },
      height: "98vh",
      maxWidth: "98vw",
      maxHeight: "98vh",
    });

    this.finalItemRef.componentInstance.refreshFinalInspectionItemsEvent.subscribe(
      (data) => {
        //console.log("componentInstance.refreshFinalInspectionItemsEvent: " + data);
        this.refreshFinalInspectionItems(data, finalInspection);
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.finalItemRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    if (
      this.finalInspectionItems != null &&
      this.finalInspectionItems.length > 0
    ) {
      let lastFinalItem: TPPoleInspectionItem =
        this.finalInspectionItems[this.finalInspectionItems.length - 1];
      this.finalItemRef.componentInstance.customerId =
        lastFinalItem.guCustomerId;
      this.finalItemRef.componentInstance.orderId = lastFinalItem.guOrderId;
    }

    // this.finalItemRef.backdropClick().subscribe(() => {
    //   this.finalItemRef.close();
    // });

    this.finalItemRef
      .afterClosed()
      //.pipe(filter(data => data))
      .subscribe(
        async (data: {
          status: string;
          finalInspectionItem: TPPoleInspectionItem;
          selectedTPChargeItem: dtoTPChargeItem;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log("data - ", data);
          //console.log("data status - ", data.status);
          if (data.status != "Cancel") {
            //console.log(data.finalInspectionItem);
            let finalItem: TPPoleInspectionItem =
              await this.tpPoleInspectionItemService.createTPPoleInspectionItemPromise(
                data.finalInspectionItem
              );

            //Link this final item to the charge item so we know not to use this consecutive nubmer again.
            if (data.selectedTPChargeItem != null) {
              let chargeItem: TPChargeItem =
                await this.tpChargeItemService.getTPChargeItemPromise(
                  data.selectedTPChargeItem.rowguid
                );
              chargeItem.guTPPoleInspectionItemId = finalItem.rowguid;
              await this.tpChargeItemService.updateTPChargeItemPromise(
                chargeItem
              );
              console.log("chargeItem: ", chargeItem);
            }

            await this.loadFinalInspectionItems(finalInspection);
          } else {
            // We have cancellled
          }
        }
      );
  }

  editfinalInspectionItem(
    finalInspectionItem: TPPoleInspectionItem,
    finalInspection: dtoTPPoleInspection,
    rowIdx: number
  ) {
    //console.log(finalInspectionItem);

    if (this.finalItemUpdateRef != null) {
      this.finalItemUpdateRef.close();
    }

    if (this.finalInspectionExpanded != finalInspection) {
      this.expandElement(finalInspection, rowIdx);
    }

    this.finalItemUpdateRef = this.dialog.open(
      FinalInspectionItemUpdateComponent,
      {
        data: {
          finalInspection: finalInspection,
          finalInspectionItem: finalInspectionItem,
        },
        height: "98vh",
        maxWidth: "98vw",
        maxHeight: "98vh",
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.finalItemUpdateRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.finalItemUpdateRef
      .afterClosed()
      //.pipe(filter(data => data))
      .subscribe(
        async (data: {
          status: string;
          finalInspectionItem: TPPoleInspectionItem;
          selectedTPChargeItem: dtoTPChargeItem;
        }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the FinalInspectionCreate dialog, null data."
            );
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.finalInspectionItem");
            console.log("data.finalInspectionItem: ", data.finalInspectionItem);
            let finalItem: TPPoleInspectionItem =
              await this.tpPoleInspectionItemService.updateTPPoleInspectionItemPromise(
                data.finalInspectionItem
              );

            //Link this final item to the charge item so we know not to use this consecutive nubmer again.
            if (data.selectedTPChargeItem != null) {
              let chargeItem: TPChargeItem =
                await this.tpChargeItemService.getTPChargeItemPromise(
                  data.selectedTPChargeItem.rowguid
                );
              chargeItem.guTPPoleInspectionItemId = finalItem.rowguid;
              await this.tpChargeItemService.updateTPChargeItemPromise(
                chargeItem
              );
              console.log("chargeItem: ", chargeItem);
            }

            await this.loadFinalInspectionItems(finalInspection);
          } else {
            // We have cancellled
          }
        }
      );
  }

  async removefinalInspectionItem(
    finalInspectionItem: TPPoleInspectionItem,
    finalInspection: dtoTPPoleInspection
  ) {
    if (finalInspectionItem == null) {
      this.alertService.openSnackBar(
        "There is no final inspection item to remove",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
    }

    let poleNumber: string = "";

    if (
      finalInspectionItem.txtPoleNumber != null &&
      finalInspectionItem.txtPoleNumber != ""
    ) {
      poleNumber = finalInspectionItem.txtPoleNumber;
    }

    if (
      finalInspectionItem.txtPoleNumber == null ||
      finalInspectionItem.txtPoleNumber == ""
    ) {
      poleNumber = finalInspectionItem.txtConsecutiveNumber;
    }

    let response: string = await this.alertService.openSnackBarCustomPromise(
      "Remove Inspection Item",
      "Are you sure you want to remove this inspection item " +
        poleNumber +
        "?",
      "Yes",
      "No",
      "center",
      "bottom",
      "",
      0
    );

    if (response == "Yes") {
      let removed: boolean =
        await this.tpPoleInspectionItemService.removeTPPoleInspectionItemPromise(
          finalInspectionItem.rowguid
        );
      //console.log("removed before: " + removed);

      if (removed == true) {
        //console.log("removed after: " + removed);
        this.alertService.openSnackBar(
          "The inspection item " + poleNumber + " has been removed",
          "Close",
          "center",
          "bottom",
          "",
          3000
        );

        await this.tpPoleInspectionItemService.reSetItemNumbers(
          finalInspection.rowguid
        );

        await this.loadFinalInspectionItems(finalInspection);
      }
    }
  }

  async setStatusComplete(finalInspection: dtoTPPoleInspection, index: number) {
    let finalInsp: TPPoleInspection =
      await this.tpPoleInspectionService.getTPPoleInspectionPromise(
        finalInspection.rowguid
      );

    finalInsp.intStatus = TPPoleInspection.enStatus.Complete;
    await this.tpPoleInspectionService.updateTPPoleInspectionPromise(finalInsp);

    this.datasource.data[index].intStatus = finalInsp.intStatus;
    this.datasource.data[index].txtStatus =
      this.tpPoleInspectionService.statusText(finalInsp.intStatus);

    this.alertService.openSnackBar(
      "The Final Inspection is now complete",
      "Close",
      "center",
      "bottom",
      "",
      3000
    );
  }

  async setStatusReadyForLoading(
    finalInspection: dtoTPPoleInspection,
    index: number
  ) {
    let msg = await this.alertService.openSnackBarCustomPromise(
      "Complete Final",
      "Are you sure you want to Complete this Final",
      "Yes",
      "No",
      "center",
      "bottom",
      "",
      0
    );
    if (msg != "Yes") {
      return;
    }

    let finalInsp: TPPoleInspection =
      await this.tpPoleInspectionService.getTPPoleInspectionPromise(
        finalInspection.rowguid
      );

    finalInsp.intStatus = TPPoleInspection.enStatus.ReadyForLoading;
    await this.tpPoleInspectionService.updateTPPoleInspectionPromise(finalInsp);

    this.datasource.data[index].intStatus = finalInsp.intStatus;
    this.datasource.data[index].txtStatus =
      this.tpPoleInspectionService.statusText(finalInsp.intStatus);

    this.alertService.openSnackBar(
      "The Final Inspection is now complete",
      "Close",
      "center",
      "bottom",
      "",
      3000
    );
  }

  async createLoadingDocket(finalInspection: dtoTPPoleInspection) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(
      new SQLParamArray("intFinalNo", finalInspection.intFinalNo.toString())
    );
    aParamList.push(aParam);

    let finalPoleInspectionLoadingDockets =
      await this.dtoTPPoleInspectionLoadingDocketService.getdtoTPPoleInspectionLoadingDocketParamArrayPromise(
        aParamList
      );

    //console.log(finalPoleInspectionLoadingDockets);

    this.loadingDocketService.downloadLoadingDockect(
      finalPoleInspectionLoadingDockets
    );
  }

  async createDeliveryDocket(finalInspection: dtoTPPoleInspection) {
    let path: string =
      "https://localhost:44356/delivery-docket-search?docketno=61731&tab=4";
    //const url = window.URL.createObjectURL(path);
    window.open(path);

    //this.openDeliveryDocketDialog();
  }

  async addToDeliveryDocket(finalInspection: dtoTPPoleInspection) {
    let msg: string = await this.alertService.openSnackBarCustomPromise(
      "Create Delivery Docket",
      "Are you sure you want to create a delivery docket?",
      "Yes",
      "No",
      "center",
      "bottom",
      "",
      0
    );

    if (msg != "Yes") {
      return;
    }

    let knowHauler: string = await this.alertService.openSnackBarCustomPromise(
      "Hauler",
      "Do you know who is hauling it yet?",
      "Yes",
      "No",
      "center",
      "bottom",
      "",
      0
    );

    let pickDeliveryRate: boolean = false;
    let customerid: string;
    let orderid: string;
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let addDeliveryCharge: boolean = false;
    const datepipe: DatePipe = new DatePipe("en-AU");

    aParam.push(
      new SQLParamArray("guTPPoleInspectionId", finalInspection.rowguid)
    );
    aParamList.push(aParam);

    let finalItems: dtoTPPoleInspectionItem[] =
      await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(
        aParamList
      );

    if (finalItems == null || finalItems.length == 0) {
      this.alertService.openSnackBar(
        "There are no final items to put on a delivery docket",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      return;
    }

    //Get first customer id
    for (let i = 0; i <= finalItems.length - 1; i++) {
      customerid = finalItems[i].guCustomerId;
      orderid = finalItems[i].guOrderId;
      break;
    }

    let order: dtoOrders = await this.orderService.getDTOOrderPromise(orderid);
    let customerContactId = order.guCustomerContactId;

    let deliveryDocket: DeliveryDocket = new DeliveryDocket();
    deliveryDocket.intSystem = 0;
    deliveryDocket.intDeliveryType = DeliveryDocket.enDeliveryType.Delivery;
    //deliveryDocket.dteCreatedDate = this.sharedService.currentDatePlusTZOffset();
    deliveryDocket.dteCreatedDate = new Date(
      datepipe.transform(Date.now(), "yyyy-MM-dd")
    );
    deliveryDocket.guCustomerId = customerid;
    deliveryDocket.guEmployeeCreatedId =
      this.authService.getAppUserEmployeeId();
    deliveryDocket.intLoadNo = finalInspection.intLoadNo;
    deliveryDocket.intMaxLoads = finalInspection.intMaxLoads;

    //deliveryDocket.dteStatusDate = new Date(datepipe.transform(this.sharedService.currentDatePlusTZOffset(), 'yyyy-MM-dd'));
    deliveryDocket.dteStatusDate = new Date(
      datepipe.transform(Date.now(), "yyyy-MM-dd")
    );
    deliveryDocket.intStatus = DeliveryDocket.enDeliveryStatus.ReadyForDispatch;

    //console.log("blnAddDeliveryChargeToDocket", finalInspection.blnAddDeliveryChargeToDocket);
    //console.log("orderid", orderid);
    if (finalInspection.blnAddDeliveryChargeToDocket == true) {
      let aParamList2: SQLParamArray[][] = [];
      let aParam2: SQLParamArray[] = [];

      aParam2.push(new SQLParamArray("guOrderId", orderid));
      aParamList2.push(aParam2);

      let deliveryDocketRateArray: dtoOrdersDeliveryRate[] =
        await this.dtoOrdersDeliveryRateService.getdtoOrdersDeliveryRateParamArrayPromise(
          aParamList2
        );

      //console.log("deliveryDocketRateArray", deliveryDocketRateArray);

      if (deliveryDocketRateArray != null) {
        //console.log("deliveryDocketRateArray", deliveryDocketRateArray);

        //ASK WHICH DELIVERY RATE APPLIES.
        if (deliveryDocketRateArray.length > 1) {
          pickDeliveryRate = true;
        }

        if (deliveryDocketRateArray.length == 1) {
          //console.log("deliveryDocketRateArray only 1:", deliveryDocketRateArray);
          deliveryDocket.fltDeliveryCharge =
            deliveryDocketRateArray[0].fltPrice;
          deliveryDocket.fltEscort = deliveryDocketRateArray[0].fltEscort;
          deliveryDocket.fltAdditionalKM =
            deliveryDocketRateArray[0].fltAdditionalKM;
        }
      }
    }

    //console.log(deliveryDocket);

    deliveryDocket =
      await this.deliveryDocketService.createDeliveryDocketPromise(
        deliveryDocket
      );

    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("guOrderId", orderid));
    aParamList.push(aParam);

    let ordersCustomerDeliveryContact: dtoOrdersCustomerDeliveryContact[] =
      await this.dtoOrdersCustomerDeliveryContactService.getdtoOrdersCustomerDeliveryContactParamArrayPromise(
        aParamList
      );
    if (ordersCustomerDeliveryContact != null) {
      for (let i = 0; i <= ordersCustomerDeliveryContact.length - 1; i++) {
        let deliveryCustomerDeliveryContact: DeliveryDocketCustomerDeliveryContact =
          new DeliveryDocketCustomerDeliveryContact();
        deliveryCustomerDeliveryContact.guCustomerContactId =
          ordersCustomerDeliveryContact[i].guCustomerContactId;
        deliveryCustomerDeliveryContact.guDeliveryDocketId =
          deliveryDocket.rowguid;
        deliveryCustomerDeliveryContact.dteDateCreated =
          this.sharedService.currentDatePlusTZOffset();
        await this.deliveryDocketCustomerDeliveryContactService.createDeliveryDocketCustomerDeliveryContactPromise(
          deliveryCustomerDeliveryContact
        );
      }
    }

    if (
      ordersCustomerDeliveryContact == null ||
      ordersCustomerDeliveryContact.length == 0
    ) {
      //deliveryDocket.guCustomerContactId = customerContactId;
      let deliveryCustomerDeliveryContact: DeliveryDocketCustomerDeliveryContact =
        new DeliveryDocketCustomerDeliveryContact();
      deliveryCustomerDeliveryContact.guCustomerContactId = customerContactId;
      deliveryCustomerDeliveryContact.guDeliveryDocketId =
        deliveryDocket.rowguid;
      deliveryCustomerDeliveryContact.dteDateCreated =
        this.sharedService.currentDatePlusTZOffset();
      await this.deliveryDocketCustomerDeliveryContactService.createDeliveryDocketCustomerDeliveryContactPromise(
        deliveryCustomerDeliveryContact
      );
    }

    // CREATE LOADIKNG DOCKET.
    let aParamList2: SQLParamArray[][] = [];
    let aParam2: SQLParamArray[] = [];

    aParam2.push(new SQLParamArray("txtName", "CHH"));
    aParamList2.push(aParam2);

    let loadingLocations: LoadingLocation[] =
      await this.loadingLocationService.getLoadingLocationParamArrayPromise(
        aParamList2
      );

    let loadingDocket: LoadingDocket = new LoadingDocket();
    loadingDocket.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
    loadingDocket.dteDateLoaded = null;
    loadingDocket.intLoadNo = finalInspection.intLoadNo;
    loadingDocket.intMaxLoads = finalInspection.intMaxLoads;
    loadingDocket.intYardSection = SharedService.enYardSection.TreatmentPlant;

    if (loadingLocations != null) {
      loadingDocket.guLoadingLocationId = loadingLocations[0].rowguid;
    }

    loadingDocket = await this.loadingDocketService.createLoadingDocketPromise(
      loadingDocket
    );

    //console.log("loadingDocket", loadingDocket);

    let loadingDocketDelivery: LoadingDocketDelivery =
      new LoadingDocketDelivery();
    loadingDocketDelivery.guDeliveryDocketId = deliveryDocket.rowguid;
    loadingDocketDelivery.guLoadingDocketId = loadingDocket.rowguid;
    //loadingDocketDelivery.txtDestination = '';

    await this.loadingDocketDelivery.createLoadingDocketDeliveryPromise(
      loadingDocketDelivery
    );

    if (deliveryDocket == null) {
      this.alertService.openSnackBarError(
        "There was a problem creating the delivery docket",
        "Close",
        "center",
        "bottom",
        4000,
        true,
        "error creating delivery docket from final inspection button"
      );
      return;
    }

    if (deliveryDocket != null) {
      deliveryDocket =
        await this.deliveryDocketService.getDeliveryDocketPromise(
          deliveryDocket.rowguid
        );

      if (knowHauler != "Yes") {
        this.alertService.openSnackBar(
          "The delivery docket has been created",
          "Close",
          "center",
          "bottom",
          "",
          3000
        );
      }
    }

    let setFinalComplete: string =
      await this.alertService.openSnackBarCustomPromise(
        "Set Complete",
        "Do you want to set this final as Complete?",
        "Yes",
        "No",
        "center",
        "bottom",
        "",
        0
      );

    if (setFinalComplete == "Yes") {
      let tpPoleInspection: TPPoleInspection =
        await this.tpPoleInspectionService.getTPPoleInspectionPromise(
          finalInspection.rowguid
        );
      if (tpPoleInspection != null) {
        tpPoleInspection.intStatus = TPPoleInspection.enStatus.Complete;
        await this.tpPoleInspectionService.updateTPPoleInspectionPromise(
          tpPoleInspection
        );
      }
    }

    if (knowHauler == "Yes" || pickDeliveryRate == true) {
      let haulerAndRateMsg: string = "";
      if (knowHauler == "Yes") {
        haulerAndRateMsg = "Please select a hauler";
      }

      if (knowHauler == "Yes" && pickDeliveryRate == true) {
        haulerAndRateMsg += " and also select a delivery rate";
      }

      //console.log("knowHauler : " + knowHauler);
      //console.log("pickDeliveryRate : " + pickDeliveryRate);

      if (knowHauler != "Yes" && pickDeliveryRate == true) {
        haulerAndRateMsg += "Please select a delivery rate";
      }

      let deliveryPopupMsg: string =
        await this.alertService.openSnackBarCustomPromise(
          "Create Delivery Docket",
          haulerAndRateMsg,
          "Ok",
          "",
          "center",
          "bottom",
          "",
          0,
          false
        );

      this.updateDeliveryDocket(
        deliveryDocket,
        loadingDocket,
        finalInspection,
        orderid
      );
    }

    if (knowHauler != "Yes" && pickDeliveryRate == false) {
      // OPEN DELVERY DOCKET ADD ITEMS TAB.
      let url: string =
        "https://" +
        window.location.host +
        "/delivery-docket-search?tab=4&docketno=" +
        deliveryDocket.intDocketNo.toString() +
        "&finalno=" +
        finalInspection.intFinalNo.toString() +
        "&viewtype=1";
      window.open(url);
    }
  }

  async updateDeliveryDocket(
    deliveryDocket: DeliveryDocket,
    loadingDocket: LoadingDocket,
    finalInspection: dtoTPPoleInspection,
    orderId: string
  ) {
    if (this.deliveryDocketUpdateDialogRef != null) {
      this.deliveryDocketUpdateDialogRef.close();
    }

    this.deliveryDocketUpdateDialogRef = this.dialog.open(
      DeliveryDocketUpdateDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
        data: { deliveryDocket: deliveryDocket, orderId: orderId },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    // this.deliveryDocketUpdateDialogRef.backdropClick().subscribe(() => {
    //   this.deliveryDocketUpdateDialogRef.close();
    // });

    this.deliveryDocketUpdateDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; deliveryDocket: DeliveryDocket }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the FinalInspectionCreate dialog, null data."
            );
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            // OPEN DELVERY DOCKET ADD ITEMS TAB.
            let url: string =
              "https://" +
              window.location.host +
              "/delivery-docket-search?tab=4&docketno=" +
              deliveryDocket.intDocketNo.toString() +
              "&finalno=" +
              finalInspection.intFinalNo.toString() +
              "&viewtype=1";
            window.open(url);
          } else {
            // We have cancellled
          }
        }
      );
  }

  async printLoadingDocket(finalInspection: dtoTPPoleInspection) {
    let printers: Printer[] =
      await this.appPrinterService.getPrintersByAppNameAndTypePromise(
        "WEBAPP-TP",
        Printer.enType.Normal
      );

    // Should only be one printer.
    // If we have two printers then we will need to open dialog so user can pick.
    // Alternatively we should set up user preferences for default printer!!

    if (printers != null && printers.length > 0) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(
        new SQLParamArray("intFinalNo", finalInspection.intFinalNo.toString())
      );
      aParamList.push(aParam);

      let finalPoleInspectionLoadingDockets =
        await this.dtoTPPoleInspectionLoadingDocketService.getdtoTPPoleInspectionLoadingDocketParamArrayPromise(
          aParamList
        );

      //this.loadingDocketService.printLoadingDockect(finalPoleInspectionLoadingDockets, printers[0].rowguid).subscribe(data => {

      //  console.log(data);

      //}, err => {
      //  console.log(err);
      //})
    }
  }

  async downloadFinalInspection(finalInspection: dtoTPPoleInspection) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    let finalItems =
      await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemByInspectionIdPromise(
        finalInspection.rowguid
      );

    if (finalItems == null) {
      this.alertService.openSnackBarError(
        "There was a problem getting the final items",
        "Close",
        "center",
        "bottom",
        4000,
        true,
        "There was a problem getting the final items"
      );
      return;
    }

    console.log(finalItems);

    this.dtoTPPoleInspectionItemService.downloadDeliveryDockect(finalItems);

    this.rowColorPropagation(finalInspection.rowguid);
  }

  async createFinalPDF(finalInspection: dtoTPPoleInspection) {
    this.finalInspectionPDFId = finalInspection.rowguid;

    this.finalInspectionItemPrintApp.finalInspectionId =
      finalInspection.rowguid;
    //this.finalInspectionPrintApp.pdfPDFMode = true;

    await this.finalInspectionItemPrintApp.loadData();

    setTimeout(() => {
      //  this.finalInspectionPrintApp.appLoadingDocketView.deliveryDocketId = finalInspection.rowguid;
      //  this.finalInspectionPrintApp.appLoadingDocketView.deliveryDocketIdInput = finalInspection.rowguid;
      //  this.finalInspectionPrintApp.appLoadingDocketView.loadData();
    }, 300);

    setTimeout(async () => {
      let htmlElement: HTMLDivElement = document.getElementById(
        "finalInspectionItemPrintContainer"
      ) as HTMLDivElement;
      htmlElement.classList.toggle("final-inspection-print-container-visible");
      let htmlSummaryElement: HTMLDivElement = document.getElementById(
        "finalInspectionSummaryId"
      ) as HTMLDivElement;
      let divHeight: number = htmlElement.clientHeight;

      let finalInspectionArrayBuffer: ArrayBuffer;
      let finalInspectionSummaryArrayBuffer: ArrayBuffer;
      finalInspectionArrayBuffer = await this.fileService.HTMLToPdfArrayBuffer(
        htmlElement,
        "l"
      );
      finalInspectionSummaryArrayBuffer =
        await this.fileService.HTMLToPdfArrayBuffer(htmlSummaryElement, "l");
      let arrBuffer: ArrayBuffer[] = [];
      arrBuffer.push(finalInspectionArrayBuffer);
      arrBuffer.push(finalInspectionSummaryArrayBuffer);

      if (finalInspectionArrayBuffer == null) {
        await this.alertService.openSnackBarCustomPromise(
          "Final Inspection PDF",
          "There was a problem creating the final inspection pdf",
          "OK",
          "",
          "center",
          "bottom",
          "",
          0,
          false
        );
        return;
      }

      let fileAttachment: FileAttachment =
        await this.fileService.createPDFFileAttachment(
          "FinalInspection_" + finalInspection.intFinalNo.toString() + ".pdf",
          arrBuffer
        );
      if (fileAttachment != null) {
        this.fileService.downloadAttachment(fileAttachment);
      }
      //await this.fileService.createPDF("finalInspectionItemPrintContainer", "FinalInspection_" + finalInspection.intFinalNo.toString(), "l");

      setTimeout(() => {
        htmlElement.classList.toggle(
          "final-inspection-print-container-visible"
        );
      }, 500);
    }, 1000);
  }

  updatedComponentColumnsEvent(columns: string[]) {
    this.displayedfinalInspections = columns;
  }

  changeView(finalInspection: dtoTPPoleInspection, val: number) {
    //console.log("val: " + val);
    if (val == 1) {
      this.loadFinalInspectionItemSummary(finalInspection);
    }

    if (val == 0) {
      this.loadFinalInspectionItems(finalInspection);
    }

    this.viewType = val;
  }

  copyToClipboard(value: string) {
    this.formService.copyToClipboard(value);
    this.alertService.openSnackBar(
      "The value " + value + " has been copied to the clipboard",
      "Close",
      "center",
      "bottom",
      "",
      3000
    );
  }

  async openDeliveryDocketDialog() {
    if (this.deliveryDocketCreateDialogRef != null) {
      this.deliveryDocketCreateDialogRef.close();
    }

    this.deliveryDocketCreateDialogRef = this.dialog.open(
      DeliveryDocketCreateDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketCreateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    // this.deliveryDocketCreateDialogRef.backdropClick().subscribe(() => {
    //   this.deliveryDocketCreateDialogRef.close();
    // });

    this.deliveryDocketCreateDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; deliveryDocket: DeliveryDocket }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the FinalInspectionCreate dialog, null data."
            );
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            let path: any = "https://localhost:44356/delivery-docket-search";
            const url = window.URL.createObjectURL(path);
            window.open(url);

            //let deliveryDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(data.deliveryDocket.rowguid);
            //this.deliveryDocket = deliveryDocket;
            //this.loadDeliveryDocketData();
          } else {
            // We have cancellled
          }
        }
      );
  }

  async transferredfinalInspectionItem(
    val,
    finalInspection: dtoTPPoleInspection
  ) {
    //console.log("transferredfinalInspectionItem");

    await this.loadFinalInspectionItems(finalInspection);
  }

  async createDiscCSV(finalInspection: dtoTPPoleInspection) {
    if (finalInspection == null) {
      this.alertService.openSnackBarDefault("There is no final inspection");
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(
      new SQLParamArray("guTPPoleInspectionId", finalInspection.rowguid)
    );
    aParamList.push(aParam);

    let finalInspectionItems: dtoTPPoleInspectionItem[] =
      await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(
        aParamList
      );

    //console.log("finalInspectionItems: " + finalInspectionItems);

    if (finalInspectionItems == null) {
      this.alertService.openSnackBarDefault("There are no inspection items");
      return;
    }

    await this.dtoTPPoleInspectionService.createDiscCSVPromise(
      finalInspectionItems
    );
    this.alertService.openSnackBarDefault("The CSV File has been created");
  }

  async createSelectedDiscCSV(finalInspection: dtoTPPoleInspection) {
    //console.log(this.finalInspectionItems);

    let finalItems: dtoTPPoleInspectionItem[] = [];
    for (let i = 0; i <= this.finalInspectionItems.length - 1; i++) {
      if (this.finalInspectionItems[i].blnSelectCSVDisc == true) {
        finalItems.push(this.finalInspectionItems[i]);
      }
    }

    if (finalItems.length > 0) {
      await this.dtoTPPoleInspectionService.createDiscCSVPromise(finalItems);
      this.alertService.openSnackBarDefault("The CSV File has been created");
    }
  }

  setRowColor(deliveryDocketId: string) {
    if (this.selectedRow == "" || deliveryDocketId != this.selectedRow) {
      this.selectedRow = deliveryDocketId;
      return;
    }

    if (deliveryDocketId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }
  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  doubleclick(idx: number) {
    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(function () {
        if (div.getAttribute("data-dblclick") == "1") {
          window.alert("single");
        }
        div.removeAttribute("data-dblclick");
      }, 300);
    } else {
      div.removeAttribute("data-dblclick");
      window.alert("ondouble");
    }
  }
}
