export interface RoyaltyRate
{
	blnCurrent: boolean;
	dteDateCreated: Date;
	dteDateDeactivated: Date;
	dteDateExpires: Date;
	dteLastUpdated: Date;
	fltCHHRoyaltyRate: number;
	fltForestryRoyaltyRate: number;
	guPoleId: string;
	intDurabilityClass: number;
	rowguid: string;
	txtComments: string;
}

export class RoyaltyRate
{
	blnCurrent: boolean;
	dteDateCreated: Date;
	dteDateDeactivated: Date;
	dteDateExpires: Date;
	dteLastUpdated: Date;
	fltCHHRoyaltyRate: number;
	fltForestryRoyaltyRate: number;
	guPoleId: string;
	intDurabilityClass: number;
	rowguid: string;
	txtComments: string;
}

export namespace RoyaltyRate
{
	export enum enDurabilityClass
	{
		Blackbutt,
		SpottedGum,
		DurabilityClass1
	}
}

