import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
  animateChild,
  query,
} from "@angular/animations";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  getMatIconFailedToSanitizeLiteralError,
  MatIcon,
} from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { MatTableDataSource } from "@angular/material/table";
import { StockItem } from "../../fetch-data/fetch-data.component";
import { MoulderDialogComponent } from "../../moulder/moulder-dialog/moulder-dialog.component";
import { BoardTallyRecordMachineRun } from "../../_shared/business-objects/board-tally-record-machine-run.bo";
import { BoardTallyRecordMoulder } from "../../_shared/business-objects/board-tally-record-moulder.bo";
import { BoardTallyRecordOrderItem } from "../../_shared/business-objects/board-tally-record-order-item.bo";
import { dtoBoardTallyRecordOrderItem } from "../../_shared/business-objects/dto-board-tally-record-order-item.bo";
import { dtoMachineRun } from "../../_shared/business-objects/dto-machine-run.bo";
import { dtoOrderItem } from "../../_shared/business-objects/dto-order-item.bo";
import { dtoStockItem } from "../../_shared/business-objects/dto-stock-item.bo";
import { MachineRun } from "../../_shared/business-objects/machine-run.bo";
import { Moulder } from "../../_shared/business-objects/moulder.bo";
import { OrderItem } from "../../_shared/business-objects/order-item.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { StockItemContainer } from "../../_shared/business-objects/stock-item-container";
import { AlertService } from "../../_shared/services/alert.service";
import { dtoStockItemService } from "../../_shared/services/dto-stock-item.service";
import { MoulderService } from "../../_shared/services/moulder.service";
import { SharedService } from "../../_shared/services/shared.service";
import { BpFloorMachineRunDialogComponent } from "../bp-floor-machine-run-dialog/bp-floor-machine-run-dialog.component";
import { BpFloorOrderItemListComponent } from "../bp-floor-order-item-list/bp-floor-order-item-list.component";

@Component({
  selector: "app-stock-item-multiples",
  templateUrl: "./stock-item-multiples.component.html",
  styleUrls: ["./stock-item-multiples.component.css"],
  animations: [
    trigger("displaySpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayMachineRunSpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayMachineRunTick", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.9s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayMachineRunCancel", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.2s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", [
        animate("425ms cubic-bezier(0.4, 0.0, 0.2, 1)"), //225
        query("@*", animateChild(), { optional: true }),
      ]),
    ]),
  ],
})
export class StockItemMultiplesComponent implements OnInit {
  @ViewChild("txtLength") txtLength: ElementRef;
  @ViewChildren("guMoulderId") moulderSelects: QueryList<MatSelect>;

  @ViewChildren("txtQuantity") txtQuantity: QueryList<MatInput>;

  @Input() datasource: MatTableDataSource<dtoOrderItem>;

  orderItem: dtoOrderItem;
  orderItems: dtoOrderItem[] = [];
  boardTallyRecordOrderItems: dtoBoardTallyRecordOrderItem[] = [];
  stockItem: dtoStockItem = new dtoStockItem();
  moulders: Moulder[];

  selectedMoulders: string[];
  selectedMachineMoulderObjs: Moulder[] = [];
  selectedMachineRuns: dtoMachineRun[] = [];
  selectedMachineRunNos: string;
  orderItemMachineRuns: OrderItemMachineRun[] = [];

  machineRunDialogRef: MatDialogRef<BpFloorMachineRunDialogComponent>;
  moulderDialogRef: MatDialogRef<MoulderDialogComponent>;

  orderItemExpanded: dtoOrderItem;
  orderItemStockItems: dtoStockItem[];

  showSpinner: boolean;
  overLength: boolean = false;

  orderItemLM: number;
  stockItemWaste: number;

  showMachineRunCancel: boolean;

  displayedOrderItems: string[] = [
    "intItemNo",
    "txtProductTypeName",
    "txtProfileName",
    "fltWidth",
    "fltThickness",
    "fltLength",
    "txtSpecies",
    "txtGradeName",
    "fltQtyTotal",
    "fltQtyBal",
    "fltQuantity",
    "txtQuantityUOM",
    "btnViewStockItems",
  ];

  constructor(
    private dialogRef: MatDialogRef<BpFloorOrderItemListComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { orderItem: dtoOrderItem },
    private alertService: AlertService,
    private dtoStockItemService: dtoStockItemService,
    private moulderService: MoulderService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.orderItem = data.orderItem;

    this.orderItems.push(this.orderItem);
  }

  @HostListener("window:keyup.esc") onKeyUp() {
    let response: {
      status: string;
      boardTallyRecordOrderItems: BoardTallyRecordOrderItem[];
      stockItem: dtoStockItem;
    };
    response.status = "Cancel";
    response.boardTallyRecordOrderItems = null;
    response.stockItem = null;

    //console.log(response);

    this.dialogRef.close(response);
  }

  ngOnInit(): void {
    this.stockItem = this.dtoStockItemService.createStockItemFromOrderItem(
      this.orderItem,
      this.orderItem.fltLength,
      1,
      "",
      "",
      1
    );

    this.orderItemLM = 0;

    if (this.datasource != null && this.datasource.data != null) {
      //console.log("going to filter datasource: ");
      this.datasource.data = this.datasource.data.filter((a, b) => {
        return (
          a.txtProductName == this.stockItem.txtProductName &&
          a.txtProfileName == this.stockItem.txtProfileName &&
          a.fltWidth == this.stockItem.intWidth &&
          a.fltThickness == this.stockItem.intThickness &&
          a.txtSpeciesName == this.stockItem.txtSpeciesName &&
          a.txtGradeName == this.stockItem.txtGradeName
        );
      });
    }

    if (this.datasource == null) {
      this.datasource = new MatTableDataSource<dtoOrderItem>();
    }

    this.getMoulders();
  }

  ngAfterViewInit() {
    this.setDefaultQuantity();
    this.cdr.detectChanges();
  }

  async getMoulders() {
    this.moulders = await this.moulderService.getAllMouldersPromise();
    this.moulders.sort((a, b) => {
      return a.txtName >= b.txtName ? 1 : -1;
    });
    let moulder: Moulder = new Moulder();
    moulder.txtName = "";
    moulder.rowguid = this.sharedService.EmptyGuid;
    this.moulders.splice(0, 0, moulder);
  }

  async setDefaultQuantity() {
    for (let i = 0; i <= this.datasource.data.length - 1; i++) {
      if (
        this.orderItem.rowguid == this.datasource.data[i].rowguid &&
        this.datasource.data[i].fltQtyBal > 0
      ) {
        let txtQty: ElementRef = this.txtQuantity.toArray()[
          i
        ] as unknown as ElementRef;
        txtQty.nativeElement.value = "1";
        this.validateOrderItemQuantity(1, this.datasource.data[i], i);
        this.calcWaste();
        break;
      }
    }
  }

  async validateStockItemLength() {
    console.log(this.txtLength.nativeElement.value);

    let val: number = parseFloat(this.txtLength.nativeElement.value);

    if (val > 6) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Stock Item Length",
        "The stock item length seems to be too long is it correct",
        "Yes",
        "No",
        "center",
        "bottom",
        "",
        0,
        true
      );
      if (msg != "Yes") {
        return;
      }
    }

    //this.stockItem.intTotalLM = val;

    this.calcWaste();
  }

  calcWaste() {
    this.stockItemWaste = 0;
    let length: number = 0;
    this.orderItemLM = 0;
    this.overLength = false;

    if (this.txtLength != null) {
      length = parseFloat(this.txtLength.nativeElement.value);
    }

    if (length > 0) {
      this.stockItemWaste = 100;
      this.stockItem.intTotalLM = length;

      for (let i = 0; i <= this.boardTallyRecordOrderItems.length - 1; i++) {
        this.orderItemLM +=
          this.boardTallyRecordOrderItems[i].fltBTOrderItemQuantity *
          this.boardTallyRecordOrderItems[i].fltOrderItemLength;
      }
      this.orderItemLM = parseFloat(this.orderItemLM.toFixed(2));
    }

    if (this.orderItemLM > length) {
      this.overLength = true;
    }

    if (this.orderItemLM > 0 && length > 0) {
      this.stockItemWaste = parseFloat(
        ((1 - this.orderItemLM / length) * 100).toFixed(1)
      );
      console.log("stockItemWaste: ", this.stockItemWaste);
    }
  }

  calcTotalLM() {}

  async validateLength() {
    if (this.orderItem == null) {
      return false;
    }

    if (this.orderItem.intQuantityUoM == OrderItem.enQuantityUoM.Each) {
      console.log(
        "this.orderItemLM: " +
          this.orderItemLM +
          ",  this.orderItem.fltQtyBal: " +
          this.orderItem.fltQtyBal
      );
      if (this.orderItemLM > this.stockItem.intTotalLM) {
        let msg: string = await this.alertService.openSnackBarCustomPromise(
          "Over Length",
          "The total order item lineal meters being supplied is " +
            this.orderItemLM.toString() +
            "m\nThis is " +
            (this.orderItemLM - this.stockItem.intTotalLM).toFixed(1) +
            "m over the supplied stock item length of " +
            this.stockItem.intTotalLM +
            "\nIs this correct?",
          "Yes",
          "No",
          "center",
          "bottom",
          "",
          0,
          true
        );
        if (msg != "Yes") {
          return false;
        }
      }

      if (this.txtLength.nativeElement.value == "") {
        await this.alertService.openSnackBarCustomPromise(
          "Stock Item Length",
          "You must enter a stock item length",
          "Ok",
          "",
          "center",
          "bottom",
          "",
          0,
          false
        );
        this.txtLength.nativeElement.focus();
        return false;
      }
    }

    return true;
  }

  async validateOrderItemQuantity(
    quantity: number,
    orderItem: dtoOrderItem,
    index: number
  ) {
    if (quantity == null || orderItem == null) {
      return;
    }

    if (orderItem.intQuantityUoM == OrderItem.enQuantityUoM.Each) {
      if (quantity > orderItem.fltQtyBal) {
        let msg: string = await this.alertService.openSnackBarCustomPromise(
          "Over Supply",
          "The quantity you are supplying is more than what is on order",
          "Ok",
          "",
          "center",
          "bottom",
          "",
          0,
          false
        );
      }
    }

    //if (quantity > 0) {
    //  // Update value or add to pack automatically.
    //  let tempTotalOrderItemLM: number = 0;

    //  if (this.boardTallyRecordOrderItems != null && this.boardTallyRecordOrderItems.length > 0) {
    //    for (let i = 0; i >= this.boardTallyRecordOrderItems.length - 1; i++) {
    //      if (orderItem.rowguid === this.boardTallyRecordOrderItems[i].guOrderItemId) {
    //        if (quantity > 0) {
    //          // Minus out the previous quantity from the totalLM and get what the new total lm will be.
    //          if (quantity != this.boardTallyRecordOrderItems[i].fltOrderItemQuantity) {
    //            this.orderItemLM -= this.boardTallyRecordOrderItems[i].fltOrderItemQuantity * orderItem.fltLength
    //            tempTotalOrderItemLM = quantity * orderItem.fltLength;
    //          }
    //          break;
    //        }
    //      }
    //    }

    //  }

    //  if (tempTotalOrderItemLM > this.stockItem.intTotalLM) {
    //    let msg: string = await this.alertService.openSnackBarCustomPromise("Over Length", "The total lineal meters for the order item(s) " + (this.orderItemLM + parseFloat((quantity * orderItem.fltLength).toFixed(2))).toString() +  " is longer than the stock item length of " + this.stockItem.intTotalLM.toString() + "\nPlease change the quantity of the order item(s) or increase the length of the stock item.", "Ok", "", "center", "bottom", "", 0, false);
    //    return;
    //  }

    //  console.log("orderItemLM before: " + this.orderItemLM);
    //  console.log("quantity: " + quantity);
    //  console.log("orderItem.fltLength: " + orderItem.fltLength);
    //  console.log("(quantity * orderItem.fltLength): " + (quantity * orderItem.fltLength));
    //  console.log("(quantity * orderItem.fltLength).toFixed(2): " + (quantity * orderItem.fltLength).toFixed(2));

    //  console.log("orderItemLM after: " + this.orderItemLM);

    //  this.linkOrderItem(orderItem, quantity);

    //  if (this.boardTallyRecordOrderItems != null && this.boardTallyRecordOrderItems.length > 0) {
    //    for (let i = 0; i >= this.boardTallyRecordOrderItems.length - 1; i++) {
    //      if (orderItem.rowguid === this.boardTallyRecordOrderItems[i].guOrderItemId) {
    //        if (this.boardTallyRecordOrderItems[i].fltOrderItemQuantity > 0) {
    //          this.orderItemLM += this.boardTallyRecordOrderItems[i].fltOrderItemQuantity * orderItem.fltLength
    //        }
    //        break;
    //      }
    //    }

    //  }

    //}

    //if (quantity == 0) {
    //  if (this.orderItemLM > 0) {
    //    this.orderItemLM -= parseFloat((quantity * orderItem.fltLength).toFixed(2));
    //  }

    //  this.linkOrderItem(orderItem, quantity);

    //  this.calcWaste();
    //}

    this.linkOrderItem(orderItem, quantity);

    this.calcWaste();
  }

  async save() {
    if ((await this.validateLength()) == true) {
      let boardTallyRecordMachineRuns: BoardTallyRecordMachineRun[] = [];
      let boardTallyRecordMoulders: BoardTallyRecordMoulder[] = [];

      this.selectedMachineRuns.forEach((mr) => {
        let boardTallyRecordMachineRun: BoardTallyRecordMachineRun =
          new BoardTallyRecordMachineRun();
        boardTallyRecordMachineRun.guBoardTallyRecordId =
          this.stockItem.rowguid;
        boardTallyRecordMachineRun.guMachineRunId = mr.rowguid;
        boardTallyRecordMachineRuns.push(boardTallyRecordMachineRun);
      });

      this.selectedMachineMoulderObjs.forEach((m) => {
        let boardTallyRecordMoulder: BoardTallyRecordMoulder =
          new BoardTallyRecordMoulder();
        boardTallyRecordMoulder.guBoardTallyRecordId = this.stockItem.rowguid;
        boardTallyRecordMoulder.guMoulderId = m.rowguid;
        boardTallyRecordMoulders.push(boardTallyRecordMoulder);
      });

      let stockItemContainer: StockItemContainer = new StockItemContainer();
      stockItemContainer.stockItem = this.stockItem;
      stockItemContainer.boardTallyRecordOrderItemArray =
        this.boardTallyRecordOrderItems;
      stockItemContainer.boardTallyRecordMachineRunArray =
        boardTallyRecordMachineRuns;
      stockItemContainer.boardTallyRecordMoulderArray =
        boardTallyRecordMoulders;

      // Close dialog with boardTallyRecordOrderItems, stockItem, machineRuns and moulders
      this.dialogRef.close({
        status: "Save",
        stockItemContainer: stockItemContainer,
      });
    }
  }

  async cancel() {
    this.dialogRef.close({
      status: "Cancel",
      boardTallyRecordOrderItems: null,
      stockItem: null,
    });
  }

  linkOrderItem(orderItem: dtoOrderItem, quantity: number) {
    let updated: boolean = false;
    if (
      this.boardTallyRecordOrderItems != null &&
      this.boardTallyRecordOrderItems.length > 0
    ) {
      for (let i = 0; i <= this.boardTallyRecordOrderItems.length - 1; i++) {
        if (
          orderItem.rowguid === this.boardTallyRecordOrderItems[i].guOrderItemId
        ) {
          if (quantity == 0) {
            this.boardTallyRecordOrderItems.splice(i, 1);
            updated = true;
            break;
          }
          this.boardTallyRecordOrderItems[i].fltBTOrderItemQuantity = quantity;
          updated = true;
          break;
        }
      }
    }

    if (updated == false) {
      let boardTallyRecordOrderItem: dtoBoardTallyRecordOrderItem =
        new dtoBoardTallyRecordOrderItem();
      boardTallyRecordOrderItem.txtOrderItemProductName =
        orderItem.txtProductName;
      boardTallyRecordOrderItem.txtOrderItemProfileName =
        orderItem.txtProfileName;
      boardTallyRecordOrderItem.fltOrderItemWidth = orderItem.fltWidth;
      boardTallyRecordOrderItem.fltOrderItemThickness = orderItem.fltThickness;
      boardTallyRecordOrderItem.txtOrderItemSpeciesName =
        orderItem.txtSpeciesName;
      boardTallyRecordOrderItem.txtOrderItemGradeName = orderItem.txtGradeName;
      boardTallyRecordOrderItem.fltBTOrderItemQuantity = quantity;
      boardTallyRecordOrderItem.intBTOrderItemQuantityUoM =
        orderItem.intQuantityUoM;
      boardTallyRecordOrderItem.fltOrderItemLength = orderItem.fltLength;
      boardTallyRecordOrderItem.guOrderItemId = orderItem.rowguid;
      boardTallyRecordOrderItem.guBoardTallyRecordId = this.stockItem.rowguid;
      this.boardTallyRecordOrderItems.push(boardTallyRecordOrderItem);
    }

    console.log(this.boardTallyRecordOrderItems);
  }

  async getMachineRun() {
    //orderItem: dtoOrderItem, index: number

    //let moulderId: string = "";
    //let moulderArr = this.moulderSelects.toArray();
    //if (moulderArr != null && moulderArr.length > 0) {
    //  if (moulderArr[index] != null) {
    //    moulderId = moulderArr[index].value;
    //  }
    //}

    //if (moulderId == null || moulderId == "") {
    //  await this.alertService.openSnackBarCustom("Select a Moulder", "Please select a Moulder", "Ok", "", "center", "bottom", "", 0, false);
    //  return;
    //}

    if (this.machineRunDialogRef != null) {
      this.machineRunDialogRef.close();
    }

    //let moulder: Moulder = this.moulders.find(m => { return m.rowguid == moulderId });

    //if (moulder == null) {
    //  return;
    //}

    this.machineRunDialogRef = this.dialog.open(
      BpFloorMachineRunDialogComponent,
      {
        height: "80vh",
        maxHeight: "80vh",
        width: "80vw",
        maxWidth: "80vw",
        data: {
          selectedMachineRuns: this.selectedMachineRuns,
          selectedMoulders: this.selectedMachineMoulderObjs,
        },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketOrderItemUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.machineRunDialogRef.backdropClick().subscribe(() => {
      this.machineRunDialogRef.close();
    });

    this.machineRunDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          machineRuns: dtoMachineRun[];
          moulders: Moulder[];
        }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the MachineRun dialog, null data."
            );
            return;
          }

          console.log(data);
          if (data.status != "Cancel") {
            //let hasMachineRuns: OrderItemMachineRun = this.orderItemMachineRuns.find(mr => { return mr.guOrderItemId == orderItem.rowguid });
            //if (hasMachineRuns == null) {
            //  this.orderItemMachineRuns.push(new OrderItemMachineRun(orderItem.rowguid, data.machineRuns));
            //}

            //// If this order item already has machine runs linked to it then
            //// over write with what we just selected in the machine run dialog.
            //if (hasMachineRuns != null) {
            //  hasMachineRuns.machineRuns = data.machineRuns;
            //}

            //console.log("this.orderItemMachineRuns: ", this.orderItemMachineRuns);

            //let machineRunsText: HTMLDivElement = document.getElementById("machineRuns" + index.toString()) as HTMLDivElement;

            ////let txtMachineRun2: HTMLInputElement = document.getElementById("txtMachineRun2" + index.toString()) as HTMLInputElement;
            //let machineRunCancelContainer2: HTMLSpanElement = document.getElementById('machineRunCancelContainer2' + index.toString()) as HTMLSpanElement;

            //machineRunsText.innerHTML = "";

            //// Go through existing selected machine runs.
            //// if one does not exist then put it in the selectedMachineRuns array.
            //// and add to the html of the machine runs on this row.
            data.machineRuns.forEach((m) => {
              if (
                this.selectedMachineRuns.find((mr) => {
                  return mr.rowguid == m.rowguid;
                }) == null
              ) {
                this.selectedMachineRuns.push(m);
              }
              //machineRunsText.innerHTML += m.intRunNo.toString() + "<br>";
            });

            this.setMachineRunNos();

            console.log("moulders: ", data.moulders);

            this.selectedMachineMoulderObjs = data.moulders;

            ////machineRunValueText2.value = data.machineRun.rowguid;

            ////====================================================================
            //// TO DO:
            //// We need to run through each hidden field and check what rowguid still exist
            //// before we remove it from selectedMachineRuns array.
            //// so if another order item has the same machine run associated with it then
            //// leave that machine run in the selectedMachineRuns that display up to until the
            //// last one is removed then remove it from selectedMachineRuns
            ////====================================================================
            ////txtMachineRun2.value = data.machineRun.intRunNo.toString();

            //machineRunCancelContainer2.style.visibility = "visible";
            //machineRunCancelContainer2.style.width = "40px;"
            //this.showMachineRunCancel = true;
          } else {
            // We have cancellled
          }
        }
      );
  }

  getMoulder() {
    if (this.moulderDialogRef != null) {
      this.moulderDialogRef.close();
    }

    this.moulderDialogRef = this.dialog.open(MoulderDialogComponent, {
      height: "80vh",
      maxHeight: "80vh",
      width: "80vw",
      maxWidth: "80vw",
      data: { selectedMoulders: this.selectedMachineMoulderObjs },
    });

    this.moulderDialogRef.backdropClick().subscribe(() => {
      this.moulderDialogRef.close();
    });

    this.moulderDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string; moulders: Moulder[] }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError(
            "Something went wrong!\nAn email has been sent to the IT department.",
            "Close",
            "center",
            "bottom",
            4000,
            true,
            "Something went wrong with closing the Moulder dialog, null data."
          );
          return;
        }

        console.log(data);
        if (data.status != "Cancel") {
          data.moulders.forEach((m) => {
            if (
              this.selectedMachineMoulderObjs.find((mr) => {
                return mr.rowguid == m.rowguid;
              }) == null
            ) {
              this.selectedMachineMoulderObjs.push(m);
            }
          });
        } else {
          // We have cancellled
        }
      });
  }

  showMachineRunCancelFN(index: number) {
    console.log("showMachineRunCancelFN: " + index);
  }

  cancelMachineRun(orderItem: dtoOrderItem, index: number) {
    //cancelIcon._elementRef.nativeElement.style.visibility = "hidden";

    //let machineRunValueText: HTMLInputElement = document.getElementById("machineRunValue2" + index.toString()) as HTMLInputElement;
    //let txtMachineRun: HTMLInputElement = document.getElementById("txtMachineRun2" + index.toString()) as HTMLInputElement;
    let machineRunCancelContainer: HTMLSpanElement = document.getElementById(
      "machineRunCancelContainer2" + index.toString()
    ) as HTMLSpanElement;
    let machineRunsText: HTMLDivElement = document.getElementById(
      "machineRuns" + index.toString()
    ) as HTMLDivElement;

    machineRunsText.innerHTML = "";

    machineRunCancelContainer.style.visibility = "hidden";
    machineRunCancelContainer.style.width = "0px;";
    //this.showMachineRunCancel = false;

    //machineRunValueText.value = "";
    //txtMachineRun.value = "";

    let oiMachineRun: OrderItemMachineRun = this.orderItemMachineRuns.find(
      (oimr) => {
        return (oimr.guOrderItemId = orderItem.rowguid);
      }
    );
    if (oiMachineRun != null) {
      let machineRuns: dtoMachineRun[] = oiMachineRun.machineRuns;
      let machineRunCount: number = 0;
      // For each machine run we are trying to cancel.
      // find out if we have more than one in current machine runs linked to order items.
      machineRuns.forEach((mr) => {
        machineRunCount = 0;

        this.selectedMachineRuns.forEach((smr) => {
          if (mr.rowguid == smr.rowguid) {
            machineRunCount++;
          }
        });

        //If we only have one machine run left in all of the machine runs linked to each order item then remove it.
        //If we still have the same machine run in other order items then don't remove it.
        if (machineRunCount == 1) {
          let iSelectedIndex: number = -1;
          for (let i = 0; i <= this.selectedMachineRuns.length - 1; i++) {
            if (this.selectedMachineRuns[i].rowguid == mr.rowguid) {
              iSelectedIndex = i;
              break;
            }
          }

          // Remove selected machine run since it is the last one.
          if (iSelectedIndex > -1) {
            this.selectedMachineRuns.splice(iSelectedIndex, 1);
          }
        }
      });

      // Remove the cancelled machine runs from OrderItemMachineRun array.
      let iMachineRunIndex: number = -1;
      for (let i = 0; i <= this.orderItemMachineRuns.length - 1; i++) {
        if (this.orderItemMachineRuns[i] == oiMachineRun) {
          iMachineRunIndex = i;
          break;
        }
      }

      if (iMachineRunIndex > -1) {
        this.orderItemMachineRuns.splice(iMachineRunIndex, 1);
      }

      this.setMachineRunNos();

      console.log("orderItemMachineRuns: ", this.orderItemMachineRuns);
    }
  }

  setMachineRunNos() {
    let machineRunNos: string = "";
    this.selectedMachineRunNos = "";

    for (let i = 0; i <= this.selectedMachineRuns.length - 1; i++) {
      machineRunNos += this.selectedMachineRuns[i].intRunNo.toString();

      if (i < this.selectedMachineRuns.length - 1) {
        machineRunNos += ", ";
      }
    }

    if (machineRunNos != "") {
      this.selectedMachineRunNos = "Machine Runs: " + machineRunNos;
    }
  }

  setMoulderNames() {
    //let moulders: string = "";
    //for (let i = 0; i <= this.selectedMachineRuns.length - 1; i++) {
    //  moulders += this.selectedMachineRuns[i].intRunNo.toString();
    //  if (i < this.selectedMachineRuns.length - 1) {
    //    machineRunNos += ", ";
    //  }
    //};
    //if (machineRunNos != "") {
    //  this.selectedMachineRunNos = "Moulders: " + machineRunNos;
    //}
  }

  copyAbove(orderItem: dtoOrderItem, index: number) {
    if (index == null || index == 0) {
      return;
    }

    //let machineRunValueTextAbove: HTMLInputElement = document.getElementById("machineRunValue2" + (index - 1).toString()) as HTMLInputElement;
    //let txtMachineRunAbove: HTMLInputElement = document.getElementById("txtMachineRun2" + (index - 1).toString()) as HTMLInputElement;

    //let machineRunValueText: HTMLInputElement = document.getElementById("machineRunValue2" + index.toString()) as HTMLInputElement;
    //let txtMachineRun: HTMLInputElement = document.getElementById("txtMachineRun2" + index.toString()) as HTMLInputElement;
    let machineRunCancelContainer: HTMLSpanElement = document.getElementById(
      "machineRunCancelContainer2" + index.toString()
    ) as HTMLSpanElement;

    //machineRunValueText.value = machineRunValueTextAbove.value;
    //txtMachineRun.value = txtMachineRunAbove.value;

    let moulderValue: string = "";
    let iRowCount: number = 0;
    //Get previous row moulder id.
    this.moulderSelects.forEach((m) => {
      if (iRowCount == index - 1) {
        moulderValue = m.value;
      }
      iRowCount++;
    });

    iRowCount = 0;
    this.moulderSelects.forEach((m) => {
      if (iRowCount == index) {
        m.value = moulderValue;
      }
      iRowCount++;
    });

    let machineRunsText: HTMLDivElement = document.getElementById(
      "machineRuns" + index.toString()
    ) as HTMLDivElement;
    machineRunsText.innerHTML = "";

    console.log("orderItems: ", this.orderItems);

    // Add copy OrderItemMachineRun from previous row if we have one.
    let orderItemIdAbove: string =
      this.orderItems[this.orderItems.length - 1].rowguid;

    if (
      this.orderItemMachineRuns != null &&
      this.orderItemMachineRuns.length > 0
    ) {
      let orderItemMachineRunAbove: OrderItemMachineRun =
        this.orderItemMachineRuns.find((oimr) => {
          return oimr.guOrderItemId == orderItemIdAbove;
        });

      if (orderItemMachineRunAbove != null) {
        // Add new OrderItemMachineRun with current orderItemId and cloned machine runs from row above.
        this.orderItemMachineRuns.push(
          new OrderItemMachineRun(orderItem.rowguid, [
            ...orderItemMachineRunAbove.machineRuns,
          ])
        );

        this.orderItemMachineRuns[
          this.orderItemMachineRuns.length - 1
        ].machineRuns.forEach((m) => {
          machineRunsText.innerHTML += m.intRunNo.toString() + "<br>";
        });

        machineRunCancelContainer.style.visibility = "visible";
        machineRunCancelContainer.style.width = "40px;";
        this.showMachineRunCancel = true;
      }
    }
  }

  async expandElement(orderItem: dtoOrderItem, index: number) {
    this.orderItemExpanded =
      this.orderItemExpanded === orderItem ? null : orderItem;

    console.log("expand: ", this.orderItemExpanded);

    if (this.orderItemExpanded != null) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guOrderItemId", orderItem.rowguid));
      aParamList.push(aParam);

      this.orderItemStockItems =
        await this.dtoStockItemService.getdtoStockItemParamArrayPromise(
          aParamList
        );
      this.orderItemStockItems.sort((a, b) => {
        return a.intIdentifier - b.intIdentifier;
      });
    }
  }

  moulderChange(moulderObj: Moulder, moulder: string, index: number) {
    this.selectedMoulders = [];

    let quantityText: HTMLInputElement;
    let quantity: number = 0;
    let gotMoulder: boolean = false;

    let iCount: number = 0;
    this.moulderSelects.forEach((m) => {
      gotMoulder = false;
      quantityText = document.getElementById(
        "txtQuantity2" + iCount.toString()
      ) as HTMLInputElement;
      quantity = 0;

      if (quantityText.value != null && quantityText.value != "") {
        quantity = parseInt(quantityText.value);
      }

      console.log(m.value, quantity);
      if (m.value != null && quantity > 0) {
        if (
          this.selectedMoulders.find((md) => {
            return md == m.value;
          }) != null
        ) {
          gotMoulder = true;
        }

        if (gotMoulder == false && m.value != this.sharedService.EmptyGuid) {
          this.selectedMoulders.push(m.value);
          this.selectedMachineMoulderObjs.push(moulderObj);
        }
      }

      iCount++;
    });

    console.log(this.selectedMoulders);

    //let gotMoulder: boolean = false;

    //if (this.selectedMoulders.find(m => { return m == moulder }) != null) {
    //  gotMoulder = true;
    //  //console.log(moulder);
    //}

    //if (gotMoulder == false && moulder != this.sharedService.EmptyGuid) {
    //  this.selectedMoulders.push(moulder);
    //}
    //console.log(this.selectedMoulders);
  }

  setNoMoulder() {
    let noneMoulder: Moulder = null;
    for (let i = 0; i <= this.moulders.length - 1; i++) {
      if (this.moulders[i].txtName.toLocaleLowerCase() == "none") {
        noneMoulder = this.moulders[i];
        break;
      }
    }

    this.selectedMachineMoulderObjs = [];
    this.selectedMoulders = [];

    this.selectedMoulders.push(noneMoulder.txtName);
    this.selectedMachineMoulderObjs.push(noneMoulder);
  }
}

export class OrderItemMachineRun {
  constructor(orderId: string, machineRuns: dtoMachineRun[]) {
    this.guOrderItemId = orderId;
    this.machineRuns = machineRuns;
  }

  guOrderItemId: string;
  machineRuns: dtoMachineRun[];
}
