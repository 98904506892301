import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { dtoSpecies } from "../../_shared/business-objects/dto-species.bo";
import { Species } from "../../_shared/business-objects/species.bo";
import { AlertService } from "../../_shared/services/alert.service";
import { dtoSpeciesService } from "../../_shared/services/dto-species.service";
import { SpeciesService } from "../../_shared/services/species.service";
import { SpeciesUpdateDialogComponent } from "../species-update-dialog/species-update-dialog.component";

@Component({
  selector: "app-species-select",
  templateUrl: "./species-select.component.html",
  styleUrl: "./species-select.component.scss",
})
export class SpeciesSelectComponent {
  @ViewChild("speciesFilter") speciesFilter: ElementRef;
  @ViewChild("speciesListContainer") speciesListContainer: ElementRef;
  @ViewChild("multipleSpecies") multipleSpecies: MatSlideToggle;

  @Input() species: dtoSpecies[];
  @Input() showSpeciesASCode: boolean;
  @Output() selectedSpeciesEvent = new EventEmitter<dtoSpecies[]>();

  showSpinner: boolean;
  addressHoverId: string;

  speciesUpdateDialogRef: MatDialogRef<SpeciesUpdateDialogComponent>;
  selectedSpecies: dtoSpecies[];
  speciesFiltered: dtoSpecies[];
  speciesExpanded: dtoSpecies;

  speciesFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showSpeciesList: boolean = false;
  showSpeciesListTransition: boolean = false;

  speciesSelectionExpanded: boolean = false;
  speciesListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor(
    private speciesService: SpeciesService,
    private dtoSpeciesService: dtoSpeciesService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedSpecies = [];

    document.addEventListener("click", this.openCloseSpeciesList.bind(this));
  }

  openCloseSpeciesList(e) {
    if (this.speciesListPinned == true) {
      return;
    }

    if (
      e != null &&
      e.target != null &&
      e.target.dataset != null &&
      e.target.dataset.pushPin != null
    ) {
      return;
    }

    if (
      e != null &&
      e.target != null &&
      e.target.dataset != null &&
      e.target.dataset.speciesInput != null &&
      this.speciesFilter.nativeElement.value != null &&
      this.speciesFilter.nativeElement.value.toString() != ""
    ) {
      this.showSpeciesList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.speciesSelect == null) {
    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.speciesListPinned == false) {
      this.showSpeciesList = false;

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }
  }

  checkSpeciesListIsOpen(inputValue: string) {
    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showSpeciesList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showSpeciesList = true;
  }

  rowEnter(speciesId: string) {
    this.addressHoverId = speciesId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  async selectSpecies(species: dtoSpecies, selected: boolean) {
    if (species == null) {
      this.alertService.openSnackBarDefault(
        "There was a problem selecting the species - null option",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      return;
    }

    // Remove All Species
    if (this.multipleSpecies != null && this.multipleSpecies.checked == false) {
      let speciesSelected: dtoSpecies[] = this.species.filter((a) => {
        return a.guSelectedSpeciesId != null && a.guSelectedSpeciesId != "";
      });
      for (let i = 0; i <= speciesSelected.length - 1; i++) {
        speciesSelected[i].guSelectedSpeciesId = "";
      }

      for (let i = this.selectedSpecies.length - 1; i >= 0; i--) {
        this.selectedSpecies.splice(i, 1);
      }

      for (let i = this.speciesFiltered.length - 1; i >= 0; i--) {
        this.speciesFiltered[i].guSelectedSpeciesId = "";
      }
    }

    // Remove Species
    if (selected == false) {
      for (let i = this.selectedSpecies.length - 1; i >= 0; i--) {
        if (this.selectedSpecies[i].rowguid == species.rowguid) {
          this.selectedSpecies.splice(i, 1);
          species.guSelectedSpeciesId = "";
          //console.log("unselected species");
          this.selectedSpeciesEvent.emit(this.selectedSpecies);
          break;
        }
      }
    }

    // Add Species
    if (selected == true) {
      species.guSelectedSpeciesId = "tempvalue";

      this.selectedSpecies.push(species);
      this.selectedSpeciesEvent.emit(this.selectedSpecies);

      if (
        this.multipleSpecies.checked == false &&
        this.speciesListPinned == false
      ) {
        this.showSpeciesList = false;
      }

      //console.log("added species");
    }
  }

  async updateSpecies(species: dtoSpecies) {
    console.log(species);
    if (species == null) {
      this.alertService.openSnackBarDefault("There is no species to edit");
      return;
    }

    if (this.speciesUpdateDialogRef != null) {
      this.speciesUpdateDialogRef.close();
    }

    this.speciesUpdateDialogRef = this.dialog.open(
      SpeciesUpdateDialogComponent,
      {
        height: "95vh",
        maxWidth: "90vw",
        data: { selectedSpecies: species },
      }
    );

    this.speciesUpdateDialogRef.backdropClick().subscribe(() => {
      this.speciesUpdateDialogRef.close();
    });

    this.speciesUpdateDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string; species: Species }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          let cust: dtoSpecies =
            await this.dtoSpeciesService.getdtoSpeciesPromise(
              data.species.rowguid
            );
          this.selectedSpecies.push(cust);

          for (let i = 0; i <= this.species.length - 1; i++) {
            if (this.species[i].rowguid == data.species.rowguid) {
              this.species[i] = cust;
              this.species[i].guSelectedSpeciesId = "tempvalue";
              break;
            }
          }

          this.species.sort((a, b) => {
            return a.txtName > b.txtName ? 1 : -1;
          });

          this.alertService.openSnackBarDefault("The Species has been updated");
        } else {
          // We have cancellled
        }
      });
  }

  addSpecies() {}

  nextSpecies(e, species: dtoSpecies) {
    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      this.speciesFilter.nativeElement.focus();
      this.speciesFilter.nativeElement.setSelectionRange(
        this.speciesFilter.nativeElement.value.length,
        this.speciesFilter.nativeElement.value.length
      );

      this.showSpeciesList = false;
      return;
    }

    if (
      (e.code.toLowerCase() == "arrowdown" ||
        e.code.toLowerCase() == "arrowup") &&
      this.showSpeciesList == true
    ) {
      //console.log("e ", e);

      if (this.speciesFilteredItems != null) {
        let tabIndex: number = e.target.tabIndex;

        let speciesUpdateDiv = document.getElementById(
          "speciesUpdateDiv" + tabIndex
        );

        if (
          tabIndex < this.speciesFilteredItems.length - 1 &&
          e.code.toLowerCase() == "arrowdown"
        ) {
          let speciesUpdateDivPlus = document.getElementById(
            "speciesUpdateDiv" + (tabIndex + 1)
          );

          //console.log("e down ", tabIndex);

          this.speciesListContainer.nativeElement.classList.add(
            "species-container-no-scroll"
          );

          this.speciesFilteredItems[tabIndex].classList.remove(
            "chh-list-item-row-selected"
          );
          speciesUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.speciesFilteredItems[tabIndex + 1].focus();
          this.speciesFilteredItems[tabIndex + 1].classList.add(
            "chh-list-item-row-selected"
          );
          speciesUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.speciesContainer.style overflow-y to scroll here
          this.speciesListContainer.nativeElement.classList.remove(
            "species-container-no-scroll"
          );
        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {
          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.speciesFilteredItems[tabIndex].classList.remove(
              "chh-list-item-row-selected"
            );
            speciesUpdateDiv.classList.remove("chh-list-item-row-selected");

            this.speciesFilter.nativeElement.focus();
            this.speciesFilter.nativeElement.setSelectionRange(
              this.speciesFilter.nativeElement.value.length,
              this.speciesFilter.nativeElement.value.length
            );
            return;
          }

          let speciesUpdateDivMinus = document.getElementById(
            "speciesUpdateDiv" + (tabIndex - 1)
          );

          //console.log("e up ", tabIndex);

          this.speciesListContainer.nativeElement.classList.add(
            "species-container-no-scroll"
          );

          this.speciesFilteredItems[tabIndex].classList.remove(
            "chh-list-item-row-selected"
          );
          speciesUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.speciesFilteredItems[tabIndex - 1].focus();
          this.speciesFilteredItems[tabIndex - 1].classList.add(
            "chh-list-item-row-selected"
          );
          speciesUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.speciesListContainer.nativeElement.classList.remove(
            "species-container-no-scroll"
          );
        }
      }
      //console.log("nextSpecies");
    }

    if (e.code.toLowerCase() == "enter") {
      this.selectSpecies(species, !(species.guSelectedSpeciesId != ""));
    }
  }

  filterSpecies(speciesFilter: string, e) {
    if (e.code.toLowerCase() == "arrowdown" && this.showSpeciesList == true) {
      //console.log("e ", e);

      this.speciesFilteredItems = document.getElementsByClassName(
        "species-name"
      ) as HTMLCollectionOf<HTMLDivElement>;
      if (
        this.speciesFilteredItems != null &&
        this.speciesFilteredItems.length > 0
      ) {
        this.speciesFilteredItems[0].classList.add(
          "chh-list-item-row-selected"
        );
        let speciesUpdateDiv = document.getElementById("speciesUpdateDiv0");

        speciesUpdateDiv.classList.add("chh-list-item-row-selected");

        this.speciesFilteredItems[0].focus();
        console.log(
          "filterSpecies TabIndex: ",
          this.speciesFilteredItems[0].tabIndex
        );
      }

      //console.log("speciesElements", this.speciesFilteredItems);
    }

    this.getSpecies(speciesFilter);
  }

  getSpecies(speciesFilter: string) {
    this.speciesFiltered = this.species;

    if (speciesFilter == null || speciesFilter == "") {
      this.speciesFiltered = [];
      this.showSpeciesList = false;
      return;
    }

    let species: dtoSpecies[] = this.species;

    species = species.filter(
      (a) =>
        a.txtName.toLowerCase().indexOf(speciesFilter.toLowerCase()) > -1 ||
        a.txtCode.toLowerCase().indexOf(speciesFilter.toLowerCase()) > -1 ||
        (a.txtCodeAlias != null &&
          a.txtCodeAlias.toLowerCase().indexOf(speciesFilter.toLowerCase()) >
            -1)
    );

    species = species.slice(0, 50);

    species.sort((a, b) => {
      return a.txtName > b.txtName ? 1 : -1;
    });

    this.speciesFiltered = species;

    console.log(this.speciesFiltered);

    this.showSpeciesList = true;

    this.setSpeciesListTranition(1000);
  }

  setSpeciesListTranition(delay: number) {
    setTimeout(() => {
      this.showSpeciesListTransition = true;
    }, delay);
  }

  checkMultipleSpecies(multipleSpecies: boolean) {
    if (multipleSpecies == false) {
      //Remove multiple selected species so only one remains.
      let multipleCust: dtoSpecies[] = this.species.filter((a) => {
        return a.guSelectedSpeciesId != null && a.guSelectedSpeciesId != "";
      });
      if (multipleCust != null && multipleCust.length > 0) {
        multipleCust.sort((a, b) => {
          return a.txtName > b.txtName ? 1 : -1;
        });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          //Leave the first element
          multipleCust.pop();
        }

        for (let i = 0; i <= this.species.length - 1; i++) {
          if (multipleCust[0].rowguid != this.species[i].rowguid) {
            this.species[i].guSelectedSpeciesId = "";
          }
        }
        this.selectedSpecies.length = 0;
        this.selectedSpecies.push(multipleCust[0]);
        this.selectedSpeciesEvent.emit(this.selectedSpecies);
      }
    }
  }

  removeSpecies(species: dtoSpecies) {
    if (species == null) {
      return;
    }

    for (let i = this.selectedSpecies.length - 1; i >= 0; i--) {
      if (this.selectedSpecies[i].rowguid == species.rowguid) {
        this.selectedSpecies.splice(i, 1);
        species.guSelectedSpeciesId = "";
        //console.log("unselected species");
        this.selectedSpeciesEvent.emit(this.selectedSpecies);
        break;
      }
    }
  }

  expandSelectedSpecies(e: PointerEvent) {
    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.speciesSelectionExpanded = !this.speciesSelectionExpanded;

    let selectedSpecies: HTMLDivElement = document.getElementById(
      "selectedSpecies"
    ) as HTMLDivElement;
    selectedSpecies.classList.toggle("selected-species-expanded");

    if (this.speciesSelectionExpanded == true) {
    }
  }

  clickPin(e) {
    this.speciesListPinned = !this.speciesListPinned;

    if (this.speciesListPinned == false) {
      this.showSpeciesList = false;
    }

    this.toolTipText = this.speciesListPinned == true ? "unpin" : "pin";
  }
}
