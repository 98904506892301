import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { dtoLoadingDocket } from "../../_shared/business-objects/dto-loading-docket.bo";
import { LoadingDocket } from "../../_shared/business-objects/loading-docket.bo";
import { AlertService } from "../../_shared/services/alert.service";
import { FormService } from "../../_shared/services/form.service";
import { LoadingDocketService } from "../../_shared/services/loading-docket.service";
import { dtoLoadingDocketService } from "../../_shared/services/dto-loading-docket.service";
import { SharedService } from "../../_shared/services/shared.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  animateChild,
} from "@angular/animations";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { dtoDeliveryDocket } from "../../_shared/business-objects/dto-delivery-docket.bo";
import { dtoDeliveryDocketService } from "../../_shared/services/dto-delivery-docket.service";
import { dtoLoadingDocketItemService } from "../../_shared/services/dto-loading-docket-item.service";
import { DeliveryDocketListComponent } from "../../delivery-docket/delivery-docket-list/delivery-docket-list.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MobilePlantEquipmentSelectionComponent } from "../../mobile-plant/mobile-plant-equipment-selection/mobile-plant-equipment-selection.component";
import { MobilePlantEquipment } from "../../_shared/business-objects/mobile-plant-equipment.bo";
import { MobilePlantCategoryService } from "../../_shared/services/mobile-plant-category.service";
import { MobilePlantCategory } from "../../_shared/business-objects/mobile-plant-category.bo";
import { TruckTrailerSelectionDialogComponent } from "../truck-trailer-selection-dialog/truck-trailer-selection-dialog.component";
import { HaulingCompany } from "../../_shared/business-objects/hauling-company.bo";
import { HaulingCompanyService } from "../../_shared/services/hauling-company.service";
import { DateSelectorComponent } from "../../utilities/date-selector/date-selector.component";
import { DeliveryDocket } from "../../_shared/business-objects/delivery-docket.bo";
import { DeliveryDocketService } from "../../_shared/services/delivery-docket.service";
import { MatIcon } from "@angular/material/icon";
import { HaulingCompanyDriver } from "../../_shared/business-objects/hauling-company-driver.bo";
import { HaulingCompanyDriverService } from "../../_shared/services/hauling-company-driver.service";
import { CustomerDeliveryAddressDialogComponent } from "../../customer-delivery-address/customer-delivery-address-dialog/customer-delivery-address-dialog.component";
import { ClientDeliveryAddress } from "../../_shared/business-objects/client-delivery-address.bo";
import { DeliveryDocketOrderItem } from "../../_shared/business-objects/delivery-docket-order-item.bo";
import { DeliveryDocketOrderItemService } from "../../_shared/services/delivery-docket-order-item.service";
import { dtoCustomerDeliveryAddress } from "../../_shared/business-objects/dto-customer-delivery-address.bo";
import { dtoCustomerDeliveryAddressService } from "../../_shared/services/dto-customer-delivery-address.service";
import { dtoDeliveryDocketOrderItem } from "../../_shared/business-objects/dto-delivery-docket-order-item.bo";
import { dtoDeliveryDocketOrderItemService } from "../../_shared/services/dto-delivery-docket-order-item.service";
import { SendMailDialogComponent } from "../../email/send-mail-dialog/send-mail-dialog.component";
import { AuthService } from "../../account/auth.service";
import jsPDF from "jspdf";
import { FileAttachment } from "../../_shared/app-objects/file-attachment";
import { LoadingDocketPrintComponent } from "../loading-docket-print/loading-docket-print.component";
import { MatButton } from "@angular/material/button";
import { FileService } from "../../_shared/services/app-services/file.service";
import { User } from "../../account/user";
import { MobilePlantEquipmentService } from "../../_shared/services/mobile-plant-equipment.service";

@Component({
  selector: "app-loading-docket-list",
  templateUrl: "./loading-docket-list.component.html",
  styleUrls: ["./loading-docket-list.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", [
        animate("425ms cubic-bezier(0.4, 0.0, 0.2, 1)"), //225
        query("@*", animateChild(), { optional: true }),
      ]),
    ]),
  ],
})
export class LoadingDocketListComponent implements OnInit {
  @ViewChild("tblLoadingDocket", { static: false })
  tblLoadingDocket: MatTable<dtoLoadingDocket>;
  @ViewChild("appdeliveryDocketlist")
  deliveryDocketlist: DeliveryDocketListComponent;
  @ViewChild("loadingDocketPrintApp")
  loadingDocketPrintApp: LoadingDocketPrintComponent;

  @Input() datasource: MatTableDataSource<dtoLoadingDocket>;
  @Input() totalTonne: number;
  @Output() editLoadingDocketEvent = new EventEmitter<dtoLoadingDocket>();
  @Output() removeLoadingDocketEvent = new EventEmitter<dtoLoadingDocket>();
  @Output() addLoadingDocketEvent = new EventEmitter<LoadingDocket>();
  @Output() addLoadingDocketItemsEvent = new EventEmitter<dtoLoadingDocket>();
  @Output() updatedLoadingDocketEvent = new EventEmitter<dtoLoadingDocket>();
  @Output() searchLoadingDockets = new EventEmitter();

  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  form: FormGroup;

  loadedComponent: boolean = false;

  ms_authenticated: boolean = false;

  loadingDocket: LoadingDocket;
  loadingDocketExpanded: dtoLoadingDocket;
  deliveryDockets: dtoDeliveryDocket[];
  viewType: number = 0;

  truckTrailerSelectionDialogRef: MatDialogRef<TruckTrailerSelectionDialogComponent>;
  customerDeliveryAddressDialogRef: MatDialogRef<CustomerDeliveryAddressDialogComponent>;
  sendMailDialogRef: MatDialogRef<SendMailDialogComponent>;

  selectedLoadingDate: string = "";
  selectedExpectedLoadingDate: string = "";
  selectedExpectedDeliveryDate: string = "";
  selectedRow: string = "";
  selectedDispatchedDate: string = "";
  openedDispatchedSelectorByAlert: boolean = false;

  loadingDocketPDFId: string = "";

  showInlineSpinner: boolean = true;
  showTick: boolean = false;
  showCheckSheet: boolean = true;

  displayedColumns: string[] = [
    "intDocketNo",
    "dteDateLoaded",
    "txtHaulingCompanyName",
    "txtHaulingCompanyDriverName",
    "dteDateCreated",
    "txtDestination",
    "intLoadNo",
    "loadNoSeparator",
    "intMaxLoads",
    "txtComments",
    "fltActualM3",
    "fltActualTonne",
    "btnEditLoadingDocket",
    "btnRemoveLoadingDocket",
    "btnAddDeliveryDockets",
    "btnDownloadLoadingDocket",
    "btnDownloadPoleLoadingDocket",
    "btnViewDeliveryDockets",
  ];

  constructor(
    private dtoLoadingDocketService: dtoLoadingDocketService,
    private loadingDocketService: LoadingDocketService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private alertService: AlertService,
    private dtoDeliveryDocketService: dtoDeliveryDocketService,
    private route: ActivatedRoute,
    private formService: FormService,
    private dtoLoadingDocketItemService: dtoLoadingDocketItemService,
    private dialog: MatDialog,
    private mobilePlantCategoryService: MobilePlantCategoryService,
    private mobilePlantEquipmentService: MobilePlantEquipmentService,
    private haulingCompanyService: HaulingCompanyService,
    private haulingCompanyDriverService: HaulingCompanyDriverService,
    private deliveryDocketService: DeliveryDocketService,
    private deliveryDocketOrderItemService: DeliveryDocketOrderItemService,
    private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService,
    private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService,
    private authService: AuthService,
    private fileService: FileService
  ) {
    if (this.columnBarPage == null || this.columnBarPage == "") {
      this.columnBarPage = "Loading Dockets";
    }

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "loadingDocketContainer";
    }
  }

  ngOnInit(): void {
    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }

    this.loadedComponent = true;
    this.viewType = 0;

    this.getUser();
  }

  async getUser() {
    console.log("ms_authenticated - before", this.authService.ms_authenticated);

    let user: User = await this.authService.getUser();
    console.log("user", user);

    this.ms_authenticated = this.authService.ms_authenticated;

    console.log("ms_authenticated - after", this.authService.ms_authenticated);
  }

  positionSpinner(top: number, left: number) {
    //setTimeout(() => {
    //}, 500);

    let spinnerInlineContent: HTMLCollectionOf<HTMLDivElement> =
      document.getElementsByClassName(
        "spinner-inline-tick-content"
      ) as HTMLCollectionOf<HTMLDivElement>;
    let inlineSpinner = document.getElementById("inlineSpinnerId");
    inlineSpinner.style.position = "absolute";
    inlineSpinner.style.top = top.toString() + "px";
    inlineSpinner.style.left = left.toString() + "px";
    inlineSpinner.style.padding = "0";

    //inlineSpinner.style.opacity = "0.1"
    inlineSpinner.style.backgroundColor = "#868686";

    if (spinnerInlineContent[0] != null) {
      spinnerInlineContent[0].style.backgroundColor = "#868686";
    }

    console.log("inlineSpinner", inlineSpinner);
    //console.log("spinnerInlineContent", spinnerInlineContent[0]);
  }

  updatedComponentColumnsEvent(columns: string[]) {
    this.displayedColumns = columns;
  }

  onTabChange(e) {}

  expandElementAndSetColor(loadingDocket: dtoLoadingDocket, idx: number) {
    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(loadingDocket.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(loadingDocket);
      this.selectedRow = loadingDocket.rowguid; //Always highlight row when double clicking.

      //window.alert('ondouble');
    }
  }

  async expandElement(loadingDocket: dtoLoadingDocket) {
    console.log(this.loadingDocketExpanded === loadingDocket);

    this.loadingDocketExpanded =
      this.loadingDocketExpanded === loadingDocket ? null : loadingDocket;

    await this.loadDeliveryDockets(loadingDocket);

    this.rowColorPropagation(loadingDocket.rowguid);
  }

  async loadDeliveryDockets(loadingDocket: dtoLoadingDocket) {
    if (this.loadingDocketExpanded == loadingDocket) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(
        new SQLParamArray(
          "guLoadingDocketId",
          loadingDocket.rowguid,
          SQLParamArray.enSQLOperator.Equals,
          SQLParamArray.enSQLLogicalOperator.AND,
          null,
          "",
          SQLParamArray.enSQLStatementType.SubQuerySQL
        )
      );
      aParamList.push(aParam);

      this.deliveryDockets =
        await this.dtoDeliveryDocketService.getDeliveryDocketPromiseParamArrayPromise(
          aParamList
        );
      if (this.deliveryDocketlist != null) {
        this.deliveryDocketlist.loadedComponent = true;
      }
    }
  }

  async setAsScheduled(loadingDocket) {
    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    loadDocket.intStatus = LoadingDocket.enLoadingDocketStatus.Scheduled;
    loadDocket.dteDateDispatched = null;

    loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(
      loadDocket
    );

    //loadingDocket = await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(loadingDocket.rowguid);

    console.log(loadingDocket);

    this.tblLoadingDocket.renderRows();

    this.alertService.openSnackBarDefault(
      "Loading Docket " + loadingDocket.intDocketNo + " is set as Scheduled"
    );

    this.rowColorPropagation(loadingDocket.rowguid);

    this.searchLoadingDockets.emit();
  }

  async setAsLoaded(loadingDocket) {
    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    loadDocket.intStatus = LoadingDocket.enLoadingDocketStatus.Loaded;
    loadDocket.dteDateDispatched = null;

    loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(
      loadDocket
    );

    this.tblLoadingDocket.renderRows();

    this.alertService.openSnackBarDefault(
      "Loading Docket " + loadingDocket.intDocketNo + " is set as Loaded"
    );

    this.rowColorPropagation(loadingDocket.rowguid);

    this.searchLoadingDockets.emit();
  }

  async setAsDispatched(loadingDocket) {
    this.openedDispatchedSelectorByAlert = false;

    let msg: string =
      await this.alertService.openSnackBarCustomDefaultShowCancel(
        "Set Dispatched Date",
        "Is the load being dispatched today?",
        "Yes",
        "No"
      );
    if (msg != "Yes") {
      this.selectedDispatchedDate = loadingDocket.rowguid;
      this.openedDispatchedSelectorByAlert = true;
    }

    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    loadDocket.intStatus = LoadingDocket.enLoadingDocketStatus.Dispatched;
    loadDocket.dteDateDispatched = this.sharedService.currentDatePlusTZOffset();

    loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(
      loadDocket
    );

    if (msg == "Yes") {
      this.tblLoadingDocket.renderRows();

      this.alertService.openSnackBarDefault(
        "Loading Docket " + loadingDocket.intDocketNo + " is set as Dispatched"
      );

      this.rowColorPropagation(loadingDocket.rowguid);

      this.searchLoadingDockets.emit();
    }
  }

  async setDispatchedDate(loadingDocket, dispatchedDate) {
    //console.log("date, ", dispatchedDate);
    dispatchedDate = this.sharedService.convertDateAEST(dispatchedDate);

    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    loadDocket.dteDateDispatched = dispatchedDate;

    loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(
      loadDocket
    );

    loadingDocket =
      await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(
        loadingDocket.rowguid
      );

    if (this.datasource.data != null) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == loadingDocket.rowguid) {
          this.datasource.data[i] = loadingDocket;
          //console.log("setDispatchedDate: ", this.datasource.data[i]);
          this.tblLoadingDocket.renderRows();
          break;
        }
      }
    }
    this.selectedDispatchedDate = "";

    if (this.openedDispatchedSelectorByAlert == true) {
      this.openedDispatchedSelectorByAlert = false;
      this.searchLoadingDockets.emit();
    }
  }

  async setAsComplete(loadingDocket) {
    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    loadDocket.intStatus = LoadingDocket.enLoadingDocketStatus.Complete;

    loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(
      loadDocket
    );

    this.tblLoadingDocket.renderRows();

    this.alertService.openSnackBarDefault(
      "Loading Docket " + loadingDocket.intDocketNo + " is set as Complete"
    );

    this.rowColorPropagation(loadingDocket.rowguid);

    this.searchLoadingDockets.emit();
  }

  async setAsReadyForLoading(loadingDocket) {
    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    loadDocket.intStatus = LoadingDocket.enLoadingDocketStatus.ReadyForLoading;

    loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(
      loadDocket
    );

    this.tblLoadingDocket.renderRows();

    this.alertService.openSnackBarDefault(
      "Loading Docket " +
        loadingDocket.intDocketNo +
        " is set as Ready For Loading"
    );

    this.rowColorPropagation(loadingDocket.rowguid);

    this.searchLoadingDockets.emit();
  }

  async editLoadingDocket(loadingDocket: dtoLoadingDocket) {
    this.editLoadingDocketEvent.emit(loadingDocket);
    this.rowColorPropagation(loadingDocket.rowguid);
  }

  removeLoadingDocket(loadingDocket: dtoLoadingDocket) {
    this.removeLoadingDocketEvent.emit(loadingDocket);
  }

  addDeliveryDockets(loadingDocket: dtoLoadingDocket, rowIdx: number) {
    this.addLoadingDocketItemsEvent.emit(loadingDocket);
  }

  changeView(loadingDocket: dtoLoadingDocket, type: number) {
    this.viewType = type;
  }

  async downloadLoadingDocket(
    loadingDocket: dtoLoadingDocket,
    rowIdx: number,
    type: string
  ) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guLoadingDocketId", loadingDocket.rowguid));
    aParamList.push(aParam);

    let loadingItems =
      await this.dtoLoadingDocketItemService.getdtoLoadingDocketItemParamArrayPromise(
        aParamList
      );

    if (loadingItems == null) {
      this.alertService.openSnackBarError(
        "There was a problem getting the loading docket items",
        "Close",
        "center",
        "bottom",
        4000,
        true,
        "There was a problem getting the loading docket items"
      );
      return;
    }

    loadingItems.sort(
      (a, b) => b.intPoleLength - a.intPoleLength || b.intPoleKN - a.intPoleKN
    );

    console.log("type: ", type);

    this.dtoLoadingDocketService.downloadLoadingDockect(loadingItems, type);

    this.rowColorPropagation(loadingDocket.rowguid);
  }

  printLoadingDocket(loadingDocket: dtoLoadingDocket) {
    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf("localhost") == -1) {
      path = "https://" + location.hostname;
    }

    var windowUrl =
      path +
      "/loading-docket-print?loadingdocketid=" +
      loadingDocket.rowguid +
      "&hidenav=yes&openprintdialog=yes";
    var uniqueName = new Date();
    var windowName = "_blank";
    //var printWindow = window.open(windowUrl, windowName, 'left=400,top=200,width=950,height=600');
    var printWindow = window.open(windowUrl, windowName).focus();

    this.rowColorPropagation(loadingDocket.rowguid);
  }

  async openDeliveryAddressDialog(
    loadingDocket: dtoLoadingDocket,
    index: number
  ) {
    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (loadingDocket == null) {
      this.alertService.openSnackBarDefault(
        "There is no loading docket selected."
      );
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guLoadingDocketId", loadingDocket.rowguid));
    aParamList.push(aParam);

    let deliveryDockets: dtoDeliveryDocket[] =
      await this.dtoDeliveryDocketService.getDeliveryDocketPromiseParamArrayPromise(
        aParamList
      );

    if (deliveryDockets == null || deliveryDockets.length == 0) {
      this.alertService.openSnackBarDefault(
        "There are no delivery dockets attached to this loading docket."
      );
      return;
    }

    aParamList = [];
    aParam = [];
    aParam.push(
      new SQLParamArray("guDeliveryDocketId", deliveryDockets[0].rowguid)
    );
    aParamList.push(aParam);

    let dtoDelAddress: dtoCustomerDeliveryAddress = null;
    let dtoDeliveryDocketOrderItem: dtoDeliveryDocketOrderItem[] =
      await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemParamArrayPromise(
        aParamList
      );

    if (
      dtoDeliveryDocketOrderItem != null &&
      dtoDeliveryDocketOrderItem.length > 0
    ) {
      let dtoDelOrderItem: dtoDeliveryDocketOrderItem =
        dtoDeliveryDocketOrderItem[0];

      aParamList = [];
      aParam = [];
      aParam.push(
        new SQLParamArray("guDeliveryDocketId", deliveryDockets[0].rowguid)
      );
      aParamList.push(aParam);

      dtoDelAddress =
        await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressPromise(
          dtoDelOrderItem.guCustomerDeliveryAddressId
        );
    }

    this.rowColorPropagation(loadingDocket.rowguid);

    this.customerDeliveryAddressDialogRef = this.dialog.open(
      CustomerDeliveryAddressDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
        data: {
          customerId: deliveryDockets[0].guCustomerId,
          customerName: deliveryDockets[0].txtCustomerName,
          selectedCustomerDeliveryAddress: dtoDelAddress,
        },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.customerDeliveryAddressDialogRef.backdropClick().subscribe(() => {
      this.customerDeliveryAddressDialogRef.close();
    });

    this.customerDeliveryAddressDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          customerDeliveryAddress: ClientDeliveryAddress;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            let loadDocket: LoadingDocket =
              await this.loadingDocketService.getLoadingDocketPromise(
                loadingDocket.rowguid
              );
            if (loadDocket != null) {
              if (data.customerDeliveryAddress == null) {
                this.alertService.openSnackBar(
                  "There is no delivery address selected.",
                  "Close",
                  "center",
                  "bottom",
                  "",
                  5000
                );
                return;
              }

              if (data.customerDeliveryAddress != null) {
                let aParamList: SQLParamArray[][] = [];
                let aParam: SQLParamArray[] = [];
                aParam.push(
                  new SQLParamArray(
                    "guLoadingDocketId",
                    loadingDocket.rowguid,
                    SQLParamArray.enSQLOperator.Equals,
                    SQLParamArray.enSQLLogicalOperator.AND,
                    null,
                    "",
                    SQLParamArray.enSQLStatementType.SubQuerySQL,
                    1
                  )
                );
                aParamList.push(aParam);

                let deliveryDockets: DeliveryDocket[] =
                  await this.deliveryDocketService.getDeliveryDocketParamArrayPromise(
                    aParamList
                  );
                if (deliveryDockets != null) {
                  for (let d = 0; d <= deliveryDockets.length - 1; d++) {
                    let deliveryDocketOrderItem: DeliveryDocketOrderItem[] =
                      await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemByDocketIdPromise(
                        deliveryDockets[d].rowguid
                      );

                    for (
                      let i = 0;
                      i <= deliveryDocketOrderItem.length - 1;
                      i++
                    ) {
                      deliveryDocketOrderItem[i].guCustomerDeliveryAddressId =
                        data.customerDeliveryAddress.rowguid;
                      await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(
                        deliveryDocketOrderItem[i]
                      );

                      //console.log("updateDeliveryDocektOrderItemPromise:", deliveryDocketOrderItem[i]);
                    }

                    //console.log("delivery docket:", d);
                  }

                  loadingDocket =
                    await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(
                      loadingDocket.rowguid
                    );

                  if (this.datasource.data != null) {
                    for (let i = 0; i <= this.datasource.data.length - 1; i++) {
                      if (
                        this.datasource.data[i].rowguid == loadingDocket.rowguid
                      ) {
                        this.datasource.data[i] = loadingDocket;
                        //console.log("setLoadingDate: ", this.datasource.data[i]);
                        this.tblLoadingDocket.renderRows();
                        break;
                      }
                    }
                  }
                }
              }
            }

            this.alertService.openSnackBarDefault(
              "Delivery address has been updated."
            );
          } else {
            // We have cancellled
          }
        }
      );
  }

  openDateSelectorDialog(loadingDocket: dtoLoadingDocket, index: number) {
    let selectorDiv: HTMLDivElement = document.getElementById(
      "loadedDateSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "loaded-date-selector-location" + index
    ) as HTMLDivElement;
    //console.log(selectorLocationDiv.style.position);

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 210;
    var left = offsets.left + window.scrollX;
    ////var doc = document.documentElement;

    //console.log("offsets: ", offsets);
    //console.log("top: ", top);
    //console.log("left: ", left);
    //console.log("scroll top: ", selectorLocationDiv.scrollTop);
    //console.log("scroll left: ", window.pageXOffset);
    ////console.log("doc.clientLeft: ", doc.clientLeft);

    selectorDiv.style.top = top.toString() + "px";
    selectorDiv.style.left = left.toString() + "px";
    if (
      this.selectedLoadingDate == "" ||
      this.selectedLoadingDate == null ||
      loadingDocket.rowguid != this.selectedLoadingDate
    ) {
      this.selectedLoadingDate = loadingDocket.rowguid;
      this.selectedExpectedDeliveryDate = "";
      this.selectedExpectedLoadingDate = "";
      this.selectedRow = loadingDocket.rowguid;
      return;
    }

    if (this.selectedLoadingDate != "") {
      this.selectedLoadingDate = "";
      window.event.stopPropagation();
      //this.selectedRow = "";
      return;
    }
  }

  openExpectedLoadingDateSelector(
    loadingDocket: dtoLoadingDocket,
    index: number
  ) {
    //console.log("in openExpectedLoadingDateSelector: " + loadingDocket.rowguid);

    let selectorDiv: HTMLDivElement = document.getElementById(
      "expectingLoadingDateSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "expecting-loading-date-selector-location" + index
    ) as HTMLDivElement;
    //console.log(selectorLocationDiv.style.position);

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 210;
    var left = offsets.left + window.scrollX;
    ////var doc = document.documentElement;

    //console.log("offsets: ", offsets);
    //console.log("top: ", top);
    //console.log("left: ", left);
    //console.log("scroll top: ", selectorLocationDiv.scrollTop);
    //console.log("scroll left: ", window.pageXOffset);
    ////console.log("doc.clientLeft: ", doc.clientLeft);

    selectorDiv.style.top = top.toString() + "px";
    selectorDiv.style.left = left.toString() + "px";

    if (
      this.selectedExpectedLoadingDate == "" ||
      this.selectedExpectedLoadingDate == null ||
      loadingDocket.rowguid != this.selectedExpectedLoadingDate
    ) {
      this.selectedExpectedLoadingDate = loadingDocket.rowguid;
      this.selectedLoadingDate = "";
      this.selectedExpectedDeliveryDate = "";

      this.selectedRow = loadingDocket.rowguid;
      return;
    }

    if (this.selectedExpectedLoadingDate != "") {
      this.selectedExpectedLoadingDate = "";
      window.event.stopPropagation();
      //this.selectedRow = "";
      return;
    }
  }

  openExpectedDeliveryDateSelector(
    loadingDocket: dtoLoadingDocket,
    index: number
  ) {
    let selectorDiv: HTMLDivElement = document.getElementById(
      "expectingDeliveryDateSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "expecting-delivery-date-selector-location" + index
    ) as HTMLDivElement;
    console.log(selectorLocationDiv.style.position);

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 210;
    var left = offsets.left + window.scrollX;
    ////var doc = document.documentElement;

    //console.log("offsets: ", offsets);
    //console.log("top: ", top);
    //console.log("left: ", left);
    //console.log("scroll top: ", selectorLocationDiv.scrollTop);
    //console.log("scroll left: ", window.pageXOffset);
    ////console.log("doc.clientLeft: ", doc.clientLeft);

    selectorDiv.style.top = top.toString() + "px";
    selectorDiv.style.left = left.toString() + "px";

    if (
      this.selectedExpectedDeliveryDate == "" ||
      this.selectedExpectedDeliveryDate == null ||
      loadingDocket.rowguid != this.selectedExpectedDeliveryDate
    ) {
      this.selectedExpectedDeliveryDate = loadingDocket.rowguid;
      this.selectedRow = loadingDocket.rowguid;

      this.selectedExpectedLoadingDate = "";
      this.selectedLoadingDate = "";

      return;
    }

    if (this.selectedExpectedDeliveryDate != "") {
      this.selectedExpectedDeliveryDate = "";
      window.event.stopPropagation();
      //this.selectedRow = "";
      return;
    }
  }

  openDispatchedDateSelector(loadingDocket: dtoLoadingDocket, index: number) {
    let selectorDiv: HTMLDivElement = document.getElementById(
      "dispatchedDateSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "dispatched-date-selector-location" + index
    ) as HTMLDivElement;
    console.log(selectorLocationDiv.style.position);

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 210;
    var left = offsets.left + window.scrollX;

    selectorDiv.style.top = top.toString() + "px";
    selectorDiv.style.left = left.toString() + "px";

    if (
      this.selectedDispatchedDate == "" ||
      this.selectedDispatchedDate == null ||
      loadingDocket.rowguid != this.selectedDispatchedDate
    ) {
      this.selectedDispatchedDate = loadingDocket.rowguid;
      this.selectedRow = loadingDocket.rowguid;

      return;
    }

    if (this.selectedDispatchedDate != "") {
      this.selectedDispatchedDate = "";
      window.event.stopPropagation();
      //this.selectedRow = "";
      return;
    }
  }

  setPosition(index: number) {
    let selectorDiv: HTMLDivElement = document.getElementById(
      "dateSelector" + index
    ) as HTMLDivElement;
    let selectorLocationDiv: HTMLDivElement = document.getElementById(
      "date-selector-location" + index
    ) as HTMLDivElement;
    console.log(selectorLocationDiv.style.position);

    var offsets = selectorLocationDiv.getBoundingClientRect();
    var top = offsets.top;
    var left = offsets.left;
    console.log("offsets: ", offsets);
    console.log("top: ", top);
    console.log("left: ", left);

    selectorDiv.style.top = "300px";
    selectorDiv.style.left = "400px";
  }

  setRowColor(loadingDocketId: string) {
    // If we have clicked on any date selectors then don't toggle color.
    // in the date selector click event it will set the color there.
    // if they click on the date selector again we don't want to toggle the color.
    if (
      String(this.selectedExpectedDeliveryDate) != "" ||
      String(this.selectedExpectedLoadingDate) != "" ||
      String(this.selectedLoadingDate) != ""
    ) {
      console.log(
        " + this.selectedExpectedDeliveryDate: " +
          String(this.selectedExpectedDeliveryDate)
      );
      console.log(
        " + this.selectedExpectedLoadingDate: " +
          String(this.selectedExpectedLoadingDate)
      );
      console.log(
        " + this.selectedLoadingDate: " + String(this.selectedLoadingDate)
      );
      return;
    }

    //console.log("in set row color: " + loadingDocketId);

    if (this.selectedRow == "" || loadingDocketId != this.selectedRow) {
      this.selectedRow = loadingDocketId;
      return;
    }

    if (loadingDocketId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }
  }

  async setLoadingDate(loadingDocket: dtoLoadingDocket, loadingDate: Date) {
    let d: Date = new Date();
    console.log("d: ", d);
    d.setHours(0, 0, 0, 0);

    loadingDate = this.sharedService.convertDateAEST(loadingDate);

    if (loadingDate != null && loadingDate < d) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Past Date",
        "The date is in the past is this correct?",
        "Yes",
        "No",
        "center",
        "bottom"
      );
      if (msg != "Yes") {
        return;
      }
    }

    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    if (loadDocket == null) {
      return;
    }

    loadDocket.dteDateLoaded = loadingDate;

    await this.loadingDocketService.updateLoadingDocketPromise(loadDocket);

    loadingDocket =
      await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(
        loadingDocket.rowguid
      );

    if (this.datasource.data != null) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == loadingDocket.rowguid) {
          this.datasource.data[i] = loadingDocket;
          console.log("setLoadingDate: ", this.datasource.data[i]);
          this.tblLoadingDocket.renderRows();
          break;
        }
      }
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guLoadingDocketId", loadingDocket.rowguid));
    aParamList.push(aParam);

    //let deliveryDockets: DeliveryDocket[] = await this.deliveryDocketService.getDeliveryDocketParamArrayPromise(aParamList);
    //if (deliveryDockets != null) {
    //  deliveryDockets.forEach(async d => {
    //    d.dteStatusDate = loadingDate;
    //    d.intStatus = DeliveryDocket.enDeliveryStatus.Dispatched;
    //    await this.deliveryDocketService.updateDeliveryDocketPromise(d);
    //  });
    //}

    this.alertService.openSnackBarDefault("The date loaded has been updated");

    console.log("loading Date: " + loadingDate);

    this.selectedLoadingDate = "";
  }

  closeLoadingDate() {
    this.selectedLoadingDate = "";
  }

  async setExpectedLoadingDate(
    loadingDocket: dtoLoadingDocket,
    expectedLoadingDate: Date
  ) {
    let d: Date = new Date();
    console.log("d: ", d);
    d.setHours(0, 0, 0, 0);

    expectedLoadingDate =
      this.sharedService.convertDateAEST(expectedLoadingDate);

    if (expectedLoadingDate != null && expectedLoadingDate < d) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Past Date",
        "The date is in the past is this correct?",
        "Yes",
        "No",
        "center",
        "bottom"
      );
      if (msg != "Yes") {
        return;
      }
    }

    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    if (loadDocket == null) {
      return;
    }

    loadDocket.dteExpectedLoadingDate = expectedLoadingDate;

    await this.loadingDocketService.updateLoadingDocketPromise(loadDocket);

    loadingDocket =
      await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(
        loadingDocket.rowguid
      );

    if (this.datasource.data != null) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == loadingDocket.rowguid) {
          this.datasource.data[i] = loadingDocket;
          console.log("setexpectedLoadingDate: ", this.datasource.data[i]);
          this.tblLoadingDocket.renderRows();
          break;
        }
      }
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(
      new SQLParamArray(
        "guLoadingDocketId",
        loadingDocket.rowguid,
        SQLParamArray.enSQLOperator.Equals,
        SQLParamArray.enSQLLogicalOperator.AND,
        null,
        "",
        SQLParamArray.enSQLStatementType.SubQuerySQL,
        1
      )
    );
    aParamList.push(aParam);

    let deliveryDockets: DeliveryDocket[] =
      await this.deliveryDocketService.getDeliveryDocketParamArrayPromise(
        aParamList
      );
    if (deliveryDockets != null) {
      deliveryDockets.forEach(async (d) => {
        console.log("updating delivery dockets ");
        d.dteExpectedLoadingDate = expectedLoadingDate;
        await this.deliveryDocketService.updateDeliveryDocketPromise(d);
      });
    }

    this.alertService.openSnackBarDefault(
      "The expected loading date has been updated"
    );

    console.log("expectedLoadingDate: " + expectedLoadingDate);

    this.selectedExpectedLoadingDate = "";
  }

  closeExpectedLoadingDate() {
    this.selectedExpectedLoadingDate = "";
  }

  async setExpectedDeliveryDate(
    loadingDocket: dtoLoadingDocket,
    expectedDeliveryDate: Date
  ) {
    let d: Date = new Date();
    console.log("d: ", d);
    d.setHours(0, 0, 0, 0);

    console.log("expectedDeliveryDate - before: " + expectedDeliveryDate);

    expectedDeliveryDate =
      this.sharedService.convertDateAEST(expectedDeliveryDate);

    console.log("expectedDeliveryDate - after: " + expectedDeliveryDate);

    if (expectedDeliveryDate != null && expectedDeliveryDate < d) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Past Date",
        "The date is in the past is this correct?",
        "Yes",
        "No",
        "center",
        "bottom"
      );
      if (msg != "Yes") {
        return;
      }
    }

    let loadDocket: LoadingDocket =
      await this.loadingDocketService.getLoadingDocketPromise(
        loadingDocket.rowguid
      );
    if (loadDocket == null) {
      return;
    }

    loadDocket.dteExpectedDeliveryDate = expectedDeliveryDate;

    await this.loadingDocketService.updateLoadingDocketPromise(loadDocket);

    loadingDocket =
      await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(
        loadingDocket.rowguid
      );

    if (this.datasource.data != null) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == loadingDocket.rowguid) {
          this.datasource.data[i] = loadingDocket;
          console.log("setexpectedDeliveryDate: ", this.datasource.data[i]);
          this.tblLoadingDocket.renderRows();
          break;
        }
      }
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(
      new SQLParamArray(
        "guLoadingDocketId",
        loadingDocket.rowguid,
        SQLParamArray.enSQLOperator.Equals,
        SQLParamArray.enSQLLogicalOperator.AND,
        null,
        "",
        SQLParamArray.enSQLStatementType.SubQuerySQL,
        1
      )
    );
    aParamList.push(aParam);

    let deliveryDockets: DeliveryDocket[] =
      await this.deliveryDocketService.getDeliveryDocketParamArrayPromise(
        aParamList
      );
    if (deliveryDockets != null) {
      deliveryDockets.forEach(async (d) => {
        d.dteExpectedDeliveryDate = expectedDeliveryDate;
        await this.deliveryDocketService.updateDeliveryDocketPromise(d);
      });
    }

    this.alertService.openSnackBarDefault(
      "The expected delivery date has been updated"
    );

    console.log("expectedDeliveryDate: " + expectedDeliveryDate);

    this.selectedExpectedDeliveryDate = "";
  }

  closeExpectedDeliveryDate() {
    this.selectedExpectedDeliveryDate = "";
  }

  async openEquipmentDialog(loadingDocket: dtoLoadingDocket) {
    if (this.truckTrailerSelectionDialogRef != null) {
      this.truckTrailerSelectionDialogRef.close();
    }

    if (loadingDocket == null) {
      this.alertService.openSnackBarDefault(
        "There is no loading docket selected."
      );
      return;
    }

    this.rowColorPropagation(loadingDocket.rowguid);

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("txtName", "Trailer"));
    aParamList.push(aParam);

    let mobilePlantCategories: MobilePlantCategory[] =
      await this.mobilePlantCategoryService.getMobilePlantCategoryParamArrayPromise(
        aParamList
      );
    let categoryId: string = "";

    if (mobilePlantCategories != null && mobilePlantCategories.length > 0) {
      categoryId = mobilePlantCategories[0].rowguid;
    }

    let selectedHaulingCompany: HaulingCompany = null;
    if (loadingDocket.guHaulerId != null) {
      selectedHaulingCompany =
        await this.haulingCompanyService.getHaulingCompanyPromise(
          loadingDocket.guHaulerId
        );
    }

    let selectedHaulingCompanyDriver: HaulingCompanyDriver = null;
    if (loadingDocket.guDriverId != null) {
      selectedHaulingCompanyDriver =
        await this.haulingCompanyDriverService.getHaulingCompanyDriverPromise(
          loadingDocket.guDriverId
        );
    }

    let selectedTrailer: MobilePlantEquipment = null;
    if (loadingDocket.guTrailerId != null) {
      selectedTrailer =
        await this.mobilePlantEquipmentService.getMobilePlantEquipmentPromise(
          loadingDocket.guTrailerId
        );
    }

    this.truckTrailerSelectionDialogRef = this.dialog.open(
      TruckTrailerSelectionDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
        data: {
          equipmentCategoryId: categoryId,
          haulingCompany: selectedHaulingCompany,
          haulingCompanyDriver: selectedHaulingCompanyDriver,
          trailerEquipment: selectedTrailer,
          independentSelection: false,
        },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.truckTrailerSelectionDialogRef.backdropClick().subscribe(() => {
      this.truckTrailerSelectionDialogRef.close();
    });

    this.truckTrailerSelectionDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          haulingCompany: HaulingCompany;
          haulingCompanyDriver: HaulingCompanyDriver;
          trailerEquipment: MobilePlantEquipment;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            let loadDocket: LoadingDocket =
              await this.loadingDocketService.getLoadingDocketPromise(
                loadingDocket.rowguid
              );
            if (loadDocket != null) {
              if (data.haulingCompany != null) {
                loadDocket.guHaulerId = data.haulingCompany.rowguid;
                loadDocket.guMobilePlantEquipmentId =
                  data.haulingCompany.guMobilePlantEquipmentId;
              } else {
                loadDocket.guHaulerId = null;
                loadDocket.guMobilePlantEquipmentId = null;
              }

              loadDocket.guDriverId = null;
              if (data.haulingCompanyDriver != null) {
                loadDocket.guDriverId = data.haulingCompanyDriver.rowguid;
                //console.log("updated driver: ", loadDocket.guDriverId);
              } else {
                loadDocket.guDriverId = null;
              }

              if (data.trailerEquipment != null) {
                loadDocket.guTrailerId = data.trailerEquipment.rowguid;
              } else {
                loadDocket.guTrailerId = null;
              }

              await this.loadingDocketService.updateLoadingDocketPromise(
                loadDocket
              );

              loadingDocket =
                await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(
                  loadingDocket.rowguid
                );

              //this.updatedLoadingDocketEvent.emit(loadingDocket);

              if (this.datasource.data != null) {
                for (let i = 0; i <= this.datasource.data.length - 1; i++) {
                  if (
                    this.datasource.data[i].rowguid == loadingDocket.rowguid
                  ) {
                    this.datasource.data[i] = loadingDocket;
                    //this.datasource.data[i].txtHaulingCompanyName = "test!!!!!";
                    //this.loadingDockets
                    //console.log("updating loading docket: ", this.datasource.data[i]);
                    this.tblLoadingDocket.renderRows();
                    break;
                  }
                }
              }

              //Only set delivery docket hauler and driver if we are a treatment plant or crossarm loading docket.
              if (
                loadingDocket.intYardSection ==
                  SharedService.enYardSection.TreatmentPlant ||
                loadingDocket.intYardSection ==
                  SharedService.enYardSection.XArms
              ) {
                let aParamList: SQLParamArray[][] = [];
                let aParam: SQLParamArray[] = [];
                aParam.push(
                  new SQLParamArray(
                    "guLoadingDocketId",
                    loadingDocket.rowguid,
                    SQLParamArray.enSQLOperator.Equals,
                    SQLParamArray.enSQLLogicalOperator.AND,
                    null,
                    "",
                    SQLParamArray.enSQLStatementType.SubQuerySQL,
                    1
                  )
                );
                aParamList.push(aParam);

                let deliveryDockets: DeliveryDocket[] =
                  await this.deliveryDocketService.getDeliveryDocketParamArrayPromise(
                    aParamList
                  );
                if (deliveryDockets != null) {
                  deliveryDockets.forEach(async (d) => {
                    if (data.haulingCompany != null) {
                      d.guHaulingId = data.haulingCompany.rowguid;
                    }

                    d.guDriverId = null;
                    if (data.haulingCompanyDriver != null) {
                      d.guDriverId = data.haulingCompanyDriver.rowguid;
                    }

                    console.log(
                      "haulingCompanyDriver:",
                      data.haulingCompanyDriver
                    );
                    console.log("delivery docket:", d);

                    await this.deliveryDocketService.updateDeliveryDocketPromise(
                      d
                    );
                  });
                }
              }
            }

            this.alertService.openSnackBarDefault(
              "The loading docket has been updated with a truck and trailer"
            );
          } else {
            // We have cancellled
          }
        }
      );
  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  setTotalTonne() {
    this.totalTonne = 0;
    if (this.datasource != null && this.datasource.data != null) {
      this.datasource.data.forEach((ld) => {
        this.totalTonne += ld.fltActualTonne;
      });
    }
  }

  doubleclick(idx: number) {
    let div: HTMLDivElement = document.getElementById(
      "locationrow" + idx.toString()
    ) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(function () {
        if (div.getAttribute("data-dblclick") == "1") {
          window.alert("single");
        }
        div.removeAttribute("data-dblclick");
      }, 300);
    } else {
      div.removeAttribute("data-dblclick");
      window.alert("ondouble");
    }
  }

  async createLoadingDocketPDF(loadingDocket: dtoLoadingDocket) {
    this.loadingDocketPDFId = loadingDocket.rowguid;

    this.loadingDocketPrintApp.loadingDocketId = loadingDocket.rowguid;
    this.loadingDocketPrintApp.pdfMode = true;

    this.showCheckSheet = false;

    await this.loadingDocketPrintApp.loadData();

    setTimeout(() => {
      this.loadingDocketPrintApp.appLoadingDocketView.loadingDocketId =
        loadingDocket.rowguid;
      this.loadingDocketPrintApp.appLoadingDocketView.loadingDocketIdInput =
        loadingDocket.rowguid;
      this.loadingDocketPrintApp.appLoadingDocketView.loadData();
      this.loadingDocketPrintApp.appLoadingDocketView.isPrintPage = true;
    }, 300);

    setTimeout(async () => {
      let htmlElement: HTMLDivElement = document.getElementById(
        "loadingDocketPDF"
      ) as HTMLDivElement;
      htmlElement.classList.toggle("loading-docket-print-container-visible");
      let divHeight: number = htmlElement.clientHeight;
      let loadingDocketBottom: HTMLDivElement = document.getElementById(
        "loadingDocketBottom"
      ) as HTMLDivElement;

      loadingDocketBottom.style.marginTop = "10px";

      if (divHeight > 1700 && divHeight < 1960) {
        //console.log("deliveryDocketBottom: ", deliveryDocketBottom);
        loadingDocketBottom.style.marginTop = 1960 - divHeight + "px";
      }

      console.log("height: ", htmlElement.clientHeight);
      //==============================================================================================
      // TO DO:
      // If height is greater than 1700px and less than 1960px (htmlElement.clientHeight) then set a
      // margin-top: 260px on the Proof of delivery box.
      // If its greatert than 1960px it means the POD box is on the next page.
      // We could cater for the delivery docket going over two pages but is very unlikely.
      //==============================================================================================

      await this.createPDF(loadingDocket);

      setTimeout(() => {
        htmlElement.classList.toggle("loading-docket-print-container-visible");
      }, 500);
    }, 1000);
  }

  async createPDF(loadingDocket: dtoLoadingDocket) {
    //var pdf = new jsPDF('p', 'px', 'A4');
    var pdf = new jsPDF("p", "pt", "A4");

    //var pdf = new jsPDF('p', 'px', [297, 210]);
    //
    let htmlElement: HTMLElement = document.getElementById("loadingDocketPDF");
    //htmlElement.innerHTML = "<div style='width:500px;height500px;background-color: #92a8d1;'>Test Div</div>";

    if (htmlElement == null) {
      console.log("null element");
      return;
    }

    console.log("htmlElement", htmlElement);

    pdf.html(htmlElement, {
      html2canvas: {
        // insert html2canvas options here, e.g.
        scale: 0.5,
      },
      callback: (pdf) => {
        //let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
        //console.log(arrBuffer);
        //this.dtoDeliveryDocketService.printDeliveryDocket(arrBuffer);

        //this.http.post<Blob>("https://localhost:44358/api/Print/SaveFile/", arrBuffer, { headers: headers, responseType: 'blob' as 'json' }).subscribe(data => {
        //console.log("data: " + data);
        //});
        pdf.save(
          "LoadingDocket_" + loadingDocket.intDocketNo.toString() + ".pdf"
        );
      },
    });
  }

  getPDFBase64(loadingDocket: dtoLoadingDocket) {
    return new Promise<FileAttachment>(async (resolve) => {
      this.loadingDocketPDFId = loadingDocket.rowguid;

      this.loadingDocketPrintApp.loadingDocketId = loadingDocket.rowguid;
      this.loadingDocketPrintApp.pdfMode = true;

      await this.loadingDocketPrintApp.loadData();

      setTimeout(async () => {
        this.loadingDocketPrintApp.appLoadingDocketView.loadingDocketId =
          loadingDocket.rowguid;
        this.loadingDocketPrintApp.appLoadingDocketView.loadingDocketIdInput =
          loadingDocket.rowguid;
        this.showCheckSheet = false;
        await this.loadingDocketPrintApp.appLoadingDocketView.loadData();
        this.loadingDocketPrintApp.appLoadingDocketView.isPrintPage = true;
      }, 300);

      setTimeout(async () => {
        let htmlElement: HTMLDivElement = document.getElementById(
          "loadingDocketPDF"
        ) as HTMLDivElement;
        htmlElement.classList.toggle("loading-docket-print-container-visible");
        let divHeight: number = htmlElement.clientHeight;
        let loadingDocketBottom: HTMLDivElement = document.getElementById(
          "loadingDocketBottom"
        ) as HTMLDivElement;

        loadingDocketBottom.style.marginTop = "10px";

        if (divHeight > 1700 && divHeight < 1960) {
          //console.log("deliveryDocketBottom: ", deliveryDocketBottom);
          loadingDocketBottom.style.marginTop = 1960 - divHeight + "px";
        }

        //==============================================================================================
        // TO DO:
        // If height is greater than 1700px and less than 1960px (htmlElement.clientHeight) then set a
        // margin-top: 260px on the Proof of delivery box.
        // If its greatert than 1960px it means the POD box is on the next page.
        // We could cater for the delivery docket going over two pages but is very unlikely.
        //==============================================================================================

        //var pdf = new jsPDF('p', 'pt', 'A4');

        if (htmlElement == null) {
          console.log("null element");
          return;
        }

        //pdf.html(htmlElement,
        //  {
        //    html2canvas: {
        //      // insert html2canvas options here, e.g.
        //      scale: 0.5
        //    },
        //    callback: (pdf) => {
        //      let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
        //      let bs64 = pdf.output('datauri');

        //      //console.log("arrBuffer Length", arrBuffer.toString());
        //      //console.log("bs64 length", bs64.toString().length);

        //      let fileAttachment: FileAttachment = new FileAttachment("LoadingDocket_" + loadingDocket.intDocketNo.toString() + ".pdf", "application/pdf", bs64.toString(), arrBuffer.byteLength);
        //      resolve(fileAttachment);

        //      //pdf.save('DeliveryDocket_' + deliveryDocket.intDocketNo.toString() + '.pdf');

        //    }
        //  });

        let loadingArrayBuffer: ArrayBuffer;

        loadingArrayBuffer = await this.fileService.HTMLToPdfArrayBuffer(
          htmlElement
        );
        let arrBuffer: ArrayBuffer[] = [];
        arrBuffer.push(loadingArrayBuffer);

        if (loadingArrayBuffer == null) {
          await this.alertService.openSnackBarCustomPromise(
            "Loading Docket PDF",
            "There was a problem creating the loading docket pdf",
            "OK",
            "",
            "center",
            "bottom",
            "",
            0,
            false
          );
          resolve(null);
        }

        resolve(
          this.fileService.createPDFFileAttachment(
            "LoadingDocket_" + loadingDocket.intDocketNo.toString() + ".pdf",
            arrBuffer
          )
        );

        setTimeout(() => {
          htmlElement.classList.toggle(
            "loading-docket-print-container-visible"
          );
        }, 500);
      }, 1000);
    });
  }

  showAndPositionInlineSpinner(top: number, left: number) {
    this.showInlineSpinner = true;
    setTimeout(() => {
      this.positionSpinner(top, left);
    }, 200);
  }

  hideInlineSpinner() {
    this.showInlineSpinner = false;

    setTimeout(() => {
      this.showTick = true;
    }, 400);
    setTimeout(() => {
      this.showTick = false;
    }, 600);
  }

  async emailLoadingDocket(
    loadingDocket: dtoLoadingDocket,
    emailButtonTD: HTMLTableDataCellElement
  ) {
    var offsets = emailButtonTD.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 175;
    var left = offsets.left + window.scrollX - 25;

    //console.log("top" + top + " - left: " + left);

    this.showAndPositionInlineSpinner(top, left);

    this.rowColorPropagation(loadingDocket.rowguid);

    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (loadingDocket == null || loadingDocket.rowguid == "") {
      this.alertService.openSnackBarDefault(
        "There is no loading docket selected."
      );
      return;
    }

    let fileAttachments: FileAttachment[] = [];
    let fileAttachment: FileAttachment = await this.getPDFBase64(loadingDocket);
    if (fileAttachment != null) {
      fileAttachments.push(fileAttachment);
    }
    //console.log(fileAttachments);
    let username: string = (await this.authService.getUser()).displayName;

    this.hideInlineSpinner();

    this.sendMailDialogRef = this.dialog.open(SendMailDialogComponent, {
      height: "auto",
      maxWidth: "90vw",
      data: {
        toAddresses: "",
        ccAddresses: "",
        bccAddresses: "",
        emailSubject: "Coffs Harbour Hardwoods Loading Docket",
        emailMessage:
          "Hi\n\nPlease find attached loading docket " +
          loadingDocket.intDocketNo.toString() +
          "\n\nRegards\n\n" +
          username,
        fileAttachements: fileAttachments,
      },
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.sendMailDialogRef.backdropClick().subscribe(() => {
      this.sendMailDialogRef.close();
    });

    this.sendMailDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);
          //if (data.customerDeliveryAddress == null) {
          //  this.alertService.openSnackBar("There is no delivery address selected.", "Close", "center", "bottom", "", 5000);
          //  return;
          //}
          //if (data.customerDeliveryAddress != null) {
          //  this.alertService.openSnackBarDefault("Delivery address has been updated.");
          //}
        } else {
          // We have cancellled
        }
      });
  }
}
