import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { EmailMessage } from "../app-objects/email-message";
import { SharedService } from "./shared.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SendMailDialogComponent } from "../../email/send-mail-dialog/send-mail-dialog.component";
import { FileAttachment } from "../app-objects/file-attachment";
import { MSGraphEmailMessage } from "../app-objects/msgraph-email-message";

@Injectable()
export class EmailService {
  public _baseUrl: string;

  sendMailDialogRef: MatDialogRef<SendMailDialogComponent>;

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {
    this._baseUrl = baseUrl;
  }

  sendEmail(emailMessage: EmailMessage) {
    return new Promise<string>((resolve) => {
      let serverPath: string = this.sharedService.PrintServerURI;
      //serverPath = "https://localhost:44358/";

      this.http
        .post(serverPath + "api/Email/SendEmail/", emailMessage, {
          responseType: "text",
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            console.log(err);
            resolve("");
          }
        );
    });
  }

  sendEmailOnBehalfOf(emailMessage: MSGraphEmailMessage, userId: string) {
    return new Promise<string>((resolve) => {
      this.http
        .post(this._baseUrl + "api/Email/SendEmail2/" + userId, emailMessage, {
          responseType: "text",
        })
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err);
            resolve("");
          }
        );
    });
  }

  async openEmailDialog(
    toAddresses: string,
    ccAddresses: string,
    subject: string,
    message: string,
    emailDiv: HTMLDivElement,
    fileAttachments: FileAttachment[]
  ) {
    var offsets = emailDiv.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 175;
    var left = offsets.left + window.scrollX - 25;

    //console.log("top" + top + " - left: " + left);

    if (this.sendMailDialogRef != null) {
      this.sendMailDialogRef.close();
    }

    //let fileAttachment: FileAttachment = await this.getPDFBase64(deliveryDocket);
    //if (fileAttachment != null) {
    //  fileAttachments.push(fileAttachment);
    //}

    //console.log(fileAttachments);

    this.sendMailDialogRef = this.dialog.open(SendMailDialogComponent, {
      height: "auto",
      maxWidth: "90vw",
      data: {
        toAddresses: toAddresses,
        ccAddresses: ccAddresses,
        bccAddresses: "",
        emailSubject: subject,
        emailMessage: message,
        fileAttachements: fileAttachments,
      },
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.sendMailDialogRef.backdropClick().subscribe(() => {
      this.sendMailDialogRef.close();
    });

    this.sendMailDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
        } else {
          // We have cancellled
        }
      });
  }
}
