import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { QueryList } from "@angular/core";
import { ElementRef, Input, ViewChild } from "@angular/core";
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  MatDatepicker,
  MatDateRangePicker,
} from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { MatSelectChange } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { share, takeUntil } from "rxjs/operators";
import { AuthService } from "../../account/auth.service";
import { FinalInspectionListComponent } from "../../final-inspection/final-inspection-list/final-inspection-list.component";
import { CHHPoleStock } from "../../_shared/business-objects/chhpole-stock.bo";
import { Client } from "../../_shared/business-objects/client.bo";
import { ContractCutSawLog } from "../../_shared/business-objects/contract-cut-saw-log.bo";
import {
  dtoIntKeyValue,
  dtoKeyValue,
} from "../../_shared/business-objects/dto-int-key-value.bo";
import { dtoOrderItem } from "../../_shared/business-objects/dto-order-item.bo";
import { dtoTPChargeItem } from "../../_shared/business-objects/dto-tpcharge-item.bo";
import { ElectricalAuthoritySpec } from "../../_shared/business-objects/electrical-authority-spec.bo";
import { OrderItem } from "../../_shared/business-objects/order-item.bo";
import { Orders } from "../../_shared/business-objects/orders.bo";
import { Pole } from "../../_shared/business-objects/pole.bo";
import { ProductType } from "../../_shared/business-objects/product-type.bo";
import { Species } from "../../_shared/business-objects/species.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { TPChargeItem } from "../../_shared/business-objects/tpcharge-item.bo";
import { TPPoleInspectionItem } from "../../_shared/business-objects/tppole-inspection-item.bo";
import { TPPoleInspection } from "../../_shared/business-objects/tppole-inspection.bo";
import { ErrorMessage } from "../../_shared/ErrorMessage.class";
import { AlertService } from "../../_shared/services/alert.service";
import { CHHPoleStockService } from "../../_shared/services/chhpole-stock.service";
import { ClientService } from "../../_shared/services/client.service";
import { dtoOrderItemService } from "../../_shared/services/dto-order-item.service";
import { dtoTPChargeItemService } from "../../_shared/services/dto-tpcharge-item.service";
import { ElectricalAuthoritySpecService } from "../../_shared/services/electrical-authority-spec.service";
import { FormService } from "../../_shared/services/form.service";
import { OrderItemService } from "../../_shared/services/order-item.service";
import { OrdersService } from "../../_shared/services/orders.service";
import { PoleCubeService } from "../../_shared/services/pole-cube.service";
import { PoleService } from "../../_shared/services/pole.service";
import { ProductTypeService } from "../../_shared/services/product-type.service";
import { SharedService } from "../../_shared/services/shared.service";
import { SpeciesService } from "../../_shared/services/species.service";
import { TPChargeItemService } from "../../_shared/services/tpcharge-item.service";
import { TPPoleInspectionItemService } from "../../_shared/services/tppole-inspection-item.service";
import { ConsecutiveNumberListComponent } from "../consecutive-number-list/consecutive-number-list.component";

@Component({
  selector: "app-final-inspection-item-update",
  templateUrl: "./final-inspection-item-update.component.html",
  styleUrls: ["./final-inspection-item-update.component.css"],
})
export class FinalInspectionItemUpdateComponent implements OnInit {
  form: FormGroup;
  @ViewChild("f") myNgForm;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  @ViewChild("orderitem") orderItem: ElementRef;
  @ViewChild("polenumber") poleNumber: HTMLElement;
  @ViewChild("speciesListBox") speciesMatList: MatSelectionList;
  @ViewChild("guCustomerId") customerList: MatSelectionList;
  @ViewChild("guOrderId") ordersList: MatSelectionList;
  @ViewChild("guOrderItemId") orderItemList: MatSelectionList;

  private finalInspectionItem: TPPoleInspectionItem;

  public customers: Client[];
  public customersAll: Client[];
  public orders: Orders[];
  public ordersAll: Orders[];
  public orderItems: dtoOrderItem[];
  public orderItemsAll: dtoOrderItem[];
  public productTypes: ProductType[];
  public species: Species[];
  public speciesOriginal: Species[];
  public elecAuthSpecs: ElectricalAuthoritySpec[];
  public eDiameterTypes: dtoIntKeyValue[];

  //public trimmingItemReasonForChangeData: PoleTrimmingItemReasonForChange[];
  private reasonForChangeSelected: any;
  showErrorMessage: boolean;
  errorMessage: ErrorMessage;

  //guProductTypeId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  //guSpeciesId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  public productTypeFilterCtrl: FormControl = new FormControl();
  public customersFilterCtrl: FormControl = new FormControl();
  public ordersFilterCtrl: FormControl = new FormControl();
  public orderItemFilterCtrl: FormControl = new FormControl();
  public speciesFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  public filteredProductTypes: ReplaySubject<ProductType[]> = new ReplaySubject<
    ProductType[]
  >(1);
  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<
    Client[]
  >(1);
  public filteredOrders: ReplaySubject<Orders[]> = new ReplaySubject<Orders[]>(
    1
  );
  public filteredOrderItems: ReplaySubject<dtoOrderItem[]> = new ReplaySubject<
    dtoOrderItem[]
  >(1);
  public filteredSpecies: ReplaySubject<Species[]> = new ReplaySubject<
    Species[]
  >(1);
  public reasonForChangeSelectedValues: any;
  public eOriginalProductMeasurementType: ProductType.enMeasurementType;
  public eFinishedProductMeasurementType: ProductType.enMeasurementType;
  public selectedOriginalProduct: ProductType;
  public selectedFinishProduct: ProductType;
  public selectedOrderItem: dtoOrderItem;

  public finishedDiamHint: string = "";
  public finishedGirthHint: string = "";
  public originalKNHint: string = "";
  public finishedKNHint: string = "";
  public originalLengthHint: string = "";
  public finishedLengthHint: string = "";
  public finishedDiaPlaceHolder: string = "";
  public finishedDiaTypePlaceHolder: string = "";
  public finishedGirthPlaceHolder: string = "";

  public treatmentMonthHint: string = "";
  public treatmentYearHint: string = "";

  public today = new Date();
  public year: number = this.today.getFullYear();
  public decadeDigit: string = this.year
    .toString()
    .substring(
      this.year.toString().length - 2,
      this.year.toString().length - 1
    );
  public yearDigits: number = parseInt(
    this.year
      .toString()
      .substring(this.year.toString().length - 2, this.year.toString().length)
  );

  public treatment_year_Validator: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(2),
    Validators.max(this.yearDigits),
    this.tpChargeItemService.treatment_year_Validator,
  ];
  public treatment_month_Validator: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(2),
    Validators.max(12),
    this.tpChargeItemService.treatment_month_Validator,
  ];

  public saving: boolean = false;
  public showSpinner: boolean = false;

  consecutiveNumberListDialogRef: MatDialogRef<ConsecutiveNumberListComponent>;
  selectedTPChargeItem: dtoTPChargeItem = null;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FinalInspectionListComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      finalInspection: TPPoleInspection;
      finalInspectionItem: TPPoleInspectionItem;
    },
    private sharedService: SharedService,
    private alertService: AlertService,
    private poleService: PoleService,
    private poleCubeService: PoleCubeService,
    private productTypeService: ProductTypeService,
    private formService: FormService,
    private customerService: ClientService,
    private speciesService: SpeciesService,
    private ordersService: OrdersService,
    private orderItemService: OrderItemService,
    private dtoOrderItemService: dtoOrderItemService,
    private tpChargeItemService: TPChargeItemService,
    private electricalAuthoritySpec: ElectricalAuthoritySpecService,
    private tpPoleInspectionItemService: TPPoleInspectionItemService,
    private authService: AuthService,
    private dialog: MatDialog,
    private chhPoleStockService: CHHPoleStockService,
    private dtoTPChargeItemService: dtoTPChargeItemService
  ) {
    this.finalInspectionItem = data.finalInspectionItem;


    dialogRef.disableClose = true;
  }

  @HostListener("window:keyup.esc") onKeyUp() {
    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });
  }

  ngOnInit(): void {
    if (this.finalInspectionItem == null) {
      this.alertService.openSnackBarError(
        "There is no final inspection item to update",
        "Close",
        "center",
        "bottom",
        3000,
        true,
        "No final inspection when going to edit."
      );
      return;
    }

    this.setForm();

    //productTypeFilterCtrl: ['', Validators.required],

    const datepipe: DatePipe = new DatePipe("en-AU");
    this.dteDateCreated.setValue(datepipe.transform(Date.now(), "yyyy-MM-dd"));

    this.loadData();

    this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
    this.originalLengthHint = "8m to 26m";
    this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
    this.finishedLengthHint = "8m to 26m";
    this.finishedDiaTypePlaceHolder = "GL";
    this.finishedDiaPlaceHolder =
      this.finishedDiaTypePlaceHolder + " Diameter mm";
    this.finishedGirthPlaceHolder = "Girth Diameter mm";
    this.treatmentMonthHint = "6";
    this.treatmentYearHint = "22";

    this.intFinishedDiameterType.disable();
  }

  async setForm() {
    this.form = this.formBuilder.group({
      dteDateCreated: ["", Validators.required],
      guCustomerId: ["", Validators.required],
      guOrderId: ["", Validators.required],
      guOrderItemId: ["", Validators.required],
      txtPoleNumber: [""],
      txtConsecutiveNumber: [""],
      txtChargeNumber: ["", Validators.maxLength(3)],
      txtTreatmentMonth: [
        "",
        [
          Validators.required,
          Validators.maxLength(2),
          Validators.max(12),
          this.tpChargeItemService.treatment_month_Validator,
        ],
      ],
      txtTreatmentYear: [
        "",
        [
          Validators.required,
          Validators.maxLength(2),
          Validators.max(this.yearDigits),
          this.tpChargeItemService.treatment_year_Validator,
        ],
      ],
      guOriginalProductTypeId: [
        ["F5BAF9BF-A492-498E-AA36-45037CA6423F"],
        Validators.required,
      ],
      guFinishedProductTypeId: [
        ["F5BAF9BF-A492-498E-AA36-45037CA6423F"],
        Validators.required,
      ],
      txtOriginalLength: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.max(26),
          this.poleService.original_nsw_LengthValidator,
        ],
      ],
      txtOriginalKN: [
        "",
        [Validators.required, Validators.pattern(this.poleService.nsw_kNRange)],
      ],
      txtFinishedLength: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.max(26),
          this.poleService.finished_nsw_LengthValidator,
        ],
      ],
      txtFinishedKN: [
        "",
        [Validators.required, Validators.pattern(this.poleService.nsw_kNRange)],
      ],
      fltFinishedDiameter: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.min(150),
          Validators.max(1000),
        ],
      ],
      intFinishedDiameterType: [4, Validators.required],
      fltFinishedGirth: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.min(150),
          Validators.max(1000),
        ],
      ],
      guSpeciesId: ["", Validators.required],
      guElectricalAuthoritySpecId: [""],
      txtComments: [""],
      chkOriginalQV: [false, Validators.required],
      chkFinishedQV: [false, Validators.required],
      chkOriginalSub: [false],
      chkFinishedSub: [false],
      chkOriginalRegal: [false],
      chkFinishedRegal: [false],
      guTPPoleInspectionItemReasonForChange: [""],
      chkDrilled: [""],
      chkRejected: [""],
      txtRejectedComments: [""],
    });

    if (this.finalInspectionItem != null) {
      let orderItem: OrderItem =
        await this.orderItemService.getOrderItemsPromise(
          this.finalInspectionItem.guOrderItemId
        );

      if (orderItem.intTreatmentType == SharedService.enTreatmentType.CCA) {
        //console.log("setting treatment validators");
        this.txtTreatmentMonth.setValidators(this.treatment_month_Validator);
        this.txtTreatmentMonth.updateValueAndValidity();
        this.txtTreatmentYear.setValidators(this.treatment_year_Validator);
        this.txtTreatmentYear.updateValueAndValidity();
      }

      if (orderItem.intTreatmentType != SharedService.enTreatmentType.CCA) {
        //console.log("removing treatment validators");
        this.txtTreatmentMonth.clearValidators();
        this.txtTreatmentYear.clearValidators();
        this.txtTreatmentMonth.updateValueAndValidity();
        this.txtTreatmentYear.updateValueAndValidity();
      }

      this.selectedOriginalProduct =
        await this.productTypeService.getProductTypePromise(
          this.finalInspectionItem.guOriginalProductTypeId
        );
      this.selectedFinishProduct =
        await this.productTypeService.getProductTypePromise(
          this.finalInspectionItem.guProductTypeId
        );

      let originalPole = null;
      let finishedPole = null;

      if (this.finalInspectionItem.guOriginalPoleId != null) {
        originalPole = await this.poleService.getPoleByIdPromise(
          this.finalInspectionItem.guOriginalPoleId
        );
      }

      if (this.finalInspectionItem.guFinalPoleId != null) {
        finishedPole = await this.poleService.getPoleByIdPromise(
          this.finalInspectionItem.guFinalPoleId
        );
      }

      this.setValidators(
        this.selectedOriginalProduct,
        this.selectedFinishProduct,
        originalPole,
        finishedPole
      );
    }
  }

  get dteDateCreated() {
    return this.form.get("dteDateCreated");
  }

  get txtChargeNumber() {
    return this.form.get("txtChargeNumber");
  }

  get txtTreatmentMonth() {
    return this.form.get("txtTreatmentMonth");
  }

  get txtTreatmentYear() {
    return this.form.get("txtTreatmentYear");
  }

  get fltFinishedDiameter() {
    return this.form.get("fltFinishedDiameter");
  }

  get intFinishedDiameterType() {
    return this.form.get("intFinishedDiameterType");
  }

  get fltFinishedGirth() {
    return this.form.get("fltFinishedGirth");
  }

  get chkOriginalQV() {
    return this.form.get("chkOriginalQV");
  }

  get chkFinishedQV() {
    return this.form.get("chkFinishedQV");
  }

  get chkOriginalSub() {
    return this.form.get("chkOriginalSub");
  }

  get chkFinishedRegal() {
    return this.form.get("chkFinishedRegal");
  }

  get chkOriginalRegal() {
    return this.form.get("chkOriginalRegal");
  }

  get chkFinishedSub() {
    return this.form.get("chkFinishedSub");
  }

  get txtOriginalKN() {
    return this.form.get("txtOriginalKN");
  }

  get txtFinishedKN() {
    return this.form.get("txtFinishedKN");
  }

  get txtOriginalLength() {
    return this.form.get("txtOriginalLength");
  }

  get txtFinishedLength() {
    return this.form.get("txtFinishedLength");
  }

  get chkDrilled() {
    return this.form.get("chkDrilled");
  }

  get guElectricalAuthoritySpecId() {
    return this.form.get("guElectricalAuthoritySpecId");
  }

  get guSpeciesId() {
    return this.form.get("guSpeciesId");
  }

  async loadData() {
    await this.getCustomers();
    await this.getSpecies();
    await this.getProductTypes();
    await this.getElectricalAuthoritySpecs();
    this.eDiameterTypes = await this.sharedService.getEnumObjectKeyValue(
      SharedService.enDiameterType
    );

    //if (this.finalInspectionItem.guCustomerId == null) {
    //  let order = await this.ordersService.getOrdersPromise(this.finalInspectionItem.guOrderId);
    //  this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(order.guCustomerId);
    //  this.finalInspectionItem.guCustomerId = order.guCustomerId  // temporay store.
    //}

    this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(
      this.finalInspectionItem.guCustomerId
    );

    let currentOrder = await this.ordersService.getOrdersPromise(
      this.finalInspectionItem.guOrderId
    );

    this.orders.splice(0, 0, currentOrder);

    this.ordersAll = this.orders;

    //this.filteredOrders.next(this.orders);

    //this.ordersFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrders();
    //  });

    this.orderItems =
      await this.orderItemService.getdtoOrderItemsByOrderPromise(
        this.finalInspectionItem.guOrderId
      );
    this.orderItemsAll = this.orderItems;

    if (
      this.finalInspectionItem.txtConsecutiveNumber != null &&
      this.finalInspectionItem.txtConsecutiveNumber != ""
    ) {
      //Go and find ChargeItem record.

      let chargeItem: dtoTPChargeItem =
        await this.dtoTPChargeItemService.getdtoTPChargeItemByFinalItemId(
          this.finalInspectionItem.rowguid
        );
      if (chargeItem != null) {
        this.selectedTPChargeItem = chargeItem;
      }
    }
    //this.filteredOrderItems.next(this.orderItems);

    //this.orderItemFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrderItems();
    //  });

    this.loadFinalInspectionItem();
  }

  async loadFinalInspectionItem() {
    this.selectedOriginalProduct =
      await this.productTypeService.getProductTypePromise(
        this.finalInspectionItem.guOriginalProductTypeId
      );
    this.selectedFinishProduct =
      await this.productTypeService.getProductTypePromise(
        this.finalInspectionItem.guProductTypeId
      );
    //let order = await this.ordersService.getOrdersPromise(this.finalInspectionItem.guOrderId);
    if (this.finalInspectionItem.guOrderItemId != null) {
      this.selectedOrderItem =
        await this.dtoOrderItemService.getdtoOrderItemPromise(
          this.finalInspectionItem.guOrderItemId
        );
    }

    let originalPole = null;
    let finishedPole = null;

    if (this.finalInspectionItem.guOriginalPoleId != null) {
      originalPole = await this.poleService.getPoleByIdPromise(
        this.finalInspectionItem.guOriginalPoleId
      );
    }

    if (this.finalInspectionItem.guFinalPoleId != null) {
      finishedPole = await this.poleService.getPoleByIdPromise(
        this.finalInspectionItem.guFinalPoleId
      );
    }

    let productType = await this.productTypeService.getProductTypePromise(
      this.finalInspectionItem.guProductTypeId
    );
    let originalProductType =
      await this.productTypeService.getProductTypePromise(
        this.finalInspectionItem.guOriginalProductTypeId
      );

    this.form.controls["dteDateCreated"].setValue(
      this.finalInspectionItem.dteDateCreated
    );
    this.form.controls["guCustomerId"].setValue([
      this.finalInspectionItem.guCustomerId,
    ]);
    this.form.controls["txtPoleNumber"].setValue(
      this.finalInspectionItem.txtPoleNumber
    );
    this.form.controls["txtConsecutiveNumber"].setValue(
      this.finalInspectionItem.txtConsecutiveNumber
    );
    this.form.controls["txtChargeNumber"].setValue(
      this.finalInspectionItem.txtChargeId
    );
    this.form.controls["txtTreatmentMonth"].setValue(
      this.finalInspectionItem.intTreatmentMonth
    );
    this.form.controls["txtTreatmentYear"].setValue(
      this.formatTreatmentYear(this.finalInspectionItem.intTreatmentYear)
    );

    this.form.controls["guFinishedProductTypeId"].setValue([
      this.finalInspectionItem.guProductTypeId,
    ]);
    this.form.controls["guOriginalProductTypeId"].setValue([
      this.finalInspectionItem.guOriginalProductTypeId,
    ]);

    this.form.controls["guSpeciesId"].setValue([
      this.finalInspectionItem.guSpeciesId,
    ]);

    this.form.controls["fltFinishedDiameter"].setValue(
      this.finalInspectionItem.fltDiameter
    );
    this.form.controls["intFinishedDiameterType"].setValue(
      this.finalInspectionItem.intDiameterType
    );
    this.form.controls["fltFinishedGirth"].setValue(
      this.finalInspectionItem.fltGirth
    );
    this.form.controls["guElectricalAuthoritySpecId"].setValue(
      this.finalInspectionItem.guElectricalAuthoritySpecId
    );
    this.form.controls["chkDrilled"].setValue(
      this.finalInspectionItem.blnDrilled
    );
    this.form.controls["chkRejected"].setValue(
      this.finalInspectionItem.blnRejected
    );
    this.form.controls["txtRejectedComments"].setValue(
      this.finalInspectionItem.txtRejectedComments
    );

    if (originalPole != null) {
      this.form.controls["txtOriginalLength"].setValue(originalPole.intLength);
      this.form.controls["txtOriginalKN"].setValue(originalPole.intKn);
      this.form.controls["chkOriginalQV"].setValue(originalPole.blnQV);
      this.form.controls["chkOriginalSub"].setValue(originalPole.blnSubPole);
      this.form.controls["chkOriginalRegal"].setValue(originalPole.blnRegal);
      //console.log("originalPole: ", originalPole);
    }

    if (originalPole == null) {
      this.form.controls["txtOriginalLength"].setValue(
        this.finalInspectionItem.fltOriginalLength
      );
      this.form.controls["txtOriginalLength"].disable();
      this.form.controls["txtOriginalKN"].disable();
      this.form.controls["chkOriginalSub"].disable();
      this.form.controls["chkOriginalRegal"].disable();
    }

    if (finishedPole != null) {
      this.form.controls["txtFinishedLength"].setValue(finishedPole.intLength);
      this.form.controls["txtFinishedKN"].setValue(finishedPole.intKn);
      this.form.controls["chkFinishedQV"].setValue(finishedPole.blnQV);
      this.form.controls["chkFinishedSub"].setValue(finishedPole.blnSubPole);
      this.form.controls["chkFinishedRegal"].setValue(finishedPole.blnRegal);
      this.form.controls["txtFinishedLength"].disable();
      this.form.controls["txtFinishedKN"].disable();
      this.form.controls["chkFinishedQV"].disable();
      this.form.controls["chkFinishedSub"].disable();
      this.form.controls["chkFinishedRegal"].disable();
    }

    if (finishedPole == null) {
      this.form.controls["txtFinishedLength"].setValue(
        this.finalInspectionItem.fltLength
      );
      this.form.controls["txtFinishedLength"].disable();
      this.form.controls["txtFinishedKN"].disable();
      this.intFinishedDiameterType.disable();
      this.form.controls["chkFinishedSub"].disable();
      this.form.controls["chkFinishedRegal"].disable();
    }
    //console.log("orders.length: " + this.orders.length);
    //console.log("finalInspectionItem.guOrderId: " + this.finalInspectionItem.guOrderId);

    for (let i = 0; i <= this.orders.length - 1; i++) {
      if (this.finalInspectionItem.guOrderId == this.orders[i].rowguid) {
        this.form.controls["guOrderId"].setValue([this.orders[i]]);
        //let order: Orders = this.orders[i];
        //setTimeout(() => {
        //  this.formService.scrollToMatListOption("guOrderId", order, this.customerList.options.toArray())
        //}, 500);

        break;
      }
    }

    for (let i = 0; i <= this.orderItems.length - 1; i++) {
      if (
        this.finalInspectionItem.guOrderItemId == this.orderItems[i].rowguid
      ) {
        this.form.controls["guOrderItemId"].setValue([this.orderItems[i]]);
        break;
      }
    }

    this.setValidators(
      originalProductType,
      productType,
      originalPole,
      finishedPole
    );

    setTimeout(() => {
      this.formService.scrollToMatListOption(
        "guCustomerId",
        this.finalInspectionItem.guCustomerId,
        this.customerList.options.toArray()
      );
    }, 500);

    this.scrollToSpecies();
  }

  scrollToSpecies() {
    let speciesList = document.getElementById("guSpeciesId");

    //console.log(this.speciesMatList.selectedOptions.selected[0].value);
    let selectedSpeciesValue: string = this.finalInspectionItem.guSpeciesId; // this.speciesMatList.selectedOptions.selected[0].value;
    let selectedSpeciesHeight: number = 0;

    let speciesListOptions: MatListOption[] =
      this.speciesMatList.options.toArray();

    //console.log("speciesMatList.options.toArray()", this.speciesMatList.options.toArray());

    for (let i = 0; i <= speciesListOptions.length - 1; i++) {
      let element: HTMLElement = speciesListOptions[i]._hostElement;
      selectedSpeciesHeight += element.clientHeight;
      //console.log("element.nodeValue: " + element.innerText + ",   value: " + speciesListOptions[i].value + ",   selectedSpeciesValue: " + selectedSpeciesValue);
      if (speciesListOptions[i].value == selectedSpeciesValue) {
        break;
      }
      //console.log("selectedSpeciesHeight: " + selectedSpeciesHeight + ",   clientHeight: " + element.clientHeight + ",   clientLeft: " + element.clientLeft + ",   clientTop: " + element.clientTop);
    }

    if (selectedSpeciesHeight > 500) {
      speciesList.scrollTo({
        top: selectedSpeciesHeight - 75,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  async save(mode: string) {
    if (
      (this.form.controls["txtPoleNumber"].value == null ||
        this.form.controls["txtPoleNumber"].value == "") &&
      (this.form.controls["txtConsecutiveNumber"].value == null ||
        this.form.controls["txtConsecutiveNumber"].value == "")
    ) {
      this.alertService.openSnackBar(
        "You must enter a pole number or a consective number",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      let element = document.getElementById(
        "txtPoleNumber"
      ) as HTMLInputElement;
      element.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      this.form.controls["guSpeciesId"].value == null ||
      this.form.controls["guSpeciesId"].value[0] == null
    ) {
      this.alertService.openSnackBar(
        "Please select a species",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      return;
    }

    if (
      this.form.controls["guOrderItemId"].value == null ||
      this.form.controls["guOrderItemId"].value == ""
    ) {
      this.alertService.openSnackBar(
        "Please select an order item",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      //await this.alertService.openSnackBarCustomPromise("Order Item", "Please select an order item ", "OK", "", "center", "bottom", "", 0, false);
      return;
    }

    if (
      this.selectedOrderItem.fltQuantityTotal -
        this.selectedOrderItem.intFinalCount -
        1 <
        0 &&
      this.finalInspectionItem.guOrderItemId !=
        this.form.controls["guOrderItemId"].value[0].rowguid
    ) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Over Supply",
        "You are going to over supply " +
          this.selectedOrderItem.intLength +
          " / " +
          this.selectedOrderItem.intKN +
          (this.selectedOrderItem.blnQV == true ? " Q/V" : "") +
          "'s on this order\nIs this OK?",
        "Yes",
        "No",
        "center",
        "bottom",
        "",
        0
      );
      if (msg != "Yes") {
        return;
      }
    }

    const elementWithErrors = document.querySelectorAll(".ng-invalid");

    //console.log(elementWithErrors[1]);
    console.log("FORM: " + this.form.valid);

    if (this.form.valid == false) {
      return;
    }

    let pole1: Pole = null;
    let pole2: Pole = null;
    let lastItemNo: dtoKeyValue =
      await this.tpPoleInspectionItemService.getLastItemNoPromise(
        this.data.finalInspection.rowguid
      );

    if (
      this.selectedOriginalProduct.intMeasurementType ==
      ProductType.enMeasurementType.LengthKN
    ) {
      pole1 = await this.poleService.getPolePromise(
        this.txtOriginalLength.value,
        parseInt(this.txtOriginalKN.value),
        Boolean(this.chkOriginalQV.value),
        Boolean(this.chkOriginalSub.value),
        false,
        Boolean(this.chkOriginalRegal.value)
      );

      if (pole1 == null) {
        let msg: string =
          await this.alertService.openSnackBarCustomDefaultPromise(
            "Pole Error",
            "The original Pole you are trying to save does not exist.\nPerhaps you have sub pole ticked or regal ticked?",
            "Ok"
          );
        this.saving = false;
        this.showSpinner = false;
        return;
      }

      if (
        pole1 != null &&
        (Boolean(this.chkOriginalSub.value) == true ||
          Boolean(this.chkOriginalRegal.value) == true)
      ) {
        let poleStockSub: CHHPoleStock =
          await this.chhPoleStockService.getCHHPoleStockByPole(pole1.rowguid);
        if (poleStockSub == null) {
          let msg: string =
            await this.alertService.openSnackBarCustomDefaultShowCancel(
              "No Pole Stock Record",
              "There is no Original Pole Stock record for the pole you are trying to save.\nPerhaps you have sub pole ticked or regal ticked?\nDo you want to continue?",
              "Yes",
              "No"
            );
          if (msg != "Yes") {
            this.saving = false;
            this.showSpinner = false;
            return;
          }
        }
      }
    }

    if (
      this.selectedFinishProduct.intMeasurementType ==
      ProductType.enMeasurementType.LengthKN
    ) {
      pole2 = await this.poleService.getPolePromise(
        this.txtFinishedLength.value,
        parseInt(this.txtFinishedKN.value),
        Boolean(this.chkFinishedQV.value),
        Boolean(this.chkFinishedSub.value),
        false,
        Boolean(this.chkFinishedRegal.value)
      );

      if (pole2 == null) {
        let msg: string =
          await this.alertService.openSnackBarCustomDefaultPromise(
            "Pole Error",
            "The finished Pole you are trying to save does not exist.\nPerhaps you have sub pole ticked or regal ticked?",
            "Ok"
          );
        this.saving = false;
        this.showSpinner = false;
        return;
      }

      if (
        pole2 != null &&
        (Boolean(this.chkFinishedSub.value) == true ||
          Boolean(this.chkFinishedRegal.value) == true)
      ) {
        let poleStockSub: CHHPoleStock =
          await this.chhPoleStockService.getCHHPoleStockByPole(pole2.rowguid);
        if (poleStockSub == null) {
          let msg: string =
            await this.alertService.openSnackBarCustomDefaultShowCancel(
              "No Pole Stock Record",
              "There is no Finished Pole Stock record for the pole you are trying to save.\nPerhaps you have sub pole ticked or regal ticked?\nDo you want to continue?",
              "Yes",
              "No"
            );
          if (msg != "Yes") {
            return;
          }
        }
      }

      // Check if the final pole is the same as what the order item is.
      // If not show alert to the user.

      if (
        this.selectedOrderItem.guPoleId != null &&
        this.selectedOrderItem.guPoleId != "" &&
        pole2.rowguid != this.selectedOrderItem.guPoleId
      ) {
        let selectedOrderItemPole: string = "";
        let finalItemPole: string = "";
        selectedOrderItemPole =
          this.selectedOrderItem.intLength +
          " / " +
          this.selectedOrderItem.intKN;

        //let dtoSelectedOrderItem: dtoOrderItem = await this.dtoOrderItemService.getdtoOrderItemPromise(this.selectedOrderItem.rowguid);

        //if (dtoSelectedOrderItem != null) {
        //  selectedOrderItemPole = dtoSelectedOrderItem.intLength + " / " + dtoSelectedOrderItem.intKN;
        //}

        finalItemPole = pole2.intLength + " / " + pole2.intKn;

        let msg: string = await this.alertService.openSnackBarCustomPromise(
          "Order Item Pole",
          "The selected order item pole " +
            selectedOrderItemPole +
            " is different to the Final Item pole " +
            finalItemPole +
            " you have entered\nIs this correct?",
          "Yes",
          "No",
          "center",
          "bottom"
        );
        if (msg != "Yes") {
          return;
        }
      }
    }

    this.finalInspectionItem.dteDateCreated = new Date(
      this.form.controls["dteDateCreated"].value
    );
    this.finalInspectionItem.guCustomerId =
      this.form.controls["guCustomerId"].value[0];
    this.finalInspectionItem.guOrderId =
      this.form.controls["guOrderId"].value[0].rowguid;
    this.finalInspectionItem.guOrderItemId =
      this.form.controls["guOrderItemId"].value[0].rowguid;
    this.finalInspectionItem.txtPoleNumber =
      this.form.controls["txtPoleNumber"].value != null
        ? this.form.controls["txtPoleNumber"].value.toString()
        : "";
    this.finalInspectionItem.txtConsecutiveNumber =
      this.form.controls["txtConsecutiveNumber"].value != null
        ? this.form.controls["txtConsecutiveNumber"].value.toString()
        : "";
    this.finalInspectionItem.txtChargeId =
      this.form.controls["txtChargeNumber"].value != null
        ? this.form.controls["txtChargeNumber"].value.toString()
        : "";
    //finalInspectionItem.txtChargeDate = this.form.controls['txtTreatmentMonth'].value + "/" + this.form.controls['txtTreatmentYear'].value;
    this.finalInspectionItem.intTreatmentMonth =
      this.form.controls["txtTreatmentMonth"].value == ""
        ? null
        : this.form.controls["txtTreatmentMonth"].value;
    this.finalInspectionItem.intTreatmentYear =
      this.form.controls["txtTreatmentYear"].value == ""
        ? null
        : parseInt(this.form.controls["txtTreatmentYear"].value);
    this.finalInspectionItem.guOriginalProductTypeId =
      this.form.controls["guOriginalProductTypeId"].value[0];
    this.finalInspectionItem.guProductTypeId =
      this.form.controls["guFinishedProductTypeId"].value[0];
    this.finalInspectionItem.guSpeciesId =
      this.form.controls["guSpeciesId"].value[0];
    this.finalInspectionItem.blnDrilled =
      this.form.controls["chkDrilled"].value;
    this.finalInspectionItem.blnRejected =
      this.form.controls["chkRejected"].value == true;
    this.finalInspectionItem.txtRejectedComments =
      this.form.controls["txtRejectedComments"].value;

    if (pole1 != null) {
      this.finalInspectionItem.guOriginalPoleId = pole1.rowguid;
      this.finalInspectionItem.fltOriginalLength = null;
    }

    if (pole2 != null) {
      this.finalInspectionItem.guFinalPoleId = pole2.rowguid;
      this.finalInspectionItem.fltLength = null;
      let spec: SharedService.enPoleSpec = SharedService.enPoleSpec.NSW;

      if (pole2.blnQV == true) {
        spec = SharedService.enPoleSpec.VIC;
      }
      let poleCube = await this.poleCubeService.getPoleCubeBySpecPromise(
        pole2.rowguid,
        this.finalInspectionItem.guProductTypeId,
        spec
      );

      if (poleCube == null) {
        this.alertService.openSnackBarError(
          "There is no cubic volume for this pole",
          "Close",
          "center",
          "bottom",
          3000,
          true,
          "There is no cubic volume for this pole"
        );
        this.saving = false;
        this.showSpinner = false;
        return;
      }

      this.finalInspectionItem.fltM3 = poleCube.fltM3;
      this.finalInspectionItem.fltActualM3 = this.sharedService.calcRoundM3(
        this.finalInspectionItem.fltGirth,
        SharedService.enDiameterType.Centre,
        pole2.intLength,
        0,
        3
      );
      this.finalInspectionItem.fltActualTonne = Number(
        (
          this.finalInspectionItem.fltActualM3 *
          this.sharedService.M3ToWeightConversion
        ).toFixed(3)
      );

      //console.log("this.finalInspectionItem.fltM3: " + this.finalInspectionItem.fltM3);
      //console.log("this.finalInspectionItem.fltActualM3: " + this.finalInspectionItem.fltActualM3);
      //console.log("this.finalInspectionItem.fltActualTonne: " + this.finalInspectionItem.fltActualTonne);
    }

    if (pole1 == null) {
      this.finalInspectionItem.guOriginalPoleId = null;
      this.finalInspectionItem.fltOriginalLength =
        this.form.controls["txtOriginalLength"].value;
    }

    // No pole so we are Non lengthKN so just use length.
    if (pole2 == null) {
      this.finalInspectionItem.guFinalPoleId = null;
      this.finalInspectionItem.fltLength =
        this.form.controls["txtFinishedLength"].value;
      this.finalInspectionItem.fltM3 = this.sharedService.calcRoundM3(
        this.finalInspectionItem.fltGirth,
        SharedService.enDiameterType.Centre,
        this.finalInspectionItem.fltLength,
        0,
        3
      );
      this.finalInspectionItem.fltActualM3 = this.sharedService.calcRoundM3(
        this.finalInspectionItem.fltGirth,
        SharedService.enDiameterType.Centre,
        this.finalInspectionItem.fltLength,
        0,
        3
      );
      this.finalInspectionItem.fltActualTonne = Number(
        (
          this.finalInspectionItem.fltActualM3 *
          this.sharedService.M3ToWeightConversion
        ).toFixed(3)
      );
    }

    this.finalInspectionItem.fltDiameter =
      this.form.controls["fltFinishedDiameter"].value;
    this.finalInspectionItem.intDiameterType =
      this.form.controls["intFinishedDiameterType"].value;
    this.finalInspectionItem.fltGirth =
      this.form.controls["fltFinishedGirth"].value;
    this.finalInspectionItem.guEmployeeEnteredId =
      this.authService.getAppUserEmployeeId();

    //finalInspectionItem.intSpecification = -1;  //None, not using spec anymore, now we use Electrical Authority Spec below.

    if (
      this.form.controls["guElectricalAuthoritySpecId"].value != null &&
      this.form.controls["guElectricalAuthoritySpecId"].value !=
        this.sharedService.EmptyGuid
    ) {
      this.finalInspectionItem.guElectricalAuthoritySpecId =
        this.form.controls["guElectricalAuthoritySpecId"].value;
    }

    this.saving = false;
    this.showSpinner = false;

    if (mode == "Update") {
      console.log("this.finalInspectionItem:", this.finalInspectionItem);

      this.dialogRef.close({
        status: "Updated",
        finalInspectionItem: this.finalInspectionItem,
        selectedTPChargeItem: this.selectedTPChargeItem,
      });
    }
  }

  cancel() {
    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });
  }

  reset() {
    let dteDateCreated = this.form.controls["dteDateCreated"].value;
    let guCustomerId = this.form.controls["guCustomerId"].value;
    let guOrderId = this.form.controls["guOrderId"].value;
    let guOrderItemId = this.form.controls["guOrderItemId"].value;
    let guFinishedProductTypeId =
      this.form.controls["guFinishedProductTypeId"].value[0];
    let guOriginalProductTypeId =
      this.form.controls["guOriginalProductTypeId"].value[0];
    let guSpeciesId = this.form.controls["guSpeciesId"].value;
    let intFinishedDiameterType =
      this.form.controls["intFinishedDiameterType"].value;
    let guElectricalAuthoritySpecId =
      this.form.controls["guElectricalAuthoritySpecId"].value;
    let txtOriginalLength = this.form.controls["txtOriginalLength"].value;
    let txtOriginalKN = this.form.controls["txtOriginalKN"].value;
    let txtFinishedLength = this.form.controls["txtFinishedLength"].value;
    let txtFinishedKN = this.form.controls["txtFinishedKN"].value;
    let chkOriginalQV = this.form.controls["chkOriginalQV"].value;
    let chkOriginalSub = this.form.controls["chkOriginalSub"].value;
    let chkOriginalRegal = this.form.controls["chkOriginalRegal"].value;
    let chkFinishedQV = this.form.controls["chkFinishedQV"].value;
    let chkFinishedSub = this.form.controls["chkFinishedSub"].value;
    let chkFinishedRegal = this.form.controls["chkFinishedRegal"].value;

    this.formGroupDirective.resetForm(this.form.value);
    this.form.reset();

    this.form.controls["dteDateCreated"].setValue(dteDateCreated);
    this.form.controls["guCustomerId"].setValue([guCustomerId]);
    this.form.controls["guOrderId"].setValue([guOrderId]);
    this.form.controls["guOrderItemId"].setValue([guOrderItemId]);
    this.form.controls["guFinishedProductTypeId"].setValue([
      guFinishedProductTypeId,
    ]);
    this.form.controls["guOriginalProductTypeId"].setValue([
      guOriginalProductTypeId,
    ]);
    this.form.controls["guSpeciesId"].setValue([guSpeciesId]);
    this.form.controls["intFinishedDiameterType"].setValue(
      intFinishedDiameterType
    );
    this.form.controls["guElectricalAuthoritySpecId"].setValue(
      guElectricalAuthoritySpecId
    );
    this.form.controls["txtOriginalLength"].setValue(txtOriginalLength);
    this.form.controls["txtOriginalKN"].setValue(txtOriginalKN);
    this.form.controls["txtFinishedLength"].setValue(txtFinishedLength);
    this.form.controls["txtFinishedKN"].setValue(txtFinishedKN);
    this.form.controls["chkOriginalQV"].setValue(chkOriginalQV);
    this.form.controls["chkOriginalSub"].setValue(chkOriginalSub);
    this.form.controls["chkOriginalRegal"].setValue(chkOriginalRegal);
    this.form.controls["chkFinishedQV"].setValue(chkFinishedQV);
    this.form.controls["chkFinishedSub"].setValue(chkFinishedSub);
    this.form.controls["chkFinishedRegal"].setValue(chkFinishedRegal);
  }

  public validateDiameter(diameterControl: AbstractControl, qv: boolean) {
    return this.poleService.validateDiameter(diameterControl, qv);
  }

  public validateKN(knControl: AbstractControl) {
    return this.poleService.validateKN(knControl);
  }

  public validateLength(lengthControl: AbstractControl) {
    return this.poleService.validateLength(lengthControl);
  }

  public validateTreatmentMonth(treatmentMonthControl: AbstractControl) {
    return this.tpChargeItemService.validateTreatmentMonth(
      treatmentMonthControl
    );
  }

  public validateTreatmentYear(treatmentYearControl: AbstractControl) {
    return this.tpChargeItemService.validateTreatmentYear(treatmentYearControl);
  }

  async onOriginalProductSelect(productTypeId: string) {
    let pt: ProductType = await this.productTypeService.getProductTypePromise(
      productTypeId
    );

    this.eOriginalProductMeasurementType = pt.intMeasurementType;
    this.selectedOriginalProduct = pt;

    if (pt.intMeasurementType == ProductType.enMeasurementType.LengthKN) {
      this.txtOriginalKN.enable();
      this.chkOriginalQV.enable();

      this.txtOriginalLength.setValidators(
        this.poleService.original_nsw_LengthValidator
      );
      this.txtOriginalLength.updateValueAndValidity();
      this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
      this.originalLengthHint = "8m to 26m";
    } else {
      this.txtOriginalKN.setValue("");
      this.chkOriginalQV.setValue(false);

      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);

      this.txtOriginalLength.removeValidators(
        this.poleService.original_nsw_LengthValidator
      );
      this.txtOriginalLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtOriginalLength.updateValueAndValidity();

      this.originalLengthHint = "1m to 26m";

      this.txtOriginalKN.disable();
      this.chkOriginalQV.disable();
    }
  }

  async onFinishedProductSelect(productTypeId: string) {
    let pt: ProductType = await this.productTypeService.getProductTypePromise(
      productTypeId
    );

    this.eFinishedProductMeasurementType = pt.intMeasurementType;
    this.selectedFinishProduct = pt;

    //if (this.form.controls['guFinishedProductTypeId'].enabled == false) {
    //  return;
    //}

    if (pt.intMeasurementType == ProductType.enMeasurementType.LengthKN) {
      this.txtFinishedKN.enable();
      this.chkFinishedQV.enable();
      this.chkFinishedSub.enable();

      this.txtFinishedLength.setValidators(
        this.poleService.finished_nsw_LengthValidator
      );
      this.txtFinishedLength.updateValueAndValidity();

      this.fltFinishedDiameter.setValidators([
        Validators.required,
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1000),
      ]);
      this.fltFinishedDiameter.updateValueAndValidity();
      this.txtFinishedKN.setValidators([
        Validators.required,
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedKN.updateValueAndValidity();
      this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
      this.finishedLengthHint = "8m to 26m";
      this.intFinishedDiameterType.setValue(SharedService.enDiameterType.GL);
      this.finishedDiaTypePlaceHolder = "GL";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.intFinishedDiameterType.disable();
    } else {
      this.txtFinishedKN.setValue("");
      this.chkFinishedQV.setValue(false);
      this.chkFinishedSub.setValue(false);

      this.fltFinishedDiameter.setValidators([
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1000),
      ]);
      this.fltFinishedDiameter.updateValueAndValidity();
      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedKN.updateValueAndValidity();

      this.txtFinishedLength.removeValidators(
        this.poleService.finished_nsw_LengthValidator
      );
      this.txtFinishedLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtFinishedLength.updateValueAndValidity();

      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Diameter mm";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedLengthHint = "1m to 26m";

      this.txtFinishedKN.disable();
      this.chkFinishedQV.disable();
      this.chkFinishedSub.disable();
      //this.intFinishedDiameterType.enable();  // was enabled but think it should be changed at the Order Item Level in MTECH. 8/5/2023
      this.intFinishedDiameterType.disable();
    }
  }

  public onOriginalQVClick() {
    if (this.chkOriginalQV.value == true) {
      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.qv_kNRange),
      ]);
      if (
        this.eOriginalProductMeasurementType ==
        ProductType.enMeasurementType.LengthKN
      ) {
        this.txtOriginalLength.setValidators(
          this.poleService.finished_qv_LengthValidator
        );
      } else {
        this.txtOriginalLength.removeValidators(
          this.poleService.finished_qv_LengthValidator
        );
      }

      this.originalKNHint = "5, 8, 12, 20";

      this.originalLengthHint = "9.5m to 26m";
    } else {
      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      if (
        this.eOriginalProductMeasurementType ==
        ProductType.enMeasurementType.LengthKN
      ) {
        this.txtOriginalLength.setValidators(
          this.poleService.finished_nsw_LengthValidator
        );
      } else {
        this.txtOriginalLength.removeValidators(
          this.poleService.finished_nsw_LengthValidator
        );
      }
      this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";

      this.originalLengthHint = "8m to 26m";
    }
    this.txtOriginalKN.updateValueAndValidity();
  }

  public onFinishedQVClick() {
    // Remove both length validators.
    this.txtFinishedLength.removeValidators(
      this.poleService.finished_qv_LengthValidator
    );
    this.txtFinishedLength.removeValidators(
      this.poleService.finished_nsw_LengthValidator
    );

    let groundLineMeasurementType: ElectricalAuthoritySpec.enGroundLineMeasurementType =
      ElectricalAuthoritySpec.enGroundLineMeasurementType.Diameter;
    //console.log("this.elecAuthSpecs", this.elecAuthSpecs);

    for (let i = 0; i <= this.elecAuthSpecs.length - 1; i++) {
      //  console.log("this.elecAuthSpecs[i].intGroundLineMeasurementType == ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference", this.elecAuthSpecs[i].intGroundLineMeasurementType);
      //console.log("this.guElectricalAuthoritySpecId.value", this.form.controls['guElectricalAuthoritySpecId'].value);
      if (
        this.form.controls["guElectricalAuthoritySpecId"].value ==
        this.elecAuthSpecs[i].rowguid
      ) {
        if (
          this.elecAuthSpecs[i].intGroundLineMeasurementType ==
          ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference
        ) {
          groundLineMeasurementType =
            ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference;
          //console.log("groundLineMeasurementType", groundLineMeasurementType);
          break;
        }
      }
    }

    if (this.chkFinishedQV.value == true) {
      setTimeout(() => {
        if (
          groundLineMeasurementType ==
          ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference
        ) {
          this.fltFinishedDiameter.setValidators([
            Validators.required,
            Validators.maxLength(5),
            Validators.min(470),
            Validators.max(3100),
          ]);
          this.finishedDiamHint = "min: 470mm,  max: 3100mm";
          this.finishedDiaPlaceHolder =
            this.finishedDiaTypePlaceHolder + " Circumference mm";
        }

        if (
          groundLineMeasurementType ==
          ElectricalAuthoritySpec.enGroundLineMeasurementType.Diameter
        ) {
          this.fltFinishedDiameter.setValidators([
            Validators.required,
            Validators.maxLength(4),
            Validators.min(150),
            Validators.max(1000),
          ]);
          this.finishedDiamHint = "min: 150mm,  max: 1000mm";
          this.finishedDiaPlaceHolder =
            this.finishedDiaTypePlaceHolder + " Diameter mm";
        }
      }, 500);

      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.qv_kNRange),
      ]);
      this.txtFinishedLength.setValidators(
        this.poleService.finished_qv_LengthValidator
      );
      console.log("setting QV validators:");
      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Circumference mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedKNHint = "5, 8, 12, 20";

      this.finishedLengthHint = "9.5m to 26m";
    } else {
      this.fltFinishedDiameter.setValidators([
        Validators.required,
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1000),
      ]);
      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedLength.setValidators(
        this.poleService.finished_nsw_LengthValidator
      );

      console.log("setting NSW validators:");

      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Diameter mm";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";

      this.finishedLengthHint = "8m to 26m";
    }
    this.fltFinishedDiameter.updateValueAndValidity();
    this.txtFinishedKN.updateValueAndValidity();
  }

  setValidators(
    originalPT: ProductType,
    finalPT: ProductType,
    originalPole: Pole,
    finalPole: Pole
  ) {
    if (
      originalPole != null &&
      originalPT.intMeasurementType == ProductType.enMeasurementType.LengthKN
    ) {
      if (originalPole.blnQV == true) {
        this.txtOriginalKN.setValidators([
          Validators.pattern(this.poleService.qv_kNRange),
        ]);
        this.txtOriginalLength.setValidators(
          this.poleService.finished_qv_LengthValidator
        );
        this.originalKNHint = "5, 8, 12, 20";
        this.originalLengthHint = "9.5m to 26m";
      } else {
        this.txtOriginalLength.setValidators(
          this.poleService.original_nsw_LengthValidator
        );
        this.txtOriginalLength.updateValueAndValidity();
        this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
        this.originalLengthHint = "8m to 26m";

        this.txtOriginalKN.setValidators([
          Validators.pattern(this.poleService.nsw_kNRange),
        ]);
      }
      this.txtOriginalKN.updateValueAndValidity();
    }

    if (
      finalPole != null &&
      finalPT.intMeasurementType == ProductType.enMeasurementType.LengthKN
    ) {
      let groundLineMeasurementType: ElectricalAuthoritySpec.enGroundLineMeasurementType =
        ElectricalAuthoritySpec.enGroundLineMeasurementType.Diameter;
      //console.log("this.elecAuthSpecs", this.elecAuthSpecs);

      for (let i = 0; i <= this.elecAuthSpecs.length - 1; i++) {
        //console.log("this.guElectricalAuthoritySpecId.value", this.form.controls['guElectricalAuthoritySpecId'].value);
        if (
          this.form.controls["guElectricalAuthoritySpecId"].value ==
          this.elecAuthSpecs[i].rowguid
        ) {
          //console.log("this.form.controls['guElectricalAuthoritySpecId'].value == this.elecAuthSpecs[i].rowguid", this.form.controls['guElectricalAuthoritySpecId'].value + " : " + this.elecAuthSpecs[i].rowguid);
          //console.log("this.elecAuthSpecs[i].intGroundLineMeasurementType == ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference", this.elecAuthSpecs[i].intGroundLineMeasurementType);
          if (
            this.elecAuthSpecs[i].intGroundLineMeasurementType ==
            ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference
          ) {
            groundLineMeasurementType =
              ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference;
            //console.log("groundLineMeasurementType", groundLineMeasurementType);
            break;
          }
        }
      }

      //console.log("finalPole.blnQV", finalPole.blnQV);
      if (finalPole.blnQV == true) {
        setTimeout(() => {
          if (
            groundLineMeasurementType ==
            ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference
          ) {
            this.fltFinishedDiameter.setValidators([
              Validators.required,
              Validators.maxLength(5),
              Validators.min(470),
              Validators.max(3100),
            ]);
            this.fltFinishedDiameter.updateValueAndValidity();
            this.finishedDiamHint = "min: 470mm,  max: 3100mm";
            this.finishedDiaPlaceHolder =
              this.finishedDiaTypePlaceHolder + " Circumference mm";
          }

          if (
            groundLineMeasurementType ==
            ElectricalAuthoritySpec.enGroundLineMeasurementType.Diameter
          ) {
            this.fltFinishedDiameter.setValidators([
              Validators.required,
              Validators.maxLength(4),
              Validators.min(150),
              Validators.max(1000),
            ]);
            this.fltFinishedDiameter.updateValueAndValidity();
            this.finishedDiamHint = "min: 150mm,  max: 1000mm";
            this.finishedDiaPlaceHolder =
              this.finishedDiaTypePlaceHolder + " Diameter mm";
          }
        }, 500);

        this.txtFinishedKN.setValidators([
          Validators.pattern(this.poleService.qv_kNRange),
        ]);
        this.txtFinishedKN.updateValueAndValidity();
        this.txtFinishedLength.setValidators(
          this.poleService.finished_qv_LengthValidator
        );

        this.finishedGirthHint = "min: 150mm,  max: 1000mm";
        //this.finishedDiaPlaceHolder = "GL Circumference mm";
        this.finishedGirthPlaceHolder = "Girth Diameter mm";
        this.finishedKNHint = "5, 8, 12, 20";

        this.finishedLengthHint = "9.5m to 26m";

        console.log("setValidators - finalPole.blnQV");
      } else {
        this.txtFinishedLength.setValidators(
          this.poleService.finished_nsw_LengthValidator
        );
        this.txtFinishedLength.updateValueAndValidity();

        this.fltFinishedDiameter.setValidators([
          Validators.required,
          Validators.maxLength(4),
          Validators.min(150),
          Validators.max(1000),
        ]);
        this.fltFinishedDiameter.updateValueAndValidity();
        this.txtFinishedKN.setValidators([
          Validators.required,
          Validators.pattern(this.poleService.nsw_kNRange),
        ]);
        this.txtFinishedKN.updateValueAndValidity();
        this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
        this.finishedLengthHint = "8m to 26m";

        this.finishedDiamHint = "min: 150mm,  max: 1000mm";
        this.finishedGirthHint = "min: 150mm,  max: 1000mm";

        this.finishedDiaPlaceHolder =
          this.finishedDiaTypePlaceHolder + " Diameter mm";
        this.finishedGirthPlaceHolder = "Girth Diameter mm";
      }
    }

    if (
      originalPole == null &&
      originalPT.intMeasurementType != ProductType.enMeasurementType.LengthKN
    ) {
      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);

      this.txtOriginalLength.removeValidators(
        this.poleService.original_nsw_LengthValidator
      );
      this.txtOriginalLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtOriginalLength.updateValueAndValidity();

      this.originalLengthHint = "1m to 26m";
    }

    if (
      finalPole == null &&
      finalPT.intMeasurementType != ProductType.enMeasurementType.LengthKN
    ) {
      this.fltFinishedDiameter.setValidators([
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1000),
      ]);
      this.fltFinishedDiameter.updateValueAndValidity();
      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedKN.updateValueAndValidity();

      this.txtFinishedLength.removeValidators(
        this.poleService.finished_nsw_LengthValidator
      );
      this.txtFinishedLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtFinishedLength.updateValueAndValidity();

      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Diameter mm";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedLengthHint = "1m to 26m";
    }
  }

  getSpecies() {
    this.speciesService.getAllSpecies().subscribe(
      (data) => {
        this.species = data;
        this.speciesOriginal = data;

        this.filteredSpecies.next(this.species);

        this.speciesFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterSpecies();
          });
      },
      (errResponse: HttpErrorResponse) => {
        this.errorMessage = new ErrorMessage();
        this.errorMessage.message = errResponse.error.message;
        this.showErrorMessage = true;
      }
    );
  }

  getProducts() {
    this.productTypeService.getAllRoundProductTypes().subscribe(
      (data) => {
        this.productTypes = data;
        this.filteredProductTypes.next(this.productTypes);

        this.productTypeFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterProductTypes();
          });
      },
      (errResponse: HttpErrorResponse) => {
        this.alertService.openSnackBarError(
          "Error getting products",
          "Close",
          "center",
          "bottom",
          3000,
          true,
          errResponse.error.message
        );
      }
    );
  }

  async getCustomers() {
    let customers = await this.customerService.getTreatmentPlantCustomers(
      true,
      false
    );
    if (customers == null) {
      return;
    }

    this.customers = customers;
    this.customersAll = customers;

    //  this.filteredCustomers.next(this.customers);

    //  this.customersFilterCtrl.valueChanges
    //    .pipe(takeUntil(this._onDestroy))
    //    .subscribe(() => {
    //      this.filterCustomers();
    //    });
  }

  getProductTypes() {
    this.productTypeService.getAllPoleFinalProductTypes().subscribe((data) => {
      this.productTypes = data;
      let polePT: ProductType;
      polePT = this.productTypes.find((pt) => pt.txtName == "Pole");
      this.form.controls["guOriginalProductTypeId"].setValue([polePT.rowguid]);
      this.form.controls["guFinishedProductTypeId"].setValue([polePT.rowguid]);
    });
  }

  getElectricalAuthoritySpecs() {
    this.electricalAuthoritySpec
      .getAllElectricalAuthoritySpecs()
      .subscribe((data) => {
        this.elecAuthSpecs = data;
        let elecAuthSpec: ElectricalAuthoritySpec =
          new ElectricalAuthoritySpec();
        elecAuthSpec.rowguid = this.sharedService.EmptyGuid;
        elecAuthSpec.txtAuthorityName = "None";
        this.elecAuthSpecs.splice(0, 0, elecAuthSpec);
      });
  }

  private filterSpecies() {
    if (!this.species) {
      return;
    }

    // get the search keyword
    let search = this.speciesFilterCtrl.value;
    if (!search) {
      this.filteredSpecies.next(this.species);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the Species
    this.filteredSpecies.next(
      this.species.filter(
        (sp) =>
          sp.txtCode.toLowerCase().indexOf(search) > -1 ||
          sp.txtCodeAlias.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterProductTypes() {
    if (!this.productTypes) {
      return;
    }

    // get the search keyword
    let search = this.productTypeFilterCtrl.value;
    if (!search) {
      this.filteredProductTypes.next(this.productTypes);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredProductTypes.next(
      this.productTypes.filter(
        (pt) => pt.txtName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  searchCustomers(name: string) {
    if (this.customers == null) {
      return;
    }

    this.customers = this.customersAll;

    this.customers = this.customers.filter(
      (a) => a.txtName.toLowerCase().indexOf(name.toLowerCase()) > -1
    );
  }

  searchOrders(orderNo: string) {
    if (this.orders == null) {
      return;
    }

    this.orders = this.ordersAll;

    this.orders = this.orders.filter(
      (a) => a.txtOrderNo.toLowerCase().indexOf(orderNo.toLowerCase()) > -1
    );
  }

  searchOrderItems(item: string) {
    if (this.orderItems == null) {
      return;
    }

    this.orderItems = this.orderItemsAll;

    this.orderItems = this.orderItems.filter(
      (a) => a.txtDescription.toLowerCase().indexOf(item.toLowerCase()) > -1
    );
  }

  private filterCustomers() {
    if (!this.customers) {
      return;
    }

    // get the search keyword
    let search = this.customersFilterCtrl.value;
    if (!search) {
      this.filteredCustomers.next(this.customers);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredCustomers.next(
      this.customers.filter((c) => c.txtName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterOrders() {
    if (!this.orders) {
      return;
    }

    // get the search keyword
    let search = this.ordersFilterCtrl.value;
    if (!search) {
      this.filteredOrders.next(this.orders);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredOrders.next(
      this.orders.filter((o) => o.txtOrderNo.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterOrderItems() {
    if (!this.orderItems) {
      return;
    }

    // get the search keyword
    let search = this.orderItemFilterCtrl.value;
    if (!search) {
      this.filteredOrderItems.next(this.orderItems);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredOrders.next(
      this.orders.filter((o) => o.txtOrderNo.toLowerCase().indexOf(search) > -1)
    );
  }

  async customerChanged(matSelectChange: MatSelectChange) {
    this.orders = [];
    this.orderItems = [];
    this.filteredOrderItems.next(null);

    if (matSelectChange.value == null || matSelectChange.value == "") {
      return;
    }

    this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(
      matSelectChange.value
    );

    this.filteredOrders.next(this.orders);

    this.ordersFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrders();
      });
  }

  async orderChanged(order: Orders) {
    if (order == null) {
      return;
    }

    this.orderItems =
      await this.orderItemService.getdtoOrderItemsByOrderPromise(order.rowguid);
    this.orderItemsAll = this.orderItems;

    //If changing order number then reset the order item.
    this.form.controls["guOrderItemId"].setValue([""]);

    //this.filteredOrderItems.next(this.orderItems);

    //this.orderItemFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrderItems();
    //  });
  }

  async orderItemChanged(orderItem: dtoOrderItem) {
    //console.log("in orderItemChange");

    this.selectedOrderItem = orderItem;
    let finalProduct = this.form.controls["guFinishedProductTypeId"];

    if (orderItem == null) {
      finalProduct.enable();
      return;
    }

    this.txtFinishedLength.enable();
    this.txtFinishedKN.enable();
    this.chkDrilled.enable();

    this.txtOriginalLength.setValue("");
    this.txtOriginalKN.setValue("");
    this.txtFinishedLength.setValue("");
    this.txtFinishedKN.setValue("");
    this.chkOriginalQV.setValue(0);
    this.chkFinishedQV.setValue(0);
    this.chkOriginalSub.setValue(0);
    this.chkFinishedSub.setValue(0);
    this.chkOriginalRegal.setValue(0);
    this.chkFinishedRegal.setValue(0);
    this.guElectricalAuthoritySpecId.setValue(this.sharedService.EmptyGuid);

    let originalProduct = this.form.controls["guOriginalProductTypeId"];
    if (originalProduct != null && this.productTypes != null) {
      let productIds: string[] = this.productTypes.map((pt) => pt.rowguid);

      if (productIds.includes(orderItem.guProductTypeId)) {
        originalProduct.setValue([orderItem.guProductTypeId]);

        finalProduct.setValue([orderItem.guProductTypeId]);
        finalProduct.disable();

        for (let i = 0; i <= this.productTypes.length - 1; i++) {
          if (this.productTypes[i].rowguid == orderItem.guProductTypeId) {
            // Non Pole Product
            if (orderItem.guPoleId == null || orderItem.guPoleId == "") {
              this.txtOriginalLength.setValue(orderItem.fltLength);
              this.txtFinishedLength.setValue(orderItem.fltLength);

              //this.intFinishedDiameterType.removeValidators(Validators.required);
              //this.intFinishedDiameterType.updateValueAndValidity();

              if (orderItem.intDiameterType != null) {
                this.intFinishedDiameterType.setValue(
                  orderItem.intDiameterType
                );
                this.finishedDiameterTypeChange(orderItem.intDiameterType);
              }

              this.chkFinishedQV.disable();
              this.chkFinishedSub.disable();
            }

            // Pole Product
            if (orderItem.guPoleId != null && orderItem.guPoleId != "") {
              this.txtOriginalLength.setValue(orderItem.intLength);
              this.txtOriginalKN.setValue(orderItem.intKN);
              this.txtFinishedLength.setValue(orderItem.intLength);
              this.txtFinishedKN.setValue(orderItem.intKN);
              this.chkOriginalSub.setValue(orderItem.blnSubPole);
              this.chkFinishedSub.setValue(orderItem.blnSubPole);
              this.chkOriginalRegal.setValue(orderItem.blnRegal);
              this.chkFinishedRegal.setValue(orderItem.blnRegal);
            }

            this.txtFinishedLength.disable();
            setTimeout(() => {
              this.txtFinishedKN.disable();
            }, 500); // won't disable straight away so have to delay, some angular bug or something..

            this.onOriginalProductSelect(this.productTypes[i].rowguid);
            this.onFinishedProductSelect(this.productTypes[i].rowguid);

            if (orderItem.blnQV == true) {
              this.chkOriginalQV.setValue(1);
              this.onOriginalQVClick();
              this.chkFinishedQV.setValue(1);
              this.onFinishedQVClick();
              this.chkFinishedQV.disable();

              this.fltFinishedDiameter.setValidators([
                Validators.required,
                Validators.maxLength(5),
                Validators.min(470),
                Validators.max(3100),
              ]);
              this.fltFinishedDiameter.updateValueAndValidity();

              console.log("fltFinishedDiameter.updateValueAndValidity()");
            }

            // Just in case we change from VIC order item to NSW order item.
            // Probably won't happen but better reset validation on diameters and kn's
            this.onOriginalQVClick();
            this.onFinishedQVClick();

            this.chkDrilled.setValue(orderItem.blnDrilled);
            this.chkDrilled.disable();

            if (
              orderItem.guElectricalAuthoritySpecId != null &&
              orderItem.guElectricalAuthoritySpecId != ""
            ) {
              this.guElectricalAuthoritySpecId.setValue(
                orderItem.guElectricalAuthoritySpecId
              );
            }

            if (
              orderItem.intTreatmentType == SharedService.enTreatmentType.CCA
            ) {
              this.txtTreatmentMonth.setValidators(
                this.treatment_month_Validator
              );
              this.txtTreatmentMonth.updateValueAndValidity();
              this.txtTreatmentYear.setValidators(
                this.treatment_year_Validator
              );
              this.txtTreatmentYear.updateValueAndValidity();
            }

            if (
              orderItem.intTreatmentType != SharedService.enTreatmentType.CCA
            ) {
              //this.txtTreatmentMonth.removeValidators(this.treatment_month_Validator);
              //this.txtTreatmentYear.removeValidators(this.treatment_year_Validator);
              this.txtTreatmentMonth.clearValidators();
              this.txtTreatmentYear.clearValidators();
              this.txtTreatmentMonth.updateValueAndValidity();
              this.txtTreatmentYear.updateValueAndValidity();
            }

            break;
          }
        }
      }
    }

    let originalPole = null;
    let finishedPole = null;

    if (this.finalInspectionItem.guOriginalPoleId != null) {
      originalPole = await this.poleService.getPoleByIdPromise(
        this.finalInspectionItem.guOriginalPoleId
      );
    }

    if (this.finalInspectionItem.guFinalPoleId != null) {
      finishedPole = await this.poleService.getPoleByIdPromise(
        this.finalInspectionItem.guFinalPoleId
      );
    }

    this.setValidators(
      this.selectedOriginalProduct,
      this.selectedFinishProduct,
      originalPole,
      finishedPole
    );
    //this.orderItems = await this.orderItemService.getOrderItemsByOrderPromise(order.rowguid);
  }

  finishedDiameterTypeChange(diameterType: number) {
    if (diameterType == SharedService.enDiameterType.SED) {
      this.finishedDiaTypePlaceHolder = "SED";
    }

    if (diameterType == SharedService.enDiameterType.BED) {
      this.finishedDiaTypePlaceHolder = "BED";
    }

    if (diameterType == SharedService.enDiameterType.Centre) {
      this.finishedDiaTypePlaceHolder = "Centre";
    }

    if (diameterType == SharedService.enDiameterType.GL) {
      this.finishedDiaTypePlaceHolder = "GL";
    }

    if (this.chkFinishedQV.value == true) {
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Circumference mm";
    }

    if (this.chkFinishedQV.value == false) {
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
    }
  }

  testReset() {
    //this.form.markAsPristine();
    //this.form.markAsUntouched();
    this.txtTreatmentMonth.clearValidators();
    this.txtTreatmentMonth.updateValueAndValidity();

    //this.form.updateValueAndValidity();
  }

  checkValidation(
    orderElement: HTMLDivElement,
    poleNumberElement: HTMLElement
  ) {
    const elementWithErrors = document.querySelectorAll(".ng-invalid");
    if (
      elementWithErrors != null &&
      elementWithErrors.length > 1 &&
      elementWithErrors[1] != null
    ) {
      elementWithErrors[1].scrollIntoView({ behavior: "smooth" });
      //return;
    }

    //console.log(elementWithErrors);

    if (this.form.invalid == false) {
      this.scrollIntoView(orderElement);
      this.setFocus(poleNumberElement);
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.updateValueAndValidity();
      //this.form.reset();
    }

    // Might be useful for somthing.
    if (this.form.invalid == true) {
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
    }
  }

  scrollIntoView(el: HTMLElement) {
    this.formService.scrollIntoView(el);
  }

  setFocus(el: HTMLElement) {
    this.formService.setAutoFocusByElement(el);
  }

  testTreatmentMonth(el: HTMLInputElement) {
    if (el.value == null || el.value == "") {
      return;
    }

    if (el.value.length == 2) {
      this.formService.setAutoFocusTimeout("txtTreatmentYear");
    }
  }

  formatTreatmentYear(year: number) {
    if (year == null) {
      return "";
    }

    if (year >= 10) {
      return year.toString();
    }

    if (year < 10) {
      return "0" + year.toString();
    }
  }

  searchSpecies(code: string) {
    this.species = this.speciesOriginal;

    this.species = this.species.filter(
      (c) =>
        c.txtCode.toLowerCase().startsWith(code.toLowerCase()) ||
        (c.txtCodeAlias != null
          ? c.txtCodeAlias.toLowerCase().startsWith(code.toLowerCase())
          : false)
    );
  }

  async selectConsecutiveNumber() {
    if (
      this.form.controls["txtChargeNumber"].value == null ||
      this.form.controls["txtChargeNumber"].value == ""
    ) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Charge Number",
        "Please enter a charge number",
        "Ok"
      );
      return;
    }

    if (
      this.form.controls["txtTreatmentYear"].value == null ||
      this.form.controls["txtTreatmentYear"].value == ""
    ) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Treatment Year",
        "Please enter a treatment year",
        "Ok"
      );
      return;
    }

    if (this.consecutiveNumberListDialogRef != null) {
      this.consecutiveNumberListDialogRef.close();
    }

    let chargeNo: number = this.form.controls["txtChargeNumber"].value;
    let chargeYear: number = this.form.controls["txtTreatmentYear"].value;

    this.consecutiveNumberListDialogRef = this.dialog.open(
      ConsecutiveNumberListComponent,
      {
        height: "auto",
        maxWidth: "95vw",
        data: { chargeNo: chargeNo, chargeYear: chargeYear },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.consecutiveNumberListDialogRef.backdropClick().subscribe(() => {
      this.consecutiveNumberListDialogRef.close();
    });

    this.consecutiveNumberListDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; chargeItem: dtoTPChargeItem }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the FinalInspectionCreate dialog, null data."
            );
          }

          if (data.status != "Cancel") {
            //If we have used a consective number we will link this final item to the charge item apon saving.
            console.log(data);
            if (data.chargeItem != null) {
              console.log(
                "this.selectedTPChargeItem: ",
                this.selectedTPChargeItem
              );

              if (this.selectedTPChargeItem != null) {
                //We already have a linked charge item so unlink the other one first.
                let chargeItem: TPChargeItem =
                  await this.tpChargeItemService.getTPChargeItemPromise(
                    this.selectedTPChargeItem.rowguid
                  );
                chargeItem.guTPPoleInspectionItemId = null;
                await this.tpChargeItemService.updateTPChargeItemPromise(
                  chargeItem
                );
                console.log("unlinking chargeItem: ", chargeItem);
              }

              this.form.controls["txtConsecutiveNumber"].setValue(
                data.chargeItem.intConsecutiveNo.toString()
              );
              this.selectedTPChargeItem = data.chargeItem;
            }

            //this.transferredFinalInspectionItemEvent.emit(true);
          } else {
            // We have cancellled
            if (this.selectedTPChargeItem != null) {
              let msg: string =
                await this.alertService.openSnackBarCustomDefaultShowCancel(
                  "Clear Consecutive Number",
                  "You already have a consecutive number (" +
                    this.selectedTPChargeItem.intConsecutiveNo.toString() +
                    ") set.\nDo you want to clear it?",
                  "Yes",
                  "No"
                );
              if (msg == "Yes") {
                this.selectedTPChargeItem = null;
                this.form.controls["txtConsecutiveNumber"].setValue("");
              }
            }
          }
        }
      );
  }
}
