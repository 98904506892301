import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ContractCutSawLog } from "../../_shared/business-objects/contract-cut-saw-log.bo";
import { dtoTPPoleInspectionItem } from "../../_shared/business-objects/dto-tppole-inspection-item.bo";
import { dtoTPPoleInspection } from "../../_shared/business-objects/dto-tppole-inspection.bo";
import { Printer } from "../../_shared/business-objects/printer.bo";
import { TPPoleInspectionItem } from "../../_shared/business-objects/tppole-inspection-item.bo";
import { AlertService } from "../../_shared/services/alert.service";
import { PrintService } from "../../_shared/services/app-services/print.service";
import { PrinterService } from "../../_shared/services/printer.service";
import { SharedService } from "../../_shared/services/shared.service";
import { FinalInspectionItemTransferDialogComponent } from "../final-inspection-item-transfer-dialog/final-inspection-item-transfer-dialog.component";
import { dtoTPPoleInspectionService } from "../../_shared/services/dto-tppole-inspection.service";
import { MatCheckbox } from "@angular/material/checkbox";
import html2canvas from "html2canvas";
import { PrintDocument } from "../../_shared/app-objects/PrintDocument";
import { DataURLFileAttachment } from "../../_shared/app-objects/dataurl-file-attachment";
import { FileService } from "../../_shared/services/app-services/file.service";
import { PoleTag } from "../../_shared/app-objects/PoleTag";

@Component({
  selector: "app-final-inspection-item-list",
  templateUrl: "./final-inspection-item-list.component.html",
  styleUrls: ["./final-inspection-item-list.component.css"],
})
export class FinalInspectionItemListComponent implements OnInit, AfterViewInit {
  @Input() mode: string = "";
  @Input() datasource: MatTableDataSource<dtoTPPoleInspectionItem>;
  @Input() finalInspection: dtoTPPoleInspection;
  @Input() columnListId: string;

  @Output() editFinalInspectionItemEvent =
    new EventEmitter<dtoTPPoleInspectionItem>();
  @Output() addFinalInspectionItemEvent =
    new EventEmitter<dtoTPPoleInspectionItem>();
  @Output() removeFinalInspectionItemEvent =
    new EventEmitter<dtoTPPoleInspectionItem>();
  @Output() transferredFinalInspectionItemEvent = new EventEmitter<true>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("tblfinalInspectionItems")
  tblfinalInspectionItems: MatTable<dtoTPPoleInspectionItem>;

  transferFinalInspectionItemDialogRef: MatDialogRef<FinalInspectionItemTransferDialogComponent>;
  treatmentPlantPrinter: Printer;

  displayedfinalInspectionItems: string[] = [
    "intItemNo",
    "txtCustomerName",
    "txtOrderNo",
    "txtPoleNumber",
    "txtConsecutiveNumber",
    "txtChargeId",
    "txtChargeDate",
    "txtOriginalPole",
    "txtFinalPole",
    "txtDrilled",
    "txtSpec",
    "fltLength",
    "fltGLDia",
    "txtProductName",
    "txtSpeciesName",
    "fltGirth",
    "fltActualM3",
    "fltActualTonne",
    "intDeliveryDocketNo",
    "blnRejected",
    "txtRejectedComments",
    "btnEditfinalInspectionItem",
    "btnRemovefinalInspectionItem",
    "btnTransferfinalInspectionItem",
  ];

  totalM3: number = 0;
  totalTonne: number = 0;
  selectedRow: string = "";

  poleTagBytes: string = "";
  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private sharedService: SharedService,
    private printService: PrintService,
    private dtoTPPoleInspectionService: dtoTPPoleInspectionService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.updatedComponentColumnsEvent(this.displayedfinalInspectionItems);

    this.getTreatmentPlantPrinter();
  }

  async getTreatmentPlantPrinter() {
    this.treatmentPlantPrinter =
      await this.printService.getPrinterByNamePromise(
        "WEB-APP-TP",
        Printer.enType.Mobile
      );
    console.log("treatmentPlantPrinter: ", this.treatmentPlantPrinter);
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  editFinalInspectionItem(finalInspectionItem: dtoTPPoleInspectionItem) {
    console.log("editFinalInspectionItem");
    console.log(finalInspectionItem);

    this.editFinalInspectionItemEvent.emit(finalInspectionItem);

    this.rowColorPropagation(finalInspectionItem.rowguid);
  }

  removeFinalInspectionItem(finalInspectionItem: dtoTPPoleInspectionItem) {
    console.log("removeFinalInspectionItem");
    console.log(finalInspectionItem);

    this.removeFinalInspectionItemEvent.emit(finalInspectionItem);

    this.rowColorPropagation(finalInspectionItem.rowguid);
  }

  updatedComponentColumnsEvent(columns: string[]) {
    console.log("print mode: ", this.mode);
    if (this.mode == "print") {
      columns = columns.filter(function (obj) {
        return (
          obj !== "txtDrilled" &&
          obj !== "txtSpec" &&
          obj !== "intDeliveryDocketNo" &&
          obj !== "blnRejected" &&
          obj !== "txtRejectedComments" &&
          obj !== "btnEditfinalInspectionItem" &&
          obj !== "btnRemovefinalInspectionItem" &&
          obj !== "btnTransferfinalInspectionItem" &&
          obj !== "btnPrintPoleBarcode"
        );
      });
    }

    this.displayedfinalInspectionItems = columns;
  }

  calcTotals() {
    this.totalM3 = 0;
    this.totalTonne = 0;

    console.log("calc totals - datasource", this.datasource);

    if (this.datasource != null) {
      let db: dtoTPPoleInspectionItem[] = [];
      db = this.datasource as unknown as dtoTPPoleInspectionItem[];

      db.forEach((pi) => {
        this.totalM3 += pi.fltActualM3;
        this.totalTonne += pi.fltActualTonne;
      });

      this.totalM3 = parseFloat(this.totalM3.toFixed(2));
      this.totalTonne = parseFloat(this.totalTonne.toFixed(2));

      this.tblfinalInspectionItems.renderRows();

      let tfoot = document.getElementsByTagName(
        "tfoot"
      ) as unknown as HTMLCollectionOf<HTMLTableElement>;
      if (tfoot != null) {
        if (tfoot.length > 0) {
          tfoot[0].style.display = "table-row-group";
        }
        if (tfoot.length >= 1) {
          tfoot[1].style.display = "table-row-group";
        }
      }
      console.log("tfoot: ", tfoot);
    }
  }

  transferFinalInspectionItem(finalInspectionItem: dtoTPPoleInspectionItem) {
    if (this.transferFinalInspectionItemDialogRef != null) {
      this.transferFinalInspectionItemDialogRef.close();
    }

    let finalItemArray: dtoTPPoleInspectionItem[] = [];
    finalItemArray.push(finalInspectionItem);

    this.transferFinalInspectionItemDialogRef = this.dialog.open(
      FinalInspectionItemTransferDialogComponent,
      {
        height: "auto",
        maxWidth: "95vw",
        data: {
          finalInspectionItems: finalItemArray,
          finalInspection: this.finalInspection,
        },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.transferFinalInspectionItemDialogRef.backdropClick().subscribe(() => {
      this.transferFinalInspectionItemDialogRef.close();
    });

    this.transferFinalInspectionItemDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError(
            "Something went wrong!\nAn email has been sent to the IT department.",
            "Close",
            "center",
            "bottom",
            4000,
            true,
            "Something went wrong with closing the FinalInspectionCreate dialog, null data."
          );
        }

        console.log(data);
        if (data.status != "Cancel") {
          this.transferredFinalInspectionItemEvent.emit(true);
        } else {
          // We have cancellled
        }
      });

    this.rowColorPropagation(finalInspectionItem.rowguid);
  }

  printBarcode(finalInspectionItem: dtoTPPoleInspectionItem) {
    if (finalInspectionItem == null) {
      this.alertService.openSnackBarError(
        "There was no final item to print from",
        "Close",
        "center",
        "bottom",
        2000,
        false,
        ""
      );
      return false;
    }

    let poleNumber: string = finalInspectionItem.txtPoleNumber;
    if (poleNumber == "") {
      poleNumber = finalInspectionItem.txtConsecutiveNumber;
    }

    this.printService.printPoleBarcode(poleNumber, this.treatmentPlantPrinter);

    this.rowColorPropagation(finalInspectionItem.rowguid);
  }

  async printPoleTag(finalInspectionItem: dtoTPPoleInspectionItem) {
    if (finalInspectionItem == null) {
      this.alertService.openSnackBarError(
        "There was no final item to print from",
        "Close",
        "center",
        "bottom",
        2000,
        false,
        ""
      );
      return false;
    }

    let poleNumber: string = finalInspectionItem.txtPoleNumber;
    if (poleNumber == "") {
      poleNumber = finalInspectionItem.txtConsecutiveNumber;
    }

    var dataURL: string = await this.getImageDataURL(
      "/assets/img/BlackLogo.bmp",
      "image/bmp"
    );

    //await this.renderHTMLToPDF();
    //console.log("finalInspectionItem", finalInspectionItem);

    //let printDocument: PrintDocument = new PrintDocument();
    //printDocument.printer = this.treatmentPlantPrinter;
    ////printDocument.Attachment = new DataURLFileAttachment("PoleNumber_" + finalInspectionItem.txtPoleNumber + ".pdf", "", "application/pdf", this.fileService.base64WithoutPrefix(this.poleTagBytes), false);
    //printDocument.Attachment = new DataURLFileAttachment("logo.bmp", "", "image/bmp", this.fileService.base64WithoutPrefix(dataURL), false);

    let poleTag: PoleTag = new PoleTag();
    poleTag.Printer = this.treatmentPlantPrinter;
    poleTag.txtPoleNumber = finalInspectionItem.txtPoleNumber;
    if (
      finalInspectionItem.txtPoleNumber == null ||
      finalInspectionItem.txtPoleNumber == ""
    ) {
      poleTag.txtPoleNumber = finalInspectionItem.txtConsecutiveNumber;
    }
    poleTag.txtSize = ""; //finalInspectionItem.fltPoleFinalLength.toString() + " / " + finalInspectionItem.intPoleFinalKN.toString();
    poleTag.txtLogoBase64 = this.fileService.base64WithoutPrefix(dataURL);

    //console.log("printPoleTagByNumber", dataURL);
    this.printService.printPoleTag(poleTag);

    this.rowColorPropagation(finalInspectionItem.rowguid);
  }

  getImageDataURL(src: string, type: string) {
    return new Promise<string>((resolve) => {
      var dataURL: string = "";
      var image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        context.drawImage(image, 0, 0);
        dataURL = canvas.toDataURL(type);
        console.log("in image load", dataURL);
        resolve(dataURL);
      };
      image.src = src;
    });
  }

  renderHTMLToPDF() {
    return new Promise<boolean>(async (resolve) => {
      let element: HTMLElement = document.getElementById("poletag");

      this.poleTagBytes = await this.fileService.HTMLToPdfDataURL(
        element,
        "l",
        "mm",
        [90, 60]
      );

      resolve(true);
    });
  }

  toggleCreateCSVTickbox(
    matCheckbox: MatCheckbox,
    finalInspectionItem: dtoTPPoleInspectionItem
  ) {
    finalInspectionItem.blnSelectCSVDisc = matCheckbox.checked;

    console.log("checkbox", matCheckbox);
    console.log("finalInspectionItem", finalInspectionItem);
  }

  async createDiscCSV(finalInspectionItem: dtoTPPoleInspectionItem) {
    if (finalInspectionItem == null) {
      this.alertService.openSnackBarDefault(
        "There is no final inspection item"
      );
      return;
    }

    let finalInspectionItems: dtoTPPoleInspectionItem[] = [];

    finalInspectionItems.push(finalInspectionItem);

    console.log("mode: " + this.mode);

    if (finalInspectionItems == null) {
      this.alertService.openSnackBarDefault("There are no inspection items");
      return;
    }

    await this.dtoTPPoleInspectionService.createDiscCSVPromise(
      finalInspectionItems
    );
    this.alertService.openSnackBarDefault("The CSV File has been created");

    this.rowColorPropagation(finalInspectionItem.rowguid);
  }

  setRowColor(finalInspectionItemId: string) {
    if (this.selectedRow == "" || finalInspectionItemId != this.selectedRow) {
      this.selectedRow = finalInspectionItemId;
      return;
    }

    if (finalInspectionItemId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }
  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }
}
