<div class="royalty-rate-list-container">

  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblRoyaltyRate [dataSource]="datasource" class="mat-elevation-z8 royalty-rate-table" *ngIf="datasource != null">

    <ng-container matColumnDef="dteDateCreate">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH alignRight"> Date Created </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD dateCreated alignRight"> {{royaltyRate.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="intLength">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH alignRight"> Length </th>
      <td mat-cell *matCellDef="let royaltyRate" class="docketNo royaltyRateTD alignRight"> {{royaltyRate.intLength}} </td>
    </ng-container>

    <ng-container matColumnDef="intKN">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH alignRight"> KN </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD kn alignRight"> {{royaltyRate.intKn}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPoleType">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH">  </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD kn"> {{royaltyRate.txtPoleType}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDurabilityClass">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"> Durability Class </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD durabilityClass"> {{royaltyRate.txtDurabilityClass}} </td>
    </ng-container>


    <ng-container matColumnDef="fltForestryRoyaltyRate">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"> Forestry Royalty Rate </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD forestryRoyaltyRate" (click)="updateRoyaltyRate = royaltyRate.rowguid">
        <div #royaltyRateValue *ngIf="updateRoyaltyRate?.toString()==''"> {{royaltyRate.fltForestryRoyaltyRate}} </div>
        <mat-form-field class="example-full-width header-button royaltRateFormField" appearance="fill" *ngIf="updateRoyaltyRate?.toString() == royaltyRate.rowguid">
          <mat-label>Royalty Rate</mat-label>
          <input #royaltRateInput class="textbox-input chh-texbox-50 royaltyRateTextbox" matInput value="{{royaltyRate.fltForestryRoyaltyRate}}" type="text">
          <mat-icon class="user-column-list-add-icon" (click)="updateRoyaltyReate(royaltyRate, royaltRateInput.value)">save</mat-icon>
          <mat-icon (click)="updateRoyaltyRate=''">close</mat-icon>
        </mat-form-field>
        <div class=""></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fltCHHRoyaltyRate">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"> CHH Royalty Rate </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD chhRoyaltyRate"> {{royaltyRate.fltCHHRoyaltyRate}} </td>
    </ng-container>

    <ng-container matColumnDef="blnCurrent">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"> Current </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD current"> {{royaltyRate.blnCurrent == true ? 'Yes' : 'No'}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateExpires">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH alignRight"> Date Expires </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD dateExpires alignRight"> {{royaltyRate.dteDateExpires | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dteLastUpdated">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH alignRight"> Last Updated </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD lastUpdated alignRight"> {{royaltyRate.dteLastUpdated | date: 'dd/MM/yyyy hh:mm:ss'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"> Comments </th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD comments"> {{royaltyRate.txtComments}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditRoyaltyRate">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"></th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD"><button class="edit-royalty-rate-button" type="button" mat-flat-button color="primary" (click)="editRoyaltyRate(royaltyRate)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveRoyaltyRate">
      <th mat-header-cell *matHeaderCellDef class="royaltyRateTH"></th>
      <td mat-cell *matCellDef="let royaltyRate" class="royaltyRateTD"><button class="remove-royalty-rate-button" type="button" mat-flat-button color="primary" (click)="removeRoyaltyRate(royaltyRate.rowguid)">Remove</button></td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>


