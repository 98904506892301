import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
} from "@angular/animations";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatInput } from "@angular/material/input";
import {
  MatSelectionList,
  MatSelectionListChange,
} from "@angular/material/list";
import { MatSelectChange } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../../account/auth.service";
import { EmailMessage } from "../../_shared/app-objects/email-message";
import { Client } from "../../_shared/business-objects/client.bo";
import { dtoOrderItem } from "../../_shared/business-objects/dto-order-item.bo";
import { dtoOrders } from "../../_shared/business-objects/dto-orders.bo";
import { dtoTPPoleInspectionItem } from "../../_shared/business-objects/dto-tppole-inspection-item.bo";
import { dtoTPPoleInspection } from "../../_shared/business-objects/dto-tppole-inspection.bo";
import { Orders } from "../../_shared/business-objects/orders.bo";
import { Settings } from "../../_shared/business-objects/settings.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { TPPoleInspectionItem } from "../../_shared/business-objects/tppole-inspection-item.bo";
import { TPPoleInspection } from "../../_shared/business-objects/tppole-inspection.bo";
import { AlertService } from "../../_shared/services/alert.service";
import { ClientService } from "../../_shared/services/client.service";
import { dtoOrderItemService } from "../../_shared/services/dto-order-item.service";
import { dtoTPPoleInspectionItemService } from "../../_shared/services/dto-tppole-inspection-item.service";
import { dtoTPPoleInspectionService } from "../../_shared/services/dto-tppole-inspection.service";
import { EmailService } from "../../_shared/services/email.service";
import { FormService } from "../../_shared/services/form.service";
import { OrderItemService } from "../../_shared/services/order-item.service";
import { OrdersService } from "../../_shared/services/orders.service";
import { SettingsService } from "../../_shared/services/settings.service";
import { SharedService } from "../../_shared/services/shared.service";
import { TPPoleInspectionItemService } from "../../_shared/services/tppole-inspection-item.service";

@Component({
  selector: "app-final-inspection-item-transfer",
  templateUrl: "./final-inspection-item-transfer.component.html",
  styleUrls: ["./final-inspection-item-transfer.component.css"],
  animations: [
    trigger("displaySpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayTick", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.9s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayCancel", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.2s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
  ],
})
export class FinalInspectionItemTransferComponent implements OnInit {
  @Input() finalInspectionItems: dtoTPPoleInspectionItem[];
  @Input() finalInspection: dtoTPPoleInspection;
  @Output() finalInspectionItemTransferred = new EventEmitter<boolean>();
  @Output() finalInspectionItemTransferCancelled = new EventEmitter<boolean>();

  @ViewChild("txtFinalNoToo") txtFinalNo: HTMLInputElement;
  @ViewChild("guCustomerId") guCustomerId: MatSelectionList;
  @ViewChild("orderItemSummaryList") orderItemSummaryList: MatSelectionList;
  @ViewChild("sameOrder") sameOrder: MatCheckbox;

  form: FormGroup;

  showSpinner: boolean = false;
  showTick: boolean = false;
  showCancel: boolean = false;
  parkedFinalsExpanded: boolean;

  public customers: Client[];
  public orders: Orders[];
  public orderItems: dtoOrderItem[];

  public customersAll: Client[];
  public ordersAll: Orders[];
  public orderItemsAll: dtoOrderItem[];

  finalInspectionToo: dtoTPPoleInspection;
  finalInspectionItemsToo: dtoTPPoleInspectionItem[];
  finalInspectionsParked: dtoTPPoleInspection[];
  finalInspectionsInProgress: dtoTPPoleInspection[];
  orderItemSummary: dtoOrderItem[];
  currentOrderItem: dtoOrderItem;

  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<
    Client[]
  >(1);
  public filteredOrders: ReplaySubject<Orders[]> = new ReplaySubject<Orders[]>(
    1
  );
  public filteredOrderItems: ReplaySubject<dtoOrderItem[]> = new ReplaySubject<
    dtoOrderItem[]
  >(1);
  public customersFilterCtrl: FormControl = new FormControl();
  public ordersFilterCtrl: FormControl = new FormControl();
  public orderItemFilterCtrl: FormControl = new FormControl();

  private _onDestroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private alertService: AlertService,
    private tpPoleInspectionItemService: TPPoleInspectionItemService,
    private dtoTPPoleInspectionService: dtoTPPoleInspectionService,
    private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService,
    private cdref: ChangeDetectorRef,
    private formService: FormService,
    private orderItemService: OrderItemService,
    private dtoOrderItemService: dtoOrderItemService,
    private ordersService: OrdersService,
    private customerService: ClientService,
    private emailService: EmailService,
    private authService: AuthService,
    private settingsService: SettingsService
  ) {
    this.form = this.formBuilder.group({
      guCustomerId: [""],
      guOrderId: [""],
      guOrderItemId: [""],
      guElectricalAuthoritySpecId: [""],
      txtFinalNoToo: [""],
      blnSameOrder: [0],
      orderItemSummaryList: [""],
    });
  }

  ngOnInit(): void {
    this.parkedFinalsExpanded = false;

    this.getParkedFinals();
    this.getInProgressFinals();

    this.loadData();
  }

  async loadData() {
    await this.getCustomers();

    //===================================================================================
    // WILL HAVE TO TAKE FIRST INSPECTION ITEM FOR NOW.
    // WE MAY BE SENDING MULTIPLE INSPECTION ITEMS.
    // THE ASSUMPTION WILL BE THAT THEY ARE ALL GOING ON ONE ORDER ITEM.
    //===================================================================================

    let finalInspectionItem: dtoTPPoleInspectionItem =
      this.finalInspectionItems[0];

    //===================================================================================

    //if (finalInspectionItem.guCustomerId == null) {
    //}

    //if (finalInspectionItem.guCustomerId != null) {
    //  this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(finalInspectionItem.guCustomerId);
    //}

    this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(
      finalInspectionItem.guCustomerId
    );
    this.ordersAll = this.orders;

    // Dont think we should be setting it on first load.
    //if (finalInspectionItem.guOrderId != null) {
    //  let currentOrder = await this.ordersService.getOrdersPromise(finalInspectionItem.guOrderId);
    //  this.orders.splice(0, 0, currentOrder);
    //  this.form.controls["guOrderId"].setValue(currentOrder);
    //}

    this.filteredOrders.next(this.orders);

    this.ordersFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrders();
      });

    if (
      finalInspectionItem.guOrderId != null &&
      this.finalInspection.blnParkedFinal == false
    ) {
      this.orderItems =
        await this.orderItemService.getdtoOrderItemsByOrderPromise(
          finalInspectionItem.guOrderId
        );
      this.currentOrderItem =
        await this.dtoOrderItemService.getdtoOrderItemPromise(
          finalInspectionItem.guOrderItemId
        );
      this.filteredOrderItems.next(this.orderItems);
      console.log("in guOrderId != null && ParkedFinal == false");
    }

    if (
      finalInspectionItem.guTransferredFromOrderItemId != null &&
      this.finalInspection.blnParkedFinal == true
    ) {
      this.currentOrderItem =
        await this.dtoOrderItemService.getdtoOrderItemPromise(
          finalInspectionItem.guTransferredFromOrderItemId
        );
      this.orderItems =
        await this.orderItemService.getdtoOrderItemsByOrderPromise(
          this.currentOrderItem.guOrderId
        );
      this.filteredOrderItems.next(this.orderItems);
      console.log(
        "in guTransferredFromOrderItemId != null && ParkedFinal == true"
      );
    }

    this.orderItemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrderItems();
      });

    if (finalInspectionItem.guCustomerId != null) {
      // Dont think we should be setting it on first load.
      //this.form.controls["guCustomerId"].setValue(finalInspectionItem.guCustomerId);
    }

    //this.form.controls["guOrderItemId"].setValue(currentOrderItem);

    // Dont think we should be setting it on first load.
    //if (this.orderItems != null) {
    //  for (let i = 0; i <= this.orderItems.length - 1; i++) {
    //    if (finalInspectionItem.guOrderItemId == this.orderItems[i].rowguid) {
    //      this.form.controls['guOrderItemId'].setValue(this.orderItems[i]);
    //      break;
    //    }
    //  }
    //}

    //console.log("guCustomerId: " + finalInspectionItem.guCustomerId);
    //console.log("guOrderId: ", currentOrder);
    //console.log("guOrderItemId: ", currentOrderItem);
    //console.log("guOrderItemId form value: ", this.form.controls["guOrderItemId"].value);
  }

  async getParkedFinals() {
    this.finalInspectionsParked =
      await this.dtoTPPoleInspectionService.getParkedFinalsPromise(
        TPPoleInspection.enOnDeliveryDocket.Either
      );
  }

  async getInProgressFinals() {
    this.finalInspectionsInProgress =
      await this.dtoTPPoleInspectionService.getInProgressFinalsPromise(
        TPPoleInspection.enOnDeliveryDocket.Either
      );
  }

  async getOrderItemSummary() {
    if (this.finalInspectionItemsToo == null) {
      this.alertService.openSnackBarDefault("There is no final selected");
      return;
    }

    let customerId: string[] = [];
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    for (let i = 0; i <= this.finalInspectionItemsToo.length - 1; i++) {
      let cId: string = customerId.find((o) => {
        return o == this.finalInspectionItemsToo[i].guCustomerId;
        //console.log("o: " + o + " == guCustomerId: " + this.finalInspectionItemsToo[i].guCustomerId);
      });
      if (cId == null) {
        customerId.push(this.finalInspectionItemsToo[i].guCustomerId);
        //console.log("adding customerId: " + this.finalInspectionItemsToo[i].guCustomerId);
      }
    }

    //console.log("customerId: ", customerId);
    if (customerId != null && customerId.length > 0) {
      //  for (let i = 0; i <= orderId.length - 1; i++) {
      //    aParam.push(new SQLParamArray("guOrderId", orderId[i]));
      //  }
      //  aParamList.push(aParam);

      let finalInspectionItem: dtoTPPoleInspectionItem =
        this.finalInspectionItems[0];

      //// Filter on the pole id being transferred.
      //aParam = [];
      //aParam.push(new SQLParamArray("guPoleId", finalInspectionItem.guFinalPoleId));
      //aParamList.push(aParam);

      //console.log("about to get order summary");

      this.orderItemSummary =
        await this.dtoOrderItemService.getCustomerOrderItems(
          customerId,
          [Orders.enStatus.Active, Orders.enStatus.PartialComplete],
          finalInspectionItem.guFinalPoleId
        );

      console.log("order summary: ", this.orderItemSummary);

      if (this.orderItemSummary == null) {
        return;
      }
      //this.orderItemSummary = this.orderItemSummary.sort(function(a, b){
      //  return a.txtCustomerName > b.txtCustomerName ? 1 : 0 || a.txtOrderNo > b.txtOrderNo ? 1 : 0 || a.intLength > b.intLength ? 1 : 0 || a.intKN > b.intKN ? 1 : 0;
      //});
      //console.log("order summary", this.orderItemSummary);

      this.orderItemSummary = this.orderItemSummary.sort(function (a, b) {
        //return a.txtCustomerName < b.txtCustomerName ? -1 : a.txtCustomerName > b.txtCustomerName ? 1 : 0;
        return (
          a.txtCustomerName
            .toLowerCase()
            .localeCompare(b.txtCustomerName.toLowerCase()) ||
          a.txtOrderNo.toLowerCase().localeCompare(b.txtOrderNo.toLowerCase())
        );
      });
    }
  }

  async getFinalInspection(finalNoToo: number) {
    if (
      isNaN(finalNoToo) ||
      finalNoToo == null ||
      parseInt(finalNoToo.toString()) == 0
    ) {
      this.alertService.openSnackBarDefault("Invalid final number");
      return;
    }

    let spanSpinnerContainer: HTMLSpanElement = document.getElementById(
      "spanSpinnerContainer"
    ) as HTMLSpanElement;
    let iconTickContainer: HTMLSpanElement = document.getElementById(
      "iconTickContainer"
    ) as HTMLSpanElement;
    let iconCancelContainer: HTMLSpanElement = document.getElementById(
      "iconCancelContainer"
    ) as HTMLSpanElement;

    this.showSpinner = true;
    this.cdref.detectChanges();
    spanSpinnerContainer.style.visibility = "visible";
    spanSpinnerContainer.style.width = "40px;";

    iconCancelContainer.style.visibility = "hidden";
    iconCancelContainer.style.width = "0px;";

    this.finalInspectionToo =
      await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionByFinalNoPromise(
        finalNoToo.toString(),
        TPPoleInspection.enOnDeliveryDocket.Either
      );

    if (this.finalInspectionToo == null) {
      //console.log("finalToo: is null");
      iconCancelContainer.style.visibility = "visible";
      iconCancelContainer.style.width = "40px;";
      this.showCancel = true;
      this.showSpinner = false;
      this.cdref.detectChanges();

      return;
    }

    this.showSpinner = false;
    this.cdref.detectChanges();

    spanSpinnerContainer.style.visibility = "hidden";
    spanSpinnerContainer.style.width = "0px;";

    iconTickContainer.style.visibility = "visible";
    iconTickContainer.style.width = "40px;";
    this.showTick = true;

    this.cdref.detectChanges();

    setTimeout(() => {
      this.showTick = false;
      this.cdref.detectChanges();
    }, 1000);

    setTimeout(() => {
      this.showTick = false;
      iconTickContainer.style.visibility = "hidden";
      iconTickContainer.style.width = "0px;";
    }, 1400);

    this.finalInspectionItemsToo =
      await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemByInspectionIdPromise(
        this.finalInspectionToo.rowguid
      );

    this.setSameOrder(this.sameOrder.checked);

    //console.log("to items: ", this.finalInspectionItemsToo);
  }

  async checkCharLength(finalNoToo: number) {
    console.log(finalNoToo);

    if (
      finalNoToo == null ||
      finalNoToo == 0 ||
      finalNoToo.toString().length < 5
    ) {
      this.finalInspectionToo = null;
      this.finalInspectionItemsToo = [];
      this.orderItemSummary = null;

      //this.orders = null;
      //this.orderItems = null;
      this.form.controls["guCustomerId"].setValue("");
      this.form.controls["guOrderId"].setValue("");
      this.form.controls["guOrderItemId"].setValue("");

      return;
    }

    console.log("about to get final inspection");

    await this.getFinalInspection(finalNoToo);

    console.log("about to get final summary");
    await this.getOrderItemSummary();
  }

  customerLocation(finalInspection: dtoTPPoleInspection) {
    if (
      finalInspection == null ||
      finalInspection.txtCustomer == null ||
      finalInspection.txtLocation == null
    ) {
      return;
    }

    return finalInspection.txtCustomer.indexOf(",") > -1 &&
      finalInspection.txtLocation.indexOf(",") > -1
      ? finalInspection.intFinalNo.toString() +
          " - " +
          finalInspection.txtCustomerLocation
      : finalInspection.intFinalNo.toString() +
          " - " +
          finalInspection.txtCustomer +
          " - " +
          finalInspection.txtLocation;
  }

  setSameOrder(sameOrder: boolean) {
    if (sameOrder == false) {
      return;
    }

    if (this.finalInspectionItems == null) {
      return;
    }

    if (this.finalInspectionToo == null) {
      return;
    }

    console.log("sameOrder: ", sameOrder);
    console.log("sameOrder: ", this.finalInspectionItems[0]);

    if (
      this.finalInspectionItems[0].guOrderItemId != null &&
      this.finalInspection.blnParkedFinal == false
    ) {
      this.form.controls["orderItemSummaryList"].setValue([
        this.finalInspectionItems[0].guOrderItemId,
      ]);
    }

    if (
      this.finalInspectionItems[0].guTransferredFromOrderItemId != null &&
      this.finalInspection.blnParkedFinal == true
    ) {
      this.form.controls["orderItemSummaryList"].setValue([
        this.finalInspectionItems[0].guTransferredFromOrderItemId,
      ]);
    }

    if (
      this.currentOrderItem != null &&
      (this.currentOrderItem.rowguid ==
        this.finalInspectionItems[0].guOrderItemId ||
        this.currentOrderItem.rowguid ==
          this.finalInspectionItems[0].guTransferredFromOrderItemId)
    ) {
      this.setOrderItem(this.currentOrderItem);
      console.log("this.currentOrderItem", this.currentOrderItem);
    }
  }

  async transferfinalInspectionItem() {
    //eventData: { finalInspectionItem: TPPoleInspectionItem, finalInspectionTo: dtoTPPoleInspection }, finalInspectionFrom: dtoTPPoleInspection

    // ----------------------------------------------  TO DO  ------------------------------------------------------------------------
    // ===============================================================================================================================
    // ===============================================================================================================================
    //
    // IF TRANSFERRING TO A PARKED FINAL WE MUST CHECK IF THE POLE IS LINKED TO A DELIVERY DOCKET.
    // IF SO WE MUST ASK THE USER IF ITS OK TO TO UNLIK IT FROM THE DELIVERY DOCKET
    //
    // ===============================================================================================================================
    // ===============================================================================================================================

    if (
      this.finalInspectionItems == null ||
      this.finalInspectionItems.length == 0
    ) {
      this.alertService.openSnackBar(
        "There are no final inspection items to transfer",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
    }

    let customerId: string = this.form.controls["guCustomerId"].value[0];
    let order: Orders = this.form.controls["guOrderId"].value[0];
    let orderItem: dtoOrderItem = this.form.controls["guOrderItemId"].value[0];

    //console.log("customerid", customerId);
    //console.log("order", order);
    //console.log("orderItem", orderItem);

    if (this.finalInspectionToo.blnParkedFinal == false) {
      if (customerId == null || customerId == "") {
        this.alertService.openSnackBarDefault("There is no Customer selected");
        return;
      }

      if (order == null) {
        this.alertService.openSnackBarDefault("There is no Order selected");
        return;
      }

      if (orderItem == null) {
        this.alertService.openSnackBarDefault(
          "There is no Order Item selected"
        );
        return;
      }
    }

    if (
      this.finalInspectionToo.blnParkedFinal == false &&
      orderItem.fltQuantityTotal - orderItem.intFinalCount - 1 < 0
    ) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Over Supply",
        "You are going to over supply " +
          orderItem.intLength +
          " / " +
          orderItem.intKN +
          (orderItem.blnQV == true ? " Q/V" : "") +
          "'s on this order\nIs this OK?",
        "Yes",
        "No",
        "center",
        "bottom",
        "",
        0
      );
      if (msg != "Yes") {
        return;
      }
    }

    let poleNumber: string = "";

    let response: string = await this.alertService.openSnackBarCustomPromise(
      "Transfer Inspection Item",
      "Are you sure you want to transfer the inspection item(s)?",
      "Yes",
      "No",
      "center",
      "bottom",
      "",
      0
    );
    let updatedFinalItem: boolean = false;
    let errorUpdatingFinalItem: boolean = false;

    if (response == "Yes") {
      for (let i = 0; i <= this.finalInspectionItems.length - 1; i++) {
        //if (this.finalInspectionItems[i].txtPoleNumber != null && this.finalInspectionItems[i].txtPoleNumber != "") {
        //  poleNumber = this.finalInspectionItems[i].txtPoleNumber;
        //}

        //if (this.finalInspectionItems[i].txtPoleNumber == null || this.finalInspectionItems[i].txtPoleNumber == "") {
        //  poleNumber = this.finalInspectionItems[i].txtConsecutiveNumber;
        //}

        let dtoFinalItem: dtoTPPoleInspectionItem =
          this.finalInspectionItems[i];
        let finalItem: TPPoleInspectionItem =
          await this.tpPoleInspectionItemService.getTPPoleInspectionItemPromise(
            this.finalInspectionItems[i].rowguid
          );
        let final: TPPoleInspection =
          await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionPromise(
            finalItem.guTPPoleInspectionId
          );

        if (this.finalInspectionItems[0].guDeliveryDocketOrderItemId != null) {
          let unlinkMsg: string =
            await this.alertService.openSnackBarCustomPromise(
              "Unlink From Delivery Docket",
              "This final item is linked to a delivery docket.\nDo you want to unlink it?",
              "Yes",
              "No"
            );

          if (unlinkMsg == "Yes") {
            finalItem.guDeliveryDocketOrderItemId = null;
          }
        }

        if (this.finalInspectionToo.blnParkedFinal == true) {
          if (
            this.finalInspectionToo.guElectricalAuthoritySpecId !=
            finalItem.guElectricalAuthoritySpecId
          ) {
            let specCurrent: string =
              "This final item is not linked to any spec\n";
            let specToo: string =
              "The Parked Final you are transferring too does not have a spec set\n";
            let alertMsg: string;

            if (
              this.finalInspectionItems[i].txtElectricalAuthoritySpec != null
            ) {
              specCurrent =
                "This final item is linked to the spec " +
                this.finalInspectionItems[i].txtElectricalAuthoritySpec +
                "\n";
            }

            if (this.finalInspectionToo.txtElectricalAuthoritySpec != null) {
              specToo =
                "You're trying to add it to a Parked Final with a spec set for " +
                this.finalInspectionToo.txtElectricalAuthoritySpec +
                "\n";
            }

            alertMsg = specCurrent + specToo + "\nDo you want to continue?";

            let msg: string = await this.alertService.openSnackBarCustomPromise(
              "Incorrect Specification",
              alertMsg,
              "Yes",
              "No"
            );
            if (msg != "Yes") {
              break;
            }
          }
        }

        finalItem.guTPPoleInspectionId = this.finalInspectionToo.rowguid;

        console.log("final: ", final);

        // Set customer, order and order item to null when going to parked.
        // But write in the comments field what order number it came from.
        if (this.finalInspectionToo.blnParkedFinal == true) {
          finalItem.txtComments =
            "From PO - " + this.finalInspectionItems[0].txtOrderNo;
          finalItem.guTransferredFromOrderItemId =
            this.finalInspectionItems[0].guOrderItemId;
          finalItem.guCustomerId = null;
          finalItem.guOrderId = null;
          finalItem.guOrderItemId = null;

          if (
            dtoFinalItem.intWebAppCustomActions ==
            Client.enWebAppCustomActions.AlertQuantityChange
          ) {
            //TPQuantityChange
            let setting: Settings =
              await this.settingsService.getSettingsByName("TPQuantityChange");
            let toEmail: string = setting.txtValue;
            let msg: string = await this.alertService.openSnackBarCustomPromise(
              "Change Quantity",
              "Please remember to change the order quantity on paper for pole " +
                dtoFinalItem.txtFinalPole +
                " on PO - " +
                dtoFinalItem.txtOrderNo,
              "Ok",
              "",
              "center",
              "bottom",
              "",
              0,
              false
            );

            console.log("toEmail: ", toEmail);

            if (toEmail != null && toEmail != "") {
              await this.emailService.sendEmail(
                new EmailMessage(
                  "tp@coffshardwoods.com.au",
                  toEmail,
                  "",
                  "",
                  "Change Quantity",
                  "<h1>Please remember to change the order quantity on paper for pole " +
                    dtoFinalItem.txtFinalPole +
                    " on PO - " +
                    dtoFinalItem.txtOrderNo +
                    "</h1>",
                  null,
                  null,
                  true
                )
              );
            }
          }
        }

        if (
          this.finalInspectionToo.blnParkedFinal == null ||
          this.finalInspectionToo.blnParkedFinal == false
        ) {
          finalItem.txtComments = "";
          finalItem.guCustomerId = customerId;
          finalItem.guOrderId = order.rowguid;
          finalItem.guOrderItemId = orderItem.rowguid;
          let finalItemsToo: dtoTPPoleInspectionItem[] =
            await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemByInspectionIdPromise(
              this.finalInspectionToo.rowguid
            );
          //console.log("finalItemsToo: ", finalItemsToo);
          if (finalItemsToo != null && finalItemsToo.length > 0) {
            finalItem.intItemNo = finalItemsToo.length + 1;
            //console.log("finalItem.intItemNo: ", finalItem.intItemNo);
          }
        }

        let transferred: TPPoleInspectionItem =
          await this.tpPoleInspectionItemService.updateTPPoleInspectionItemPromise(
            finalItem
          );
        //console.log("transferred before: " + transferred);

        if (transferred == null) {
          this.alertService.openSnackBarError(
            "There was a problem transfering the final item",
            "Close",
            "center",
            "bottom",
            4000,
            true,
            "There was a problem transfering the final item - " +
              finalItem.txtPoleNumber
          );
          errorUpdatingFinalItem = true;
        } else {
          updatedFinalItem = true;
        }
      } // for loop

      if (updatedFinalItem == true) {
        this.alertService.openSnackBar(
          "The inspection item(s) have been transferred",
          "Close",
          "center",
          "bottom",
          "",
          3000
        );

        await this.tpPoleInspectionItemService.reSetItemNumbers(
          this.finalInspectionToo.rowguid
        );
        await this.tpPoleInspectionItemService.reSetItemNumbers(
          this.finalInspection.rowguid
        );

        this.finalInspectionItemTransferred.emit(true);
      }
    } // end of if
  }

  async setDefaultParkedFinal() {
    this.parkedFinalsExpanded = !this.parkedFinalsExpanded;

    if (
      this.finalInspectionsParked != null &&
      this.parkedFinalsExpanded == true
    ) {
      console.log("setDefaultParkedFinal expanded");

      let gotParked: boolean = false;

      for (let i = 0; i <= this.finalInspectionsParked.length - 1; i++) {
        if (
          this.finalInspectionsParked[i].guElectricalAuthoritySpecId ==
          this.finalInspectionItems[0].guElectricalAuthoritySpecId
        ) {
          console.log(
            "selectedParkedFinal: " +
              this.finalInspectionsParked[i].guElectricalAuthoritySpecId
          );
          //this.selectedParkedFinal = this.finalInspectionsParked[i].guElectricalAuthoritySpecId;
          this.form.controls["guElectricalAuthoritySpecId"].setValue([
            this.finalInspectionsParked[i].guElectricalAuthoritySpecId,
          ]);
          this.form.controls["txtFinalNoToo"].setValue(
            this.finalInspectionsParked[i].intFinalNo.toString()
          );
          await this.getFinalInspection(
            this.finalInspectionsParked[i].intFinalNo
          );
          await this.getOrderItemSummary();

          gotParked = true;
          break;
        }
      }

      if (gotParked == false) {
        this.alertService.openSnackBarDefault(
          "Could not set default parked final"
        );
      }
    }
  }

  cancel() {
    this.finalInspectionItemTransferCancelled.emit(true);
  }

  clearFinalNo(txtFinalNoToo: MatInput) {
    let iconCancelContainer: HTMLSpanElement = document.getElementById(
      "iconCancelContainer"
    ) as HTMLSpanElement;

    iconCancelContainer.style.visibility = "hidden";
    iconCancelContainer.style.width = "0px;";
    this.showCancel = false;

    txtFinalNoToo.value = "";
    txtFinalNoToo.focus();

    this.finalInspectionToo = null;
    this.finalInspectionItemsToo = [];
    this.orderItemSummary = null;

    this.form.controls["guCustomerId"].setValue("");
    this.form.controls["guOrderId"].setValue("");
    this.form.controls["guOrderItemId"].setValue("");
  }

  getFinalInspectionAfterClick(textbox: MatInput) {
    setTimeout(async () => {
      await this.getFinalInspection(parseInt(textbox.value));
      this.getOrderItemSummaryAfterClick();
    }, 300);
  }

  getOrderItemSummaryAfterClick() {
    setTimeout(() => {
      this.getOrderItemSummary();
    }, 800);
  }

  async pasteFromClipboard(textbox: MatInput) {
    textbox.value = await this.formService.pasteFromClipboard();
  }

  async clearTextbox(textbox: MatInput) {
    textbox.value = "";
    textbox.focus();

    this.finalInspectionToo = null;
    this.finalInspectionItemsToo = [];
    this.orderItemSummary = null;

    this.form.controls["guCustomerId"].setValue("");
    this.form.controls["guOrderId"].setValue("");
    this.form.controls["guOrderItemId"].setValue("");
  }

  clearOrderLists() {
    this.form.controls["guCustomerId"].setValue("");
    this.form.controls["guOrderId"].setValue("");
    this.form.controls["guOrderItemId"].setValue("");
    this.orderItemSummary = null;
  }

  async customerChanged(matSelectChange: MatSelectionListChange) {
    this.resetOrders();

    if (matSelectChange.options[0] == null) {
      return;
    }

    //console.log("matSelectChange.value", matSelectChange.options[0].value);

    this.populateOrders(matSelectChange.options[0].value);
  }

  async orderChanged(order: Orders) {
    if (order == null) {
      return;
    }

    this.orderItems =
      await this.orderItemService.getdtoOrderItemsByOrderPromise(order.rowguid);
    this.orderItemsAll = this.orderItems;

    //this.filteredOrderItems.next(this.orderItems);

    //this.orderItemFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrderItems();
    //  });
  }

  resetOrders() {
    this.orders = [];
    this.orderItems = [];
    this.filteredOrderItems.next(null);
  }

  async populateOrders(customerId: string) {
    this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(
      customerId
    );
    this.ordersAll = this.orders;

    console.log(this.orders);

    //this.filteredOrders.next(this.orders);

    //this.ordersFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrders();
    //  });
  }

  private filterCustomers() {
    if (!this.customers) {
      return;
    }

    // get the search keyword
    let search = this.customersFilterCtrl.value;
    if (!search) {
      this.filteredCustomers.next(this.customers);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredCustomers.next(
      this.customers.filter((c) => c.txtName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterOrders() {
    if (!this.orders) {
      return;
    }

    // get the search keyword
    let search = this.ordersFilterCtrl.value;
    if (!search) {
      this.filteredOrders.next(this.orders);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredOrders.next(
      this.orders.filter((o) => o.txtOrderNo.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterOrderItems() {
    if (!this.orderItems) {
      return;
    }

    // get the search keyword
    let search = this.orderItemFilterCtrl.value;
    if (!search) {
      this.filteredOrderItems.next(this.orderItems);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredOrderItems.next(
      this.orderItems.filter(
        (oi) => oi.intLength.toString().indexOf(search) > -1
      )
    );
  }

  async getCustomers() {
    let customers = await this.customerService.getTreatmentPlantCustomers(
      true,
      false
    );
    if (customers == null) {
      return;
    }

    this.customersAll = customers;
    this.customers = customers;
    //this.filteredCustomers.next(this.customers);

    //this.customersFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterCustomers();
    //  });
  }

  searchCustomers(name: string) {
    this.customers = this.customersAll;
    this.customers = this.customers.filter(
      (c) => c.txtName.toLowerCase().indexOf(name.toLowerCase()) > -1
    );
  }

  searchOrders(orderNo: string) {
    this.orders = this.ordersAll;
    this.orders = this.orders.filter(
      (o) => o.txtOrderNo.toLowerCase().indexOf(orderNo.toLowerCase()) > -1
    );
  }

  searchOrderItems(description: string) {
    this.orderItems = this.orderItemsAll;
    this.orderItems = this.orderItems.filter(
      (a) =>
        a.txtDescription.toLowerCase().indexOf(description.toLowerCase()) > -1
    );
  }

  async setOrderItem(orderItem: dtoOrderItem) {
    //console.log("set order item: ", orderItem);

    this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(
      orderItem.guCustomerId
    );
    let currentOrder = await this.ordersService.getOrdersPromise(
      orderItem.guOrderId
    );

    this.orders.splice(0, 0, currentOrder);

    //this.filteredOrders.next(this.orders);

    //this.ordersFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrders();
    //  });

    this.orderItems =
      await this.orderItemService.getdtoOrderItemsByOrderPromise(
        orderItem.guOrderId
      );
    //let currentOrderItem = await this.dtoOrderItemService.getdtoOrderItemPromise(orderItem.rowguid);
    //this.filteredOrderItems.next(this.orderItems);

    //this.orderItemFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrderItems();
    //  });

    this.form.controls["guCustomerId"].setValue([orderItem.guCustomerId]);

    setTimeout(() => {
      this.formService.scrollToMatListOption(
        "guCustomerId",
        orderItem.guCustomerId,
        this.guCustomerId.options.toArray()
      );
    }, 500);

    this.form.controls["guOrderId"].setValue([currentOrder]);
    //this.form.controls["guOrderItemId"].setValue(orderItem);

    for (let i = 0; i <= this.orderItems.length - 1; i++) {
      if (orderItem.rowguid == this.orderItems[i].rowguid) {
        this.form.controls["guOrderItemId"].setValue([this.orderItems[i]]);
        break;
      }
    }
  }
}
