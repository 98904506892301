import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dtoRoyaltyRate } from '../../../_shared/business-objects/dto-royalty-rate.bo';
import { RoyaltRate } from '../../../_shared/business-objects/royalt-rate.bo';
import { RoyaltyRate } from '../../../_shared/business-objects/royalty-rate.bo';
import { AlertService } from '../../../_shared/services/alert.service';
import { RoyaltyRateService } from '../../../_shared/services/royalty-rate.service';

@Component({
  selector: 'app-royalty-rate-list',
  templateUrl: './royalty-rate-list.component.html',
  styleUrls: ['./royalty-rate-list.component.css']
})
export class RoyaltyRateListComponent implements OnInit {

  @ViewChild('tblRoyaltyRate', { static: false }) tblRoyaltyRate: MatTable<dtoRoyaltyRate>;

  @Input() datasource: MatTableDataSource<dtoRoyaltyRate>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() delOrderItemColumnBarContainer: string;
  @Input() columnListId: string;
  @Output() editRoyaltyRateEvent = new EventEmitter<dtoRoyaltyRate>();
  @Output() updateRoyaltyRateEvent = new EventEmitter<dtoRoyaltyRate>();


  loadedComponent: boolean = false;

  displayedColumns: string[] = ['dteDateCreate', 'intLength', 'intKN', 'txtPoleType', 'txtDurabilityClass', 'fltForestryRoyaltyRate', 'fltCHHRoyaltyRate', 'blnCurrent', 'dteDateExpires', 'txtComments', 'btnEditRoyaltyRate', 'btnRemoveRoyaltyRate'];

  updateRoyaltyRate: string = "";

  constructor(private royaltyRateService: RoyaltyRateService, private alertService: AlertService) { }

  ngOnInit(): void {

    this.columnBarPage = "Royalty Rates";

    this.loadedComponent = true;

    console.log("columnBarPage: " + this.columnBarPage);
    console.log("columnBarContainer: " + this.columnBarContainer);
    console.log("columnListId: " + this.columnListId);

  }


  updatedComponentColumnsEvent(columns: string[]) {

    this.displayedColumns = columns;

  }


  async editRoyaltyRate(royaltyRate: dtoRoyaltyRate) {
    this.editRoyaltyRateEvent.emit(royaltyRate);
  }

  removeRoyaltyRate(royaltyRateId: string) {

  }

  async updateRoyaltyReate(royaltyRate: dtoRoyaltyRate, val: number) {

    if (royaltyRate == null) {

      this.alertService.openSnackBarCustomDefaultPromise("No Royalty Rate", "No royalty rate to update");
      return;
    }

    royaltyRate.fltForestryRoyaltyRate = val;

    let rr: RoyaltyRate = await this.royaltyRateService.getRoyaltyRatePromise(royaltyRate.rowguid);
    rr.fltForestryRoyaltyRate = val;

    await this.royaltyRateService.updateRoyaltyRatePromise(rr);

    this.alertService.openSnackBarCustomDefaultPromise("Royalty Rate Updated", "The royalty rate has been update");

    this.updateRoyaltyRate = "";

    console.log(rr);

  }


}
