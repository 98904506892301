import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../../account/auth.service";
import { Client } from "../../_shared/business-objects/client.bo";
import { ContractCutSawLog } from "../../_shared/business-objects/contract-cut-saw-log.bo";
import { CustomerContact } from "../../_shared/business-objects/customer-contact.bo";
import { DeliveryDocket } from "../../_shared/business-objects/delivery-docket.bo";
import { dtoHaulingCompanyDriver } from "../../_shared/business-objects/dto-hauling-company-driver.bo";
import { dtoHaulingCompany } from "../../_shared/business-objects/dto-hauling-company.bo";
import { dtoKeyValue } from "../../_shared/business-objects/dto-int-key-value.bo";
import { dtoTPPoleInspectionItem } from "../../_shared/business-objects/dto-tppole-inspection-item.bo";
import { HaulingCompany } from "../../_shared/business-objects/hauling-company.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { AlertService } from "../../_shared/services/alert.service";
import { ClientService } from "../../_shared/services/client.service";
import { CustomerContactService } from "../../_shared/services/customer-contact.service";
import { DeliveryDocketService } from "../../_shared/services/delivery-docket.service";
import { dtoHaulingCompanyService } from "../../_shared/services/dto-hauling-company.service";
import { FormService } from "../../_shared/services/form.service";
import { HaulingCompanyService } from "../../_shared/services/hauling-company.service";
import { dtoHaulingCompanyDriverService } from "../../_shared/services/dto-hauling-company-driver.service";
import { SharedService } from "../../_shared/services/shared.service";
import { MobilePlantEquipment } from "../../_shared/business-objects/mobile-plant-equipment.bo";
import { MobilePlantCategory } from "../../_shared/business-objects/mobile-plant-category.bo";
import { MobilePlantCategoryService } from "../../_shared/services/mobile-plant-category.service";
import { MobilePlantEquipmentService } from "../../_shared/services/mobile-plant-equipment.service";
import { dtoOrdersDeliveryRate } from "../../_shared/business-objects/dto-orders-delivery-rate.bo";
import { DeliveryDocketCustomerDeliveryContact } from "../../_shared/business-objects/delivery-docket-customer-delivery-contact.bo";
import { DeliveryDocketCustomerDeliveryContactService } from "../../_shared/services/delivery-docket-customer-delivery-contact.service";
import { dtoDeliveryDocketCustomerDeliveryContact } from "../../_shared/business-objects/dto-delivery-docket-customer-delivery-contact.bo";
import { dtoCustomerContact } from "../../_shared/business-objects/dto-customer-contact.bo";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomerContactCreateDialogComponent } from "../../customer-contact/customer-contact-create-dialog/customer-contact-create-dialog.component";
import { CustomerContactUpdateDialogComponent } from "../../customer-contact/customer-contact-update-dialog/customer-contact-update-dialog.component";
import { dtoCustomerContactService } from "../../_shared/services/dto-customer-contact.service";
import { dtoCustomer } from "../../_shared/business-objects/dto-customer.bo";

@Component({
  selector: "app-delivery-docket-create",
  templateUrl: "./delivery-docket-create.component.html",
  styleUrls: ["./delivery-docket-create.component.css"],
  animations: [
    trigger("displaySpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryDocketCreateComponent implements OnInit {
  form: FormGroup;

  @Output() deliveryDocketCreated = new EventEmitter<
    [DeliveryDocket, string]
  >();
  @Output() deliveryDocketCancelled = new EventEmitter<boolean>();

  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public selectedCustomer: Client;
  public customers: Client[];
  public customersAll: Client[];
  public customerContacts: dtoCustomerContact[];
  public customerContactsAll: dtoCustomerContact[];
  public selectedCustomerContacts: CustomerContact[] = [];
  public haulers: dtoHaulingCompany[];
  public haulersAll: dtoHaulingCompany[];
  public haulingCompanyDrivers: dtoHaulingCompanyDriver[] = [];
  public haulingCompanyDriversAll: dtoHaulingCompanyDriver[] = [];

  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<
    Client[]
  >(1);
  public filteredCustomerContacts: ReplaySubject<CustomerContact[]> =
    new ReplaySubject<CustomerContact[]>(1);
  public filteredHaulers: ReplaySubject<HaulingCompany[]> = new ReplaySubject<
    HaulingCompany[]
  >(1);

  customerContactCreateDialogRef: MatDialogRef<CustomerContactCreateDialogComponent>;
  customerContactUpdateDialogRef: MatDialogRef<CustomerContactUpdateDialogComponent>;

  private _onDestroy = new Subject<void>();

  deliveryStatuses: dtoKeyValue[] = [];

  public firstNameMaxWidth: number;

  showSpinner: boolean;
  showUpdateButton: boolean;
  addressHoverId: string;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private customerService: ClientService,
    private dtoHaulerService: dtoHaulingCompanyService,
    private sharedService: SharedService,
    private customerContactService: CustomerContactService,
    private alertService: AlertService,
    private authService: AuthService,
    private deliveryDocketService: DeliveryDocketService,
    private dtoHaulingCompanyDriverService: dtoHaulingCompanyDriverService,
    private deliveryDocketCustomerDeliveryContactService: DeliveryDocketCustomerDeliveryContactService,
    private dialog: MatDialog,
    private dtoCustomerContactService: dtoCustomerContactService
  ) {
    this.deliveryStatuses = this.sharedService.getEnumObject2(
      DeliveryDocket.enDeliveryStatus,
      true
    );
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      guCustomerId: ["", Validators.required],
      guCustomerContactId: ["", Validators.required],
      guHaulerId: [""],
      guHaulingCompanyDriverId: [""],
      dteStatusDate: ["", Validators.required],
      dteExpectedLoadingDate: [""],
      dteExpectedDeliveryDate: [""],
      intStatus: [""],
      btnCreateInspectionItem: [""],
      txtComments: [""],
    });

    this.showSpinner = false;

    this.firstNameMaxWidth = 50;

    this.form.controls["intStatus"].setValue(0);

    this.loadData();
  }

  async loadData() {
    const datepipe: DatePipe = new DatePipe("en-AU");

    this.form.controls["dteStatusDate"].setValue(
      datepipe.transform(new Date(Date.now()), "yyyy-MM-dd")
    );

    this.getCustomers();
    this.getHaulers();
    this.getHaulingCompanyDrivers("");
    //this.getTrailers();
  }

  async customerChanged(customerId: string) {
    //this.resetOrders();
    console.log("customerId: ", customerId);

    if (customerId == null || customerId == "") {
      return;
    }

    this.selectedCustomer = await this.customerService.getClientPromise(
      customerId
    );

    this.getCustomerContacts(customerId);
  }

  async getCustomers() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    console.log("about to get customers: " + Date.now());

    let customers = await this.customerService.getClientParamArrayPromise(
      aParamList,
      false
    );

    console.log("got customers: " + Date.now());

    if (customers == null) {
      return;
    }

    this.customersAll = customers;

    this.customers = customers.slice(0, 50);

    console.log("loaded customers: " + Date.now());

    //this.filteredCustomers.next(this.customers);

    //this.customersFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterCustomers();
    //  });
  }

  async getCustomerContacts(customerId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (customerId == null) {
      return false;
    }

    console.log("customer contacts: ", customerId);

    aParam.push(new SQLParamArray("guCustomerId", customerId));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    let customerContacts: dtoCustomerContact[] =
      await this.dtoCustomerContactService.getdtoCustomerContactParamArrayPromise(
        aParamList
      );
    if (customerContacts == null) {
      return;
    }

    customerContacts.sort((a, b) => {
      return (
        (a.txtLastName > b.txtLastName ? 1 : -1) ||
        (a.txtName > b.txtName ? 1 : -1)
      );
    });

    this.customerContactsAll = customerContacts;

    this.customerContacts = customerContacts;
  }

  async addCustomerContact(matselect) {
    if (this.selectedCustomer == null) {
      await this.alertService.openSnackBarCustom(
        "No Customer",
        "There is no customer selected",
        "Ok",
        "",
        "center",
        "bottom",
        "",
        0,
        false
      );
      return;
    }

    console.log("matselect", matselect);

    if (this.customerContactCreateDialogRef != null) {
      this.customerContactCreateDialogRef.close();
    }

    this.customerContactCreateDialogRef = this.dialog.open(
      CustomerContactCreateDialogComponent,
      {
        height: "95vh",
        maxWidth: "90vw",
        data: {
          customerId: this.selectedCustomer.rowguid,
          customerName: this.selectedCustomer.txtName,
        },
      }
    );

    this.customerContactCreateDialogRef.backdropClick().subscribe(() => {
      this.customerContactCreateDialogRef.close();
    });

    this.customerContactCreateDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; customerContact: CustomerContact }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            this.selectedCustomerContacts.push(data.customerContact);

            let dtoCusContact: dtoCustomerContact =
              await this.dtoCustomerContactService.getdtoCustomerContactPromise(
                data.customerContact.rowguid
              );
            dtoCusContact.guCustomerDeliveryContactId = "tempvalue";

            this.customerContacts.splice(0, 0, dtoCusContact);
            this.customerContactsAll = this.customerContacts;

            //await this.getCustomerContacts(customerId);
            //setTimeout(async () => { await this.setCustomerContacts2() }, 500);

            this.alertService.openSnackBarDefault(
              "The Customer Contact has been added"
            );
          } else {
            // We have cancellled
          }
        }
      );
  }

  async saveCustomerContact(
    customerContactId: string,
    deliveryDocketId: string
  ) {
    let deliveryDocketCustomerContact: DeliveryDocketCustomerDeliveryContact =
      new DeliveryDocketCustomerDeliveryContact();
    deliveryDocketCustomerContact.guCustomerContactId = customerContactId;
    deliveryDocketCustomerContact.guDeliveryDocketId = deliveryDocketId;
    deliveryDocketCustomerContact.dteDateCreated =
      this.sharedService.currentDatePlusTZOffset();
    deliveryDocketCustomerContact =
      await this.deliveryDocketCustomerDeliveryContactService.createDeliveryDocketCustomerDeliveryContactPromise(
        deliveryDocketCustomerContact
      );

    if (deliveryDocketCustomerContact != null) {
      this.alertService.openSnackBarDefault(
        "The delivery contact has been added."
      );
    }
  }

  showEditButton() {
    this.showUpdateButton = !this.showUpdateButton;
  }

  async updateDeliveryContact2(
    customerContact: dtoCustomerContact,
    checked: boolean
  ) {
    // Dont want to do anything if we are in update mode.
    if (this.showUpdateButton == true) {
      return;
    }

    if (customerContact == null) {
      this.alertService.openSnackBarDefault(
        "There was a problem updating the customer contact - null option",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      return;
    }

    // Remove Customer Contact
    if (checked == false) {
      for (let i = this.selectedCustomerContacts.length - 1; i >= 0; i--) {
        if (
          this.selectedCustomerContacts[i].rowguid == customerContact.rowguid
        ) {
          this.selectedCustomerContacts.splice(i, 1);
          customerContact.guCustomerDeliveryContactId = "";
          console.log("removed contact");

          break;
        }
      }
    }

    // Add Customer Contact
    if (checked == true) {
      let custContact: CustomerContact =
        await this.customerContactService.getCustomerContactPromise(
          customerContact.rowguid
        );

      this.selectedCustomerContacts.push(custContact);

      customerContact.guCustomerDeliveryContactId = "tempvalue";

      console.log("added contact");
    }

    //this.getCustomerContacts(this.deliveryDocket.guCustomerId);
  }

  async updateCustomerContact(customerContactId: string) {
    console.log(customerContactId);
    if (customerContactId == null || customerContactId == "") {
      this.alertService.openSnackBarDefault("There is no contact to edit");
      return;
    }

    let customerContact: CustomerContact =
      await this.customerContactService.getCustomerContactPromise(
        customerContactId
      );

    if (customerContact == null) {
      this.alertService.openSnackBarDefault("There is no contact to edit");
      return;
    }

    //console.log(customerContact);

    if (this.customerContactUpdateDialogRef != null) {
      this.customerContactUpdateDialogRef.close();
    }

    this.customerContactUpdateDialogRef = this.dialog.open(
      CustomerContactUpdateDialogComponent,
      {
        height: "95vh",
        maxWidth: "90vw",
        data: {
          customerId: this.selectedCustomer.rowguid,
          customerName: this.selectedCustomer.txtName,
          selectedCustomerContact: customerContact,
        },
      }
    );

    this.customerContactUpdateDialogRef.backdropClick().subscribe(() => {
      this.customerContactUpdateDialogRef.close();
    });

    this.customerContactUpdateDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; customerContact: CustomerContact }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            this.selectedCustomerContacts.push(data.customerContact);
            let custContact: dtoCustomerContact =
              await this.dtoCustomerContactService.getdtoCustomerContactPromise(
                data.customerContact.rowguid
              );

            for (let i = 0; i <= this.customerContacts.length - 1; i++) {
              if (
                this.customerContacts[i].rowguid == data.customerContact.rowguid
              ) {
                this.customerContacts[i] = custContact;
                this.customerContacts[i].guCustomerDeliveryContactId =
                  "tempvalue";
                break;
              }
            }

            this.customerContacts.sort((a, b) => {
              return (
                (a.txtLastName > b.txtLastName ? 1 : -1) ||
                (a.txtName > b.txtName ? 1 : -1)
              );
            });

            //await this.saveCustomerContact(data.customerContact.rowguid);

            //await this.getCustomerContacts(this.deliveryDocket.guCustomerId);
            //setTimeout(async () => { await this.setCustomerContacts2() }, 500);

            this.showUpdateButton = false;

            this.alertService.openSnackBarDefault(
              "The Customer Contact has been updated"
            );
          } else {
            // We have cancellled
          }
        }
      );
  }

  returnContactOptions(e) {
    console.log(e);
    if (this.customerContacts == null) {
      return "";
    }

    //return "<div class='customer-contact-first-name'>" + this.customerContacts[index].txtName + "</div> <div class='customer-contact-last-name'>" + this.customerContacts[index].txtLastName + "</div>";
  }

  async getHaulers() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    let haulers =
      await this.dtoHaulerService.getdtoHaulingCompanyParamArrayPromise(
        paramArrayList
      );
    if (haulers == null) {
      return;
    }

    this.haulersAll = haulers;
    this.haulers = haulers;

    //First load filter by chh vechiles.
    this.filterHaulers("", true);
  }

  filterCustomers(customerFilter: string) {
    let customers: Client[] = this.customersAll;

    customers = customers.filter(
      (a) => a.txtName.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1
    );

    customers = customers.slice(0, 50);

    this.customers = customers;

    //if (this.selectedCustomer != null) {
    //  setTimeout(() => { this.scrollToHauler(), 500 });
    //}
  }

  async getHaulingCompanyDrivers(haulingCompanyId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    this.haulingCompanyDriversAll = [];

    console.log("haulingCompanyId", haulingCompanyId);

    if (haulingCompanyId != "") {
      aParam.push(new SQLParamArray("guHaulingCompanyId", haulingCompanyId));
      aParamList.push(aParam);

      this.haulingCompanyDriversAll =
        await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(
          aParamList
        );
      this.haulingCompanyDriversAll.sort((a, b) => {
        return a.txtFirstName > b.txtFirstName ? 1 : -1;
      });
    }

    let blankHaulingCompany: dtoHaulingCompanyDriver =
      new dtoHaulingCompanyDriver();
    blankHaulingCompany.rowguid = null;
    blankHaulingCompany.txtFirstName = "None";

    this.haulingCompanyDriversAll.splice(0, 0, blankHaulingCompany);

    this.haulingCompanyDrivers = this.haulingCompanyDriversAll;

    for (let i = 0; i <= this.haulingCompanyDrivers.length - 1; i++) {
      console.log(
        "blnDefaultDriver: ",
        this.haulingCompanyDrivers[i].blnDefaultDriver
      );
      if (this.haulingCompanyDrivers[i].blnDefaultDriver == true) {
        this.form.controls["guHaulingCompanyDriverId"].setValue([
          this.haulingCompanyDrivers[i].rowguid,
        ]);
        break;
      }
    }

    setTimeout(() => {
      return console.log(
        "guHaulingCompanyDriverId: ",
        this.form.controls["guHaulingCompanyDriverId"].value
      );
    }, 1500);
  }

  getHaulingCompanyDriver() {
    console.log(
      "getHaulingCompanyDriver: ",
      this.form.controls["guHaulingCompanyDriverId"].value
    );
  }

  //async getTrailers() {

  //  let aParamList: SQLParamArray[][] = [];
  //  let aParam: SQLParamArray[] = [];
  //  aParam.push(new SQLParamArray("txtName", "Trailer"));
  //  aParamList.push(aParam);

  //  let mobilePlantCategories: MobilePlantCategory[] = await this.mobilePlantCategoryService.getMobilePlantCategoryParamArrayPromise(aParamList);
  //  let categoryId: string = "";

  //  if (mobilePlantCategories != null && mobilePlantCategories.length > 0) {
  //    categoryId = mobilePlantCategories[0].rowguid;
  //  }

  //  aParamList = [];
  //  aParam = [];

  //  if (categoryId != "") {
  //    aParam.push(new SQLParamArray("guMobilePlantCategoryId", categoryId));
  //    aParamList.push(aParam);
  //  }

  //  let blankEquipment: MobilePlantEquipment = new MobilePlantEquipment();
  //  blankEquipment.rowguid = null;
  //  blankEquipment.txtName = "None";

  //  //console.log("EquipmentCategory: " + this.EquipmentCategory);

  //  this.mobilePlantEquipmentAll = [];
  //  this.mobilePlantEquipment = [];

  //  this.mobilePlantEquipmentAll = await this.mobilePlantEquipmentService.getMobilePlantEquipmentParamArrayPromise(aParamList);
  //  this.mobilePlantEquipmentAll.sort((a, b) => { return (a.intIdentifier - b.intIdentifier) });

  //  this.mobilePlantEquipment = this.mobilePlantEquipmentAll;
  //  //console.log("this.mobilePlantEquipment: ", this.mobilePlantEquipment);

  //  //this.mobilePlantEquipment = this.mobilePlantEquipment.sort((a, b) => { return (a.txtName < b.txtName ? 0 : 1) });
  //  //console.log("this.mobilePlantEquipment after sort: ", this.mobilePlantEquipment);
  //  //if (this.chhVehicles == true) {
  //  //}

  //  this.mobilePlantEquipment.splice(0, 0, blankEquipment);

  //}

  //filterEquipment(equipmentFilter: string) {
  //  this.mobilePlantEquipment = this.mobilePlantEquipmentAll;

  //  this.mobilePlantEquipment = this.mobilePlantEquipment.filter(a => a.txtName.toLowerCase().indexOf(equipmentFilter.toLowerCase()) > -1 || a.txtName == "None");

  //  //console.log("tableFilter: " + this.codeBuilderTables);

  //}

  //private filterCustomers() {

  //  if (!this.customers) {
  //    return;
  //  }

  //  // get the search keyword
  //  let search = this.customersFilterCtrl.value;
  //  if (!search) {
  //    this.filteredCustomers.next(this.customers);
  //    return;
  //  } else {
  //    search = search.toLowerCase();
  //  }

  //  // filter the ProductTypes
  //  this.filteredCustomers.next(
  //    this.customers.filter(c => c.txtName.toLowerCase().startsWith(search) == true)
  //  );
  //}

  filterCustomerContacts(customerFilter: string) {
    let customerContacts: dtoCustomerContact[] = this.customerContactsAll;

    customerContacts = customerContacts.filter(
      (a) =>
        a.txtName.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1 ||
        String(a.txtLastName)
          .toLowerCase()
          .indexOf(customerFilter.toLowerCase()) > -1
    );

    customerContacts = customerContacts.slice(0, 50);

    customerContacts.sort((a, b) => {
      return (
        (a.txtLastName > b.txtLastName ? 1 : -1) ||
        (a.txtName > b.txtName ? 1 : -1)
      );
    });

    this.customerContacts = customerContacts;
  }

  //private filterCustomerContacts() {

  //  if (!this.customerContacts) {
  //    return;
  //  }

  //  // get the search keyword
  //  let search = this.customerContactsFilterCtrl.value;
  //  if (!search) {
  //    this.filteredCustomerContacts.next(this.customerContacts);
  //    return;
  //  } else {
  //    search = search.toLowerCase();
  //  }

  //  // filter the ProductTypes
  //  this.filteredCustomerContacts.next(
  //    this.customerContacts.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
  //  );
  //}

  filterHaulers(haulerFilter: string, chhVehicles: boolean) {
    let haulers: dtoHaulingCompany[] = this.haulersAll;

    haulers = haulers.filter(
      (a) => a.txtName.toLowerCase().indexOf(haulerFilter.toLowerCase()) > -1
    );

    if (chhVehicles == true) {
      haulers = haulers.filter(
        (a) =>
          (a.blnCHHVehicle == chhVehicles &&
            a.guMobilePlantEquipmentId != null &&
            a.txtMobilePlantCategoryName.toLowerCase() == "truck") ||
          a.txtName == "None"
      );
    }
    haulers = haulers.slice(0, 50);

    this.haulers = haulers;
  }

  //private filterHaulers() {

  //  if (!this.haulers) {
  //    return;
  //  }

  //  // get the search keyword
  //  let search = this.haulersFilterCtrl.value;
  //  if (!search) {
  //    this.filteredHaulers.next(this.haulers);
  //    return;
  //  } else {
  //    search = search.toLowerCase();
  //  }

  //  // filter the ProductTypes
  //  this.filteredHaulers.next(
  //    this.haulers.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
  //  );
  //}

  async createDeliveryDocket(mode: string) {
    let customerId: string = this.form.controls["guCustomerId"].value[0];
    //let customerContactId: string[] = this.form.controls['guCustomerContactId'].value;
    let haulerId: string = this.form.controls["guHaulerId"].value;
    let driverId: string = this.form.controls["guHaulingCompanyDriverId"].value;
    let statusDate: string = this.form.controls["dteStatusDate"].value;
    let expectedLoadingDate: string =
      this.form.controls["dteExpectedLoadingDate"].value;
    let expectedDeliveryDate: string =
      this.form.controls["dteExpectedDeliveryDate"].value;
    let status: number = this.form.controls["intStatus"].value;
    let comments: string = this.form.controls["txtComments"].value;

    //console.log("guHaulingCompanyDriverId: ", this.form.controls['guHaulingCompanyDriverId'].value);

    if (customerId == null || customerId.toString() == "") {
      this.alertService.openSnackBar(
        "Please select a customer.",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      return;
    }

    if (
      this.selectedCustomerContacts == null ||
      this.selectedCustomerContacts.length == 0
    ) {
      this.alertService.openSnackBar(
        "Please select a customer contact.",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      return;
    }

    if (statusDate == null || statusDate.toString() == "") {
      this.alertService.openSnackBar(
        "Please select a status date.",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      return;
    }

    if (status == null || status < 0) {
      this.alertService.openSnackBar(
        "Please select a status.",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      return;
    }

    let deliveryDocket: DeliveryDocket = new DeliveryDocket();
    deliveryDocket.intSystem = 0;
    deliveryDocket.intDeliveryType = DeliveryDocket.enDeliveryType.Delivery;
    deliveryDocket.dteCreatedDate =
      this.sharedService.currentDatePlusTZOffset();
    deliveryDocket.guCustomerId = customerId;

    if (haulerId != null && haulerId[0] != "") {
      deliveryDocket.guHaulingId = haulerId[0];
    }

    console.log("driverId: ", driverId);

    deliveryDocket.guDriverId = null;
    if (driverId != null && driverId[0] != "") {
      deliveryDocket.guDriverId = driverId[0];
    }

    deliveryDocket.guEmployeeCreatedId =
      this.authService.getAppUserEmployeeId();

    console.log("expected loading date: ", expectedLoadingDate);
    deliveryDocket.dteExpectedLoadingDate = null;
    if (expectedLoadingDate != null && expectedLoadingDate != "") {
      deliveryDocket.dteExpectedLoadingDate =
        this.sharedService.convertDateAEST(new Date(expectedLoadingDate));
    }

    console.log("expected delivery date: ", expectedDeliveryDate);
    deliveryDocket.dteExpectedDeliveryDate = null;
    if (expectedDeliveryDate != null && expectedDeliveryDate != "") {
      deliveryDocket.dteExpectedDeliveryDate =
        this.sharedService.convertDateAEST(new Date(expectedDeliveryDate));
    }

    deliveryDocket.dteStatusDate = this.sharedService.convertDateAEST(
      new Date(statusDate)
    );
    deliveryDocket.intStatus = status;
    deliveryDocket.txtComments = comments;

    this.showSpinner = true;

    console.log(deliveryDocket);

    deliveryDocket =
      await this.deliveryDocketService.createDeliveryDocketPromise(
        deliveryDocket
      );

    if (deliveryDocket != null) {
      console.log(deliveryDocket.rowguid);
      deliveryDocket =
        await this.deliveryDocketService.getDeliveryDocketPromise(
          deliveryDocket.rowguid
        );

      // Add Customer Contacts
      for (let i = 0; i <= this.selectedCustomerContacts.length - 1; i++) {
        let deliveryDocketCustomerContact: DeliveryDocketCustomerDeliveryContact =
          new DeliveryDocketCustomerDeliveryContact();
        deliveryDocketCustomerContact.guCustomerContactId =
          this.selectedCustomerContacts[i].rowguid;
        deliveryDocketCustomerContact.guDeliveryDocketId =
          deliveryDocket.rowguid;
        deliveryDocketCustomerContact.dteDateCreated =
          this.sharedService.currentDatePlusTZOffset();
        deliveryDocketCustomerContact =
          await this.deliveryDocketCustomerDeliveryContactService.createDeliveryDocketCustomerDeliveryContactPromise(
            deliveryDocketCustomerContact
          );

        if (deliveryDocketCustomerContact != null) {
          this.alertService.openSnackBarDefault(
            "The delivery contact has been added."
          );
        }
      }

      this.alertService.openSnackBar(
        "The delivery docket has been created",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      this.showSpinner = false;
      this.deliveryDocketCreated.emit([deliveryDocket, mode]);
    }

    if (deliveryDocket == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError(
        "There was a problem creating the delivery docket",
        "Close",
        "center",
        "bottom",
        3000,
        true,
        "There was a problem creating the delivery docket"
      );
    }
  }

  cancelDeliveryDocket() {
    this.deliveryDocketCancelled.emit(true);
  }

  rowEnter(customerDeliveryAddressId: string) {
    this.addressHoverId = customerDeliveryAddressId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }
}
