import { Component, OnInit, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { dtoTaxInvoiceDailyTotalsService } from '../../../_shared/business-objects/dto-tax-invoice-daily-totals.service';
import { NavigationService } from '../../../_shared/services/navigation.service';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { dtoTaxInvoiceDailyTotals } from '../../../_shared/business-objects/dto-tax-invoice-daily-totals.bo';
import { ChhChartComponent } from '../chh-chart/chh-chart.component';
import { SaleLocation } from '../../../_shared/business-objects/sale-location.bo';
import { SaleLocationService } from '../../../_shared/services/sale-location.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSlider } from '@angular/material/slider';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../_shared/services/email.service';
import { EmailMessage } from '../../../_shared/app-objects/email-message';
import { AuthService } from '../../../account/auth.service';
import { dtoTaxInvoiceWeeklyTotals } from '../../../_shared/business-objects/dto-tax-invoice-weekly-totals.bo';
import { dtoTaxInvoiceMonthlyTotals } from '../../../_shared/business-objects/dto-tax-invoice-monthly-totals.bo';
import { dtoTaxInvoiceWeeklyTotalsService } from '../../../_shared/services/dto-tax-invoice-weekly-totals.service';
import { dtoTaxInvoiceMonthlyTotalsService } from '../../../_shared/services/dto-tax-invoice-monthly-totals.service';
import { dtoTaxInvoiceProductSummaryService } from '../../../_shared/services/dto-tax-invoice-product-summary.service';
import { dtoTaxInvoiceProductSummary } from '../../../_shared/business-objects/dto-tax-invoice-product-summary.bo';
import { DBFieldFunction, SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { PosProductSummaryComponent } from '../pos-product-summary/pos-product-summary.component';
import html2canvas from 'html2canvas';
import { DataURLFileAttachment } from '../../../_shared/app-objects/dataurl-file-attachment';
import { FileService } from '../../../_shared/services/app-services/file.service';
import { Body, EmailAddress, From, MSGraphEmailMessage } from '../../../_shared/app-objects/msgraph-email-message';
import { FileAttachment } from '../../../_shared/app-objects/file-attachment';
import { MicrosoftGraphFileAttachment } from '../../../_shared/app-objects/microsoft-graph-file-attachment';
import { dtoIntKeyValue, dtoStrKeyValue } from '../../../_shared/business-objects/dto-int-key-value.bo';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { SharedService } from '../../../_shared/services/shared.service';


export enum enChartReportType { DailySales, WeeklySales, MonthlySales, QuarterlySales, YearlySales }
export enum enSummaryReportType { Yesterday, LastWeek, LastMonth, LastQuarter, LastYear }


@Component({
  selector: 'app-pos-sales',
  templateUrl: './pos-sales.component.html',
  styleUrl: './pos-sales.component.scss',
  encapsulation: ViewEncapsulation.None
})

export class PosSalesComponent implements OnInit {

  @ViewChild("posSalesChart") posSalesChart: ChhChartComponent;
  @ViewChild("chartWidthSlider") chartWidthSlider: MatSlider;
  @ViewChild("productSummaryApp") productSummaryApp: PosProductSummaryComponent;
  @ViewChild("chartReportTypeList") chartReportTypeList: MatSelectionList;
  @ViewChild("productTypeSummaryReportTypeList") productTypeSummaryReportTypeList: MatSelectionList;

  taxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals[];
  taxInvoiceWeeklyTotals: dtoTaxInvoiceWeeklyTotals[];
  taxInvoiceMonthlyTotals: dtoTaxInvoiceMonthlyTotals[];
  chartTitle: string;
  chartDataSet: ChartDataset[];
  chartLabel: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartOptions: ChartOptions;

  enChartType = enChartReportType;
  enSummaryType = enSummaryReportType;

  reportType: number[] = [];
  reportTypeSummary: number[] = [];

  saleLocations: SaleLocation[];

  form: FormGroup;

  productSummary: dtoTaxInvoiceProductSummary[];
  chartReportTypes: dtoIntKeyValue[];
  productSummaryReportTypes: dtoIntKeyValue[];

  dateRange: { value: number, text: string }[];
  posProductSummaryDateRange: string;

  chartFromDate: string;
  chartToDate: string;
  productSummaryFromDate: string;
  productSummaryToDate: string;
  productSummaryYear: number;
  productSummaryMonth: number;
  productSummaryWeek: number;

  saleLocationId: string;
  saleLocationName: string;

  chartWidth: number;
  screenMode: boolean = true;
  chartBase64Array: string[] = [];


  constructor(private dtoTaxInvoiceDailyTotalsService: dtoTaxInvoiceDailyTotalsService, private dtoTaxInvoiceWeeklyTotalsService: dtoTaxInvoiceWeeklyTotalsService
    , private dtoTaxInvoiceMonthlyTotalsService: dtoTaxInvoiceMonthlyTotalsService
    , private navigationService: NavigationService, private saleLocationService: SaleLocationService, private fb: FormBuilder, private route: ActivatedRoute
    , private emailService: EmailService, private authService: AuthService, private dtoTaxInvoiceProductSummaryService: dtoTaxInvoiceProductSummaryService
    , private fileService: FileService, private sharedService: SharedService) {

    this.form = this.fb.group({
      dteDateFrom: [''],
      dteDateTo: [''],
      guSaleLocationId: [''],
      txtDateSelection: [4],
      chartReportTypeList: [''],
      productSummaryReportType:['']
    });

  }

  ngOnInit(): void {

    const now = new Date(2021, 3, 1); // specify the date you want to get the week of
    const onejan = new Date(now.getFullYear(), 0, 1); // January 1st of the same year
    const week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
    console.log("week", week);

    let weekNo = this.sharedService.getWeekNumber(new Date("2024-01-01"));
    console.log("weekNo", weekNo);


    this.chartReportTypes = [];
    this.chartReportTypes.push(new dtoIntKeyValue(this.enChartType.DailySales, "Daily Sales"));
    this.chartReportTypes.push(new dtoIntKeyValue(this.enChartType.WeeklySales, "Weekly Sales"));
    this.chartReportTypes.push(new dtoIntKeyValue(this.enChartType.MonthlySales, "Monthly Sales"));
    this.chartReportTypes.push(new dtoIntKeyValue(this.enChartType.QuarterlySales, "Quarterly Sales"));
    this.chartReportTypes.push(new dtoIntKeyValue(this.enChartType.YearlySales, "Yearly Sales"));

    // Default to weeklysales
    this.reportType.push(this.enChartType.QuarterlySales);
    this.form.controls['chartReportTypeList'].setValue([this.enChartType.QuarterlySales]);
    //console.log("this.form.controls - chartreporttype: ", this.form.controls['chartReportTypeList'].value);

    this.productSummaryReportTypes = [];
    this.productSummaryReportTypes.push(new dtoIntKeyValue(this.enSummaryType.Yesterday, "Yesterday"));
    this.productSummaryReportTypes.push(new dtoIntKeyValue(this.enSummaryType.LastWeek, "Last Week"));
    this.productSummaryReportTypes.push(new dtoIntKeyValue(this.enSummaryType.LastMonth, "Last Month"));
    this.productSummaryReportTypes.push(new dtoIntKeyValue(this.enSummaryType.LastQuarter, "Last Quarter"));
    this.productSummaryReportTypes.push(new dtoIntKeyValue(this.enSummaryType.LastYear, "Last Year"));

    this.reportTypeSummary.push(this.enSummaryType.LastQuarter);
    this.form.controls['productSummaryReportType'].setValue([this.enSummaryType.LastQuarter]);

    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        let displayMode: string = params["displayMode"];
        let email: string = params["email"];
        let fromDate: string = params["fromDate"];
        let toDate: string = params["toDate"];
        let saleLocationId: string = params["saleLocationId"];
        let chartReportType: number = params["chartreporttype"];
        let productSumFromDate: string = params["productsummaryfromdate"];
        let productSumToDate: string = params["productsummarytodate"];
        let productSumYear: string = params["productsummaryyear"];
        let productSumMonth: string = params["productsummarymonth"];
        let productSumWeek: string = params["productsummaryweek"];

        //console.log("chartreporttype: ", params["chartreporttype"]);
        //console.log("chartreporttypeArr: ", Array.isArray(params["chartreporttype"]));

        
        if (fromDate != null && fromDate != "" && Object.prototype.toString.call(new Date(fromDate)) === '[object Date]') {
          this.chartFromDate = fromDate;
          //console.log("fromDate: ", fromDate);
          this.form.controls['dteDateFrom'].setValue(new Date(fromDate));
        }

        if (toDate != null && toDate != "" && Object.prototype.toString.call(new Date(toDate)) === '[object Date]') {
          this.chartToDate = toDate;
          this.form.controls['dteDateTo'].setValue(new Date(toDate));
          //console.log("toDate: ", toDate);
        }

        if (productSumFromDate != null && productSumFromDate != "" && Object.prototype.toString.call(new Date(productSumFromDate)) === '[object Date]') {
          this.productSummaryFromDate = productSumFromDate;
          //console.log("productSumFromDate: ", productSumFromDate);
          
        }

        if (productSumToDate != null && productSumToDate != "" && Object.prototype.toString.call(new Date(productSumToDate)) === '[object Date]') {
          this.productSummaryToDate = productSumToDate;
          //console.log("productSumToDate: ", productSumToDate);
        }

        if (productSumYear != null && productSumYear != "") {
          this.productSummaryYear = Number(productSumYear);
          //console.log("productSumYear: ", productSumYear);
        }

        if (productSumMonth != null && productSumMonth != "") {
          this.productSummaryMonth = Number(productSumMonth);
          //console.log("productSumMonth: ", productSumMonth);
        }

        if (productSumWeek != null && productSumWeek != "") {
          this.productSummaryWeek = Number(productSumWeek);
          //console.log("productSumWeek: ", productSumWeek);
        }

        if ((displayMode != null && displayMode.toLowerCase() == "noscreen")) {
          this.screenMode = false;

          if (params["txtToken"] == null || params["txtToken"] == "") {
            // No token no go.
            return;
          }


          if ((chartReportType != null && (isNaN(chartReportType) == false || Array.isArray(chartReportType)))) {
            if (Array.isArray(chartReportType)) {
              this.reportType = chartReportType;
              this.reportTypeSummary = chartReportType
            }

            if (Array.isArray(chartReportType) == false) {
              this.reportType.push(chartReportType);
              this.reportTypeSummary.push(chartReportType);
           }
          }

          //console.log("this.reportType: ", this.reportType);
          //console.log("hidden: ", this.navBar.nativeElement.hidden);
          let emailReports: boolean = (email != null && email.toLowerCase() == "yes");

          await this.runTasks(emailReports);

          //setTimeout(async () => {
            //We email down in runTAsks
            //if ((email != null && email.toLowerCase() == "yes")) {
            //    await this.emailPOSGraphs();
            //}

          //}, 1000);


        }


      }

    });


    this.chartWidth = 1600;


    this.dateRange = [];
    this.dateRange.push({ value: 1, text: "1 Week" });
    this.dateRange.push({ value: 2, text: "2 Weeks" });
    this.dateRange.push({ value: 3, text: "3 Weeks" });
    this.dateRange.push({ value: 4, text: "4 Weeks" });
    this.dateRange.push({ value: 3, text: "3 Months" });
    this.dateRange.push({ value: 6, text: "6 Months" });
    this.dateRange.push({ value: 12, text: "12 Months" });

    //this.setSalesDate(this.form.controls['txtDateSelection'].value);

    //this.weeklyPOSSales(2024, '22222222-2222-2222-2222-222222222222');

    //this.reportType = "weeklysales";
    //this.runTasks();

  }



  ngAfterViewInit() {

    console.log("ngAfterViewInit");
    if (this.screenMode = true) {
      this.loadData();
    }

    
    //this.chartReportTypeList.selectedOptions.select(["dailysales"] as any);

    console.log();

    //setTimeout(() => {
    //  this.chartReportTypeList._value[0] = "dailysales";
    //}, 1500);

  }

  async testEmail() {
    let emailBody: string = "Test Email";

    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Test Email", emailBody, null, null, true);
    this.emailService.sendEmail(emailMessage);

  }


  async office365Login() {
    let isLoggedIn: boolean = await this.authService.isOffice365LoggedIn();

    console.log("isLoggedIn: ", isLoggedIn);

    let emailBody: string = "Test Office 365 Login\n\nIsLoggedIn  - " + isLoggedIn;

    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Test Office 365 Login", emailBody, null, null, true);
    this.emailService.sendEmail(emailMessage);

  }


  async loadData() {



    //setTimeout(async () => {
    //}, 1500);

    //if (String(this.chartFromDate) == "" && String(this.chartToDate) == "") {
    //  this.setSalesDate(4);
    //}

    //this.chartTitle = "Lawson Cresent POS Sales";
    //let saleLocationId: string = await this.navigationService.getLocationGuid("Coffs");
    //this.form.controls['guSaleLocationId'].setValue(saleLocationId);
    //this.getTaxInvoiceDailyTotals(this.chartFromDate, this.chartToDate, saleLocationId);

  }

  reportTypeChange(val) {

    this.reportType = [];
    this.reportTypeSummary = [];

    this.reportType.push(val);
    this.reportTypeSummary.push(val)

    this.form.controls['productSummaryReportType'].setValue([val]);
    
  }


  async testTasks() {
    //this.reportType = "dailysales";
    //this.reportType = "weeklysales";

    console.log("reporttype: ", this.reportType);

    await this.runTasks(false);

  }


  async runTasks(email: boolean) {
    return new Promise<boolean>(async resolve => {
      await this.getSaleLocations();
      await this.runPOSGraphs();
      await this.runPOSSummary();



      //setTimeout(() => {
      //  this.renderHTMLToCanvas();
      //  console.log("renderedSummary");
      //  this.productSummaryApp.printMode = true;
      //}, 500);

      if (email == true) {
        await this.emailPOSGraphs();
      }
      resolve(true);
    });

  }

  renderHTMLToCanvas() {
    return new Promise<boolean>(resolve => {
      let productSummaryApp = document.getElementById("productSummaryApp");
      let productSummaryCanvas = document.getElementById("productSummaryCanvas") as HTMLCanvasElement;
      html2canvas(document.querySelector("#productSummaryApp"), { scale: 1 }).then(async canvas => {
        productSummaryCanvas.appendChild(canvas)
        console.log("canvas.toDataURL()");
        this.chartBase64Array.push(canvas.toDataURL());
        //await this.emailPOSGraphs();
        resolve(true);
      });

    });
  }

  async runSummary() {
    await this.getSaleLocations();
    await this.runPOSSummary();
  }

  async viewSummary() {

    let dateRangeHeadingPrefix: string = "";

    if (this.reportTypeSummary[0] == this.enSummaryType.LastWeek) {
      let lastWeek: Date = this.sharedService.lastWeeksDate(new Date());
      console.log("lastweeksdate: ", lastWeek);

      this.productSummaryFromDate = this.sharedService.startOfWeek(lastWeek).toString();
      this.productSummaryToDate = this.sharedService.endOfWeek(lastWeek).toString();
      console.log("this.productSummaryFromDate", this.productSummaryFromDate);
      console.log("this.productSummaryToDate", this.productSummaryToDate);

    }

    if (this.reportTypeSummary[0] == this.enSummaryType.LastMonth) {

      console.log("startOfLastMonth", this.sharedService.startOfLastMonth(new Date()));
      console.log("endOfLastMonth", this.sharedService.endOfLastMonth(new Date()));

      this.productSummaryFromDate = this.sharedService.dateToISOLocal(this.sharedService.startOfLastMonth(new Date()));
      this.productSummaryToDate = this.sharedService.dateToISOLocal(this.sharedService.endOfLastMonth(new Date()));
      console.log("this.productSummaryFromDate", this.productSummaryFromDate);
      console.log("this.productSummaryToDate", this.productSummaryToDate);

    }

    if (this.reportTypeSummary[0] == this.enSummaryType.LastQuarter) {

      dateRangeHeadingPrefix = "Q"
      let fyQuarter: number = this.sharedService.FYQuarter(new Date()) - 1;
      if (fyQuarter == 0) {
        fyQuarter = 4
      }
      dateRangeHeadingPrefix += fyQuarter.toString();

      console.log("start: " + this.sharedService.lastFYQuarterMonthStart(new Date()) + "  end: " + this.sharedService.lastFYQuarterMonthEnd(new Date()));

      this.productSummaryFromDate = this.sharedService.dateToISOLocal(this.sharedService.lastFYQuarterMonthStart(new Date("2024-01-01")))
      this.productSummaryToDate = this.sharedService.dateToISOLocal(this.sharedService.lastFYQuarterMonthEnd(new Date("2024-01-01")));
      console.log("this.productSummaryFromDate", this.productSummaryFromDate);
      console.log("this.productSummaryToDate", this.productSummaryToDate);

    }

    let saleLocation: SaleLocation[] = this.saleLocations.map((s) => { return s.rowguid == this.form.controls['guSaleLocationId'].value ? s : null });

    this.saleLocationName = saleLocation[0].txtLocationName;

    if (this.chartFromDate != this.chartToDate) {
      this.posProductSummaryDateRange = "From: ";
    } else {
      this.posProductSummaryDateRange = "Date: ";
    }

    this.posProductSummaryDateRange += new Date(this.productSummaryFromDate).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });

    if (this.chartFromDate != this.chartToDate) {
      this.posProductSummaryDateRange += "  -  To: " + new Date(this.productSummaryToDate).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
    }

    if (dateRangeHeadingPrefix != "") {
      this.posProductSummaryDateRange = dateRangeHeadingPrefix + " - " + this.posProductSummaryDateRange;
    }
    this.productSummary = await this.dtoTaxInvoiceProductSummaryService.getdtoTaxInvoiceProductSummaryByDate(this.productSummaryFromDate, this.productSummaryToDate, this.form.controls['guSaleLocationId'].value);
    await this.timeWait(1000);
    this.productSummaryApp.calcTotal();

  }


  async runPOSSummary() {

    return new Promise<boolean>(async resolve => {

      if (this.saleLocations != null) {


        for (let i = 0; i <= this.saleLocations.length - 1; i++) {

          this.productSummary = null; // have to do this to refresh pos summary.

          this.saleLocationName = this.saleLocations[i].txtLocationName;



          //console.log("this.posProductSummaryDateRange", this.posProductSummaryDateRange);

          //this.productSummary = await this.dtoTaxInvoiceProductSummaryService.getdtoTaxInvoiceProductSummaryByDate(this.productSummaryFromDate, this.productSummaryToDate, this.saleLocations[i].rowguid);
          for (let r = 0; r <= this.reportTypeSummary.length - 1; r++) {


            if (this.reportTypeSummary[r] == this.enSummaryType.LastWeek) {
              this.productSummary = await this.dtoTaxInvoiceProductSummaryService.getdtoTaxInvoiceProductSummaryByWeekNumber(this.productSummaryYear, this.productSummaryWeek, this.saleLocations[i].rowguid);
              await this.timeWait(1000);
             if (this.productSummary != null && this.productSummary.length > 0) {
               this.productSummaryFromDate = new Date(this.productSummary[0].dteWeekStart).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
               this.productSummaryToDate = new Date(this.productSummary[0].dteWeekEnd).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
               this.loadProductSummaryDateRange();
               await this.loadSummary();
              }
            }

            if (this.reportTypeSummary[r] == this.enSummaryType.LastMonth) {
              this.productSummary = await this.dtoTaxInvoiceProductSummaryService.getdtoTaxInvoiceProductSummaryByMonthNumber(this.productSummaryYear, this.productSummaryMonth, this.saleLocations[i].rowguid);
              console.log("this.productSummary", this.productSummary);
              await this.timeWait(1000);
              if (this.productSummary != null && this.productSummary.length > 0) {
                this.productSummaryFromDate = new Date(this.productSummary[0].dteMonthStart).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
                this.productSummaryToDate = new Date(this.productSummary[0].dteMonthEnd).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
                this.loadProductSummaryDateRange();
                await this.loadSummary();
              }

            }

            if (this.reportTypeSummary[r] == this.enSummaryType.LastQuarter) {
            }


            await this.timeWait(2000);


          }




        }

        resolve(true);
      }

    });


  }

  async loadSummary() {

    return new Promise<boolean>(async resolve => {
      this.productSummaryApp.calcTotal();
      await this.timeWait(2000);
      //console.log("this.productSummaryApp - loadSummary", this.productSummaryApp);

      let base64: string = await this.productSummaryApp.createHTMLCanvas();
      this.chartBase64Array.push(base64);
      //console.log("summary", base64);
      let productSummaryCanvas = document.getElementById("productSummaryCanvas") as HTMLCanvasElement;
      let img = document.createElement('img');
      img.src = base64;
      productSummaryCanvas.appendChild(img);

      await this.timeWait(1000);

      //console.log("this.chartBase64Array.length", this.chartBase64Array.length);
      resolve(true);

    });
  }

  async loadProductSummaryDateRange() {
    if (this.productSummaryFromDate != this.productSummaryToDate) {
      this.posProductSummaryDateRange = "From: ";
    } else {
      this.posProductSummaryDateRange = "Date: ";
    }

    this.posProductSummaryDateRange += this.productSummaryFromDate;

    if (this.productSummaryFromDate != this.productSummaryToDate) {
      this.posProductSummaryDateRange = this.posProductSummaryDateRange + "  -  To: " + this.productSummaryToDate;
    }

  }


  async posProductSummaryLoaded() {
    //this.productSummaryApp.printMode = false;

    console.log("component loaded");

    //await this.renderHTMLToCanvas();

  }

  async runPOSGraphs() {

    return new Promise<boolean>(async resolve => {
      //console.log("this.posSalesChart: ", this.posSalesChart);

      //console.log("this.saleLocations: ", this.saleLocations);
      if (this.saleLocations != null) {

        for (let i = 0; i <= this.saleLocations.length - 1; i++) {


          this.taxInvoiceDailyTotals = null; // have to do this to refresh graph.
          this.taxInvoiceWeeklyTotals = null;
          this.taxInvoiceMonthlyTotals = null;
          //console.log("this.saleLocations: ", this.saleLocations[i].txtLocationName);

          for (let r = 0; r <= this.reportType.length - 1; r++) {
            if (this.reportType[r] == this.enChartType.DailySales) {
              await this.loadChart(this.saleLocations[i].txtLocationName + " POS Daily Sales", this.chartFromDate, this.chartToDate, this.saleLocations[i].rowguid);
            }

            if (this.reportType[r] == this.enChartType.WeeklySales) {
              await this.weeklyPOSSales(this.saleLocations[i].txtLocationName + " POS Weekly Sales", new Date().getFullYear(), this.saleLocations[i].rowguid);
              await this.timeWait(1500);

              let base64: string = await this.getChartBase64();
              this.chartBase64Array.push(base64);

            }

            if (this.reportType[r] == this.enChartType.MonthlySales) {
              await this.monthlyPOSSales(this.saleLocations[i].txtLocationName + " POS Monthly Sales", new Date().getFullYear(), this.saleLocations[i].rowguid);
              await this.timeWait(1500);
              let base64: string = await this.getChartBase64();
              this.chartBase64Array.push(base64);
           }
          }


        }

        resolve(true);

        //console.log("chartsBase64: ", this.chartBase64Array);
      }

      //console.log("runPOSGraphs");
    });

  }

  async loadChart(chartTitle: string, fromDate: string, toDate: string, saleLocationId: string) {
    return new Promise<boolean>(async resolve => {
      this.chartTitle = chartTitle;

      //console.log("chartTitle", this.chartTitle);

      await this.getTaxInvoiceDailyTotals(fromDate, toDate, saleLocationId);
      await this.timeWait(500);
      let base64: string = await this.getChartBase64();
      this.chartBase64Array.push(base64);
      console.log(chartTitle, base64);
      resolve(true);
    });

  }


  async timeWait(time: number) {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        //console.log("in timeWait")
        resolve(true);
      }, time);
    });
  }


  async emailPOSGraphs() {

    let emailBody: string = "<h1>POS Graphs</h1></br><br><br><br>";
    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "POS Graphs", "", null, null, true);
    let emailMessage2: MSGraphEmailMessage = new MSGraphEmailMessage("chhservice@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "garry@coffshardwoods.com.au", "", "POS Sales", "", []);

    //console.log(JSON.stringify(emailMessage2));


    //this.emailService.sendEmailOnBehalfOf(emailMessage2, "9702481a-0b9b-44ff-9cce-e3ef0d7bb844");


    //console.log("this.chartBase64Array.length", this.chartBase64Array.length);

    if (this.chartBase64Array.length > 0) {

      let emailBody: string = "<h1>POS Graphs</h1></br><br><br><br>";
      let emailMessage: EmailMessage = new EmailMessage("chhservice@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "POS Graphs", "", null, null, true);

      //emailBody += "<p><img src=\"" + await this.getChartBase64() + "\"></p><br><br>";
      emailMessage.DataURLAttachments = [];
      let fileExt: string = "";
      let fileAttachments: FileAttachment[] = [];

      for (let i = 0; i <= this.chartBase64Array.length - 1; i++) {
        ////emailBody += "<p><img src=" + this.chartBase64Array[i].toString() + "></p><br><br>";
        fileExt = this.fileService.getBase64ContentExtensionFileType(this.chartBase64Array[i])
        emailBody += "<p><img width=\"100%\" height=\"100%\"  src=cid:File" + (i + 1).toString() + "." + fileExt + "></p><br><br>";
        //emailMessage.DataURLAttachments.push(new DataURLFileAttachment("File" + (i + 1).toString() + "." + fileExt, "", this.fileService.getBase64ContentType(this.chartBase64Array[i]), this.fileService.base64WithoutPrefix(this.chartBase64Array[i]), true));

        ////var base64str = this.chartBase64Array[i].substring(this.chartBase64Array[i].indexOf(',') + 1)
        ////var decoded = atob(base64str);

        //console.log("FileSize: ", this.chartBase64Array[i]);

        emailMessage2.message.attachments.push(new MicrosoftGraphFileAttachment("File" + (i + 1).toString() + "." + fileExt, this.fileService.getBase64ContentType(this.chartBase64Array[i]), this.fileService.base64WithoutPrefix(this.chartBase64Array[i])));
     }

      emailMessage2.message.body = new Body(emailBody);

      console.log(emailMessage2);
      //console.log(this.posSalesChart.chartBase64());

      //this.emailService.sendEmail2(emailMessage);

      let sent = this.emailService.sendEmailOnBehalfOf(emailMessage2, "9702481a-0b9b-44ff-9cce-e3ef0d7bb844");

      //console.log("sent", sent);

      let chartBase64Div: HTMLDivElement = document.getElementById("chartBase64") as HTMLDivElement;
      chartBase64Div.innerHTML = this.chartBase64Array[ this.chartBase64Array.length - 1];

    }

  }

  async getSaleLocations() {
    return new Promise<boolean>(resolve => {

      this.saleLocationService.getAllSaleLocations().subscribe((saleLocations) => {
        this.saleLocations = saleLocations;
        console.log("sale locations");
        resolve(true);
      })
    });

  }

  async getTaxInvoiceDailyTotals(dateFrom: string, dateTo: string, saleLocationId: string) {

    return new Promise<boolean>(async resolve => {
      this.taxInvoiceDailyTotals = await this.dtoTaxInvoiceDailyTotalsService.getdtoTaxInvoiceDailyTotalsByDates(dateFrom, dateTo, saleLocationId);

      console.log("taxInvoiceDailyTotals", this.taxInvoiceDailyTotals);

      this.chartOptions = {
        responsive: true,
        layout: {
          padding: 50
        },
        plugins: {
          legend: {
            display: this.chartLegend
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 1,
            font: {
              size: 15,
              weight: 'bold'
            },
            formatter: function (value, context) {
              return '$' + value;
            }
          },
          title: {
            display: true,
            text: this.chartTitle,
            font: {
              size: 40,
              weight: 'bold'
            }
          }
        },
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return '$' + value;
              },
              font: {
                size: 15
              }
            }
          }
        }
      }

      this.chartDataSet = [{ data: this.taxInvoiceDailyTotals.map((a) => { return Math.round(a.fltTotalPriceExGST) }), label: 'POS Daily Totals', tension: 0.2 }];

      this.chartLabel = this.taxInvoiceDailyTotals.map((a) => { return new Date(a.dteDateCreated).toLocaleDateString('en-AU', { weekday: 'long' }) + '\n' + new Date(a.dteDateCreated).toLocaleDateString() });
      this.chartType = "line";
      this.chartLegend = true;

      //console.log("chartDataSet", this.chartDataSet);

      //this.posSalesChart.loadChart();

      resolve(true);
    });

  }

  async weeklyPOSSales(chartTitle: string, year: number, saleLocationId: string) {
    return new Promise<boolean>(async resolve => {
      this.taxInvoiceWeeklyTotals = await this.dtoTaxInvoiceWeeklyTotalsService.getdtoTaxInvoiceWeeklyTotalsByDates(year, saleLocationId);
      this.chartTitle = chartTitle;
      let dataSet: ChartDataset[] = [{ data: this.taxInvoiceWeeklyTotals.map((a) => { return Math.round(a.fltTotalPriceExGST) }), label: 'POS Weekly Totals', tension: 0.2 }];
      let chartLabel: string[] = this.taxInvoiceWeeklyTotals.map((a) => { return new Date(a.dteWeekEnd).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short'}) });

      //console.log(this.taxInvoiceWeeklyTotals);

      await this.setChartData(dataSet, chartLabel);
      await this.timeWait(500);
      resolve(true);

    });
  }

  async monthlyPOSSales(chartTitle: string, year: number, saleLocationId: string) {
    return new Promise<boolean>(async resolve => {
      this.taxInvoiceMonthlyTotals = await this.dtoTaxInvoiceMonthlyTotalsService.getdtoTaxInvoiceMonthlyTotalsByDates(year, saleLocationId);
      this.taxInvoiceMonthlyTotals.sort((a, b) => { return a.intMonth > b.intMonth ? 1 : -1 });
      this.chartTitle = chartTitle;
      let dataSet: ChartDataset[] = [{ data: this.taxInvoiceMonthlyTotals.map((a) => { return Math.round(a.fltTotalPriceExGST) }), label: 'POS Monthly Totals', tension: 0.2 }];

      let chartLabel: string[] = this.taxInvoiceMonthlyTotals.map((a) => {

        var date = new Date();
        //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        //console.log("Year: " + a.intYear + " -  Month: " + a.intMonth);

        var lastDay = new Date(a.intYear, a.intMonth, 0);
        //console.log("lastDay: " + lastDay);
        let label: string = "";
        let day: number;
        if (lastDay > date) {
          day = Number(new Date().toLocaleDateString('en-AU', { day: 'numeric' }));
          label = day.toString() + this.sharedService.nth(day) + " ";
        }
        label += new Date(a.intYear + '-' + a.intMonth + '-01').toLocaleDateString('en-AU', { month: 'long' });
        return label
      });


      //console.log(this.taxInvoiceMonthlyTotals);

      await this.setChartData(dataSet, chartLabel);
      resolve(true);

    });
  }



  async setChartData(chartDataSet: ChartDataset[], chartLabel: string[]) {

    return new Promise<boolean>(resolve => {
      this.chartOptions = {
        responsive: true,
        layout: {
          padding: 50
        },
        plugins: {
          legend: {
            display: this.chartLegend
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 1,
            font: {
              size: 15,
              weight: 'bold'
            },
            formatter: function (value, context) {
              return '$' + value;
            }
          },
          title: {
            display: true,
            text: this.chartTitle,
            font: {
              size: 40,
              weight: 'bold'
            }
          }
        },
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return '$' + value;
              },
              font: {
                size: 15
              }
            }
          }
        }
      }

      this.chartDataSet = chartDataSet;
      this.chartLabel = chartLabel;
      this.chartType = "line";
      this.chartLegend = true;

      //console.log("chartDataSet", this.chartDataSet);

      //this.posSalesChart.loadChart();
      resolve(true);

    });

  }

  async updateChart() {

    if (this.saleLocations == null) {
      await this.getSaleLocations();
      this.form.controls['guSaleLocationId'].setValue(this.saleLocations[0].rowguid);
      console.log("getSaleLocations");

    }
    console.log("update chart");

    this.taxInvoiceDailyTotals = null;
    this.taxInvoiceWeeklyTotals = null;
    this.taxInvoiceMonthlyTotals = null;

    let dateFrom: string = this.form.controls['dteDateFrom'].value;
    let dateTo: string = this.form.controls['dteDateTo'].value;
    let saleLocationId: string = this.form.controls['guSaleLocationId'].value;

    console.log("dateFrom", dateFrom);
    console.log("dateTo", dateTo);
    console.log("saleLocationId", saleLocationId);

    //this.getTaxInvoiceDailyTotals(dateFrom, dateTo, saleLocationId);

    let saleLocation: SaleLocation[] = this.saleLocations.map((s) => { return s.rowguid == saleLocationId ? s : null });
    console.log("this.reportType", this.reportType);

    if (this.reportType[0] == this.enChartType.DailySales) {
      await this.loadChart(saleLocation[0].txtLocationName + " POS Daily Sales - " + new Date().getFullYear().toString(), this.chartFromDate, this.chartToDate, saleLocation[0].rowguid);
    }

    if (this.reportType[0] == this.enChartType.WeeklySales) {
      await this.weeklyPOSSales(saleLocation[0].txtLocationName + " POS Weekly Sales - " + new Date().getFullYear().toString(), new Date().getFullYear(), saleLocation[0].rowguid);
    }

    if (this.reportType[0] == this.enChartType.MonthlySales) {
      await this.monthlyPOSSales(saleLocation[0].txtLocationName + " POS Monthly Sales - " + new Date().getFullYear().toString(), new Date().getFullYear(), saleLocation[0].rowguid);
    }

  }

  refreshChart() {

    let tempTotals = this.taxInvoiceDailyTotals;

    this.taxInvoiceDailyTotals = null;

    setTimeout(() => { this.taxInvoiceDailyTotals = tempTotals }, 500);


  }


  async setSalesDate(frequencyIndex: number) {

    console.log("freq: ", this.dateRange[frequencyIndex]);


    var someDate = new Date();
    let numberOfDaysToAdd: number;

    if (this.dateRange[frequencyIndex].text.toLowerCase().indexOf("weeks") > -1) {
      numberOfDaysToAdd = this.dateRange[frequencyIndex].value * 7;
      var result = someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      this.form.controls['dteDateFrom'].setValue(new Date(result));
      this.form.controls['dteDateTo'].setValue(new Date(Date.now()));

      this.chartFromDate = this.form.controls['dteDateFrom'].value;
      this.chartToDate = this.form.controls['dteDateTo'].value;

    }

    if (this.dateRange[frequencyIndex].text.toLowerCase().indexOf("month") > -1) {
      numberOfDaysToAdd = (this.dateRange[frequencyIndex].value * 4) * 7;
      var result = someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      this.form.controls['dteDateFrom'].setValue(new Date(result));
      this.form.controls['dteDateTo'].setValue(new Date(Date.now()));

      this.chartFromDate = this.form.controls['dteDateFrom'].value;
      this.chartToDate = this.form.controls['dteDateTo'].value;
    }

    //console.log(new Date(result))

    this.updateChart();


  }

  formatLabel(value: number): string {
    //console.log("value: ", value);

    if (value >= 1000) {
    }

    return Math.round(value) + 'px';

    return `${value}`;
  }

  setChartWidth(e: Event) {

    console.log("e", e.target);

    let slider: HTMLInputElement = e.target as HTMLInputElement;

    console.log("input", slider.value);

    this.chartWidth = Number(slider.value);

    this.refreshChart();

  }


  setChartTitle() {

    //let taxInvoiceDailyTotals = this.taxInvoiceDailyTotals;
    //this.taxInvoiceDailyTotals = null;
     let saleLocationId: string = this.form.controls['guSaleLocationId'].value;
    for (let i = 0; i <= this.saleLocations.length - 1; i++) {
      if (saleLocationId == this.saleLocations[i].rowguid) {
        this.chartTitle = this.saleLocations[i].txtLocationName + " POS Sales";

        console.log("freq: ", this.form.controls['guSaleLocationId'].value);
        break;
      }
    }

    this.updateChart();

    //setTimeout(() => { this.taxInvoiceDailyTotals = taxInvoiceDailyTotals }, 500);


  }

  downloadChart(){
    let chartBase64 = this.posSalesChart.downloadChart();

    console.log("chartBase64", chartBase64);

  }

  getChartBase64() {
    return this.posSalesChart.chartBase64();
  }

  async emailChart() {

    let emailBody: string = "Test Graph Email\n\nn";
    emailBody += "<p><img src=\"" + await this.getChartBase64() + "\"></p>";

    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Test Graph Email", emailBody, null, null, true);
    //console.log(JSON.stringify(emailMessage));

    this.emailService.sendEmail(emailMessage);

  }

}
