import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
  query,
  animateChild,
} from "@angular/animations";
import { identifierName } from "@angular/compiler";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatOption, MatOptionSelectionChange } from "@angular/material/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatTooltip } from "@angular/material/tooltip";
import jsPDF from "jspdf";
import { MoulderDialogComponent } from "../../moulder/moulder-dialog/moulder-dialog.component";
import { OrderItemBarcodeStickerComponent } from "../../report-centre/reports/order-item-barcode-sticker/order-item-barcode-sticker.component";
import { PrintByteArray } from "../../_shared/app-objects/PrintByteArray";
import { BoardTallyRecordMachineRun } from "../../_shared/business-objects/board-tally-record-machine-run.bo";
import { BoardTallyRecordMoulder } from "../../_shared/business-objects/board-tally-record-moulder.bo";
import { BoardTallyRecordOrderItem } from "../../_shared/business-objects/board-tally-record-order-item.bo";
import { ContractCutSawLog } from "../../_shared/business-objects/contract-cut-saw-log.bo";
import { dtoBoardTallyRecordOrderItem } from "../../_shared/business-objects/dto-board-tally-record-order-item.bo";
import {
  dtoKeyValue,
  dtoKeyValueBarcode,
} from "../../_shared/business-objects/dto-int-key-value.bo";
import { dtoMachineRun } from "../../_shared/business-objects/dto-machine-run.bo";
import { dtoOrderItem } from "../../_shared/business-objects/dto-order-item.bo";
import { dtoStockItem } from "../../_shared/business-objects/dto-stock-item.bo";
import { MachineRun } from "../../_shared/business-objects/machine-run.bo";
import { Moulder } from "../../_shared/business-objects/moulder.bo";
import { OrderItem } from "../../_shared/business-objects/order-item.bo";
import { Printer } from "../../_shared/business-objects/printer.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { StockItemContainer } from "../../_shared/business-objects/stock-item-container";
import { AlertService } from "../../_shared/services/alert.service";
import { PrintService } from "../../_shared/services/app-services/print.service";
import { dtoOrderItemService } from "../../_shared/services/dto-order-item.service";
import { dtoStockItemService } from "../../_shared/services/dto-stock-item.service";
import { MachineRunService } from "../../_shared/services/machine-run.service";
import { MoulderService } from "../../_shared/services/moulder.service";
import { PrinterService } from "../../_shared/services/printer.service";
import { BpFloorMachineRunDialogComponent } from "../bp-floor-machine-run-dialog/bp-floor-machine-run-dialog.component";
import { StockItemMultiplesComponent } from "../stock-item-multiples/stock-item-multiples.component";

@Component({
  selector: "app-bp-floor-order-item-list",
  templateUrl: "./bp-floor-order-item-list.component.html",
  styleUrls: ["./bp-floor-order-item-list.component.css"],
  animations: [
    trigger("displaySpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayTick", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.9s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayCancel", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.2s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayMachineRunSpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.3s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayMachineRunTick", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.9s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("displayMachineRunCancel", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.1s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.2s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", [
        animate("425ms cubic-bezier(0.4, 0.0, 0.2, 1)"), //225
        query("@*", animateChild(), { optional: true }),
      ]),
    ]),
  ],
})
export class BpFloorOrderItemListComponent implements OnInit {
  @ViewChild("tblOrderItems") tblOrderItems: MatTable<dtoOrderItem>;
  @ViewChild("spinner") spinner: HTMLDivElement;
  @ViewChildren("addItem") addItems: QueryList<MatCheckbox>;
  //@ViewChildren('guMoulderId') moulderSelects: QueryList<MatSelect>;
  @ViewChildren("txtQuantity") txtQuantity: QueryList<MatInput>;
  @ViewChildren("btnAddToPack") btnAddToPack: QueryList<MatInput>;
  @ViewChildren("moulderList") moulderList: QueryList<MatInput>;
  @ViewChildren("machineRunList") machineRunList: QueryList<MatInput>;

  @Input() datasource: MatTableDataSource<dtoOrderItem>;
  @Input() columnListId: string;
  @Input() columnBarContainer: string;
  @Input() blankStockItemRef: dtoStockItem;
  @Output() selectedOrderItemsEvent = new EventEmitter<dtoOrderItem[]>();
  @Output() addStockItemEvent = new EventEmitter<StockItemContainer[]>();

  //displayedOrderItems: string[] = ['intItemNo', 'txtCustomerName', 'txtOrderNo', 'txtProductTypeName', 'txtProfileName', 'fltWidth', 'fltThickness', 'txtLengthKN', 'fltLength', 'fltDiameter', 'txtSpecies', 'txtGradeName', 'txtTreatmentType', 'txtTreatmentHazardLevel', 'txtStockCode', 'fltQuantity', 'fltQtyBal', 'intFinalQuantity', 'txtQuantityUOM', 'txtAddress', 'txtComments', 'fltActualM3', 'fltActualTonne', 'btnEditQuantity', 'btnPrintBarcodeSticker'];
  displayedOrderItems: string[] = [
    "intItemNo",
    "txtProductTypeName",
    "txtProfileName",
    "fltWidth",
    "fltThickness",
    "fltLength",
    "fltStockItemLength",
    "txtSpecies",
    "txtGradeName",
    "txtStockKeepingUnit",
    "fltQtyTotal",
    "fltQtyBal",
    "fltQuantity",
    "fltQtyAdded",
    "txtQuantityUOM",
    "intMachineRun",
    "btnGetMachineRun",
    "guMoulderId",
    "btnGetMoulder",
    "btnNoMoulder",
    "btnCopyAbove",
    "btnAddMultipleLength",
    "chkAddToPack",
    "btnViewStockItems",
  ];

  showSpinner: boolean = false;
  showMachineRunSpinner: boolean = false;
  showMachineRunTick: boolean = false;
  showMachineRunCancel: boolean = false;

  orderItem: dtoOrderItem;
  selectedOrderItems: dtoOrderItem[] = [];
  selectedStockItems: dtoStockItem[];
  selectedStockItemContainer: StockItemContainer[];
  orderItemStockItems: dtoStockItem[];
  moulders: Moulder[];
  selectedMachineMoulderObjs: Moulder[];
  selectedMachineRuns: dtoMachineRun[] = [];

  orderItemExpanded: dtoOrderItem;

  btnAddToPackToolTipText: string = "";

  machineRunDialogRef: MatDialogRef<BpFloorMachineRunDialogComponent>;
  stockItemMultiplesDialogRef: MatDialogRef<StockItemMultiplesComponent>;
  moulderDialogRef: MatDialogRef<MoulderDialogComponent>;

  constructor(
    private printService: PrintService,
    private printerService: PrinterService,
    private dtoOrderItemService: dtoOrderItemService,
    private alertService: AlertService,
    private machineRunService: MachineRunService,
    private cdref: ChangeDetectorRef,
    private moulderService: MoulderService,
    private dtoStockItemService: dtoStockItemService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    //this.getOrderItem();
    if (this.datasource == null) {
      this.datasource = new MatTableDataSource<dtoOrderItem>();
      this.datasource.data = [];
    }

    this.getMoulders();
  }

  async getOrderItem() {
    this.orderItem = await this.dtoOrderItemService.getdtoOrderItemPromise(
      "077b6204-92e8-4d8a-a3e2-4a079dc15ed6"
    );
  }

  async getMoulders() {
    this.moulders = await this.moulderService.getAllMouldersPromise();
    this.moulders.sort((a, b) => {
      return a.txtName >= b.txtName ? 1 : -1;
    });
  }

  updatedComponentColumnsEvent(columns: string[]) {
    this.displayedOrderItems = columns;
  }

  RoundNumber(val) {
    return val;
  }

  async viewBarcodeSticker(orderItem: dtoOrderItem) {
    this.selectedOrderItems.length = 0;
    this.selectedOrderItems.push(orderItem);
    console.log("emitting event", this.selectedOrderItems);

    this.selectedOrderItemsEvent.emit(this.selectedOrderItems);
  }

  async printBarcodeSticker(orderItem: dtoOrderItem, createPack: boolean) {
    console.log("orderItem: ", orderItem);
    //var pdf = new jsPDF('p', 'px', 'A4');
    var pdf = new jsPDF("p", "px", "Letter");
    //let htmlElement: HTMLElement = document.getElementById("orderItemBarcodeSticker");

    //let htmlElement: HTMLElement = this.barcodeSticker.getHTMLElement();

    //htmlElement.innerHTML = "<div style='width:500px;height500px;background-color: #92a8d1;'>Test Div</div>";

    let printer: Printer =
      await this.printerService.getPrintersByAppNameAndTypePromise(
        "WEBAPP-HQ",
        Printer.enType.Normal
      );

    //if (htmlElement == null || printer == null) {
    //  console.log("null element");
    //  return;
    //}

    //pdf.html(htmlElement,
    //  {
    //    html2canvas: {
    //      // insert html2canvas options here, e.g.
    //      width: 200,
    //      scale: 0.5
    //    },
    //    callback: (pdf) => {

    //      window.open(pdf.output('bloburl').toString());

    //      //let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
    //      //console.log(arrBuffer);

    //      //let keyValueBarcode: dtoKeyValueBarcode = new dtoKeyValueBarcode;
    //      //keyValueBarcode.intValueBarcode = arrBuffer

    //      //const base64String = btoa(String.fromCharCode(...new Uint8Array(arrBuffer)));

    //      //let printByteArray: PrintByteArray = new PrintByteArray();
    //      //printByteArray.byteArray = base64String;
    //      //printByteArray.printer = printer;

    //      //console.log(printByteArray);

    //      //this.printService.printByteArray(printByteArray);
    //      //pdf.save('Test.pdf');
    //    }
    //  }
    //);
  }

  async validateQuantity(
    quantity: number,
    orderItem: dtoOrderItem,
    index: number
  ) {
    if (quantity == null || orderItem == null) {
      return;
    }

    if (orderItem.intQuantityUoM == OrderItem.enQuantityUoM.Each) {
      if (quantity > orderItem.fltQtyBal) {
        let msg: string = await this.alertService.openSnackBarCustomPromise(
          "Over Supply",
          "The quantity you are supplying is more than what is on order",
          "Ok",
          "",
          "center",
          "bottom",
          "",
          0,
          false
        );
      }
    }

    //if (quantity > 0) {
    // Update value or add to pack automatically.

    let updatedStock = false;
    // LETS JUST ADD NEW ITEMS ALL THE TIME.
    // THE USER CAN JUST REMOVE THE ITEM FROM THE PACK
    // BY CLICKING ON THE CROSS NEXT TO THE ITEM.
    // I THINK THIS IS A BIT MORE USER FRIENDLY THIS WAY.
    //if (this.selectedStockItemContainer != null) {
    //  for (let i = 0; i <= this.selectedStockItemContainer.length - 1; i++) {
    //    if (this.selectedStockItemContainer[i].stockItem.guOrderItemId == orderItem.rowguid) {
    //      this.selectedStockItemContainer[i].stockItem.fltQuantity = quantity;
    //      updatedStock = true;
    //      break;
    //    }
    //  }
    //}

    //  console.log("update: " + updatedStock);

    //  if (updatedStock == false) {
    //    //Need to add to stock items array.

    //    let stockItemLength: number = orderItem.fltLength;
    //    let txtStockItemLength: HTMLInputElement = document.getElementById("txtStockItemLength" + index) as HTMLInputElement;

    //    if (txtStockItemLength != null) {
    //      stockItemLength = parseFloat(txtStockItemLength.value);
    //    }

    //    this.addItemChange(orderItem, stockItemLength, true, index, quantity);
    //    let iRowCount: number = 0;
    //    this.addItems.forEach(chk => {
    //      if (iRowCount == index) {
    //        chk.checked = true;
    //      }
    //      iRowCount++;
    //    });

    //    orderItem.fltQtyAdded += parseInt(quantity.toString());

    //  }
    //}

    //if (quantity == 0) {

    //  if (this.addItems != null && this.addItems.length > 0) {
    //    let iRowCount: number = 0;
    //    this.addItems.forEach(chk => {
    //      if (iRowCount == index) {
    //        chk.checked = false;
    //        // Remove item from the pack.
    //        this.addItemChange(orderItem, 0, false, index, 0);
    //      }
    //      iRowCount++;
    //    });

    //  }
    //}
  }

  async addItemChange(orderItem: dtoOrderItem, index: number) {
    if (orderItem == null) {
      return;
    }

    let quantityTextbox: HTMLInputElement = document.getElementById(
      "txtQuantity" + index.toString()
    ) as HTMLInputElement;
    let moulderSelect: HTMLSelectElement = document.getElementById(
      "guMoulderId" + index.toString()
    ) as HTMLSelectElement;
    let machineRunValueText: HTMLInputElement = document.getElementById(
      "machineRunValue" + index.toString()
    ) as HTMLInputElement;
    let stockItemLength: number = orderItem.fltLength;
    let stockItemQuantity: number = orderItem.fltQuantityTotal;
    let txtStockItemLength: HTMLInputElement = document.getElementById(
      "txtStockItemLength" + index
    ) as HTMLInputElement;

    if (txtStockItemLength != null) {
      stockItemLength = parseFloat(txtStockItemLength.value);
    }

    if (quantityTextbox != null) {
      stockItemQuantity = parseFloat(quantityTextbox.value);
    }

    if (stockItemQuantity + orderItem.fltQtyAdded > orderItem.fltQtyBal) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Over Supply",
        "The quantity you are supplying is more than what is remaining on order is is Ok.",
        "Yes",
        "No",
        "center",
        "bottom",
        "",
        0,
        true
      );
      if (msg != "Yes") {
        return;
      }
    }

    //if (quantityTextbox != null && ((stockItemQuantity + orderItem.fltQtyAdded) - orderItem.fltQtyBal) > 0) {
    // console.log("set quantityTextbox: " + ((stockItemQuantity + orderItem.fltQtyAdded) - orderItem.fltQtyBal));
    //  quantityTextbox.value = ((stockItemQuantity + orderItem.fltQtyAdded) - orderItem.fltQtyBal).toString();
    //}

    //if (val == true) {
    let stockItem: dtoStockItem =
      this.dtoStockItemService.createStockItemFromOrderItem(
        orderItem,
        stockItemLength,
        stockItemQuantity,
        "",
        "",
        1
      );

    //stockItem.rowguid = window.crypto['randomUUID']();  // Javascript GUID generator.
    //stockItem.guProfileId = orderItem.guProductTypeId;
    //stockItem.guProductProfileId = orderItem.guProductProfileId;
    //stockItem.txtProductName = orderItem.txtProductName;
    //stockItem.txtProductShortName = orderItem.txtProductShortName;
    //stockItem.txtProfileName = orderItem.txtProfileName;
    //stockItem.txtProfileAbbrievation = orderItem.txtProfileAbbrievation;
    //stockItem.intWidth = orderItem.fltWidth;
    //stockItem.intThickness = orderItem.fltThickness;
    //stockItem.intTotalLM = orderItem.fltLength;
    //stockItem.guBoardGradeId = orderItem.guGradeId;
    //stockItem.txtGradeName = orderItem.txtGradeName;
    ////stockItem.txtGradeAbbreviation = orderItem.txtGradeAbbreviation  // order item abbreviation does not exist, should create it.
    //stockItem.guSpeciesId = orderItem.guSpeciesId;
    //stockItem.txtSpeciesName = orderItem.txtSpeciesName;
    ////stockItem.txtSpeciesCode = orderItem.txtSpeciesCode;   // order item species code does not exist, should create it.
    //stockItem.fltQuantity = orderItem.fltQtyBal;
    //if (updateQuantity != null && updateQuantity > 0) {
    //  stockItem.fltQuantity = updateQuantity;
    //}
    //stockItem.intQuantityUoM = orderItem.intQuantityUoM;
    //stockItem.txtQuantityUoM = orderItem.txtQuantityUoM;
    //stockItem.guBoardOrderId = orderItem.guOrderId;
    //stockItem.guOrderItemId = orderItem.rowguid;
    //stockItem.guCustomerId = orderItem.guCustomerId;
    //stockItem.guProductCodeId = orderItem.guProductCodeId;

    //===================================================================================
    // NEED TO SET guMoulderId FROM SELECTED MOULDER ARRAY.
    // AT THE MOMEMENT WE STILL USE DESKTOP APP WITH BoardTallyRecord.guMoulderId.
    //===================================================================================

    //let moulderArr = this.moulderSelects.toArray();
    //if (moulderArr != null && moulderArr.length > 0) {
    //  if (moulderArr[index] != null) {
    //    stockItem.guMoulderId = moulderArr[index].value;
    //  }
    //}

    if (machineRunValueText != null && machineRunValueText.value != "") {
      stockItem.guMachineRunId = machineRunValueText.value;
    }

    stockItem.intItemNo = index + 1;

    let boardTallyRecordMachineRuns: BoardTallyRecordMachineRun[] = [];
    let boardTallyRecordMoulders: BoardTallyRecordMoulder[] = [];
    let boardTallyRecordOrderItems: dtoBoardTallyRecordOrderItem[] = [];

    this.selectedMachineRuns.forEach((mr) => {
      let boardTallyRecordMachineRun: BoardTallyRecordMachineRun =
        new BoardTallyRecordMachineRun();
      boardTallyRecordMachineRun.guBoardTallyRecordId = stockItem.rowguid;
      boardTallyRecordMachineRun.guMachineRunId = mr.rowguid;
      boardTallyRecordMachineRuns.push(boardTallyRecordMachineRun);
    });

    let iMoulder: number = -1;
    this.selectedMachineMoulderObjs.forEach((m) => {
      iMoulder++;

      let boardTallyRecordMoulder: BoardTallyRecordMoulder =
        new BoardTallyRecordMoulder();
      boardTallyRecordMoulder.guBoardTallyRecordId = stockItem.rowguid;
      boardTallyRecordMoulder.guMoulderId = m.rowguid;
      boardTallyRecordMoulder.intNoOfRuns = 1;
      boardTallyRecordMoulders.push(boardTallyRecordMoulder);

      // Get first moulder to support the single moulder capability at the moment 6/8/23.
      if (iMoulder == 0) {
        stockItem.guMoulderId = m.rowguid;
      }
    });

    let boardTallyRecordOrderItem: dtoBoardTallyRecordOrderItem =
      new dtoBoardTallyRecordOrderItem();
    boardTallyRecordOrderItem.txtOrderItemProductName =
      orderItem.txtProductName;
    boardTallyRecordOrderItem.txtOrderItemProfileName =
      orderItem.txtProfileName;
    boardTallyRecordOrderItem.fltOrderItemWidth = orderItem.fltWidth;
    boardTallyRecordOrderItem.fltOrderItemThickness = orderItem.fltThickness;
    boardTallyRecordOrderItem.txtOrderItemSpeciesName =
      orderItem.txtSpeciesName;
    boardTallyRecordOrderItem.txtOrderItemGradeName = orderItem.txtGradeName;
    boardTallyRecordOrderItem.fltBTOrderItemQuantity = stockItem.fltQuantity;
    boardTallyRecordOrderItem.intBTOrderItemQuantityUoM =
      stockItem.intQuantityUoM;
    boardTallyRecordOrderItem.fltOrderItemLength = orderItem.fltLength;
    boardTallyRecordOrderItem.guOrderItemId = orderItem.rowguid;
    boardTallyRecordOrderItem.guBoardTallyRecordId = stockItem.rowguid;
    boardTallyRecordOrderItems.push(boardTallyRecordOrderItem);

    let stockItemContainer: StockItemContainer = new StockItemContainer();
    stockItemContainer.stockItem = stockItem;
    stockItemContainer.boardTallyRecordOrderItemArray =
      boardTallyRecordOrderItems;
    stockItemContainer.boardTallyRecordMachineRunArray =
      boardTallyRecordMachineRuns;
    stockItemContainer.boardTallyRecordMoulderArray = boardTallyRecordMoulders;

    console.log(
      "order-item-list fltBTOrderItemQuantity: ",
      stockItem.fltQuantity
    );

    if (this.selectedStockItemContainer == null) {
      this.selectedStockItemContainer = [];
    }

    this.selectedStockItemContainer.push(stockItemContainer);

    if (this.selectedStockItems == null) {
      this.selectedStockItems = [];
    }
    this.selectedStockItems.push(stockItem);

    ////Reset intItemNo
    //for (let i = 0; i <= this.selectedStockItemContainer.length - 1; i++) {
    //  for (let o = 0; o <= this.datasource.data.length - 1; o++) {
    //    if (this.datasource.data[o].rowguid == this.selectedStockItemContainer[i].stockItem.guOrderItemId) {
    //      this.selectedStockItemContainer[i].stockItem.intItemNo = this.datasource.data[o].intItemNo;
    //      break;
    //    }
    //  }
    //}

    this.selectedStockItemContainer.sort((a, b) => {
      return a.stockItem.intItemNo - b.stockItem.intItemNo;
    });

    this.addStockItemEvent.emit(this.selectedStockItemContainer);

    //// Reset selected for next pack.
    //// need to make sure its not referenced to the object it was
    //// added to. If so we need to clone the object befor adding.
    //this.selectedMachineMoulderObjs.length = 0;

    //this.selectedMachineRuns.length = 0;

    orderItem.fltQtyAdded += parseFloat(stockItem.fltQuantity.toString());

    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;

    if (orderItem.fltQtyBal - orderItem.fltQtyAdded <= 0) {
      matBtn.disabled = true;
    }

    //}

    // NO LONGER NEEDING TO UPDATE.
    // JUST ADD AND REMOVE FROM PACK.
    // LESS CONFUSING THIS WAY FOR THE USER.
    //if (val == false) {

    //  for (let i = 0; i <= this.selectedStockItemContainer.length - 1; i++) {
    //    if (this.selectedStockItemContainer[i].stockItem.guOrderItemId == orderItem.rowguid) {
    //      // Remove stock item from array.
    //      this.selectedStockItemContainer.splice(i, 1);
    //      break;
    //    }
    //  }

    //  ////Reset intItemNo
    //  //for (let i = 0; i <= this.selectedStockItemContainer.length - 1; i++) {
    //  //  this.selectedStockItemContainer[i].stockItem.intItemNo = i + 1;
    //  //}
    //  //this.selectedStockItemContainer.sort((a, b) => { return a.stockItem.intItemNo - b.stockItem.intItemNo });

    //  this.addStockItemEvent.emit(this.selectedStockItemContainer);

    //  if (quantityTextbox != null) {
    //    quantityTextbox.value = "";
    //  }

    //}
  }

  async validateMachineRun(
    machineRunNo: number,
    orderItem: dtoOrderItem,
    index: number
  ) {
    if (machineRunNo == null || machineRunNo == 0) {
      return;
    }

    this.showMachineRunSpinner = false;
    this.showMachineRunCancel = false;

    let iconTickContainer: HTMLSpanElement = document.getElementById(
      "machineRunTickContainer" + index.toString()
    ) as HTMLSpanElement;
    let iconCancelContainer: HTMLSpanElement = document.getElementById(
      "machineRunCancelContainer" + index.toString()
    ) as HTMLSpanElement;

    this.showMachineRunSpinner = true;
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    //let moulderid: string = "";
    //let moulderArr = this.moulderSelects.toArray();
    //if (moulderArr != null && moulderArr.length > 0) {
    //  if (moulderArr[index] != null) {
    //    moulderid = moulderArr[index].value;
    //  }
    //}

    //aParam.push(new SQLParamArray("guMoulderId", moulderid));
    //aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("intRunNo", machineRunNo.toString()));
    aParamList.push(aParam);

    console.log("about to get machine run");

    let machineRuns: MachineRun[] =
      await this.machineRunService.getMachineRunParamArrayPromise(aParamList);
    let machineRun: MachineRun = null;
    if (machineRuns == null) {
      await this.alertService.openSnackBarCustomPromise(
        "Invalid Machine Run",
        "The machine run does not exist",
        "Ok",
        "",
        "center",
        "bottom",
        "",
        0,
        false
      );

      this.showMachineRunSpinner = false;
      this.showMachineRunCancel = true;
    }

    machineRun = machineRuns[0]; // single machine run.

    let machineRunValueText: HTMLInputElement = document.getElementById(
      "machineRunValue" + index.toString()
    ) as HTMLInputElement;
    machineRunValueText.value = "";

    if (machineRun != null) {
      machineRunValueText.value = machineRun.rowguid;

      console.log("got machine run", machineRun);

      iconTickContainer.style.visibility = "visible";
      iconTickContainer.style.width = "40px;";
      this.showMachineRunTick = true;

      console.log("iconTickContainer", iconTickContainer);

      //this.cdref.detectChanges();

      //setTimeout(() => {

      //  //this.showMachineRunTick = false;  // leave tick there until we type in another value or component refreshes.
      //  this.cdref.detectChanges();

      //}, 1000);

      //setTimeout(() => {
      //  this.showMachineRunTick = false;
      //  iconTickContainer.style.visibility = "hidden";
      //  iconTickContainer.style.width = "0px;"

      //}, 1400);
    }
  }

  cancelQuantity(orderItem: dtoOrderItem, index: number) {
    if (orderItem == null) {
      return;
    }

    let quantityTextbox: HTMLInputElement = document.getElementById(
      "txtQuantity" + index.toString()
    ) as HTMLInputElement;
    let iconCancelContainer: HTMLSpanElement = document.getElementById(
      "iconCancelContainer" + index.toString()
    ) as HTMLSpanElement;

    if (quantityTextbox != null) {
      quantityTextbox.value = "";
    }

    this.showMachineRunCancel = false;
    this.showMachineRunTick = false;

    setTimeout(() => {
      iconCancelContainer.style.visibility = "hidden";
      iconCancelContainer.style.width = "0px;";
    }, 1200);
  }

  async expandElement(orderItem: dtoOrderItem, index: number) {
    this.orderItemExpanded =
      this.orderItemExpanded === orderItem ? null : orderItem;

    console.log("expand: ", this.orderItemExpanded);

    if (this.orderItemExpanded != null) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guOrderItemId", orderItem.rowguid));
      aParamList.push(aParam);

      this.orderItemStockItems =
        await this.dtoStockItemService.getdtoStockItemParamArrayPromise(
          aParamList
        );
      this.orderItemStockItems.sort((a, b) => {
        return a.intIdentifier - b.intIdentifier;
      });
    }
  }

  async getMachineRun(index: number) {
    //let moulderId: string = "";
    //let moulderArr = this.moulderSelects.toArray();
    //if (moulderArr != null && moulderArr.length > 0) {
    //  if (moulderArr[index] != null) {
    //    moulderId = moulderArr[index].value;
    //  }
    //}

    //console.log(moulderId);

    //if (moulderId == null || moulderId == "") {
    //  await this.alertService.openSnackBarCustom("Select a Moulder", "Please select a Moulder", "Ok", "", "center", "bottom", "", 0, false);
    //  return;
    //}

    if (this.machineRunDialogRef != null) {
      this.machineRunDialogRef.close();
    }

    //let moulder: Moulder = this.moulders.find(m => { return m.rowguid == moulderId });

    //console.log("moulder: ", moulder);

    //if (moulder == null) {
    //  return;
    //}

    this.machineRunDialogRef = this.dialog.open(
      BpFloorMachineRunDialogComponent,
      {
        height: "auto",
        maxWidth: "80vw",
        data: { moulder: null },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketOrderItemUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.machineRunDialogRef.backdropClick().subscribe(() => {
      this.machineRunDialogRef.close();
    });

    this.machineRunDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          machineRuns: dtoMachineRun[];
          moulders: Moulder[];
        }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the MachineRun dialog, null data."
            );
            return;
          }

          console.log(data);
          if (data.status != "Cancel") {
            this.selectedMachineRuns = [];

            //let machineRunValueText: HTMLInputElement = document.getElementById("machineRunValue" + index.toString()) as HTMLInputElement;
            //let txtMachineRun: HTMLInputElement = document.getElementById("txtMachineRun" + index.toString()) as HTMLInputElement;
            //let machineRunCancelContainer: HTMLSpanElement = document.getElementById('machineRunCancelContainer' + index.toString()) as HTMLSpanElement;

            //machineRunValueText.value = data.machineRun.rowguid;
            //txtMachineRun.value = "";

            let moulderList: ElementRef[] =
              this.moulderList.toArray() as unknown as ElementRef[];
            let moulderListSelected: ElementRef = this.moulderList.toArray()[
              index
            ] as unknown as ElementRef;
            let machineRunListSelected: ElementRef =
              this.machineRunList.toArray()[index] as unknown as ElementRef;
            let machineRunList: ElementRef[] =
              this.machineRunList.toArray() as unknown as ElementRef[];

            machineRunList.forEach((el) => {
              el.nativeElement.innerHTML = "";
            });

            for (let i = 0; i <= moulderList.length - 1; i++) {
              if (i != index) {
                moulderList[i].nativeElement.innerHTML = "";
              }
            }

            data.machineRuns.forEach((mr) => {
              this.selectedMachineRuns.push(mr);
              machineRunListSelected.nativeElement.innerHTML +=
                mr.intRunNo.toString() +
                " (" +
                mr.txtMoulderName +
                ")" +
                "<br>";
            });

            if (this.selectedMachineMoulderObjs == null) {
              this.selectedMachineMoulderObjs = [];
            }

            // Remove all moulers that were added by a previous machine run.
            // We are doing this because we are re-adding new machine run(s).
            // Therefore remove any existing moulders added by previous mahcine run selection.
            for (
              let i = this.selectedMachineMoulderObjs.length - 1;
              i >= 0;
              i--
            ) {
              if (
                this.selectedMachineMoulderObjs[i]["blnMachineRunAddedIndex"] !=
                null
              ) {
                this.selectedMachineMoulderObjs.splice(i, 1);
              }
            }

            let moulder: Moulder = null;
            data.moulders.forEach((m) => {
              moulder = null;
              moulder = this.selectedMachineMoulderObjs.find((mld) => {
                return m.rowguid == mld.rowguid;
              });

              if (
                moulder != null &&
                moulder["blnMachineRunAddedIndex"] == null
              ) {
                // We have an existing moulder that was added manually by the get moulders button.
                // Now we have added a machine run that has the same moulder.
                // So we want to add the blnMachineRunAddedIndex property to the Moulder object so we know
                // its linked to a Machine Run moulder.
                moulder["blnMachineRunAddedIndex"] = index;
                console.log("existing moulder", moulder);
              }

              if (moulder == null) {
                m["blnMachineRunAddedIndex"] = index;
                this.selectedMachineMoulderObjs.push(m);
                console.log("not existing moulder", m);
              }
            });

            console.log("moulder array", this.selectedMachineMoulderObjs);

            //Display moulders in moulder column
            moulderListSelected.nativeElement.innerHTML = "";

            this.selectedMachineMoulderObjs.forEach((mld) => {
              moulderListSelected.nativeElement.innerHTML +=
                mld.txtName + "<br>";
            });

            let matBtn: MatButton = this.btnAddToPack.toArray()[
              index
            ] as unknown as MatButton;
            matBtn.disabled = this.selectedMachineMoulderObjs.length == 0;

            //machineRunCancelContainer.style.visibility = "visible";
            //machineRunCancelContainer.style.width = "40px;"
            //this.showMachineRunCancel = true;
          } else {
            // We have cancellled
          }
        }
      );
  }

  getMoulder(index: number) {
    if (this.moulderDialogRef != null) {
      this.moulderDialogRef.close();
    }

    this.moulderDialogRef = this.dialog.open(MoulderDialogComponent, {
      height: "80vh",
      maxHeight: "80vh",
      width: "80vw",
      maxWidth: "80vw",
      data: { selectedMoulders: null },
    });

    this.moulderDialogRef.backdropClick().subscribe(() => {
      this.moulderDialogRef.close();
    });

    this.moulderDialogRef
      .afterClosed()
      .subscribe(async (data: { status: string; moulders: Moulder[] }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError(
            "Something went wrong!\nAn email has been sent to the IT department.",
            "Close",
            "center",
            "bottom",
            4000,
            true,
            "Something went wrong with closing the Moulder dialog, null data."
          );
          return;
        }

        console.log(data);
        if (data.status != "Cancel") {
          //console.log(this.moulderList.toArray()[index]);

          let machineRunList: ElementRef[] =
            this.machineRunList.toArray() as unknown as ElementRef[];
          let moulderListSelected: ElementRef = this.moulderList.toArray()[
            index
          ] as unknown as ElementRef;
          let moulderList: ElementRef[] =
            this.moulderList.toArray() as unknown as ElementRef[];

          //moulderList.nativeElement.innerHTML = "";

          // ==============================================================
          // TO DO - 5/8/2023
          //  WHEN SELECTING NEW MOULDERS WE WANT TO MAKE SURE THAT IF THERE
          //  ARE MACHINE RUNS ADDED THEN WE DO NOT WANT TO REMOVE THOSE MOULDERS.
          //  WE NEED TO SKIP THOSE BY LOOKING AT blnMachineRunAddedIndex PROPERTY.
          //  IF THE PROPERTY EXISTS AND THE INDEX VALUE IS NOT THE VALUE OF THIS
          //  INDEX ROW THEN WE CAN REMOVE IT OTHERWISE KEEP IT.
          // ==============================================================

          console.log(
            "selected moulders - before",
            this.selectedMachineMoulderObjs
          );

          moulderList.forEach((el) => {
            el.nativeElement.innerHTML = "";
          });

          // Clean up machine runs that are not on this row.
          for (let i = 0; i <= machineRunList.length - 1; i++) {
            if (i != index) {
              machineRunList[i].nativeElement.innerHTML = "";
            }
          }

          if (
            this.selectedMachineMoulderObjs != null &&
            this.selectedMachineMoulderObjs.length > 0
          ) {
            // If we selected Machine Runs that have moulders in this array we don't want to remove them.
            // Iterate in Reverse to remove items.
            for (
              let i = this.selectedMachineMoulderObjs.length - 1;
              i >= 0;
              i--
            ) {
              //console.log(this.selectedMachineMoulderObjs[i]);
              if (
                "blnMachineRunAddedIndex" in this.selectedMachineMoulderObjs[i]
              ) {
                //console.log(this.selectedMachineMoulderObjs[i]["blnMachineRunAddedIndex"]);

                if (
                  this.selectedMachineMoulderObjs[i][
                    "blnMachineRunAddedIndex"
                  ] != undefined &&
                  this.selectedMachineMoulderObjs[i][
                    "blnMachineRunAddedIndex"
                  ] != index
                ) {
                  this.selectedMachineMoulderObjs.splice(i, 1);
                }
              }
            }
          }
          if (this.selectedMachineMoulderObjs == null) {
            this.selectedMachineMoulderObjs = [];
          }

          // Add to array object first
          // Only add Moulders that don't exist in array.
          // There may be some moulders that belong to Machine Runs
          let moulder: Moulder = null;
          data.moulders.forEach((m) => {
            moulder = null;
            moulder = this.selectedMachineMoulderObjs.find((mld) => {
              return mld.rowguid == m.rowguid;
            });

            console.log("moulder exists", moulder);

            if (moulder == null) {
              this.selectedMachineMoulderObjs.push(m);
            }
          });

          console.log(
            "selected moulders - after",
            this.selectedMachineMoulderObjs
          );

          moulderListSelected.nativeElement.innerHTML = "";

          this.selectedMachineMoulderObjs.forEach((m) => {
            moulderListSelected.nativeElement.innerHTML += m.txtName + "<br>";
          });

          let matBtn: MatButton = this.btnAddToPack.toArray()[
            index
          ] as unknown as MatButton;
          matBtn.disabled = this.selectedMachineMoulderObjs.length == 0;

          //console.log(moulderList);
        } else {
          // We have cancellled
        }
      });
  }

  setNoMoulder(index: number) {
    let moulderList: ElementRef[] =
      this.moulderList.toArray() as unknown as ElementRef[];

    moulderList.forEach((el) => {
      el.nativeElement.innerHTML = "";
    });

    let noneMoulder: Moulder = null;
    for (let i = 0; i <= this.moulders.length - 1; i++) {
      if (this.moulders[i].txtName.toLocaleLowerCase() == "none") {
        noneMoulder = this.moulders[i];
        break;
      }
    }

    this.selectedMachineMoulderObjs = [];

    let moulderListSelected: ElementRef = this.moulderList.toArray()[
      index
    ] as unknown as ElementRef;
    moulderListSelected.nativeElement.innerHTML = noneMoulder.txtName;
    this.selectedMachineMoulderObjs.push(noneMoulder);

    let matBtns: MatButton[] =
      this.btnAddToPack.toArray() as unknown as MatButton[];
    matBtns.forEach((b) => {
      b.disabled = true;
    });

    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;
    matBtn.disabled = this.selectedMachineMoulderObjs.length == 0;
  }

  cancelMachineRun(orderItem: dtoOrderItem, index: number) {
    let machineRunList: ElementRef = this.machineRunList.toArray()[
      index
    ] as unknown as ElementRef;
    machineRunList.nativeElement.innerHTML = "";
    this.selectedMachineRuns = [];

    for (let i = this.selectedMachineMoulderObjs.length - 1; i >= 0; i--) {
      if (
        this.selectedMachineMoulderObjs[i]["blnMachineRunAddedIndex"] != null &&
        this.selectedMachineMoulderObjs[i]["blnMachineRunAddedIndex"] == index
      ) {
        this.selectedMachineMoulderObjs.splice(i, 1);
      }
    }

    let moulderListSelected: ElementRef = this.moulderList.toArray()[
      index
    ] as unknown as ElementRef;
    moulderListSelected.nativeElement.innerHTML = "";
    this.selectedMachineMoulderObjs.forEach((mld) => {
      moulderListSelected.nativeElement.innerHTML += mld.txtName + "<br>";
    });

    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;
    matBtn.disabled = this.selectedMachineMoulderObjs.length == 0;
  }

  cancelMoulders(orderItem: dtoOrderItem, index: number) {
    //let moulderList: ElementRef = this.moulderList.toArray()[index] as unknown as ElementRef
    //moulderList.nativeElement.innerHTML = "";
    //this.selectedMachineMoulderObjs = [];

    for (let i = this.selectedMachineMoulderObjs.length - 1; i >= 0; i--) {
      if (
        this.selectedMachineMoulderObjs[i]["blnMachineRunAddedIndex"] == null
      ) {
        this.selectedMachineMoulderObjs.splice(i, 1);
      }
    }

    let moulderListSelected: ElementRef = this.moulderList.toArray()[
      index
    ] as unknown as ElementRef;
    moulderListSelected.nativeElement.innerHTML = "";
    this.selectedMachineMoulderObjs.forEach((mld) => {
      moulderListSelected.nativeElement.innerHTML += mld.txtName + "<br>";
    });

    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;
    matBtn.disabled = this.selectedMachineMoulderObjs.length == 0;
  }

  copyAbove(index: number) {
    if (index == null) {
      return;
    }

    let matBtns: MatButton[] =
      this.btnAddToPack.toArray() as unknown as MatButton[];
    matBtns.forEach((b) => {
      b.disabled = true;
    });

    let moulderList: ElementRef[] =
      this.moulderList.toArray() as unknown as ElementRef[];
    moulderList.forEach((el) => {
      el.nativeElement.innerHTML = "";
    });

    let machineRunList: ElementRef[] =
      this.machineRunList.toArray() as unknown as ElementRef[];
    machineRunList.forEach((el) => {
      el.nativeElement.innerHTML = "";
    });

    if (this.selectedMachineMoulderObjs != null) {
      let moulderListSelected: ElementRef = this.moulderList.toArray()[
        index
      ] as unknown as ElementRef;
      this.selectedMachineMoulderObjs.forEach((mld) => {
        if (mld["blnMachineRunAddedIndex"] != null) {
          mld["blnMachineRunAddedIndex"] = index;
        }
        moulderListSelected.nativeElement.innerHTML += mld.txtName + "<br>";
      });
    }

    if (this.selectedMachineRuns != null) {
      let machineRunListSelected: ElementRef = this.machineRunList.toArray()[
        index
      ] as unknown as ElementRef;
      this.selectedMachineRuns.forEach((mr) => {
        machineRunListSelected.nativeElement.innerHTML +=
          mr.intRunNo.toString() + " (" + mr.txtMoulderName + ")" + "<br>";
      });
    }

    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;
    matBtn.disabled = this.selectedMachineMoulderObjs.length == 0;
  }

  addMultipleLength(orderItem: dtoOrderItem, index: number) {
    // Open Dialog to nominate the multiple length to order item(s).

    if (this.stockItemMultiplesDialogRef != null) {
      this.stockItemMultiplesDialogRef.close();
    }

    if (orderItem == null) {
      return;
    }

    this.stockItemMultiplesDialogRef = this.dialog.open(
      StockItemMultiplesComponent,
      {
        height: "auto",
        maxHeight: "98vh",
        maxWidth: "100vw",
        data: { orderItem: orderItem },
      }
    );

    this.stockItemMultiplesDialogRef.componentInstance.datasource =
      new MatTableDataSource<dtoOrderItem>();

    // Update order item balances with what has been currently selected in memory.
    // An order item may already have been selected to go in the pack but there may be
    // some of that order item that may go in a multi item length.
    // we don't want to over supply the order item by over doing the quantity in the multiple item dialog.
    // so we need to update the fltQtyBal in the order item that we are going to send to the
    // multiple item dialog. That way the user will get a prompt if they go over the orderItem.fltQtyBal + stockItem.fltQuantity.
    let orderItems: dtoOrderItem[] = [];
    this.datasource.data.forEach((oi) => {
      orderItems.push({ ...oi });
    });

    if (this.selectedStockItemContainer != null) {
      this.selectedStockItemContainer.forEach((sc) => {
        sc.boardTallyRecordOrderItemArray.forEach((btoi) => {
          orderItems.forEach((oi) => {
            if (oi.rowguid == btoi.guOrderItemId) {
              //console.log("order items: " + oi.rowguid + "  == " + btoi.guOrderItemId + "  qty: " + btoi.fltBTOrderItemQuantity);
              oi.fltQtyBal -= btoi.fltBTOrderItemQuantity;
            }
          });
        });
      });
    }

    this.stockItemMultiplesDialogRef.componentInstance.datasource.data =
      orderItems;

    this.stockItemMultiplesDialogRef.backdropClick().subscribe(() => {
      this.stockItemMultiplesDialogRef.close();
    });

    this.stockItemMultiplesDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          stockItemContainer: StockItemContainer;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the BoardTallyRecordOrderItemCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            if (data.stockItemContainer.stockItem == null) {
              return;
            }

            if (this.selectedStockItems == null) {
              this.selectedStockItems = [];
            }

            if (this.selectedStockItemContainer == null) {
              this.selectedStockItemContainer = [];
            }

            this.selectedStockItemContainer.push(data.stockItemContainer);

            this.selectedStockItems.push(data.stockItemContainer.stockItem);

            //Reset intItemNo
            for (
              let i = 0;
              i <= this.selectedStockItemContainer.length - 1;
              i++
            ) {
              for (let o = 0; o <= this.datasource.data.length - 1; o++) {
                if (
                  this.datasource.data[o].rowguid ==
                  this.selectedStockItemContainer[i].stockItem.guOrderItemId
                ) {
                  this.selectedStockItemContainer[i].stockItem.intItemNo =
                    this.datasource.data[o].intItemNo;
                  break;
                }
              }
            }

            this.selectedStockItems.sort((a, b) => {
              return a.intItemNo - b.intItemNo;
            });

            this.addStockItemEvent.emit(this.selectedStockItemContainer);

            let orderItemQty: number = 0;
            // Go through order items and add any multi length quantities
            for (let i = 0; i <= this.datasource.data.length - 1; i++) {
              orderItemQty = 0;
              if (this.selectedStockItemContainer != null) {
                this.selectedStockItemContainer.forEach((sc) => {
                  sc.boardTallyRecordOrderItemArray.forEach((btoi) => {
                    if (btoi.guOrderItemId == this.datasource.data[i].rowguid) {
                      orderItemQty += parseInt(
                        btoi.fltBTOrderItemQuantity.toString()
                      );
                      //console.log("added multiple length: " + btoi.guOrderItemId + "  == " + this.datasource.data[i].rowguid + "  qty: " + btoi.fltBTOrderItemQuantity);
                    }
                  });
                });
              }

              if (orderItemQty > 0) {
                let txtQty: ElementRef = this.txtQuantity.toArray()[
                  i
                ] as unknown as ElementRef;
                this.datasource.data[i].fltQtyAdded = orderItemQty;

                if (
                  this.datasource.data[i].fltQtyBal -
                    this.datasource.data[i].fltQtyAdded >=
                  0
                ) {
                  console.log(
                    "set quantityTextbox: " +
                      (
                        this.datasource.data[i].fltQtyBal -
                        this.datasource.data[i].fltQtyAdded
                      ).toString()
                  );
                  txtQty.nativeElement.value = (
                    this.datasource.data[i].fltQtyBal -
                    this.datasource.data[i].fltQtyAdded
                  ).toString();
                }
              }
            }
          } else {
            // We have cancellled
          }
        }
      );
  }

  canAddToPack(
    moulderSelect: MatSelect,
    selectionChange: MatSelectChange,
    orderItem: dtoOrderItem,
    index: number
  ) {
    //console.log(moulderSelect);
    let mtOption: MatOption = selectionChange.source.selected as MatOption;
    //console.log(mtOption.value);

    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;

    if (matBtn == null) {
      return;
    }

    matBtn.disabled = true;

    if (orderItem.fltQtyBal - orderItem.fltQtyAdded > 0) {
      matBtn.disabled = false;
      //matBtn.matToolTip = new MatTooltip(
    }
  }

  addToPackMouseEnter(
    tooltip: MatTooltip,
    orderItem: dtoOrderItem,
    index: number
  ) {
    //let mtOption: MatOption = (this.moulderSelects.toArray()[index] as unknown as MatOption);
    let matBtn: MatButton = this.btnAddToPack.toArray()[
      index
    ] as unknown as MatButton;

    //matBtn.disabled = false;

    //if (mtOption == null || mtOption.value == null) {
    //  tooltip.message = "Select a moulder"
    //  tooltip.show();
    //}

    //console.log(matBtn);

    if (
      this.selectedMachineMoulderObjs == null ||
      this.selectedMachineMoulderObjs.length == 0
    ) {
      tooltip.message = "There is no moulder selected.";
      tooltip.show();
    }

    if (orderItem.fltQtyBal - orderItem.fltQtyAdded <= 0) {
      matBtn.disabled = true;
      tooltip.message = "The remaining quantity is zero";
      tooltip.show();
    }

    //this.btnAddToPackToolTipText = "Select a moulder";
  }

  addToPackMouseLeave(tooltip: MatTooltip) {
    this.btnAddToPackToolTipText = "";

    tooltip.message = "";
    tooltip.hide();
  }

  resetAddToPackButton(orderItemId: string) {
    let moulderList: ElementRef[] =
      this.moulderList.toArray() as unknown as ElementRef[];
    let iHasMoulders: number = -1;
    for (let i = 0; i <= moulderList.length - 1; i++) {
      if (moulderList[i].nativeElement.innerHTML != "") {
        iHasMoulders = i;
        break;
      }
    }

    for (let i = 0; i <= this.datasource.data.length - 1; i++) {
      if (this.datasource.data[i].rowguid == orderItemId || orderItemId == "") {
        // If we have a moulder selected than enable the button.
        if (iHasMoulders == i) {
          this.btnAddToPack.toArray()[i].disabled = false;
        }

        // If there is no more to add then disable the button.
        if (
          this.datasource.data[i].fltQtyBal -
            this.datasource.data[i].fltQtyAdded <=
          0
        ) {
          this.btnAddToPack.toArray()[i].disabled = true;
        }

        //if (this.moulderSelects.toArray()[i].value == null) {
        //  this.btnAddToPack.toArray()[i].disabled = true;
        //}
      }
    }
  }
}
