import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { CustomerDeliveryAddressDialogComponent } from "../../customer-delivery-address/customer-delivery-address-dialog/customer-delivery-address-dialog.component";
import { ClientDeliveryAddress } from "../../_shared/business-objects/client-delivery-address.bo";
import { DeliveryDocketOrderItem } from "../../_shared/business-objects/delivery-docket-order-item.bo";
import { dtoCustomerDeliveryAddress } from "../../_shared/business-objects/dto-customer-delivery-address.bo";
import { dtoDeliveryDocketOrderStockItem } from "../../_shared/business-objects/dto-delivery-docket-order-stock-item.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { AlertService } from "../../_shared/services/alert.service";
import { DeliveryDocketOrderItemService } from "../../_shared/services/delivery-docket-order-item.service";
import { dtoCustomerDeliveryAddressService } from "../../_shared/services/dto-customer-delivery-address.service";
import { dtoDeliveryDocketOrderStockItemService } from "../../_shared/services/dto-delivery-docket-order-stock-item.service";

@Component({
  selector: "app-loading-docket-view",
  templateUrl: "./loading-docket-view.component.html",
  styleUrls: ["./loading-docket-view.component.css"],
})
export class LoadingDocketViewComponent implements OnInit {
  @Input() loadingDocketIdInput: string;
  @Output() updateCustomerDeliveryAddressEvent =
    new EventEmitter<dtoCustomerDeliveryAddress>();

  customerDeliveryAddressDialogRef: MatDialogRef<CustomerDeliveryAddressDialogComponent>;

  testArray: string[] = ["test1", "test2"];
  loadingDocketId: string = "";
  deliveryDocketOrderItems: dtoDeliveryDocketOrderStockItem[];
  deliveryDocketTotals: {
    deliveryDocketId: string;
    totalItems: number;
    totalQtyEach: number;
    totalQtyLM: number;
    totalQtyM2: number;
    totalQtyBoxes: number;
    totalM3: number;
    totalTonne: number;
    multipleUoM: boolean;
  }[] = [];
  showStrengthGroups: boolean = false;
  showStrengthGroup3: boolean = false;
  screenView: boolean = false;

  isPrintPage: boolean = false;

  constructor(
    private dtoDeliveryDocketOrderStockItemService: dtoDeliveryDocketOrderStockItemService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService,
    private dialog: MatDialog,
    private deliveryDocketOrderItemService: DeliveryDocketOrderItemService
  ) {}

  ngOnInit(): void {
    if (this.loadingDocketIdInput != null && this.loadingDocketIdInput != "") {
      this.loadingDocketId = this.loadingDocketIdInput;
    }

    this.loadData();
  }

  async loadData() {
    this.isPrintPage =
      document.location.href.indexOf("loading-docket-print") > -1;

    this.route.queryParams.subscribe(async (params) => {
      if (params != null || true) {
        // The loading docket can be supplied from the @Input so it will override the param value.
        if (
          this.loadingDocketIdInput == null ||
          this.loadingDocketIdInput == ""
        ) {
          this.loadingDocketId = params["loadingdocketid"];
        }

        let screenView: string = String(params["screenview"]);
        this.screenView = screenView.toLocaleLowerCase() == "yes";

        console.log("document.location.href", document.location.href);
      }
    });

    console.log("this.loadingDocketId ", this.loadingDocketId);

    if (this.loadingDocketId == null) {
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guLoadingDocketId", this.loadingDocketId));
    aParamList.push(aParam);

    this.deliveryDocketOrderItems =
      await this.dtoDeliveryDocketOrderStockItemService.getdtoDeliveryDocketOrderStockItemParamArrayPromise(
        aParamList
      );
    this.deliveryDocketOrderItems.sort((a, b) => {
      return a.intDocketNo - b.intDocketNo;
    });

    console.log("deliveryDocketOrderItems", this.deliveryDocketOrderItems);

    let arr = this.deliveryDocketOrderItems.filter((d, index) => {
      return this.deliveryDocketOrderItems.indexOf(d) === index;
    });
    const arrUniqDelDockets = [
      ...new Map(
        this.deliveryDocketOrderItems.map((d) => [d.guDeliveryDocketId, d])
      ).values(),
    ];

    let deliveryDocketIds: string[] = arrUniqDelDockets.map(
      (d) => d.guDeliveryDocketId
    );

    console.log("deliveryDocketIds", deliveryDocketIds);

    this.calcDeliveryDocketTotals(deliveryDocketIds);

    let grandTotalQtyEach: number = 0;
    let grandTotalQtyLM: number = 0;
    let grandTotalQtyM2: number = 0;
    let grandTotalQtyBoxes: number = 0;
    let grandTtoalM3: number = 0;
    let grandTtoalTonne: number = 0;

    this.showStrengthGroups = false;
    this.showStrengthGroup3 = false;

    let deliveryDocketId: string = "";

    for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {
      if (
        this.deliveryDocketOrderItems[i].intS1 != null ||
        this.deliveryDocketOrderItems[i].intS2 != null ||
        this.deliveryDocketOrderItems[i].intBBT != null
      ) {
        this.showStrengthGroups = true;
      }

      if (this.deliveryDocketOrderItems[i].intS3 != null) {
        this.showStrengthGroup3 = true;
      }

      this.deliveryDocketOrderItems[i]["totalQtyEach"] =
        this.getDeliveryDocketQtyEachTotal(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );
      this.deliveryDocketOrderItems[i]["totalQtyLM"] =
        this.getDeliveryDocketQtyLMTotal(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );
      this.deliveryDocketOrderItems[i]["totalQtyM2"] =
        this.getDeliveryDocketQtyM2Total(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );
      this.deliveryDocketOrderItems[i]["totalQtyBoxes"] =
        this.getDeliveryDocketQtyBoxesTotal(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );
      this.deliveryDocketOrderItems[i]["totalM3"] =
        this.getDeliveryDocketM3Total(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );
      this.deliveryDocketOrderItems[i]["totalTonne"] =
        this.getDeliveryDocketTonneTotal(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );
      this.deliveryDocketOrderItems[i]["uomMultiples"] =
        this.getDeliveryDocketUoMMultiples(
          this.deliveryDocketOrderItems[i].guDeliveryDocketId
        );

      //if (i == 0 || (i > 0 && this.deliveryDocketOrderItems[i - 1].guDeliveryDocketId != this.deliveryDocketOrderItems[i].guDeliveryDocketId)) {
      //  this.deliveryDocketOrderItems[i]['orderMultiples'] = false;
      //}

      //if (i > 0 && i <= this.deliveryDocketOrderItems.length - 1) {
      //  //==============================================================================================================================
      //  // If im on the same delivery docket and the previous items order number is different then we have multiple order numbers
      //  //==============================================================================================================================
      //  if (i > 0 && this.deliveryDocketOrderItems[i].guDeliveryDocketId != deliveryDocketId) {
      //    deliveryDocketId = "";
      //  }
      //  console.log("order numbers differ: " + this.deliveryDocketOrderItems[i].txtOrderNo + " : " + this.deliveryDocketOrderItems[i - 1].txtOrderNo);
      //  console.log("docket numbers differ: " + this.deliveryDocketOrderItems[i].guDeliveryDocketId + " : " + this.deliveryDocketOrderItems[i - 1].guDeliveryDocketId + " == " + this.deliveryDocketOrderItems[i].guDeliveryDocketId == this.deliveryDocketOrderItems[i - 1].guDeliveryDocketId);

      //  if (i == 0 || deliveryDocketId == "") {
      //    this.deliveryDocketOrderItems[i]['orderMultiples'] = (this.deliveryDocketOrderItems[i].txtOrderNo != this.deliveryDocketOrderItems[i - 1].txtOrderNo && this.deliveryDocketOrderItems[i - 1].guDeliveryDocketId == this.deliveryDocketOrderItems[i].guDeliveryDocketId);
      //  }

      //  // Go back one and set the first item to true in the array i.e [i -1] == true.
      //  if (this.deliveryDocketOrderItems[i]['orderMultiples'] == true && i == 1) {
      //    this.deliveryDocketOrderItems[i - 1]['orderMultiples'] = true;
      //  }

      //  deliveryDocketId = this.deliveryDocketOrderItems[i].guDeliveryDocketId;

      //}

      //grandTotalQtyEach += parseFloat(this.deliveryDocketOrderItems[i]['totalQtyEach'].toFixed(2));
      //grandTotalQtyLM += parseFloat(this.deliveryDocketOrderItems[i]['totalQtyLM'].toFixed(2));
      //grandTotalQtyM2 += parseFloat(this.deliveryDocketOrderItems[i]['totalQtyM2'].toFixed(2));
      //grandTotalQtyBoxes += parseFloat(this.deliveryDocketOrderItems[i]['totalQtyBoxes'].toFixed(2));
      grandTtoalM3 += parseFloat(
        this.deliveryDocketOrderItems[i].fltActualM3.toFixed(2)
      );
      grandTtoalTonne += parseFloat(
        this.deliveryDocketOrderItems[i].fltActualTonne.toFixed(2)
      );

      //  console.log("grandTotalQtyEach: " + grandTotalQtyEach);
      //  console.log("grandTotalQtyLM: " + grandTotalQtyLM);
      //  console.log("grandTotalQtyM2: " + grandTotalQtyM2);
      //  console.log("grandTotalQtyBoxes: " + grandTotalQtyBoxes);
    }

    deliveryDocketIds.forEach((d) => {
      grandTotalQtyEach += parseFloat(
        this.getDeliveryDocketQtyEachTotal(d).toFixed(2)
      );
      grandTotalQtyLM += parseFloat(
        this.getDeliveryDocketQtyLMTotal(d).toFixed(2)
      );
      grandTotalQtyM2 += parseFloat(
        this.getDeliveryDocketQtyM2Total(d).toFixed(2)
      );
      grandTotalQtyBoxes += parseFloat(
        this.getDeliveryDocketQtyBoxesTotal(d).toFixed(2)
      );

      console.log("grandTotalQtyEach: " + grandTotalQtyEach);
      console.log("grandTotalQtyLM: " + grandTotalQtyLM);
      console.log("grandTotalQtyM2: " + grandTotalQtyM2);
      console.log("grandTotalQtyBoxes: " + grandTotalQtyBoxes);
    });

    for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {
      this.deliveryDocketOrderItems[i]["grandTotalQtyEach"] = parseFloat(
        grandTotalQtyEach.toFixed(2)
      );
      this.deliveryDocketOrderItems[i]["grandTotalQtyLM"] = parseFloat(
        grandTotalQtyLM.toFixed(2)
      );
      this.deliveryDocketOrderItems[i]["grandTotalQtyM2"] = parseFloat(
        grandTotalQtyM2.toFixed(2)
      );
      this.deliveryDocketOrderItems[i]["grandTotalQtyBoxes"] = parseFloat(
        grandTotalQtyBoxes.toFixed(2)
      );
      this.deliveryDocketOrderItems[i]["grandTotalM3"] = parseFloat(
        grandTtoalM3.toFixed(2)
      );
      this.deliveryDocketOrderItems[i]["grandTotalTonne"] = parseFloat(
        grandTtoalTonne.toFixed(2)
      );
    }

    console.log(this.deliveryDocketOrderItems);
  }

  sumQuantity(deliveryDocketId: string, type: string, isParquetry: boolean) {
    let delOrderItems: dtoDeliveryDocketOrderStockItem[];

    if (isParquetry == false) {
      delOrderItems = this.deliveryDocketOrderItems.filter(
        (a) =>
          a.guDeliveryDocketId == deliveryDocketId &&
          a.txtOrderItemQuantityUom == type
      );
    }

    if (isParquetry == true) {
      delOrderItems = this.deliveryDocketOrderItems.filter(
        (a) =>
          a.guDeliveryDocketId == deliveryDocketId &&
          a.txtOrderItemQuantityUom == type &&
          a.intNoOfParquetryBoxes > 0
      );
    }

    let fltQty: number = 0;
    for (let i = 0; i <= delOrderItems.length - 1; i++) {
      if (isParquetry == false) {
        if (
          delOrderItems[i].fltBoardTallyRecordQuantity != null &&
          delOrderItems[i].fltBoardTallyRecordQuantity > 0
        ) {
          fltQty += parseFloat(
            delOrderItems[i].fltBoardTallyRecordQuantity.toFixed(2)
          );
        } else {
          fltQty += parseFloat(delOrderItems[i].fltQuantity.toFixed(2));
        }
      } else {
        fltQty += parseFloat(delOrderItems[i].intNoOfParquetryBoxes.toFixed(2));
        console.log(
          "fltQty: ",
          delOrderItems[i].intNoOfParquetryBoxes.toFixed(2)
        );
      }
    }

    return parseFloat(fltQty.toFixed(2));
    //return d.fltBoardTallyRecordQuantity != null && d.fltBoardTallyRecordQuantity > 0 ? d.fltBoardTallyRecordQuantity : d.fltQuantity
  }

  calcDeliveryDocketTotals(deliveryDocketIds: string[]) {
    //console.log(this.deliveryDocketOrderItems.reduce((n, { fltActualM3 }) => n + fltActualM3, 0));

    deliveryDocketIds.forEach((d) => {
      this.deliveryDocketTotals.push({
        deliveryDocketId: d,
        totalItems: 0,
        totalQtyEach: this.sumQuantity(d, "Each", false), // this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'Each').reduce((n, { fltQuantity }) => parseFloat((n + fltQuantity).toFixed(2)), 0),
        totalQtyLM: this.sumQuantity(d, "LM", false), // this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'LM').reduce((n, { fltQuantity }) => parseFloat((n + fltQuantity).toFixed(2)), 0),
        totalQtyM2: this.sumQuantity(d, "M2", false), // this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'M2').reduce((n, { fltQuantity }) => parseFloat((n + fltQuantity).toFixed(2)), 0),
        totalQtyBoxes: this.sumQuantity(d, "M2", true), //this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'M2' && a.intNoOfParquetryBoxes > 0).reduce((n, { intNoOfParquetryBoxes }) => parseInt((n + intNoOfParquetryBoxes).toString()), 0),
        totalM3: this.deliveryDocketOrderItems
          .filter((a) => a.guDeliveryDocketId == d)
          .reduce(
            (n, { fltActualM3 }) => parseFloat((n + fltActualM3).toFixed(2)),
            0
          ),
        totalTonne: this.deliveryDocketOrderItems
          .filter((a) => a.guDeliveryDocketId == d)
          .reduce(
            (n, { fltActualTonne }) =>
              parseFloat((n + fltActualTonne).toFixed(2)),
            0
          ),
        multipleUoM: false,
      });
    });

    this.deliveryDocketTotals.forEach((dt) => {
      let multipleUoM: number = 0;
      if (dt.totalQtyEach > 0) {
        multipleUoM += 1;
      }

      if (dt.totalQtyLM > 0) {
        multipleUoM += 1;
      }

      if (dt.totalQtyM2 > 0) {
        multipleUoM += 1;
      }

      if (dt.totalQtyBoxes > 0) {
        multipleUoM += 1;
      }

      if (multipleUoM > 1) {
        dt.multipleUoM = true;
      }
    });

    //let sum: number = this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == deliveryDocketId).reduce((n, { fltActualM3 }) => n + fltActualM3, 0);
    //console.log(sum);

    //console.log("this.deliveryDocketTotals:", this.deliveryDocketTotals);
  }

  getDeliveryDocketM3Total(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        //console.log("this.deliveryDocketTotals[i].totalM3: " + this.deliveryDocketTotals[i].totalM3);
        return this.deliveryDocketTotals[i].totalM3;
      }
    }
  }

  getDeliveryDocketTonneTotal(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        //console.log("this.deliveryDocketTotals[i].totalM3: " + this.deliveryDocketTotals[i].totalM3);
        return this.deliveryDocketTotals[i].totalTonne;
      }
    }
  }

  getDeliveryDocketQtyEachTotal(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        //console.log("this.deliveryDocketTotals[i].totalQtyEach: " + this.deliveryDocketTotals[i].totalQtyEach);
        return this.deliveryDocketTotals[i].totalQtyEach;
      }
    }
  }

  getDeliveryDocketQtyLMTotal(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        //console.log("this.deliveryDocketTotals[i].totalQtyLM: " + this.deliveryDocketTotals[i].totalQtyLM);
        return this.deliveryDocketTotals[i].totalQtyLM;
      }
    }
  }

  getDeliveryDocketQtyM2Total(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        //console.log("this.deliveryDocketTotals[i].totalQtyM2: " + this.deliveryDocketTotals[i].totalQtyM2);
        return this.deliveryDocketTotals[i].totalQtyM2;
      }
    }
  }

  getDeliveryDocketQtyBoxesTotal(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        console.log(
          "this.deliveryDocketTotals[i].totalQtyBoxes: " +
            this.deliveryDocketTotals[i].totalQtyBoxes
        );
        return this.deliveryDocketTotals[i].totalQtyBoxes;
      }
    }
  }

  getDeliveryDocketUoMMultiples(deliveryDocketId: string) {
    for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
      if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
        //console.log("this.deliveryDocketTotals[i].totalQtyBoxes: " + this.deliveryDocketTotals[i].totalQtyBoxes);
        return this.deliveryDocketTotals[i].multipleUoM;
      }
    }
  }

  updateCustomerDeliveryAddress(
    deliveryDocketOrderItem: dtoDeliveryDocketOrderStockItem
  ) {
    let customerDeliveryAddress: dtoCustomerDeliveryAddress;
    this.updateCustomerDeliveryAddressEvent.emit(customerDeliveryAddress);
  }

  async openDeliveryAddressDialog(
    deliveryDocketOrderItem: dtoDeliveryDocketOrderStockItem
  ) {
    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (deliveryDocketOrderItem == null) {
      this.alertService.openSnackBarDefault(
        "There is no delivery docket selected."
      );
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParamList = [];
    aParam = [];
    aParam.push(
      new SQLParamArray(
        "guDeliveryDocketId",
        deliveryDocketOrderItem.guDeliveryDocketId
      )
    );
    aParamList.push(aParam);

    let dtoDelAddress: dtoCustomerDeliveryAddress = null;
    dtoDelAddress =
      await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressPromise(
        deliveryDocketOrderItem.guCustomerDeliveryAddressId
      );

    this.customerDeliveryAddressDialogRef = this.dialog.open(
      CustomerDeliveryAddressDialogComponent,
      {
        height: "auto",
        maxWidth: "90vw",
        data: {
          customerId: deliveryDocketOrderItem.guDeliveryDocketCustomerId,
          customerName: deliveryDocketOrderItem.txtCustomerName,
          selectedCustomerDeliveryAddress: dtoDelAddress,
        },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.customerDeliveryAddressDialogRef.backdropClick().subscribe(() => {
      this.customerDeliveryAddressDialogRef.close();
    });

    this.customerDeliveryAddressDialogRef
      .afterClosed()
      .subscribe(
        async (data: {
          status: string;
          customerDeliveryAddress: ClientDeliveryAddress;
        }) => {
          if (data == null || data == undefined) {
            //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
            return;
          }

          //console.log(data);
          if (data.status != "Cancel") {
            //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

            if (data.customerDeliveryAddress == null) {
              this.alertService.openSnackBar(
                "There is no delivery address selected.",
                "Close",
                "center",
                "bottom",
                "",
                5000
              );
              return;
            }

            if (data.customerDeliveryAddress != null) {
              let delDocketOrderItem: DeliveryDocketOrderItem[] =
                await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemByDocketIdPromise(
                  deliveryDocketOrderItem.guDeliveryDocketId
                );

              for (let i = 0; i <= delDocketOrderItem.length - 1; i++) {
                delDocketOrderItem[i].guCustomerDeliveryAddressId =
                  data.customerDeliveryAddress.rowguid;
                await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(
                  delDocketOrderItem[i]
                );

                //console.log("updateDeliveryDocektOrderItemPromise:", deliveryDocketOrderItem[i]);
              }

              this.loadData();

              //for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {

              //}

              this.alertService.openSnackBarDefault(
                "Delivery address has been updated."
              );
            }
          } else {
            // We have cancellled
          }
        }
      );
  }
}
