import { Injectable } from "@angular/core";
import { FieldQueryParamMap } from "../app-objects/field-query-param-map.ao";
import { Params } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { MatListOption } from "@angular/material/list";

@Injectable()
export class FormService {
  setFieldsFromQueryParams(
    filterMap: FieldQueryParamMap[],
    params: Params,
    form: FormGroup
  ) {
    filterMap.forEach((fieldMap) => {
      if (params[fieldMap.txtQueryParam] != null) {
        //console.log(fieldMap.txtQueryParam + ",  " + params[fieldMap.txtQueryParam] + ", is array: " + Array.isArray(params[fieldMap.txtQueryParam]));

        if (
          Array.isArray(params[fieldMap.txtQueryParam]) ||
          params[fieldMap.txtQueryParam] != "" ||
          fieldMap.intFieldType ==
            FieldQueryParamMap.enFieldType.SelectboxMultiple
        ) {
          let aParams: any[] = [];

          //console.log("Array.isArray: " + Array.isArray(params[fieldMap.txtQueryParam]), "field name: ", fieldMap.txtField, "array values: ", params[fieldMap.txtQueryParam].map(Number));

          if (
            Array.isArray(params[fieldMap.txtQueryParam]) ||
            fieldMap.intFieldType ==
              FieldQueryParamMap.enFieldType.SelectboxMultiple
          ) {
            if (fieldMap.intValueType == FieldQueryParamMap.enValueType.Int) {
              //console.log("is Array: " + fieldMap.txtQueryParam + "  - " + Array.isArray(params[fieldMap.txtQueryParam]));
              if (
                fieldMap.intFieldType ==
                  FieldQueryParamMap.enFieldType.SelectboxMultiple &&
                Array.isArray(params[fieldMap.txtQueryParam]) == false
              ) {
                form.controls[fieldMap.txtField].setValue([
                  parseInt(params[fieldMap.txtQueryParam].toString()),
                ]);
              }

              if (Array.isArray(params[fieldMap.txtQueryParam]) == true) {
                form.controls[fieldMap.txtField].setValue(
                  params[fieldMap.txtQueryParam].map(Number)
                );
              }
            }

            //params[fieldMap.txtQueryParam].forEach(el => {

            //  if (fieldMap.intValueType == FieldQueryParamMap.enValueType.Int) {
            //    aParams.push(parseInt(el.toString()));
            //    form.controls[fieldMap.txtField].setValue(parseInt(el.toString()));
            //    console.log(fieldMap.txtField + ",  value: " + el.toString());
            //  }

            //  if (fieldMap.intValueType == FieldQueryParamMap.enValueType.Float) {
            //    aParams.push(parseFloat(el.toString()));
            //  }

            //  if (fieldMap.intValueType == FieldQueryParamMap.enValueType.String) {
            //    aParams.push(el.toString());
            //  }

            //});
          } else {
            if (fieldMap.intValueType == FieldQueryParamMap.enValueType.Int) {
              aParams.push(parseInt(params[fieldMap.txtQueryParam].toString()));
              //console.log("about to set field value....");
              form.controls[fieldMap.txtField].setValue(
                parseInt(aParams.toString())
              );
              //console.log(fieldMap.txtField + ',  ' + params[fieldMap.txtQueryParam]);
            }

            if (fieldMap.intValueType == FieldQueryParamMap.enValueType.Float) {
              aParams.push(
                parseFloat(params[fieldMap.txtQueryParam].toString())
              );
            }

            if (
              fieldMap.intValueType == FieldQueryParamMap.enValueType.String
            ) {
              aParams.push(params[fieldMap.txtQueryParam].toString());
            }

            if (
              fieldMap.intValueType == FieldQueryParamMap.enValueType.Boolean
            ) {
              //console.log(params[fieldMap.txtQueryParam]);
              if (/true/i.test(params[fieldMap.txtQueryParam])) {
                form.controls[fieldMap.txtField].setValue(true);
                //console.log("set to true");
              }
              if (/false/i.test(params[fieldMap.txtQueryParam])) {
                form.controls[fieldMap.txtField].setValue(false);
                //console.log("set to false");
              }
            }

            if (fieldMap.intValueType == FieldQueryParamMap.enValueType.Date) {
              form.controls[fieldMap.txtField].setValue(
                params[fieldMap.txtQueryParam].toString()
              );
            }
          }

          if (
            fieldMap.intValueType != FieldQueryParamMap.enValueType.Date &&
            fieldMap.intValueType != FieldQueryParamMap.enValueType.Boolean &&
            fieldMap.intValueType != FieldQueryParamMap.enValueType.Int
          ) {
            //console.log("aParams: " + aParams + ",   value type: " + fieldMap.intValueType + ",   " + fieldMap.txtQueryParam + ",  " + params[fieldMap.txtQueryParam] + ", is array: " + Array.isArray(params[fieldMap.txtQueryParam]));
            form.controls[fieldMap.txtField].setValue(aParams.toString());
          }
        }
      }
    });
  }

  setDelayedValue(id: string, value: string) {
    setTimeout(this.setValue, 1000, id, value);
  }

  setAutoFocusTimeout(id: string, delay: number = null) {
    delay = delay != null ? delay : 1000;
    setTimeout(this.setAutoFocus, delay, id);
  }

  setAutoFocusByElement(el: HTMLElement) {
    setTimeout(() => {
      el != null ? el.focus() : null;
    }, 1000);
  }

  private setAutoFocus(id) {
    let txtInput = document.getElementById(id) as HTMLInputElement;
    if (txtInput != null) {
      txtInput.focus();
    }
  }

  private setValue(id: string, value: string) {
    let txtInput = document.getElementById(id) as HTMLInputElement;
    if (txtInput != null) {
      txtInput.value = value;
    }
  }

  scrollIntoView(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  scrollIntoViewById(elementId: string) {
    let el = document.getElementById(elementId);

    el.scrollIntoView({ behavior: "smooth" });
  }

  scrollToMatListOption(
    listId: string,
    selectedValue: any,
    listOptions: MatListOption[]
  ) {
    let list = document.getElementById(listId);

    //console.log("list", list);
    let selectedHeight: number = 0;

    //console.log("listOptions", listOptions);

    for (let i = 0; i <= listOptions.length - 1; i++) {
      let element: HTMLElement = listOptions[i]._hostElement;
      selectedHeight += element.clientHeight;
      //console.log("element.nodeValue: " + element.innerText + ",   value: " + listOptions[i].value + ",   selectedValue: " + selectedValue);
      //console.log("haulerListOptions[i].value == selectedHaulerValue: " + haulerListOptions[i].value.rowguid + " == " + selectedHaulerValue);
      if (listOptions[i].value == selectedValue) {
        break;
      }
      //console.log("selectedHaulerHeight: " + selectedHaulerHeight + ",   clientHeight: " + element.clientHeight + ",   clientLeft: " + element.clientLeft + ",   clientTop: " + element.clientTop);
    }

    if (selectedHeight > 500) {
      list.scrollTo({ top: selectedHeight - 75, left: 0, behavior: "smooth" });
    }
  }

  scrollToListElement(
    listId: string,
    selectedValue: any,
    elementArray: HTMLCollectionOf<Element>,
    valueArray: string[]
  ) {
    let list = document.getElementById(listId);
    let selectedHeight: number = 0;

    for (let i = 0; i <= elementArray.length - 1; i++) {
      let element: Element = elementArray[i];
      selectedHeight += element.clientHeight;

      if (valueArray[i] == selectedValue) {
        break;
      }
    }

    if (selectedHeight > 500) {
      list.scrollTo({ top: selectedHeight - 75, left: 0, behavior: "smooth" });
    }
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
  }

  async pasteFromClipboard() {
    let text = await navigator.clipboard.readText();
    console.log("text:", text);
    return text;
  }
}
