/// <reference path="../../../main.ts" />
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Observable, observable, ReplaySubject, Subject } from 'rxjs';
import { max, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../account/auth.service';
import { Client } from '../../_shared/business-objects/client.bo';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { HaulingCompany } from '../../_shared/business-objects/hauling-company.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientService } from '../../_shared/services/client.service';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { FormService } from '../../_shared/services/form.service';
import { HaulingCompanyService } from '../../_shared/services/hauling-company.service';
import { SharedService } from '../../_shared/services/shared.service';
import { DeliveryDocketDeliveryRateComponent } from '../delivery-docket-delivery-rate/delivery-docket-delivery-rate.component';
import { dtoDeliveryDocketOrderItemService } from '../../_shared/services/dto-delivery-docket-order-item.service';
import { dtoOrdersDeliveryRateService } from '../../_shared/services/dto-orders-delivery-rate.service';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoOrdersDeliveryRate } from '../../_shared/business-objects/dto-orders-delivery-rate.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { LoadingDocketService } from '../../_shared/services/loading-docket.service';
import { OrdersDeliveryRate } from '../../_shared/business-objects/orders-delivery-rate.bo';
import { DeliveryRateService } from '../../_shared/services/delivery-rate.service';
import { OrdersDeliveryRateService } from '../../_shared/services/orders-delivery-rate.service';
import { GeoStockLocationService } from '../../_shared/services/geo-stock-location.service';
import { GeoStockLocation } from '../../_shared/business-objects/geo-stock-location.bo';
import { dtoHaulingCompany } from '../../_shared/business-objects/dto-hauling-company.bo';
import { dtoHaulingCompanyDriver } from '../../_shared/business-objects/dto-hauling-company-driver.bo';
import { dtoHaulingCompanyDriverService } from '../../_shared/services/dto-hauling-company-driver.service';
import { dtoHaulingCompanyService } from '../../_shared/services/dto-hauling-company.service';
import { dtoDeliveryDocketService } from '../../_shared/services/dto-delivery-docket.service';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { dtoOrdersService } from '../../_shared/services/dto-orders.service';
import { dtoOrders } from '../../_shared/business-objects/dto-orders.bo';
import { dtoDeliveryDocketCustomerDeliveryContactService } from '../../_shared/services/dto-delivery-docket-customer-delivery-contact.service';
import { dtoDeliveryDocketCustomerDeliveryContact } from '../../_shared/business-objects/dto-delivery-docket-customer-delivery-contact.bo';
import { DeliveryDocketCustomerDeliveryContact } from '../../_shared/business-objects/delivery-docket-customer-delivery-contact.bo';
import { DeliveryDocketCustomerDeliveryContactService } from '../../_shared/services/delivery-docket-customer-delivery-contact.service';
import { CustomerContactCreateDialogComponent } from '../../customer-contact/customer-contact-create-dialog/customer-contact-create-dialog.component';
import { CustomerContactUpdateDialogComponent } from '../../customer-contact/customer-contact-update-dialog/customer-contact-update-dialog.component';
import { dtoCustomerContactService } from '../../_shared/services/dto-customer-contact.service';
import { dtoCustomerContact } from '../../_shared/business-objects/dto-customer-contact.bo';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';


@Component({
  selector: 'app-delivery-docket-update',
  templateUrl: './delivery-docket-update.component.html',
  styleUrls: ['./delivery-docket-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryDocketUpdateComponent implements OnInit {

  form: FormGroup;

  @Output() deliveryDocketUpdated = new EventEmitter<DeliveryDocket>();
  @Output() deliveryDocketCancelled = new EventEmitter<boolean>();

  @Input() deliveryDocket: DeliveryDocket;
  @Input() loadingDocket: LoadingDocket;
  @Input() orderId: string;

  @ViewChild("guCustomerId") guCustomerId: MatSelectionList;
  //@ViewChild("guCustomerContactId") guCustomerContactId: MatSelectionList;
  @ViewChild("guHaulerId") guHaulerId: MatSelectionList;
  @ViewChild("chkShowAllCustomerContact") chkShowAllCustomerContact: MatCheckbox;

  
 deliveryRateUpdateDialogRef: MatDialogRef<DeliveryDocketDeliveryRateComponent>

  dtoDeliveryDocket: dtoDeliveryDocket;

  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public customers: Client[];
  public customersAll: Client[];
  public customerContacts: dtoCustomerContact[];
  public customerContactsAll: dtoCustomerContact[];
  public selectedCustomerContacts: CustomerContact[] = [];
  public haulers: dtoHaulingCompany[];
  public haulersAll: dtoHaulingCompany[];
  public haulingCompanyDrivers: dtoHaulingCompanyDriver[] = [];
  public haulingCompanyDriversAll: dtoHaulingCompanyDriver[] = [];
  public deliveryRates: dtoOrdersDeliveryRate[];
  public geoStockLocations: GeoStockLocation[];

  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);
  public filteredCustomerContacts: ReplaySubject<CustomerContact[]> = new ReplaySubject<CustomerContact[]>(1);
  public filteredHaulers: ReplaySubject<HaulingCompany[]> = new ReplaySubject<HaulingCompany[]>(1);

  customerContactCreateDialogRef: MatDialogRef<CustomerContactCreateDialogComponent>
  customerContactUpdateDialogRef: MatDialogRef<CustomerContactUpdateDialogComponent>

  ordersDeliveryRateId: string = "";

  private _onDestroy = new Subject<void>();

  deliveryStatuses: dtoKeyValue[] = [];

  public firstNameMaxWidth: number;
  showSpinner: boolean

  addressHoverId: string = "";
  showUpdateButton: boolean;

  constructor(private formBuilder: FormBuilder, private formService: FormService, private customerService: ClientService, private dtoHaulerService: dtoHaulingCompanyService, private sharedService: SharedService
    , private customerContactService: CustomerContactService, private dtoCustomerContactService: dtoCustomerContactService, private alertService: AlertService, private authService: AuthService
    , private deliveryDocketService: DeliveryDocketService, private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService, private dialog: MatDialog
    , private dtoOrdersDeliveryRateService: dtoOrdersDeliveryRateService, private ordersDeliveryRateService: OrdersDeliveryRateService, private loadingDocketService: LoadingDocketService, private deliveryRateService: DeliveryRateService
    , private geoStockLocationService: GeoStockLocationService, private dtoHaulingCompanyDriverService: dtoHaulingCompanyDriverService
    , private dtoDeliveryDocketService: dtoDeliveryDocketService, private dtoOrdersService: dtoOrdersService, private dtoDeliveryDocketCustomerDeliveryContactService: dtoDeliveryDocketCustomerDeliveryContactService
    , private deliveryDocketCustomerDeliveryContactService: DeliveryDocketCustomerDeliveryContactService  ) {

    this.deliveryStatuses = this.sharedService.getEnumObject2(DeliveryDocket.enDeliveryStatus, true);

  }

  ngOnInit(): void {


    this.form = this.formBuilder.group({
      guCustomerId: ['', Validators.required],
      guCustomerContactId: ['', Validators.required],
      guHaulerId: [''],
      guHaulingCompanyDriverId: [''],
      dteStatusDate: ['', Validators.required],
      dteExpectedLoadingDate: [''],
      dteExpectedDeliveryDate: [''],
      intStatus: [''],
      guOrdersDeliveryRateId: [''],
      txtDeliveryCharge: [''],
      txtEscort: [''],
      txtAdditionalKM: [''],
      txtComments: [''],
      guGeoStockLocationId: ['']
    });


    if (this.deliveryDocket == null) {
      this.alertService.openSnackBar("There is no delivery docket to update", "Close", "center", "bottom", "", 4000);
      return;
    }

    //this.orderId = "DB20C66A-1954-4463-B84C-DB0FAD9A9921";

    //console.log(("delierydocket: ", this.deliveryDocket);

    this.showSpinner = false;

    this.firstNameMaxWidth = 50;

    this.loadData();

  }

  async loadData() {

    this.dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(this.deliveryDocket.rowguid);

    await this.getCustomers();
    await this.getCustomerContacts(this.deliveryDocket.guCustomerId, false);
    await this.getHaulers();
    await this.getHaulingCompanyDrivers(this.deliveryDocket.guHaulingId);
    await this.getDeliveryRates();
    await this.getGeoStockLocation();

    await this.setFormValues();

  }

  async setFormValues() {

    this.form.controls['guCustomerId'].setValue([this.deliveryDocket.guCustomerId]);
    this.form.controls['guHaulerId'].setValue([this.deliveryDocket.guHaulingId]);
    this.form.controls['guHaulingCompanyDriverId'].setValue([this.deliveryDocket.guDriverId]);
    this.form.controls['dteStatusDate'].setValue(this.deliveryDocket.dteStatusDate);
    this.form.controls['dteExpectedLoadingDate'].setValue(this.deliveryDocket.dteExpectedLoadingDate);
    this.form.controls['dteExpectedDeliveryDate'].setValue(this.deliveryDocket.dteExpectedDeliveryDate);
    this.form.controls['intStatus'].setValue(this.deliveryDocket.intStatus);
    this.form.controls['txtDeliveryCharge'].setValue(this.deliveryDocket.fltDeliveryCharge);
    this.form.controls['txtEscort'].setValue(this.deliveryDocket.fltEscort);
    this.form.controls['txtAdditionalKM'].setValue(this.deliveryDocket.fltAdditionalKM);
    this.form.controls['txtComments'].setValue(this.deliveryDocket.txtComments);
    this.form.controls['guGeoStockLocationId'].setValue(this.deliveryDocket.guGeoStockLocationId);
    this.form.controls['guOrdersDeliveryRateId'].setValue([this.ordersDeliveryRateId]);

    //console.log("going to set customer contacts");
    await this.setCustomerContacts();

    //guOrdersDeliveryRateId
  }


  async customerChanged(matSelectChange: MatSelectChange) {

    //this.resetOrders();

    if (matSelectChange.value == null || matSelectChange.value == "") {
      return;
    }

    await this.getCustomerContacts(matSelectChange.value, true)
    await this.setCustomerContacts();

  }


  async getCustomers() {

    //console.log(("start get customers: " + Date.now());


    let customers = await this.customerService.getActiveCustomers(true, false);
    if (customers == null) {
      return;
    }

    this.customersAll = customers;

    console.log(("txtCustomerName.substring(0, 4): " + this.dtoDeliveryDocket.txtCustomerName.substring(0, this.dtoDeliveryDocket.txtCustomerName.length - 1)));

    // Filter on the first two letters of customers name to reduce the list.
    // Then scroll to item in list.
    await this.filterCustomers(this.dtoDeliveryDocket.txtCustomerName.substring(0, this.dtoDeliveryDocket.txtCustomerName.length - 1));

    setTimeout(() => {
      this.formService.scrollToMatListOption("guCustomerId", this.deliveryDocket.guCustomerId, this.guCustomerId.options.toArray());
    }, 500);

    //this.customers = customers.slice(0, 50);

  }

 
  async getCustomerContacts(customerId: string, reset: boolean) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (customerId == null) {
      return false;
    }

    aParam.push(new SQLParamArray("guCustomerId", customerId));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    //let customerContacts: dtoCustomerContact[] = await this.dtoCustomerContactService.getdtoCustomerContactParamArrayPromise(aParamList);
    let customerContactsOnDocket: dtoCustomerContact[] = await this.dtoCustomerContactService.getdtoCustomerContactsOnDeliveryDocket(this.deliveryDocket.guCustomerId, this.deliveryDocket.rowguid);
    let customerContactsNotOnDocket = this.dtoCustomerContactService.getdtoCustomerContactsNotOnDeliveryDocket(this.deliveryDocket.guCustomerId, this.deliveryDocket.rowguid, reset);
    let selectedCustomerContactsOnDocket: CustomerContact[] = await this.customerContactService.getCustomerContactsOnDeliveryDocket(this.deliveryDocket.guCustomerId, this.deliveryDocket.rowguid);

    if (customerContactsOnDocket == null) {
      return;
    }

    aParamList = [];
    aParam = [];

    aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
    aParamList.push(aParam);

    let deliveryDocketCustomerContacts: dtoDeliveryDocketCustomerDeliveryContact[] = await this.dtoDeliveryDocketCustomerDeliveryContactService.getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

    if (deliveryDocketCustomerContacts != null) {
      for (let i = 0; i <= deliveryDocketCustomerContacts.length - 1; i++) {
        for (let c = 0; c <= customerContactsOnDocket.length - 1; c++) {
          if (deliveryDocketCustomerContacts[i].guCustomerContactId == customerContactsOnDocket[c].rowguid) {
            customerContactsOnDocket[c].intContactOrder = deliveryDocketCustomerContacts[i].intContactOrder;
            break;
          }
        }
      }
    }

    if (selectedCustomerContactsOnDocket != null) {
      this.selectedCustomerContacts = selectedCustomerContactsOnDocket;
    }


    //console.log(customerContacts);

    //customerContactsOnDocket.sort((a, b) => { return (a.txtLastName > b.txtLastName ? 1 : -1) || (a.txtName > b.txtName ? 1 : -1) });
    customerContactsOnDocket.sort((a, b) => { return a.intContactOrder - b.intContactOrder });

    this.customerContactsAll = customerContactsOnDocket;
    this.customerContacts = customerContactsOnDocket;


    this.setCustomerContacts2();


  }

  async getAllCustomerContacts(checked: boolean, reset: boolean) {

    //console.log("checked: " + checked);

    let customerContactsOnDocket: dtoCustomerContact[] = await this.dtoCustomerContactService.getdtoCustomerContactsOnDeliveryDocket(this.deliveryDocket.guCustomerId, this.deliveryDocket.rowguid);
    //customerContactsOnDocket.sort((a, b) => { return (a.txtLastName > b.txtLastName ? 1 : -1) || (a.txtName > b.txtName ? 1 : -1) });
    customerContactsOnDocket.sort((a, b) => { return a.intContactOrder - b.intContactOrder });

    if (checked == true) {
      let customerContactsNotOnDocket: dtoCustomerContact[] = await this.dtoCustomerContactService.getdtoCustomerContactsNotOnDeliveryDocket(this.deliveryDocket.guCustomerId, this.deliveryDocket.rowguid, reset);
      //customerContactsNotOnDocket.sort((a, b) => { return (a.txtLastName > b.txtLastName ? 1 : -1) || (a.txtName > b.txtName ? 1 : -1) });
      customerContactsOnDocket.sort((a, b) => { return a.intContactOrder - b.intContactOrder });

      this.customerContactsAll = customerContactsOnDocket.concat(customerContactsNotOnDocket);
      this.customerContacts = customerContactsOnDocket.concat(customerContactsNotOnDocket);
      //console.log("in customerContactsNotOnDocket", customerContactsNotOnDocket);
    }

    if (checked == false) {
      this.customerContactsAll = customerContactsOnDocket;
      this.customerContacts = customerContactsOnDocket;
    }

    setTimeout(() => { this.setCustomerContacts2() }, 500);

  }

  async setCustomerContacts2() {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
    aParamList.push(aParam);


    let deliveryDocketCustomerContacts: dtoDeliveryDocketCustomerDeliveryContact[] = await this.dtoDeliveryDocketCustomerDeliveryContactService.getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

    if (deliveryDocketCustomerContacts != null && deliveryDocketCustomerContacts.length > 0) {
      for (let i = 0; i <= deliveryDocketCustomerContacts.length - 1; i++) {
        for (let c = 0; c <= this.customerContacts.length - 1; c++) {
          if (deliveryDocketCustomerContacts[i].guCustomerContactId == this.customerContacts[c].rowguid) {
            this.customerContacts[c].guCustomerDeliveryContactId = deliveryDocketCustomerContacts[i].rowguid;
          }

        }

      }
    }
  }

  async setCustomerContacts() {

    if (this.customerContacts == null) {
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    let deliveryContactIds: string[] = [];

    this.customerContacts.forEach(async c => {
      aParamList = [];
      aParam = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("guCustomerContactId", c.rowguid));
      aParamList.push(aParam);

      let deliveryDocketCustomerContacts: dtoDeliveryDocketCustomerDeliveryContact[] = await this.dtoDeliveryDocketCustomerDeliveryContactService.getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

      if (deliveryDocketCustomerContacts != null && deliveryDocketCustomerContacts.length > 0) {
        deliveryContactIds.push(deliveryDocketCustomerContacts[0].guCustomerContactId);
      }
    });


    //this.form.controls['guCustomerContactId'].setValue(deliveryContactIds);
    //setTimeout(() => { this.form.controls['guCustomerContactId'].setValue(deliveryContactIds) }, 1000);


  }


  returnContactOptions(e) {
    //console.log((e);
    if (this.customerContacts == null) {
      return "";
    }

    //return "<div class='customer-contact-first-name'>" + this.customerContacts[index].txtName + "</div> <div class='customer-contact-last-name'>" + this.customerContacts[index].txtLastName + "</div>";

  }


  async getHaulers() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];

    paramArray.push(new SQLParamArray("blnActive", "1"));
    paramArrayList.push(paramArray);

    let haulers = await this.dtoHaulerService.getdtoHaulingCompanyParamArrayPromise(paramArrayList);
    if (haulers == null) {
      return;
    }

    let blankHaulingCompany: dtoHaulingCompany = new dtoHaulingCompany();
    blankHaulingCompany.rowguid = null;
    blankHaulingCompany.txtName = "None";

    haulers.splice(0, 0, blankHaulingCompany);

    this.haulersAll = haulers;
    this.haulers = haulers;

    //console.log(("got haulers: " + Date.now());

    //console.log("this.dtoDeliveryDocket.txtHaulingCompanyName", this.dtoDeliveryDocket.txtHaulingCompanyName);
    if (this.dtoDeliveryDocket.txtHaulingCompanyName != null) {
      //First load filter by chh vechiles.
      this.filterHaulers(this.dtoDeliveryDocket.txtHaulingCompanyName.substring(0,3), this.dtoDeliveryDocket.blnHaulingCompanyCHHVehicle);

      // Then scroll to item in list.
      setTimeout(() => {
        this.formService.scrollToMatListOption("guHaulerId", this.deliveryDocket.guHaulingId, this.guHaulerId.options.toArray());
      }, 500);

    }

  }


  async getDeliveryRates() {

    //console.log("orderid: " + this.orderId);

    if (this.orderId == null || this.orderId == "") {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);

      //console.log("aParam: ", aParam);

      let deliveryDocketRates: OrdersDeliveryRate[] = await this.dtoOrdersDeliveryRateService.getdtoOrdersDeliveryRateParamArrayPromise(aParamList);

      //console.log("deliveryDocketRates: ", deliveryDocketRates);

      if (deliveryDocketRates != null && deliveryDocketRates.length > 0) {
        this.orderId = deliveryDocketRates[0].guOrderId;
      }

      if (this.orderId == null) {


        //console.log((" this.dtoDeliveryDocket.txtOrderNo: " + this.dtoDeliveryDocket.txtOrderNo);
        if (this.dtoDeliveryDocket.txtOrderNo != null) {
          let ordernumbers: string[] = this.dtoDeliveryDocket.txtOrderNo.split(",");
          if (Array.isArray(ordernumbers)) {

            aParamList = [];
            aParam = [];

            aParam.push(new SQLParamArray("txtOrderNo", ordernumbers[0]));
            aParamList.push(aParam);
            let dtoOrders: dtoOrders[] = await this.dtoOrdersService.getdtoOrdersParamArrayPromise(aParamList);

            if (dtoOrders != null && dtoOrders.length > 0) {
              this.orderId = dtoOrders[0].rowguid;
            }

            //console.log((" ordernumbers: " + ordernumbers[0]);

          }
        }

      }

      if (this.orderId == null) {
        return;
      }



      //console.log(("deliveryDocketRates - orderId: " + this.orderId);


    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", this.orderId));
    aParamList.push(aParam);

    this.deliveryRates = await this.dtoOrdersDeliveryRateService.getdtoOrdersDeliveryRateParamArrayPromise(aParamList);

    //console.log("this.deliveryRates", this.deliveryRates);
    //console.log(("got deliveryRates: " + Date.now());

    //Add Blank Option
    let orderDelRate: dtoOrdersDeliveryRate = new dtoOrdersDeliveryRate();
    orderDelRate.rowguid = null;
    orderDelRate.fltPrice = 0;
    orderDelRate.fltEscort = 0;
    orderDelRate.fltAdditionalKM = 0;
    this.deliveryRates.splice(0, 0, orderDelRate);

    this.ordersDeliveryRateId = null;

    for (let i = 0; i <= this.deliveryRates.length - 1; i++) {
      //console.log(("this.deliveryDocket.rowguid: " + this.deliveryDocket.rowguid + "  ==  this.deliveryRates[i].guDeliveryDocketId: " + this.deliveryRates[i].guDeliveryDocketId);
      if (this.deliveryDocket.rowguid == this.deliveryRates[i].guDeliveryDocketId) {
        this.ordersDeliveryRateId = this.deliveryRates[i].rowguid;
        break;
      }
    }

    //console.log("this.deliveryRates with blank", this.deliveryRates);

  }

  async getGeoStockLocation() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];

    paramArray.push(new SQLParamArray("blnActive", "1"));
    paramArrayList.push(paramArray);

    let geostocklocations = await this.geoStockLocationService.getGeoStockLocationsPromise(paramArrayList);
    if (geostocklocations == null) {
      return;
    }

    let blankGeoStockLocation: GeoStockLocation = new GeoStockLocation;
    blankGeoStockLocation.txtLocationName = "None";
    blankGeoStockLocation.rowguid = this.sharedService.EmptyGuid;
    geostocklocations.splice(0, 0, blankGeoStockLocation);

    this.geoStockLocations = geostocklocations;

    //console.log(("got geoStockLocations: " + Date.now());

  }

  filterCustomers(customerFilter: string) {

    let customers: Client[] = this.customersAll;

    customers = customers.filter(a => ((a.txtName.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1)));

    //console.log("customers filter: ", customers);

    customers = customers.slice(0, 50);

    this.customers = customers;

    //if (this.selectedCustomer != null) {
    //  setTimeout(() => { this.scrollToHauler(), 500 });
    //}

  }

  async getHaulingCompanyDrivers(haulingCompanyId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    this.haulingCompanyDriversAll = [];

    //console.log("haulingCompanyId", haulingCompanyId);

    if (haulingCompanyId != null && haulingCompanyId != "") {
      aParam.push(new SQLParamArray("guHaulingCompanyId", haulingCompanyId));
      aParamList.push(aParam);

      this.haulingCompanyDriversAll = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(aParamList);
      //console.log(("this.haulingCompanyDriversAll: ", this.haulingCompanyDriversAll);
      this.haulingCompanyDriversAll.sort((a, b) => { return (a.txtFirstName > b.txtFirstName ? 1 : -1) });
    }

    let blankHaulingCompany: dtoHaulingCompanyDriver = new dtoHaulingCompanyDriver();
    blankHaulingCompany.rowguid = null;
    blankHaulingCompany.txtFirstName = "None";

    this.haulingCompanyDriversAll.splice(0, 0, blankHaulingCompany);

    this.haulingCompanyDrivers = this.haulingCompanyDriversAll;

    //console.log(("got haulingCompanyDrivers: " + Date.now());


    for (let i = 0; i <= this.haulingCompanyDrivers.length - 1; i++) {
      //console.log("blnDefaultDriver: ", this.haulingCompanyDrivers[i].blnDefaultDriver);
      if (this.haulingCompanyDrivers[i].blnDefaultDriver == true) {
        this.form.controls['guHaulingCompanyDriverId'].setValue([this.haulingCompanyDrivers[i].rowguid]);
        break;
      }
    }


  }

  //private filterCustomers() {

  //  if (!this.customers) {
  //    return;
  //  }

  //  // get the search keyword
  //  let search = this.customersFilterCtrl.value;
  //  if (!search) {
  //    this.filteredCustomers.next(this.customers);
  //    return;
  //  } else {
  //    search = search.toLowerCase();
  //  }

  //  // filter the ProductTypes
  //  this.filteredCustomers.next(
  //    this.customers.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
  //  );
  //}

  //private filterCustomerContacts() {

  //  if (!this.customerContacts) {
  //    return;
  //  }

  //  // get the search keyword
  //  let search = this.customerContactsFilterCtrl.value;
  //  if (!search) {
  //    this.filteredCustomerContacts.next(this.customerContacts);
  //    return;
  //  } else {
  //    search = search.toLowerCase();
  //  }

  //  // filter the ProductTypes
  //  this.filteredCustomerContacts.next(
  //    this.customerContacts.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
  //  );
  //}

  //private filterHaulers() {

  //  if (!this.haulers) {
  //    return;
  //  }

  //  // get the search keyword
  //  let search = this.haulersFilterCtrl.value;
  //  if (!search) {
  //    this.filteredHaulers.next(this.haulers);
  //    return;
  //  } else {
  //    search = search.toLowerCase();
  //  }

  //  // filter the ProductTypes
  //  this.filteredHaulers.next(
  //    this.haulers.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
  //  );
  //}

  filterCustomerContacts(customerFilter: string) {

    let customerContacts: dtoCustomerContact[] = this.customerContactsAll;

    customerContacts = customerContacts.filter(a => ((a.txtName.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1) || (String(a.txtLastName).toLowerCase().indexOf(customerFilter.toLowerCase()) > -1)));

    customerContacts = customerContacts.slice(0, 50);

    customerContacts.sort((a, b) => { return (a.txtLastName > b.txtLastName ? 1 : -1) || (a.txtName > b.txtName ? 1 : -1) });

    this.customerContacts = customerContacts;

  }

  filterHaulers(haulerFilter: string, chhVehicles: boolean) {

    let haulers: dtoHaulingCompany[] = this.haulersAll;

    haulers = haulers.filter(a => ((a.txtName.toLowerCase().indexOf(haulerFilter.toLowerCase()) > -1) || a.txtName == "None"));

    if (chhVehicles == true) {
      haulers = haulers.filter(a => (a.blnCHHVehicle == chhVehicles && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtName == "None");

    }
    haulers = haulers.slice(0, 50);

    this.haulers = haulers;

  }

  async addCustomerContact() {
    if (this.customerContactCreateDialogRef != null) {
      this.customerContactCreateDialogRef.close();
    }


    this.customerContactCreateDialogRef = this.dialog.open(CustomerContactCreateDialogComponent, {
      height: '95vh',
      maxWidth: '90vw',
      data: { customerId: this.deliveryDocket.guCustomerId, customerName: this.dtoDeliveryDocket.txtCustomerName }
    });

    this.customerContactCreateDialogRef.backdropClick().subscribe(() => {
      this.customerContactCreateDialogRef.close();
    });


    this.customerContactCreateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customerContact: CustomerContact }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          this.selectedCustomerContacts.push(data.customerContact);

          await this.saveCustomerContact(data.customerContact.rowguid);

          await this.getCustomerContacts(this.deliveryDocket.guCustomerId, true);
          //setTimeout(async () => { await this.setCustomerContacts2() }, 500);

          this.alertService.openSnackBarDefault("The Customer Contact has been added");

        }
        else {
          // We have cancellled

        }

      });

  }

  showEditButton() {
    this.showUpdateButton = !this.showUpdateButton;
  }

  async updateCustomerContact(customerContactId: string) {

    //console.log(customerContactId);
    if (customerContactId == null || customerContactId == "") {
      this.alertService.openSnackBarDefault("There is no contact to edit");
      return;
    }

    let customerContact: CustomerContact = await this.customerContactService.getCustomerContactPromise(customerContactId);

    if (customerContact == null) {
      this.alertService.openSnackBarDefault("There is no contact to edit");
      return;
    }


    //console.log(customerContact);

    if (this.customerContactUpdateDialogRef != null) {
      this.customerContactUpdateDialogRef.close();
    }

    this.customerContactUpdateDialogRef = this.dialog.open(CustomerContactUpdateDialogComponent, {
      height: '95vh',
      maxWidth: '90vw',
      data: { customerId: this.deliveryDocket.guCustomerId, customerName: this.dtoDeliveryDocket.txtCustomerName, selectedCustomerContact: customerContact }
    });

    this.customerContactUpdateDialogRef.backdropClick().subscribe(() => {
      this.customerContactUpdateDialogRef.close();
    });


    this.customerContactUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customerContact: CustomerContact }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {

          //this.selectedCustomerContacts.push(data.customerContact);
          //await this.saveCustomerContact(data.customerContact.rowguid);
          if (this.chkShowAllCustomerContact.checked == true) {
            await this.getAllCustomerContacts(true, true);
          }


          if (this.chkShowAllCustomerContact.checked == false) {
            await this.getCustomerContacts(this.deliveryDocket.guCustomerId, true);
          }
          //setTimeout(async () => { await this.setCustomerContacts2() }, 500);

          this.showUpdateButton = false;

          this.alertService.openSnackBarDefault("The Customer Contact has been updated");

        }
        else {
          // We have cancellled

        }

      });

  }


  async updateDeliveryContact(val: MatSelectionListChange) {

    if (val.options[0] == null) {
      this.alertService.openSnackBarDefault("There was a problem updating the customer contact - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove Customer Contact
    if (val.options[0].selected == false) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("guCustomerContactId", val.options[0].value));
      aParamList.push(aParam);

      let deliveryDocketCustomerContacts: dtoDeliveryDocketCustomerDeliveryContact[] = await this.dtoDeliveryDocketCustomerDeliveryContactService.getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

      if (deliveryDocketCustomerContacts != null && deliveryDocketCustomerContacts.length > 0) {
        await this.deliveryDocketCustomerDeliveryContactService.deleteDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerContacts[0].rowguid);
        this.alertService.openSnackBarDefault("The delivery contact has been removed.");
      }


    }

    // Add Customer Contact
    if (val.options[0].selected == true) {
      this.saveCustomerContact(val.options[0].value);

      //let deliveryDocketCustomerContact: DeliveryDocketCustomerDeliveryContact = new DeliveryDocketCustomerDeliveryContact();
      //deliveryDocketCustomerContact.guCustomerContactId = val.options[0].value;
      //deliveryDocketCustomerContact.guDeliveryDocketId = this.deliveryDocket.rowguid;
      //deliveryDocketCustomerContact.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
      //deliveryDocketCustomerContact = await this.deliveryDocketCustomerDeliveryContactService.createDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerContact);

      //if (deliveryDocketCustomerContact != null) {
      //  this.alertService.openSnackBarDefault("The delivery contact has been added.");
      //}

    }

  }

  async updateDeliveryContact2(customerContact: dtoCustomerContact, checked: boolean) {

    // Dont want to do anything if we are in update mode.
    if (this.showUpdateButton == true) {
      return;
    }

    //console.log("checked: ", checked);


    if (customerContact == null) {
      this.alertService.openSnackBarDefault("There was a problem updating the customer contact - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove Customer Contact
    if (checked == false) {
      customerContact.guCustomerDeliveryContactId = "";

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("guCustomerContactId", customerContact.rowguid));
      aParamList.push(aParam);

      let deliveryDocketCustomerContacts: dtoDeliveryDocketCustomerDeliveryContact[] = await this.dtoDeliveryDocketCustomerDeliveryContactService.getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

      if (deliveryDocketCustomerContacts != null && deliveryDocketCustomerContacts.length > 0) {
        await this.deliveryDocketCustomerDeliveryContactService.deleteDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerContacts[0].rowguid);
        this.alertService.openSnackBarDefault("The delivery contact has been removed.");

        for (let i = this.selectedCustomerContacts.length - 1; i >= 0; i--) {
          if (this.selectedCustomerContacts[i].rowguid == customerContact.rowguid) {
            this.selectedCustomerContacts.splice(i, 1);
            customerContact.guCustomerDeliveryContactId = "";
            break;
          }
        }

      }

      //Reorder contact order number
      aParamList = [];
      aParam = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);
      let delDocketCustomerContacts: DeliveryDocketCustomerDeliveryContact[] = await this.deliveryDocketCustomerDeliveryContactService.getDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);
      if (delDocketCustomerContacts != null && delDocketCustomerContacts.length > 0) {
        delDocketCustomerContacts.sort((a, b) => { return a.intContactOrder - b.intContactOrder });
        for (let i = 0; i <= delDocketCustomerContacts.length - 1; i++) {
          delDocketCustomerContacts[i].intContactOrder = i + 1
          await this.deliveryDocketCustomerDeliveryContactService.updateDeliveryDocketCustomerDeliveryContactPromise(delDocketCustomerContacts[i]);
        }
      }

    }

    // Add Customer Contact
    if (checked == true) {
      customerContact.guCustomerDeliveryContactId = "tempvalue";
      customerContact.intContactOrder = this.selectedCustomerContacts.length + 1

      //console.log("customerContact.intContactOrder: " + customerContact.intContactOrder);

      let custContact: CustomerContact = await this.customerContactService.getCustomerContactPromise(customerContact.rowguid);
      this.selectedCustomerContacts.push(custContact);

      await this.saveCustomerContact(customerContact.rowguid);

      this.customerContacts.sort((a, b) => { return a.intContactOrder - b.intContactOrder });

    }

    this.getCustomerContacts(this.deliveryDocket.guCustomerId, true);

  }

  async saveCustomerContact(customerContactId: string) {

    let maxContacts: number = 0;
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
    aParamList.push(aParam);

    let deliveryDocketCustomerContacts = await this.deliveryDocketCustomerDeliveryContactService.getDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

    if (deliveryDocketCustomerContacts != null && deliveryDocketCustomerContacts.length > 0) {
      maxContacts = deliveryDocketCustomerContacts.length;
    }

    let deliveryDocketCustomerContact: DeliveryDocketCustomerDeliveryContact = new DeliveryDocketCustomerDeliveryContact();
    deliveryDocketCustomerContact.guCustomerContactId = customerContactId;
    deliveryDocketCustomerContact.guDeliveryDocketId = this.deliveryDocket.rowguid;
    deliveryDocketCustomerContact.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
    deliveryDocketCustomerContact.intContactOrder = maxContacts + 1;
    deliveryDocketCustomerContact = await this.deliveryDocketCustomerDeliveryContactService.createDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerContact);

    if (deliveryDocketCustomerContact != null) {
      this.alertService.openSnackBarDefault("The delivery contact has been added.");
    }

  }


  async updateDeliveryDocket() {

    let customerId: string = this.form.controls['guCustomerId'].value[0];
    //let customerContactId: string = this.form.controls['guCustomerContactId'].value[0];
    let haulerId: string = this.form.controls['guHaulerId'].value;
    let driverId: string = this.form.controls['guHaulingCompanyDriverId'].value;
    let statusDate: Date = this.form.controls['dteStatusDate'].value;
    let status: number = this.form.controls['intStatus'].value;
    let expectedLoadingDate: string = this.form.controls['dteExpectedLoadingDate'].value;
    let expectedDeliveryDate: string = this.form.controls['dteExpectedDeliveryDate'].value;
    let deliveryCharge: number = parseFloat(this.form.controls['txtDeliveryCharge'].value);
    let escort: number = parseFloat(this.form.controls['txtEscort'].value);
    let additionalKM: number = parseFloat(this.form.controls['txtAdditionalKM'].value);
    let ordersDeliveryRateId = this.form.controls['guOrdersDeliveryRateId'].value[0];
    let comments: string = this.form.controls['txtComments'].value;
    let geoStockLocationId: string = this.form.controls['guGeoStockLocationId'].value;
   const datepipe: DatePipe = new DatePipe('en-AU');


    if (customerId == null || customerId.toString() == "") {
      this.alertService.openSnackBar("Please select a customer.", "Close", "center", "bottom", "", 3000);
      return;
    }


    //if (customerContactId == null || customerContactId.toString() == "") {
    //  this.alertService.openSnackBar("Please select a customer contact.", "Close", "center", "bottom", "", 3000);
    //  return;
    //}

    if (this.selectedCustomerContacts == null || this.selectedCustomerContacts.length == 0) {
      this.alertService.openSnackBar("Please select a customer contact.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (statusDate == null || statusDate.toString() == "") {
      this.alertService.openSnackBar("Please select a status date.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (status == null || status < 0) {
      this.alertService.openSnackBar("Please select a status.", "Close", "center", "bottom", "", 3000);
      return;
    }



    this.deliveryDocket.intSystem = 0;
    //this.deliveryDocket.intDeliveryType = DeliveryDocket.enDeliveryType.Delivery;
    //this.deliveryDocket.dteCreatedDate = this.sharedService.currentDatePlusTZOffset();
    this.deliveryDocket.guCustomerId = customerId;

    //this.deliveryDocket.guCustomerContactId = customerContactId;

    this.deliveryDocket.guHaulingId = null;
    if (haulerId != null && haulerId[0] != "") {
      this.deliveryDocket.guHaulingId = haulerId[0];
    }

    this.deliveryDocket.guDriverId = null;
    if (driverId != null && driverId[0] != "") {
      this.deliveryDocket.guDriverId = driverId[0];
    }


    //this.deliveryDocket.guEmployeeCreatedId = this.authService.getAppUserEmployeeId();
    this.deliveryDocket.dteStatusDate = this.sharedService.convertDateAEST(new Date(statusDate));

    //console.log("comments: ", comments);

    this.deliveryDocket.intStatus = status;

    //console.log(("expected loading date: ", expectedLoadingDate);
    this.deliveryDocket.dteExpectedLoadingDate = null;
    if (expectedLoadingDate != null && expectedLoadingDate != "") {
      this.deliveryDocket.dteExpectedLoadingDate = this.sharedService.convertDateAEST(new Date(expectedLoadingDate));
    }

    //console.log(("expected delivery date: ", expectedDeliveryDate);
    this.deliveryDocket.dteExpectedDeliveryDate = null;
    if (expectedDeliveryDate != null && expectedDeliveryDate != "") {
      this.deliveryDocket.dteExpectedDeliveryDate = this.sharedService.convertDateAEST(new Date(expectedDeliveryDate));
    }


    this.deliveryDocket.fltDeliveryCharge = deliveryCharge;
    this.deliveryDocket.fltEscort = escort;
    this.deliveryDocket.fltAdditionalKM = additionalKM;
    this.deliveryDocket.txtComments = comments;

    this.deliveryDocket.guGeoStockLocationId = null;
    if (geoStockLocationId != null && geoStockLocationId != "" && geoStockLocationId != this.sharedService.EmptyGuid) {
      this.deliveryDocket.guGeoStockLocationId = geoStockLocationId;
    }

    this.showSpinner = true;

    //console.log((this.deliveryDocket);

    this.deliveryDocket = await this.deliveryDocketService.updateDeliveryDocketPromise(this.deliveryDocket);

    //console.log("ordersDeliveryRateId", ordersDeliveryRateId);

    if (ordersDeliveryRateId == null && this.ordersDeliveryRateId != null) {
      let msg: string = await this.alertService.openSnackBarCustomPromise("Delivery Rate", "Are you sure you want to set the delivery rate to None (zero value)?", "Yes", "No", "center", "top", "centerSnackbar");
      if (msg != "Yes") {
        this.alertService.openSnackBarDefault("Update cancelled", "Close","center", "top");
        return;
      }

      //Remove Orders Delivery Rate because we have selected None.
      if (this.ordersDeliveryRateId != null) {
        let ordersDeliveryRate: OrdersDeliveryRate = await this.ordersDeliveryRateService.getOrdersDeliveryRatePromise(this.ordersDeliveryRateId);
        if (ordersDeliveryRate != null) {
          ordersDeliveryRate.guDeliveryDocketId = null;
          await this.ordersDeliveryRateService.updateOrdersDeliveryRatePromise(ordersDeliveryRate);
          this.alertService.openSnackBarDefault("The Delivery Rate has been removed", "Close", "center", "top");
        }
      }
    }

    if (ordersDeliveryRateId != null && ordersDeliveryRateId != "") {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);

      let deliveryDocketRates: OrdersDeliveryRate[] = await this.dtoOrdersDeliveryRateService.getdtoOrdersDeliveryRateParamArrayPromise(aParamList);

      // WE NEED TO UPDATE EXISTING guDeliveryDocketId in ORDERS DELIVERY RATE to null.
      // SHOULD ONLY HAVE ONE DELIVERY DOCKET LINKED TO AN ORDERS DELIVERY RATE RECORD.
      if (deliveryDocketRates != null && deliveryDocketRates.length > 0) {
        let orderDeliveryRateOld: OrdersDeliveryRate = deliveryDocketRates[0];
        orderDeliveryRateOld.guDeliveryDocketId = null;
        await this.ordersDeliveryRateService.updateOrdersDeliveryRatePromise(orderDeliveryRateOld);
      }



      let orderDeliveryRate: OrdersDeliveryRate = await this.ordersDeliveryRateService.getOrdersDeliveryRatePromise(ordersDeliveryRateId);

      orderDeliveryRate.guDeliveryDocketId = this.deliveryDocket.rowguid;
      this.ordersDeliveryRateService.updateOrdersDeliveryRatePromise(orderDeliveryRate);

    }


    if (this.loadingDocket != null) {

      this.loadingDocket.guHaulerId = this.deliveryDocket.guHaulingId;

      if (haulerId == "") {
        this.loadingDocket.guHaulerId = null;
      }
    }

    if (this.deliveryDocket != null) {
      this.deliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(this.deliveryDocket.rowguid);

      this.alertService.openSnackBar("The delivery docket has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.deliveryDocketUpdated.emit(this.deliveryDocket);
    }


    if (this.deliveryDocket == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem updating the delivery docket", "Close", "center", "bottom", 3000, true, "There was a problem creating the delivery docket");
    }



  }

  cancelDeliveryDocket() {
    this.deliveryDocketCancelled.emit(true);
  }

  selectDeliveryRate(ordersDeliveryRate: dtoOrdersDeliveryRate, disabled: boolean) {

    if (disabled == false) {
      this.form.controls['txtDeliveryCharge'].setValue(ordersDeliveryRate.fltPrice.toString());
      this.form.controls['txtEscort'].setValue(ordersDeliveryRate.fltEscort.toString());
      this.form.controls['txtAdditionalKM'].setValue(ordersDeliveryRate.fltAdditionalKM.toString());
    }

  }


  async updateDeliveryRate(deliveryDocket: DeliveryDocket) {

    if (this.deliveryRateUpdateDialogRef != null) {
      this.deliveryRateUpdateDialogRef.close();
    }

    //let deliveryDocket: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(dtoDeliveryDocket.rowguid);
    let deliveryDocketOrderItems: dtoDeliveryDocketOrderItem[] = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemByDocketIdPromise(deliveryDocket.rowguid);

    if (deliveryDocket == null) {
      this.alertService.openSnackBarDefault("There is no delivery docket to update");
      return;
    }

    if (this.orderId == null || this.orderId == "") {
      this.alertService.openSnackBarDefault("There is no order to select a delivery rate from.");
      return;
    }

    this.deliveryRateUpdateDialogRef = this.dialog.open(DeliveryDocketDeliveryRateComponent, {
      height: 'auto',
      maxWidth: '90vw',
      data: { orderId: this.orderId, deliveryDocket: deliveryDocket }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.deliveryRateUpdateDialogRef.backdropClick().subscribe(() => {
      this.deliveryRateUpdateDialogRef.close();
    });


    this.deliveryRateUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, deliveryDocket: DeliveryDocket }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log((data);
        if (data.status != "Cancel") {
          //console.log(("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

          this.deliveryDocket = data.deliveryDocket;

          this.alertService.openSnackBarDefault("The delivery rate has been updated");

        }
        else {
          // We have cancellled

        }

      });
  }


  rowEnter(customerDeliveryAddressId: string) {
    this.addressHoverId = customerDeliveryAddressId;
    //console.log("row enter");

  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");

  }


}
