import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  MatDatepicker,
  MatDateRangePicker,
} from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";
import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from "@angular/material/list";
import { MatSelectChange } from "@angular/material/select";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, Subject } from "rxjs";
import { share, takeUntil } from "rxjs/operators";
import { AuthService } from "../../account/auth.service";
import { FinalInspectionListComponent } from "../../final-inspection/final-inspection-list/final-inspection-list.component";
import { FieldQueryParamMap } from "../../_shared/app-objects/field-query-param-map.ao";
import { CHHPoleStock } from "../../_shared/business-objects/chhpole-stock.bo";
import { Client } from "../../_shared/business-objects/client.bo";
import { ContractCutSawLog } from "../../_shared/business-objects/contract-cut-saw-log.bo";
import {
  dtoIntKeyValue,
  dtoKeyValue,
} from "../../_shared/business-objects/dto-int-key-value.bo";
import { dtoOrderItem } from "../../_shared/business-objects/dto-order-item.bo";
import { dtoTPChargeItem } from "../../_shared/business-objects/dto-tpcharge-item.bo";
import { ElectricalAuthoritySpec } from "../../_shared/business-objects/electrical-authority-spec.bo";
import { OrderItem } from "../../_shared/business-objects/order-item.bo";
import { Orders } from "../../_shared/business-objects/orders.bo";
import { PoleCube } from "../../_shared/business-objects/pole-cube.bo";
import { Pole } from "../../_shared/business-objects/pole.bo";
import { ProductType } from "../../_shared/business-objects/product-type.bo";
import { Species } from "../../_shared/business-objects/species.bo";
import { SQLParamArray } from "../../_shared/business-objects/sql-param-array";
import { TPChargeItem } from "../../_shared/business-objects/tpcharge-item.bo";
import { TPPoleInspectionItem } from "../../_shared/business-objects/tppole-inspection-item.bo";
import { TPPoleInspection } from "../../_shared/business-objects/tppole-inspection.bo";
import { ErrorMessage } from "../../_shared/ErrorMessage.class";
import { AlertService } from "../../_shared/services/alert.service";
import { CHHPoleStockService } from "../../_shared/services/chhpole-stock.service";
import { ClientService } from "../../_shared/services/client.service";
import { ElectricalAuthoritySpecService } from "../../_shared/services/electrical-authority-spec.service";
import { FormService } from "../../_shared/services/form.service";
import { OrderItemService } from "../../_shared/services/order-item.service";
import { OrdersService } from "../../_shared/services/orders.service";
import { PoleCubeService } from "../../_shared/services/pole-cube.service";
import { PoleService } from "../../_shared/services/pole.service";
import { ProductTypeService } from "../../_shared/services/product-type.service";
import { SharedService } from "../../_shared/services/shared.service";
import { SpeciesService } from "../../_shared/services/species.service";
import { TPChargeItemService } from "../../_shared/services/tpcharge-item.service";
import { TPPoleInspectionItemService } from "../../_shared/services/tppole-inspection-item.service";
import { ConsecutiveNumberListComponent } from "../consecutive-number-list/consecutive-number-list.component";

@Component({
  selector: "app-final-inspection-item-create",
  templateUrl: "./final-inspection-item-create.component.html",
  styleUrls: ["./final-inspection-item-create.component.css"],
  animations: [
    trigger("displaySpinner", [
      state(
        "open",
        style({
          opacity: 1, //,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0, //,
        })
      ),
      transition("closed => open", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })])
        ),
      ]),
      transition("open => closed", [
        animate(
          "0.5s",
          keyframes([style({ opacity: 1 }), style({ opacity: 0 })])
        ),
      ]),
    ]),
  ],
})
export class FinalInspectionItemCreateComponent implements OnInit {
  form: FormGroup;
  @ViewChild("f") myNgForm;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  @ViewChild("orderitem") orderItem: ElementRef;
  @ViewChild("polenumber") poleNumber: HTMLElement;

  @ViewChild("createInspectionItem") btnCreate;
  @ViewChild("createAndAddInspectionItem") btnCreateAndAdd;
  @ViewChild("guCustomerId") customerList: MatSelectionList;
  @ViewChild("guOrderId") ordersList: MatSelectionList;
  @ViewChild("guOrderItemId") orderItemList: MatSelectionList;

  @Output() refreshFinalInspectionItemsEvent = new EventEmitter<boolean>();

  public customers: Client[];
  public customersAll: Client[];
  public orders: Orders[];
  public ordersAll: Orders[];
  public orderItems: dtoOrderItem[];
  public orderItemsAll: dtoOrderItem[];
  public productTypes: ProductType[];
  public species: Species[];
  public speciesOriginal: Species[];
  public elecAuthSpecs: ElectricalAuthoritySpec[];
  public eDiameterTypes: dtoIntKeyValue[];

  public customerId: string;
  public orderId: string;
  //public trimmingItemReasonForChangeData: PoleTrimmingItemReasonForChange[];
  private reasonForChangeSelected: any;
  showErrorMessage: boolean;
  errorMessage: ErrorMessage;

  //guProductTypeId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  //guSpeciesId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  public productTypeFilterCtrl: FormControl = new FormControl();
  public customersFilterCtrl: FormControl = new FormControl();
  public ordersFilterCtrl: FormControl = new FormControl();
  public orderItemFilterCtrl: FormControl = new FormControl();
  public speciesFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  public filteredProductTypes: ReplaySubject<ProductType[]> = new ReplaySubject<
    ProductType[]
  >(1);
  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<
    Client[]
  >(1);
  public filteredOrders: ReplaySubject<Orders[]> = new ReplaySubject<Orders[]>(
    1
  );
  public filteredOrderItems: ReplaySubject<dtoOrderItem[]> = new ReplaySubject<
    dtoOrderItem[]
  >(1);
  public filteredSpecies: ReplaySubject<Species[]> = new ReplaySubject<
    Species[]
  >(1);
  public reasonForChangeSelectedValues: any;
  public eOriginalProductMeasurementType: ProductType.enMeasurementType;
  public eFinishedProductMeasurementType: ProductType.enMeasurementType;
  public selectedOriginalProduct: ProductType;
  public selectedFinishProduct: ProductType;
  public selectedOrderItem: dtoOrderItem;

  public finishedDiamHint: string = "";
  public finishedGirthHint: string = "";
  public originalKNHint: string = "";
  public finishedKNHint: string = "";
  public originalLengthHint: string = "";
  public finishedLengthHint: string = "";
  public finishedDiaPlaceHolder: string = "";
  public finishedDiaTypePlaceHolder: string = "";
  public finishedGirthPlaceHolder: string = "";

  public treatmentMonthHint: string = "";
  public treatmentYearHint: string = "";

  public today = new Date();
  public year: number = this.today.getFullYear();
  public decadeDigit: string = this.year
    .toString()
    .substring(
      this.year.toString().length - 2,
      this.year.toString().length - 1
    );
  public yearDigits: number = parseInt(
    this.year
      .toString()
      .substring(this.year.toString().length - 2, this.year.toString().length)
  );

  public treatment_year_Validator: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(2),
    Validators.max(this.yearDigits),
    this.tpChargeItemService.treatment_year_Validator,
  ]; //
  public treatment_month_Validator: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(2),
    Validators.max(12),
    this.tpChargeItemService.treatment_month_Validator,
  ]; //
  public finishDiaCircumferenceValidator: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(5),
    Validators.min(470),
    Validators.max(3100),
  ];
  public finishDiameterValidator: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(4),
    Validators.min(150),
    Validators.max(1250),
  ];

  public saving: boolean = false;
  public showSpinner: boolean = false;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  positionRelativeToElement: ElementRef;

  consecutiveNumberListDialogRef: MatDialogRef<ConsecutiveNumberListComponent>;
  selectedTPChargeItem: dtoTPChargeItem = null;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FinalInspectionListComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      finalInspection: TPPoleInspection;
      positionRelativeToElement: ElementRef;
    },
    private sharedService: SharedService,
    private alertService: AlertService,
    private poleService: PoleService,
    private poleCubeService: PoleCubeService,
    private productTypeService: ProductTypeService,
    private formService: FormService,
    private customerService: ClientService,
    private speciesService: SpeciesService,
    private ordersService: OrdersService,
    private orderItemService: OrderItemService,
    private tpChargeItemService: TPChargeItemService,
    private electricalAuthoritySpec: ElectricalAuthoritySpecService,
    private tpPoleInspectionItemService: TPPoleInspectionItemService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private chhPoleStockService: CHHPoleStockService,
    private cdref: ChangeDetectorRef
  ) {
    // this.dialogRef.backdropClick().subscribe(() => {
    //   this.dialogRef.close();
    // });

    dialogRef.disableClose = true;

    this.positionRelativeToElement = data.positionRelativeToElement;
  }

  @HostListener("window:keyup.esc") onKeyUp() {
    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });
  }

  ngOnInit(): void {
    this.setForm();

    //let btnCreateAndAddItem = document.getElementById('btnCreateAndAddInspectionItem') as HTMLButtonElement;
    //btnCreateAndAddItem.disabled = true;

    //console.log(btnCreateAndAddItem);

    //productTypeFilterCtrl: ['', Validators.required],

    const datepipe: DatePipe = new DatePipe("en-AU");

    //this.dteDateCreated.setValue(datepipe.transform(Date.now(), 'yyyy-MM-dd'));
    this.dteDateCreated.setValue(
      datepipe.transform(
        this.data.finalInspection.dteDateInspected,
        "yyyy-MM-dd"
      )
    );

    this.loadData();

    this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
    this.originalLengthHint = "8m to 26m";
    this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
    this.finishedLengthHint = "8m to 26m";
    this.finishedDiaTypePlaceHolder = "GL";
    this.finishedDiaPlaceHolder =
      this.finishedDiaTypePlaceHolder + " Diameter mm";
    this.finishedGirthPlaceHolder = "Girth Diameter mm";
    this.treatmentMonthHint = "6";
    this.treatmentYearHint = "22";

    this.intFinishedDiameterType.disable();

    this.route.queryParams.subscribe((params) => {
      this.formService.setFieldsFromQueryParams(
        this.filterToQueryParamMap,
        params,
        this.form
      );
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      dteDateCreated: ["", Validators.required],
      guCustomerId: ["", Validators.required],
      guOrderId: ["", Validators.required],
      guOrderItemId: ["", Validators.required],
      txtPoleNumber: [""],
      txtConsecutiveNumber: [""],
      txtChargeNumber: ["", Validators.maxLength(3)],
      txtTreatmentMonth: [
        "",
        [
          Validators.required,
          Validators.maxLength(2),
          Validators.max(12),
          this.tpChargeItemService.treatment_month_Validator,
        ],
      ], //
      txtTreatmentYear: [
        "",
        [
          Validators.required,
          Validators.maxLength(2),
          Validators.max(this.yearDigits),
          this.tpChargeItemService.treatment_year_Validator,
        ],
      ], //
      guOriginalProductTypeId: [
        ["F5BAF9BF-A492-498E-AA36-45037CA6423F"],
        Validators.required,
      ],
      guFinishedProductTypeId: [
        ["F5BAF9BF-A492-498E-AA36-45037CA6423F"],
        Validators.required,
      ],
      txtOriginalLength: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.max(26),
          this.poleService.original_nsw_LengthValidator,
        ],
      ],
      txtOriginalKN: [
        "",
        [Validators.required, Validators.pattern(this.poleService.nsw_kNRange)],
      ],
      txtFinishedLength: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.max(26),
          this.poleService.finished_nsw_LengthValidator,
        ],
      ],
      txtFinishedKN: [
        "",
        [Validators.required, Validators.pattern(this.poleService.nsw_kNRange)],
      ],
      fltFinishedDiameter: ["", this.finishDiameterValidator],
      intFinishedDiameterType: [4, Validators.required],
      fltFinishedGirth: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.min(150),
          Validators.max(1250),
        ],
      ],
      guSpeciesId: ["", Validators.required],
      guElectricalAuthoritySpecId: [""],
      txtComments: [""],
      chkOriginalQV: [false, Validators.required],
      chkFinishedQV: [false, Validators.required],
      chkOriginalSub: [false],
      chkFinishedSub: [false],
      chkOriginalRegal: [false],
      chkFinishedRegal: [false],
      guTPPoleInspectionItemReasonForChange: [""],
      chkDrilled: [""],
      btnCreateInspectionItem: [""],
      btnCreateAndAddInspectionItem: [""],
      chkRejected: [""],
      txtRejectedComments: [""],
    });
  }

  get guCustomerId() {
    return this.form.get("guCustomerId");
  }

  get guOrderId() {
    return this.form.get("guOrderId");
  }

  get dteDateCreated() {
    return this.form.get("dteDateCreated");
  }

  get txtChargeNumber() {
    return this.form.get("txtChargeNumber");
  }

  get txtTreatmentMonth() {
    return this.form.get("txtTreatmentMonth");
  }

  get txtTreatmentYear() {
    return this.form.get("txtTreatmentYear");
  }

  get fltFinishedDiameter() {
    return this.form.get("fltFinishedDiameter");
  }

  get intFinishedDiameterType() {
    return this.form.get("intFinishedDiameterType");
  }

  get fltFinishedGirth() {
    return this.form.get("fltFinishedGirth");
  }

  get chkOriginalQV() {
    return this.form.get("chkOriginalQV");
  }

  get chkFinishedQV() {
    return this.form.get("chkFinishedQV");
  }

  get chkOriginalSub() {
    return this.form.get("chkOriginalSub");
  }

  get chkFinishedSub() {
    return this.form.get("chkFinishedSub");
  }

  get chkOriginalRegal() {
    return this.form.get("chkOriginalRegal");
  }

  get chkFinishedRegal() {
    return this.form.get("chkFinishedRegal");
  }

  get txtOriginalKN() {
    return this.form.get("txtOriginalKN");
  }

  get txtFinishedKN() {
    return this.form.get("txtFinishedKN");
  }

  get txtOriginalLength() {
    return this.form.get("txtOriginalLength");
  }

  get txtFinishedLength() {
    return this.form.get("txtFinishedLength");
  }

  get chkDrilled() {
    return this.form.get("chkDrilled");
  }

  get guElectricalAuthoritySpecId() {
    return this.form.get("guElectricalAuthoritySpecId");
  }

  get guSpeciesId() {
    return this.form.get("guSpeciesId");
  }

  async loadData() {
    this.getCustomers();
    this.getSpecies();
    this.getProductTypes();
    this.getElectricalAuthoritySpecs();
    this.eDiameterTypes = this.sharedService.getEnumObjectKeyValue(
      SharedService.enDiameterType
    );

    //this.customers = await this.customerService.getTreatmentPlantCustomers();
    this.orders = [];
    this.orderItems = [];

    let order: Orders = new Orders();
    order.rowguid = this.sharedService.EmptyGuid;
    order.txtOrderNo = "Please select a customer";
    this.orders.push(order);

    //console.log(this.customersFilterCtrl);

    if (this.customerId != null && this.customerId != "") {
      this.guCustomerId.setValue([this.customerId]);

      setTimeout(() => {
        this.formService.scrollToMatListOption(
          "guCustomerId",
          this.customerId,
          this.customerList.options.toArray()
        );
      }, 500);

      this.resetOrders();
      await this.populateOrders(this.customerId);
    }

    if (this.orderId != null && this.orderId != "") {
      //console.log("orderId: " + this.orderId);
      //this.guOrderId.setValue(this.orderId);

      for (let i = 0; i <= this.orders.length - 1; i++) {
        if (this.orderId == this.orders[i].rowguid) {
          this.form.controls["guOrderId"].setValue([this.orders[i].rowguid]);

          this.orderChanged(this.orders[i].rowguid);
          break;
        }
      }

      setTimeout(() => {
        this.formService.scrollToMatListOption(
          "guOrderId",
          this.orderId,
          this.ordersList.options.toArray()
        );
      }, 500);
    }
  }

  async save(mode: string) {
    if (
      (this.form.controls["txtPoleNumber"].value == null ||
        this.form.controls["txtPoleNumber"].value == "") &&
      (this.form.controls["txtConsecutiveNumber"].value == null ||
        this.form.controls["txtConsecutiveNumber"].value == "")
    ) {
      this.alertService.openSnackBar(
        "You must enter a pole number or a consective number",
        "Close",
        "center",
        "bottom",
        "",
        3000
      );
      let element = document.getElementById(
        "txtPoleNumber"
      ) as HTMLInputElement;
      element.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      this.form.controls["guSpeciesId"].value == null ||
      this.form.controls["guSpeciesId"].value[0] == null
    ) {
      this.alertService.openSnackBar(
        "Please select a species",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      return;
    }

    if (
      this.form.controls["guOrderItemId"].value == null ||
      this.form.controls["guOrderItemId"].value == ""
    ) {
      this.alertService.openSnackBar(
        "Please select an order item",
        "Close",
        "center",
        "bottom",
        "",
        4000
      );
      //await this.alertService.openSnackBarCustomPromise("Order Item", "Please select an order item ", "OK", "", "center", "bottom", "", 0, false);
      return;
    }

    if (this.form.valid == false) {
      return;
    }

    if (
      this.selectedOrderItem.fltQuantityTotal -
        this.selectedOrderItem.intFinalCount -
        1 <
      0
    ) {
      let msg: string = await this.alertService.openSnackBarCustomPromise(
        "Over Supply",
        "You are going to over supply " +
          this.selectedOrderItem.intLength +
          " / " +
          this.selectedOrderItem.intKN +
          (this.selectedOrderItem.blnQV == true ? " Q/V" : "") +
          "'s on this order\nIs this OK?",
        "Yes",
        "No",
        "center",
        "bottom",
        "",
        0
      );
      if (msg != "Yes") {
        return;
      }
    }

    this.saving = true;
    this.showSpinner = true;

    //console.log("btnCreateInspectionItem: " + document.getElementById('btnCreateInspectionItem'));
    //let btnCreateItem = document.getElementById('btnCreateInspectionItem') as HTMLButtonElement;
    //let btnCreateAndAddItem = document.getElementById('btnCreateAndAddInspectionItem') as HTMLButtonElement;
    //btnCreateItem.disabled = true;
    //btnCreateAndAddItem.disabled = true;

    let finalInspectionItem: TPPoleInspectionItem = new TPPoleInspectionItem();
    let pole1: Pole = null;
    let pole2: Pole = null;
    let lastItemNo: dtoKeyValue =
      await this.tpPoleInspectionItemService.getLastItemNoPromise(
        this.data.finalInspection.rowguid
      );

    //console.log("selectedOriginalProduct: " + this.selectedOriginalProduct);
    //console.log("selectedFinishProduct: " + this.selectedFinishProduct);

    if (
      this.selectedOriginalProduct.intMeasurementType ==
      ProductType.enMeasurementType.LengthKN
    ) {
      pole1 = await this.poleService.getPolePromise(
        this.txtOriginalLength.value,
        parseInt(this.txtOriginalKN.value),
        Boolean(this.chkOriginalQV.value),
        Boolean(this.chkOriginalSub.value),
        false,
        Boolean(this.chkOriginalRegal.value)
      );

      if (pole1 == null) {
        let msg: string =
          await this.alertService.openSnackBarCustomDefaultPromise(
            "Pole Error",
            "The original Pole you are trying to save does not exist.\nPerhaps you have sub pole ticked?",
            "Ok"
          );
        this.saving = false;
        this.showSpinner = false;
        return;
      }

      if (
        pole1 != null &&
        (Boolean(this.chkOriginalSub.value) == true ||
          Boolean(this.chkOriginalRegal.value) == true)
      ) {
        let poleStockSub: CHHPoleStock =
          await this.chhPoleStockService.getCHHPoleStockByPole(pole1.rowguid);
        if (poleStockSub == null) {
          let msg: string =
            await this.alertService.openSnackBarCustomDefaultShowCancel(
              "No Pole Stock Record",
              "There is no Original Pole Stock record for the pole you are trying to save.\nPerhaps you have sub pole ticked?\nDo you want to continue?",
              "Yes",
              "No"
            );
          if (msg != "Yes") {
            this.saving = false;
            this.showSpinner = false;
            return;
          }
        }
      }
    }

    if (
      this.selectedFinishProduct.intMeasurementType ==
      ProductType.enMeasurementType.LengthKN
    ) {
      pole2 = await this.poleService.getPolePromise(
        this.txtFinishedLength.value,
        parseInt(this.txtFinishedKN.value),
        Boolean(this.chkFinishedQV.value),
        Boolean(this.chkFinishedSub.value),
        false,
        Boolean(this.chkFinishedRegal.value)
      );

      if (pole2 == null) {
        let msg: string =
          await this.alertService.openSnackBarCustomDefaultPromise(
            "Pole Error",
            "The finished Pole you are trying to save does not exist.\nPerhaps you have sub pole ticked?",
            "Ok"
          );
        this.saving = false;
        this.showSpinner = false;
        return;
      }

      if (
        pole2 != null &&
        (Boolean(this.chkFinishedSub.value) == true ||
          Boolean(this.chkFinishedRegal.value) == true)
      ) {
        let poleStockSub: CHHPoleStock =
          await this.chhPoleStockService.getCHHPoleStockByPole(pole2.rowguid);
        if (poleStockSub == null) {
          let msg: string =
            await this.alertService.openSnackBarCustomDefaultShowCancel(
              "No Pole Stock Record",
              "There is no Finished Pole Stock record for the pole you are trying to save.\nPerhaps you have sub pole ticked?\nDo you want to continue?",
              "Yes",
              "No"
            );
          if (msg != "Yes") {
            return;
          }
        }
      }

      // Check if the final pole is the same as what the order item is.
      // If not show alert to the user.

      //if (pole2.rowguid != this.selectedOrderItem.guPoleId) {
      //  let msg: string = await this.alertService.openSnackBarCustomPromise("Order Item Pole", "The selected order item pole is different to the Final Item pole you have entered\nIs this correct?", "Yes", "No", "center", "bottom");
      //  if (msg != "Yes") {
      //    return;
      //  }
      //}
    }

    delete finalInspectionItem.rowguid;
    //console.log("guCustomerId: " + this.form.controls['guCustomerId'].value[0]);
    //console.log("guOrderId: " + this.form.controls['guOrderId'].value[0]);
    //console.log("guOrderItemId: " + this.form.controls['guOrderItemId'].value[0]);
    //console.log("guSpeciesId: " + this.form.controls['guSpeciesId'].value);
    //console.log("txtConsecutiveNumber: " + this.form.controls['txtConsecutiveNumber'].value);
    //console.log("txtChargeNumber: " + this.form.controls['txtChargeNumber'].value);
    //console.log("txtTreatmentMonth: " + this.form.controls['txtTreatmentMonth'].value);
    //console.log("txtTreatmentYear: " + parseInt(this.form.controls['txtTreatmentYear'].value));
    //console.log("guFinishedProductTypeId: " + this.form.controls['guFinishedProductTypeId'].value);
    //console.log("guOriginalProductTypeId: " + this.form.controls['guOriginalProductTypeId'].value);
    //console.log("guSpeciesId: " + this.form.controls['guSpeciesId'].value[0]);

    finalInspectionItem.dteDateCreated = new Date(
      this.form.controls["dteDateCreated"].value
    );
    finalInspectionItem.intItemNo = lastItemNo.intValue + 1;
    finalInspectionItem.guTPPoleInspectionId =
      this.data.finalInspection.rowguid;
    finalInspectionItem.guCustomerId =
      this.form.controls["guCustomerId"].value[0];
    finalInspectionItem.guOrderId = this.form.controls["guOrderId"].value[0];
    finalInspectionItem.guOrderItemId =
      this.form.controls["guOrderItemId"].value[0].rowguid;
    finalInspectionItem.txtPoleNumber =
      this.form.controls["txtPoleNumber"].value != null
        ? this.form.controls["txtPoleNumber"].value.toString()
        : "";
    finalInspectionItem.txtConsecutiveNumber =
      this.form.controls["txtConsecutiveNumber"].value != null
        ? this.form.controls["txtConsecutiveNumber"].value.toString()
        : "";
    finalInspectionItem.txtChargeId =
      this.form.controls["txtChargeNumber"].value != null
        ? this.form.controls["txtChargeNumber"].value.toString()
        : "";
    //finalInspectionItem.txtChargeDate = this.form.controls['txtTreatmentMonth'].value + "/" + this.form.controls['txtTreatmentYear'].value;
    finalInspectionItem.intTreatmentMonth =
      this.form.controls["txtTreatmentMonth"].value == ""
        ? null
        : this.form.controls["txtTreatmentMonth"].value;
    finalInspectionItem.intTreatmentYear =
      this.form.controls["txtTreatmentYear"].value == ""
        ? null
        : parseInt(this.form.controls["txtTreatmentYear"].value);
    finalInspectionItem.guProductTypeId =
      this.form.controls["guFinishedProductTypeId"].value[0];
    finalInspectionItem.guOriginalProductTypeId =
      this.form.controls["guOriginalProductTypeId"].value[0];
    finalInspectionItem.guSpeciesId =
      this.form.controls["guSpeciesId"].value[0];
    finalInspectionItem.fltDiameter =
      this.form.controls["fltFinishedDiameter"].value;
    finalInspectionItem.intDiameterType =
      this.form.controls["intFinishedDiameterType"].value;
    finalInspectionItem.fltGirth = this.form.controls["fltFinishedGirth"].value;
    finalInspectionItem.blnDrilled =
      this.form.controls["chkDrilled"].value == true;
    finalInspectionItem.blnRejected =
      this.form.controls["chkRejected"].value == true;
    finalInspectionItem.txtRejectedComments =
      this.form.controls["txtRejectedComments"].value;

    finalInspectionItem.guEmployeeEnteredId =
      this.authService.getAppUserEmployeeId();

    if (pole1 != null) {
      finalInspectionItem.guOriginalPoleId = pole1.rowguid;
    }

    if (pole2 != null) {
      finalInspectionItem.guFinalPoleId = pole2.rowguid;
      //console.log(pole2);

      let spec: SharedService.enPoleSpec = SharedService.enPoleSpec.NSW;
      //let iSpec: number = 0;

      if (pole2.blnQV == true) {
        //console.log("in QV " + (pole2.blnQV == true));
        spec = SharedService.enPoleSpec.VIC;
        //iSpec = 2;
      }

      let poleCube = await this.poleCubeService.getPoleCubeBySpecPromise(
        pole2.rowguid,
        finalInspectionItem.guProductTypeId,
        spec
      );
      console.log(poleCube);
      //console.log(finalInspectionItem.guProductTypeId);
      //console.log(spec);

      if (poleCube == null) {
        this.alertService.openSnackBarError(
          "There is no cubic volume for this pole",
          "Close",
          "center",
          "bottom",
          3000,
          true,
          "There is no cubic volume for this pole"
        );
        this.saving = false;
        this.showSpinner = false;
        return;
      }

      //console.log("pole2.intLength: " + pole2.intLength);
      //console.log("finalInspectionItem.fltGirth: " + finalInspectionItem.fltGirth);

      finalInspectionItem.fltM3 = poleCube.fltM3;
      finalInspectionItem.fltActualM3 = this.sharedService.calcRoundM3(
        finalInspectionItem.fltGirth,
        SharedService.enDiameterType.Centre,
        pole2.intLength,
        0,
        3
      );
      finalInspectionItem.fltActualTonne = Number(
        (
          finalInspectionItem.fltActualM3 *
          this.sharedService.M3ToWeightConversion
        ).toFixed(3)
      );

      //console.log("this.finalInspectionItem.fltM3: " + finalInspectionItem.fltM3);
      //console.log("this.finalInspectionItem.fltActualM3: " + finalInspectionItem.fltActualM3);
      //console.log("this.finalInspectionItem.fltActualTonne: " + finalInspectionItem.fltActualTonne);
    }

    if (pole1 == null) {
      finalInspectionItem.guOriginalPoleId = null;
      finalInspectionItem.fltOriginalLength =
        this.form.controls["txtOriginalLength"].value;
    }

    // No pole so we are Non lengthKN so just use length.
    if (pole2 == null) {
      finalInspectionItem.guFinalPoleId = null;
      finalInspectionItem.fltLength =
        this.form.controls["txtFinishedLength"].value;
      finalInspectionItem.fltM3 = this.sharedService.calcRoundM3(
        finalInspectionItem.fltGirth,
        SharedService.enDiameterType.Centre,
        finalInspectionItem.fltLength,
        0,
        3
      );
      finalInspectionItem.fltActualM3 = this.sharedService.calcRoundM3(
        finalInspectionItem.fltGirth,
        SharedService.enDiameterType.Centre,
        finalInspectionItem.fltLength,
        0,
        3
      );
      finalInspectionItem.fltActualTonne = Number(
        (
          finalInspectionItem.fltActualM3 *
          this.sharedService.M3ToWeightConversion
        ).toFixed(3)
      );
    }

    finalInspectionItem.intSpecification = -1; //None, not using spec anymore, now we use Electrical Authority Spec below.

    if (
      this.form.controls["guElectricalAuthoritySpecId"].value != null &&
      this.form.controls["guElectricalAuthoritySpecId"].value !=
        this.sharedService.EmptyGuid
    ) {
      finalInspectionItem.guElectricalAuthoritySpecId =
        this.form.controls["guElectricalAuthoritySpecId"].value;
    }

    if (mode == "Create") {
      this.dialogRef.close({
        status: "Saved",
        finalInspectionItem: finalInspectionItem,
        selectedChargeItem: this.selectedTPChargeItem,
      });
      window.event.stopPropagation();
    }

    if (mode == "CreateAndAdd") {
      let finalItem: TPPoleInspectionItem =
        await this.tpPoleInspectionItemService.createTPPoleInspectionItemPromise(
          finalInspectionItem
        );
      await this.updateOrderItemBalance(finalInspectionItem.guOrderItemId);

      //Link this final item to the charge item so we know not to use this consecutive nubmer again.
      if (this.selectedTPChargeItem != null) {
        let chargeItem: TPChargeItem =
          await this.tpChargeItemService.getTPChargeItemPromise(
            this.selectedTPChargeItem.rowguid
          );
        chargeItem.guTPPoleInspectionItemId = finalItem.rowguid;
        await this.tpChargeItemService.updateTPChargeItemPromise(chargeItem);
        console.log("chargeItem: ", chargeItem);
      }

      this.refreshFinalInspectionItemsEvent.emit(true);

      this.reset(mode);

      this.formService.scrollIntoViewById("txtPoleNumber");
    }

    //btnCreateItem.disabled = false;
    //btnCreateAndAddItem.disabled = false;

    this.saving = false;
    this.showSpinner = false;
  }

  async updateOrderItemBalance(orderItemId: string) {
    if (orderItemId == null || orderItemId == "") {
      return;
    }

    let orderItem: dtoOrderItem =
      await this.orderItemService.getdtoOrderItemPromise(orderItemId);
    if (orderItem != null) {
      for (let i = 0; i <= this.orderItems.length - 1; i++) {
        if (this.orderItems[i].rowguid == orderItem.rowguid) {
          //console.log("Found Order Item to Update: " + orderItem.rowguid);
          this.orderItems[i].intFinalCount = orderItem.intFinalCount;

          // For Sydney Trains Stock
          if (this.orderItems[i].blnStock == true) {
            this.orderItems[i].fltQuantityTotal = orderItem.fltQuantityTotal;
          }
          break;
        }
      }
    }
  }

  cancel() {
    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });

    window.event.stopPropagation();
  }

  reset(mode: string) {
    //console.log("guCustomerId", this.form.controls['guCustomerId'].value);
    //console.log("guOrderId", this.form.controls['guOrderId'].value);
    //console.log("guOrderItemId", this.form.controls['guOrderItemId'].value);

    let dteDateCreated = this.form.controls["dteDateCreated"].value;
    let guCustomerId = this.form.controls["guCustomerId"].value[0];
    let guOrderId = this.form.controls["guOrderId"].value[0];
    let guOrderItemId = this.form.controls["guOrderItemId"].value[0];
    //let txtPoleNumber = this.form.controls['txtPoleNumber'].value.toString();
    //let txtConsecutiveNumber = this.form.controls['txtConsecutiveNumber'].value.toString();
    //let txtChargeId = this.form.controls['txtChargeNumber'].value.toString();
    //let txtChargeDate = this.form.controls['txtTreatmentMonth'].value + "/" + this.form.controls['txtTreatmentYear'].value;
    let guFinishedProductTypeId =
      this.form.controls["guFinishedProductTypeId"].value[0];
    let guOriginalProductTypeId =
      this.form.controls["guOriginalProductTypeId"].value[0];
    let guSpeciesId = this.form.controls["guSpeciesId"].value;
    let intFinishedDiameterType =
      this.form.controls["intFinishedDiameterType"].value;
    //let fltDiameter = this.form.controls['fltFinishedDiameter'].value;
    //let fltGirth = this.form.controls['fltFinishedGirth'].value;
    let guElectricalAuthoritySpecId =
      this.form.controls["guElectricalAuthoritySpecId"].value;
    let txtOriginalLength = this.form.controls["txtOriginalLength"].value;
    let txtOriginalKN = this.form.controls["txtOriginalKN"].value;
    let txtFinishedLength = this.form.controls["txtFinishedLength"].value;
    let txtFinishedKN = this.form.controls["txtFinishedKN"].value;
    let chkOriginalQV = this.form.controls["chkOriginalQV"].value;
    let chkOriginalSub = this.form.controls["chkOriginalSub"].value;
    let chkOriginalRegal = this.form.controls["chkOriginalRegal"].value;
    let chkFinishedQV = this.form.controls["chkFinishedQV"].value;
    let chkFinishedSub = this.form.controls["chkFinishedSub"].value;
    let chkFinishedRegal = this.form.controls["chkFinishedRegal"].value;
    let chkDrilled = this.form.controls["chkDrilled"].value;
    let chkRejected: boolean = this.form.controls["chkRejected"].value == true;
    let txtRejectedComments = this.form.controls["txtRejectedComments"].value;

    //this.myNgForm.resetForm();

    this.formGroupDirective.resetForm(this.form.value);
    this.form.reset();

    //this.orderItemChanged(guOrderItemId);

    this.form.controls["dteDateCreated"].setValue(dteDateCreated);
    this.form.controls["guCustomerId"].setValue([guCustomerId]);
    this.form.controls["guOrderId"].setValue([guOrderId]);
    this.form.controls["guOrderItemId"].setValue([guOrderItemId]);

    //console.log("guCustomerId - after", this.form.controls['guCustomerId'].value);
    //console.log("guOrderId - after", this.form.controls['guOrderId'].value);
    //console.log("guOrderItemId - after", this.form.controls['guOrderItemId'].value);

    //this.form.controls['txtPoleNumber'].setValue(txtPoleNumber);
    //this.form.controls['txtConsecutiveNumber'].setValue(txtConsecutiveNumber);
    //this.form.controls['txtChargeNumber'].setValue(txtChargeId);
    //this.form.controls['txtTreatmentMonth'].setValue(txtChargeDate);
    this.form.controls["guFinishedProductTypeId"].setValue([
      guFinishedProductTypeId,
    ]);
    this.form.controls["guOriginalProductTypeId"].setValue([
      guOriginalProductTypeId,
    ]);
    this.form.controls["guSpeciesId"].setValue(guSpeciesId);
    this.form.controls["intFinishedDiameterType"].setValue(
      intFinishedDiameterType
    );
    //this.form.controls['fltFinishedDiameter'].setValue(fltDiameter);
    //this.form.controls['fltFinishedGirth'].setValue(fltGirth);
    this.form.controls["guElectricalAuthoritySpecId"].setValue(
      guElectricalAuthoritySpecId
    );
    this.form.controls["txtOriginalLength"].setValue(txtOriginalLength);
    this.form.controls["txtOriginalKN"].setValue(txtOriginalKN);
    this.form.controls["txtFinishedLength"].setValue(txtFinishedLength);
    this.form.controls["txtFinishedKN"].setValue(txtFinishedKN);
    this.form.controls["chkOriginalQV"].setValue(chkOriginalQV);
    this.form.controls["chkOriginalSub"].setValue(chkOriginalSub);
    this.form.controls["chkOriginalRegal"].setValue(chkOriginalRegal);
    this.form.controls["chkFinishedQV"].setValue(chkFinishedQV);
    this.form.controls["chkFinishedSub"].setValue(chkFinishedSub);
    this.form.controls["chkFinishedRegal"].setValue(chkFinishedRegal);
    this.form.controls["chkDrilled"].setValue(chkDrilled);
    this.form.controls["chkRejected"].setValue(chkRejected);
    this.form.controls["txtRejectedComments"].setValue(txtRejectedComments);

    //this.setForm()

    //Object.keys(this.form.controls).forEach((key) => {
    //  const control = this.form.controls[key];
    //  control.markAsPristine();
    //  control.markAsUntouched();
    //  control.setErrors(null);
    //});

    //this.guSpeciesId.clearValidators()
    //this.txtTreatmentMonth.clearValidators();
    //this.txtTreatmentYear.clearValidators();

    //this.form.controls['txtPoleNumber'].setValue('');
    //this.form.controls['txtConsecutiveNumber'].setValue('');
    //this.form.controls['txtChargeNumber'].setValue('');
    //this.form.controls['txtTreatmentMonth'].setValue('');
    //this.form.controls['txtTreatmentYear'].setValue('');
    //this.form.controls['guSpeciesId'].setValue('');

    //this.guSpeciesId.markAsPristine();
    //this.guSpeciesId.markAsUntouched();
    //this.txtTreatmentMonth.markAsPristine();
    //this.txtTreatmentMonth.markAsUntouched();
    //this.txtTreatmentYear.markAsPristine();
    //this.txtTreatmentYear.markAsUntouched();

    //this.guSpeciesId.setValidators([Validators.required]);
    ////this.guSpeciesId.updateValueAndValidity();

    //this.txtTreatmentMonth.setValidators([Validators.required, Validators.maxLength(2), Validators.max(12), this.tpChargeItemService.treatment_month_Validator]);
    ////this.txtTreatmentMonth.updateValueAndValidity();

    //this.txtTreatmentYear.setValidators([Validators.required, Validators.maxLength(2), Validators.max(this.yearDigits), this.tpChargeItemService.treatment_year_Validator]);
    ////this.txtTreatmentYear.updateValueAndValidity();

    //this.form.updateValueAndValidity({ onlySelf: true });

    //this.txtTreatmentMonth.setErrors(this.txtTreatmentMonth.errors);

    //console.log(this.txtTreatmentMonth.errors);
  }

  public validateDiameter(diameterControl: AbstractControl, qv: boolean) {
    return this.poleService.validateDiameter(diameterControl, qv);
  }

  public validateKN(knControl: AbstractControl) {
    return this.poleService.validateKN(knControl);
  }

  public validateLength(lengthControl: AbstractControl) {
    return this.poleService.validateLength(lengthControl);
  }

  public validateTreatmentMonth(treatmentMonthControl: AbstractControl) {
    return this.tpChargeItemService.validateTreatmentMonth(
      treatmentMonthControl
    );
  }

  public validateTreatmentYear(treatmentYearControl: AbstractControl) {
    return this.tpChargeItemService.validateTreatmentYear(treatmentYearControl);
  }

  async onOriginalProductSelect(productTypeId: string) {
    //console.log(productTypeId);

    let pt: ProductType = await this.productTypeService.getProductTypePromise(
      productTypeId
    );

    this.eOriginalProductMeasurementType = pt.intMeasurementType;
    this.selectedOriginalProduct = pt;

    if (pt.intMeasurementType == ProductType.enMeasurementType.LengthKN) {
      //console.log("measureType - Disable: " + pt.intMeasurementType)
      this.txtOriginalKN.enable();
      this.chkOriginalQV.enable();

      this.txtOriginalLength.setValidators(
        this.poleService.original_nsw_LengthValidator
      );
      this.txtOriginalLength.updateValueAndValidity();
      this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
      this.originalLengthHint = "8m to 26m";
    } else {
      //console.log("measureType - Enable: " + pt.intMeasurementType)
      this.txtOriginalKN.setValue("");
      this.chkOriginalQV.setValue(false);

      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);

      //console.log("removed validators");

      this.txtOriginalLength.removeValidators(
        this.poleService.original_nsw_LengthValidator
      );
      this.txtOriginalLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtOriginalLength.updateValueAndValidity();

      this.originalLengthHint = "1m to 26m";

      this.txtOriginalKN.disable();
      this.chkOriginalQV.disable();
    }
  }

  async onFinishedProductSelect(productTypeId: string) {
    let pt: ProductType = await this.productTypeService.getProductTypePromise(
      productTypeId
    );

    this.eFinishedProductMeasurementType = pt.intMeasurementType;
    this.selectedFinishProduct = pt;

    //if (this.form.controls['guFinishedProductTypeId'].enabled == false) {
    //  return;
    //}

    if (pt.intMeasurementType == ProductType.enMeasurementType.LengthKN) {
      //console.log("measureType - Disable: " + pt.intMeasurementType)
      this.txtFinishedKN.enable();
      this.chkFinishedQV.enable();

      this.txtFinishedLength.setValidators(
        this.poleService.finished_nsw_LengthValidator
      );
      this.txtFinishedLength.updateValueAndValidity();
      //console.log("setting finish diameter validators for LengthKN");
      this.fltFinishedDiameter.setValidators([
        Validators.required,
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1250),
      ]);
      this.fltFinishedDiameter.updateValueAndValidity();
      this.txtFinishedKN.setValidators([
        Validators.required,
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedKN.updateValueAndValidity();
      this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
      this.finishedLengthHint = "8m to 26m";
      this.intFinishedDiameterType.setValue(SharedService.enDiameterType.GL);
      this.finishedDiaTypePlaceHolder = "GL";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.intFinishedDiameterType.disable();
    } else {
      //console.log("measureType - Enable: " + pt.intMeasurementType)
      this.txtFinishedKN.setValue("");
      this.chkFinishedQV.setValue(false);

      //console.log("setting finish diameter validators for Non Pole");

      this.fltFinishedDiameter.setValidators([
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1250),
      ]);
      this.fltFinishedDiameter.updateValueAndValidity();
      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedKN.updateValueAndValidity();

      this.txtFinishedLength.removeValidators(
        this.poleService.finished_nsw_LengthValidator
      );
      this.txtFinishedLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtFinishedLength.updateValueAndValidity();

      this.finishedDiamHint = "min: 150mm,  max: 1250mm";
      this.finishedGirthHint = "min: 150mm,  max: 1250mm";
      //this.finishedDiaPlaceHolder = "GL Diameter mm";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedLengthHint = "1m to 26m";

      this.txtFinishedKN.disable();
      this.chkFinishedQV.disable();
      //this.intFinishedDiameterType.enable();  // was enabled but think it should be changed at the Order Item Level in MTECH. 8/5/2023
      this.intFinishedDiameterType.disable();
    }
  }

  public onOriginalQVClick() {
    if (this.chkOriginalQV.value == true) {
      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.qv_kNRange),
      ]);
      if (
        this.eOriginalProductMeasurementType ==
        ProductType.enMeasurementType.LengthKN
      ) {
        this.txtOriginalLength.setValidators(
          this.poleService.finished_qv_LengthValidator
        );
      } else {
        this.txtOriginalLength.removeValidators(
          this.poleService.finished_qv_LengthValidator
        );
      }

      this.originalKNHint = "5, 8, 12, 20";

      this.originalLengthHint = "9.5m to 26m";
    } else {
      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      if (
        this.eOriginalProductMeasurementType ==
        ProductType.enMeasurementType.LengthKN
      ) {
        this.txtOriginalLength.setValidators(
          this.poleService.finished_nsw_LengthValidator
        );
      } else {
        this.txtOriginalLength.removeValidators(
          this.poleService.finished_nsw_LengthValidator
        );
      }
      this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";

      this.originalLengthHint = "8m to 26m";
    }
    this.txtOriginalKN.updateValueAndValidity();
  }

  public onFinishedQVClick() {
    // Remove both length validators.
    this.txtFinishedLength.removeValidators(
      this.poleService.finished_qv_LengthValidator
    );
    this.txtFinishedLength.removeValidators(
      this.poleService.finished_nsw_LengthValidator
    );

    let groundLineMeasurementType: ElectricalAuthoritySpec.enGroundLineMeasurementType =
      ElectricalAuthoritySpec.enGroundLineMeasurementType.Diameter;
    console.log("this.elecAuthSpecs", this.elecAuthSpecs);

    for (let i = 0; i <= this.elecAuthSpecs.length - 1; i++) {
      //  console.log("this.elecAuthSpecs[i].intGroundLineMeasurementType == ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference", this.elecAuthSpecs[i].intGroundLineMeasurementType);
      console.log(
        "this.guElectricalAuthoritySpecId.value",
        this.form.controls["guElectricalAuthoritySpecId"].value
      );
      if (
        this.form.controls["guElectricalAuthoritySpecId"].value ==
        this.elecAuthSpecs[i].rowguid
      ) {
        if (
          this.elecAuthSpecs[i].intGroundLineMeasurementType ==
          ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference
        ) {
          groundLineMeasurementType =
            ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference;
          console.log("groundLineMeasurementType", groundLineMeasurementType);
          break;
        }
      }
    }

    if (this.chkFinishedQV.value == true) {
      //We are doing this because ERGON in QLD is the only Q/V spec that uses diameter not circumference.
      setTimeout(() => {
        if (
          groundLineMeasurementType ==
          ElectricalAuthoritySpec.enGroundLineMeasurementType.Circumference
        ) {
          this.fltFinishedDiameter.removeValidators(
            this.finishDiameterValidator
          );
          this.fltFinishedDiameter.removeValidators(
            this.finishDiaCircumferenceValidator
          );
          this.fltFinishedDiameter.updateValueAndValidity();

          this.fltFinishedDiameter.setValidators(
            this.finishDiaCircumferenceValidator
          );
          this.fltFinishedDiameter.updateValueAndValidity();
          //this.fltFinishedDiameter.statusChanges.subscribe(sub => {
          //  console.log("sub: ", sub);
          //});

          //console.log("validator this.chkFinishedQV finishDiaCircumferenceValidator");

          this.finishedDiaPlaceHolder =
            this.finishedDiaTypePlaceHolder + " Circumference mm";
          this.finishedDiamHint = "min: 470mm,  max: 3100mm";

          this.cdref.detectChanges();
        }

        if (
          groundLineMeasurementType ==
          ElectricalAuthoritySpec.enGroundLineMeasurementType.Diameter
        ) {
          this.fltFinishedDiameter.removeValidators(
            this.finishDiameterValidator
          );
          this.fltFinishedDiameter.setValidators(this.finishDiameterValidator);
          this.fltFinishedDiameter.updateValueAndValidity();
          this.finishedDiaPlaceHolder =
            this.finishedDiaTypePlaceHolder + " Diameter mm";
          this.finishedDiamHint = "min: 150mm,  max: 1000mm";
          //console.log("validator this.chkFinishedQV finishDiameterValidator");
        }
      }, 500);

      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.qv_kNRange),
      ]);
      this.txtFinishedLength.setValidators(
        this.poleService.finished_qv_LengthValidator
      );

      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Circumference mm";

      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedKNHint = "5, 8, 12, 20";

      this.finishedLengthHint = "9.5m to 26m";
    } else {
      this.fltFinishedDiameter.removeValidators(this.finishDiameterValidator);
      this.fltFinishedDiameter.setValidators(this.finishDiameterValidator);
      this.fltFinishedDiameter.updateValueAndValidity();

      //console.log("validator this.chkFinishedQV finishDiameterValidator NSW");

      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedLength.setValidators(
        this.poleService.finished_nsw_LengthValidator
      );

      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Diameter mm";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";

      this.finishedLengthHint = "8m to 26m";
    }
    this.fltFinishedDiameter.updateValueAndValidity();
    this.txtFinishedKN.updateValueAndValidity();
  }

  setValidators(
    originalPT: ProductType,
    finalPT: ProductType,
    originalPole: Pole,
    finalPole: Pole
  ) {
    if (
      originalPole != null &&
      originalPT.intMeasurementType == ProductType.enMeasurementType.LengthKN
    ) {
      if (originalPole.blnQV == true) {
        this.txtOriginalKN.setValidators([
          Validators.pattern(this.poleService.qv_kNRange),
        ]);
        this.txtOriginalLength.setValidators(
          this.poleService.finished_qv_LengthValidator
        );
        this.originalKNHint = "5, 8, 12, 20";
        this.originalLengthHint = "9.5m to 26m";
      } else {
        this.txtOriginalLength.setValidators(
          this.poleService.original_nsw_LengthValidator
        );
        this.txtOriginalLength.updateValueAndValidity();
        this.originalKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
        this.originalLengthHint = "8m to 26m";

        this.txtOriginalKN.setValidators([
          Validators.pattern(this.poleService.nsw_kNRange),
        ]);
      }
      this.txtOriginalKN.updateValueAndValidity();
    }

    if (
      finalPole != null &&
      finalPT.intMeasurementType == ProductType.enMeasurementType.LengthKN
    ) {
      console.log("finalPole.blnQV", finalPole.blnQV);
      if (finalPole.blnQV == true) {
        this.fltFinishedDiameter.setValidators([
          Validators.required,
          Validators.maxLength(5),
          Validators.min(470),
          Validators.max(3100),
        ]);
        this.fltFinishedDiameter.updateValueAndValidity();
        this.txtFinishedKN.setValidators([
          Validators.pattern(this.poleService.qv_kNRange),
        ]);
        this.txtFinishedKN.updateValueAndValidity();
        this.txtFinishedLength.setValidators(
          this.poleService.finished_qv_LengthValidator
        );

        this.finishedDiamHint = "min: 470mm,  max: 3100mm";
        this.finishedGirthHint = "min: 150mm,  max: 1000mm";
        //this.finishedDiaPlaceHolder = "GL Circumference mm";
        this.finishedDiaPlaceHolder =
          this.finishedDiaTypePlaceHolder + " Circumference mm";
        this.finishedGirthPlaceHolder = "Girth Diameter mm";
        this.finishedKNHint = "5, 8, 12, 20";

        this.finishedLengthHint = "9.5m to 26m";

        console.log("setValidators - finalPole.blnQV");
      } else {
        this.txtFinishedLength.setValidators(
          this.poleService.finished_nsw_LengthValidator
        );
        this.txtFinishedLength.updateValueAndValidity();

        this.fltFinishedDiameter.setValidators([
          Validators.required,
          Validators.maxLength(4),
          Validators.min(150),
          Validators.max(1000),
        ]);
        this.fltFinishedDiameter.updateValueAndValidity();
        this.txtFinishedKN.setValidators([
          Validators.required,
          Validators.pattern(this.poleService.nsw_kNRange),
        ]);
        this.txtFinishedKN.updateValueAndValidity();
        this.finishedKNHint = "2, 4, 6, 8, 12, 16, 18, 20";
        this.finishedLengthHint = "8m to 26m";

        this.finishedDiamHint = "min: 150mm,  max: 1000mm";
        this.finishedGirthHint = "min: 150mm,  max: 1000mm";

        this.finishedDiaPlaceHolder =
          this.finishedDiaTypePlaceHolder + " Diameter mm";
        this.finishedGirthPlaceHolder = "Girth Diameter mm";
      }
    }

    if (
      originalPole == null &&
      originalPT.intMeasurementType != ProductType.enMeasurementType.LengthKN
    ) {
      this.txtOriginalKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);

      this.txtOriginalLength.removeValidators(
        this.poleService.original_nsw_LengthValidator
      );
      this.txtOriginalLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtOriginalLength.updateValueAndValidity();

      this.originalLengthHint = "1m to 26m";
    }

    if (
      finalPole == null &&
      finalPT.intMeasurementType != ProductType.enMeasurementType.LengthKN
    ) {
      this.fltFinishedDiameter.setValidators([
        Validators.maxLength(4),
        Validators.min(150),
        Validators.max(1000),
      ]);
      this.fltFinishedDiameter.updateValueAndValidity();
      this.txtFinishedKN.setValidators([
        Validators.pattern(this.poleService.nsw_kNRange),
      ]);
      this.txtFinishedKN.updateValueAndValidity();

      this.txtFinishedLength.removeValidators(
        this.poleService.finished_nsw_LengthValidator
      );
      this.txtFinishedLength.removeValidators(
        this.poleService.finished_qv_LengthValidator
      );
      this.txtFinishedLength.updateValueAndValidity();

      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedGirthHint = "min: 150mm,  max: 1000mm";
      //this.finishedDiaPlaceHolder = "GL Diameter mm";
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
      this.finishedGirthPlaceHolder = "Girth Diameter mm";
      this.finishedLengthHint = "1m to 26m";
    }
  }

  getSpecies() {
    this.speciesService.getAllSpecies().subscribe(
      (data) => {
        this.species = data;
        this.speciesOriginal = data;
        this.filteredSpecies.next(this.species);

        this.speciesFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterSpecies();
          });
        //console.log("data: " + JSON.stringify(data));
      },
      (errResponse: HttpErrorResponse) => {
        this.errorMessage = new ErrorMessage();
        this.errorMessage.message = errResponse.error.message;
        this.showErrorMessage = true;
      }
    );
  }

  getProducts() {
    this.productTypeService.getAllRoundProductTypes().subscribe(
      (data) => {
        this.productTypes = data;
        this.filteredProductTypes.next(this.productTypes);

        this.productTypeFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterProductTypes();
          });
        //console.log("data: " + JSON.stringify(data));
      },
      (errResponse: HttpErrorResponse) => {
        this.alertService.openSnackBarError(
          "Error getting products",
          "Close",
          "center",
          "bottom",
          3000,
          true,
          errResponse.error.message
        );
      }
    );
  }

  async getCustomers() {
    let customers = await this.customerService.getTreatmentPlantCustomers(
      true,
      false
    );
    if (customers == null) {
      return;
    }

    this.customersAll = customers;
    this.customers = customers;
    //this.filteredCustomers.next(this.customers);

    //  this.customersFilterCtrl.valueChanges
    //    .pipe(takeUntil(this._onDestroy))
    //    .subscribe(() => {
    //      this.filterCustomers();
    //    });
  }

  getProductTypes() {
    this.productTypeService.getAllPoleFinalProductTypes().subscribe((data) => {
      this.productTypes = data;
      let polePT: ProductType;
      polePT = this.productTypes.find((pt) => pt.txtName == "Pole");
      this.form.controls["guOriginalProductTypeId"].setValue([polePT.rowguid]);
      this.form.controls["guFinishedProductTypeId"].setValue([polePT.rowguid]);
    });
  }

  getElectricalAuthoritySpecs() {
    this.electricalAuthoritySpec
      .getAllElectricalAuthoritySpecs()
      .subscribe((data) => {
        this.elecAuthSpecs = data;
        let elecAuthSpec: ElectricalAuthoritySpec =
          new ElectricalAuthoritySpec();
        elecAuthSpec.rowguid = this.sharedService.EmptyGuid;
        elecAuthSpec.txtAuthorityName = "None";
        this.elecAuthSpecs.splice(0, 0, elecAuthSpec);
      });
  }

  private filterSpecies() {
    if (!this.species) {
      return;
    }

    // get the search keyword
    let search = this.speciesFilterCtrl.value;
    if (!search) {
      this.filteredSpecies.next(this.species);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the Species
    this.filteredSpecies.next(
      this.species.filter(
        (sp) =>
          sp.txtCode.toLowerCase().indexOf(search) > -1 ||
          sp.txtCodeAlias.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterProductTypes() {
    if (!this.productTypes) {
      return;
    }

    // get the search keyword
    let search = this.productTypeFilterCtrl.value;
    if (!search) {
      this.filteredProductTypes.next(this.productTypes);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredProductTypes.next(
      this.productTypes.filter(
        (pt) => pt.txtName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  searchCustomers(name: string) {
    if (this.customers == null) {
      return;
    }

    this.customers = this.customersAll;

    this.customers = this.customers.filter(
      (a) => a.txtName.toLowerCase().indexOf(name.toLowerCase()) > -1
    );

    //  // get the search keyword
    //  let search = this.customersFilterCtrl.value;
    //  if (!search) {
    //    this.filteredCustomers.next(this.customers);
    //    return;
    //  } else {
    //    search = search.toLowerCase();
    //  }

    //  // filter the ProductTypes
    //  this.filteredCustomers.next(
    //    this.customers.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
    //  );
  }

  searchOrders(orderNo: string) {
    if (this.orders == null) {
      return;
    }

    this.orders = this.ordersAll;

    this.orders = this.orders.filter(
      (a) => a.txtOrderNo.toLowerCase().indexOf(orderNo.toLowerCase()) > -1
    );
  }

  searchOrderItems(item: string) {
    if (this.orderItems == null) {
      return;
    }

    this.orderItems = this.orderItemsAll;

    this.orderItems = this.orderItems.filter(
      (a) => a.txtDescription.toLowerCase().indexOf(item.toLowerCase()) > -1
    );
  }

  private filterOrders() {
    if (!this.orders) {
      return;
    }

    // get the search keyword
    let search = this.ordersFilterCtrl.value;
    if (!search) {
      this.filteredOrders.next(this.orders);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredOrders.next(
      this.orders.filter((o) => o.txtOrderNo.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterOrderItems() {
    if (!this.orderItems) {
      return;
    }

    // get the search keyword
    let search = this.orderItemFilterCtrl.value;
    if (!search) {
      this.filteredOrderItems.next(this.orderItems);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredOrders.next(
      this.orders.filter((o) => o.txtOrderNo.toLowerCase().indexOf(search) > -1)
    );
  }

  async customerChanged(matSelectChange: MatSelectionListChange) {
    //console.log("matSelectChange.options.values[0]", matSelectChange.options[0].value);

    this.resetOrders();

    if (
      matSelectChange.options[0].value == null ||
      matSelectChange.options[0].value == ""
    ) {
      return;
    }

    this.populateOrders(matSelectChange.options[0].value);
  }

  resetOrders() {
    this.orders = [];
    this.orderItems = [];
    this.filteredOrderItems.next(null);
  }

  async populateOrders(customerId: string) {
    this.orders = await this.ordersService.getCustomerOrdersActiveAndPartial(
      customerId
    );
    this.ordersAll = this.orders;

    //console.log("Orders:", this.orders);

    //this.filteredOrders.next(this.orders);

    //this.ordersFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrders();
    //  });
  }

  async orderChanged(orderId: string) {
    //MatSelectionListChange

    //console.log(order.options[0].value);
    //console.log(order);

    if (orderId == null) {
      return;
    }

    this.orderItems =
      await this.orderItemService.getdtoOrderItemsByOrderPromise(orderId);
    this.orderItemsAll = this.orderItems;

    //If changing order number then reset the order item.
    //console.log("guOrderItemId - value:", this.form.controls["guOrderItemId"].value);

    this.form.controls["guOrderItemId"].setValue([""]);

    //console.log("guOrderItemId - value after:", this.form.controls["guOrderItemId"].value);

    this.orderItem;
    //this.filteredOrderItems.next(this.orderItems);

    //this.orderItemFilterCtrl.valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterOrderItems();
    //  });
  }

  async orderItemChanged(orderItem: dtoOrderItem) {
    //console.log("orderItem: ", orderItem);

    //.options[0].value
    this.selectedOrderItem = orderItem;
    let finalProduct = this.form.controls["guFinishedProductTypeId"];

    if (orderItem == null) {
      finalProduct.enable();
      return;
    }

    this.txtFinishedLength.enable();
    this.txtFinishedKN.enable();
    this.chkDrilled.enable();

    this.txtOriginalLength.setValue("");
    this.txtOriginalKN.setValue("");
    this.txtFinishedLength.setValue("");
    this.txtFinishedKN.setValue("");
    this.chkOriginalQV.setValue(0);
    this.chkFinishedQV.setValue(0);
    this.chkOriginalSub.setValue(0);
    this.chkFinishedSub.setValue(0);
    this.chkOriginalRegal.setValue(0);
    this.chkFinishedRegal.setValue(0);
    this.guElectricalAuthoritySpecId.setValue(this.sharedService.EmptyGuid);

    let originalProduct = this.form.controls["guOriginalProductTypeId"];
    if (originalProduct != null && this.productTypes != null) {
      let productIds: string[] = this.productTypes.map((pt) => pt.rowguid);

      if (productIds.includes(orderItem.guProductTypeId)) {
        console.log("setting original product");

        originalProduct.setValue([orderItem.guProductTypeId]);

        finalProduct.setValue([orderItem.guProductTypeId]);
        finalProduct.disable();

        for (let i = 0; i <= this.productTypes.length - 1; i++) {
          if (this.productTypes[i].rowguid == orderItem.guProductTypeId) {
            // Non Pole Product
            if (orderItem.guPoleId == null || orderItem.guPoleId == "") {
              this.txtOriginalLength.setValue(orderItem.fltLength);
              this.txtFinishedLength.setValue(orderItem.fltLength);

              //this.intFinishedDiameterType.removeValidators(Validators.required);
              //this.intFinishedDiameterType.updateValueAndValidity();

              if (orderItem.intDiameterType != null) {
                this.intFinishedDiameterType.setValue(
                  orderItem.intDiameterType
                );
                this.finishedDiameterTypeChange(orderItem.intDiameterType);
              }

              this.chkFinishedQV.disable();
              this.chkFinishedSub.disable();
            }

            // Pole Product
            if (orderItem.guPoleId != null && orderItem.guPoleId != "") {
              this.txtOriginalLength.setValue(orderItem.intLength);
              this.txtOriginalKN.setValue(orderItem.intKN);
              this.txtFinishedLength.setValue(orderItem.intLength);
              this.txtFinishedKN.setValue(orderItem.intKN);
              this.chkOriginalSub.setValue(orderItem.blnSubPole);
              this.chkFinishedSub.setValue(orderItem.blnSubPole);
              this.chkOriginalRegal.setValue(orderItem.blnRegal);
              this.chkFinishedRegal.setValue(orderItem.blnRegal);
            }

            this.txtFinishedLength.disable();
            setTimeout(() => this.txtFinishedKN.disable(), 5); // won't disable straight away so have to delay, some angular bug or something..

            this.onOriginalProductSelect(this.productTypes[i].rowguid);
            this.onFinishedProductSelect(this.productTypes[i].rowguid);

            //console.log("orderItem.blnQV", orderItem.blnQV);
            if (
              orderItem.guElectricalAuthoritySpecId != null &&
              orderItem.guElectricalAuthoritySpecId != ""
            ) {
              this.guElectricalAuthoritySpecId.setValue(
                orderItem.guElectricalAuthoritySpecId
              );
            }

            if (orderItem.blnQV == true) {
              this.chkOriginalQV.setValue(1);
              this.onOriginalQVClick();
              this.chkFinishedQV.setValue(1);
              this.onFinishedQVClick();
              this.chkFinishedQV.disable();
            }

            // Just in case we change from VIC order item to NSW order item.
            // Probably won't happen but better reset validation on diameters and kn's
            this.onOriginalQVClick();
            this.onFinishedQVClick();

            this.chkDrilled.setValue(orderItem.blnDrilled);
            this.chkDrilled.disable();

            if (
              orderItem.intTreatmentType == SharedService.enTreatmentType.CCA
            ) {
              //console.log("setting treatment validators");
              this.txtTreatmentMonth.setValidators(
                this.treatment_month_Validator
              );
              this.txtTreatmentMonth.updateValueAndValidity();
              this.txtTreatmentYear.setValidators(
                this.treatment_year_Validator
              );
              this.txtTreatmentYear.updateValueAndValidity();
            }

            if (
              orderItem.intTreatmentType != SharedService.enTreatmentType.CCA
            ) {
              //console.log("removing treatment validators");
              //this.txtTreatmentMonth.removeValidators(this.treatment_month_Validator);
              //this.txtTreatmentYear.removeValidators(this.treatment_year_Validator);
              this.txtTreatmentMonth.clearValidators();
              this.txtTreatmentYear.clearValidators();
              this.txtTreatmentMonth.updateValueAndValidity();
              this.txtTreatmentYear.updateValueAndValidity();
            }

            break;
          }
        }
      }
    }

    //if (orderItem.guPoleId == null || orderItem.guPoleId == "") {

    //  let originalPole = null;
    //  let finishedPole = null;

    //  originalPole = await this.poleService.getPoleByIdPromise(orderItem.guPoleId);
    //  finishedPole = await this.poleService.getPoleByIdPromise(orderItem.guPoleId);

    //  this.setValidators(this.selectedOriginalProduct, this.selectedFinishProduct, originalPole, finishedPole)

    //}
    ////this.orderItems = await this.orderItemService.getOrderItemsByOrderPromise(order.rowguid);
  }

  finishedDiameterTypeChange(diameterType: number) {
    if (diameterType == SharedService.enDiameterType.SED) {
      this.finishedDiaTypePlaceHolder = "SED";
    }

    if (diameterType == SharedService.enDiameterType.BED) {
      this.finishedDiaTypePlaceHolder = "BED";
    }

    if (diameterType == SharedService.enDiameterType.Centre) {
      this.finishedDiaTypePlaceHolder = "Centre";
    }

    if (diameterType == SharedService.enDiameterType.GL) {
      this.finishedDiaTypePlaceHolder = "GL";
    }

    if (this.chkFinishedQV.value == true) {
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Circumference mm";
    }

    if (this.chkFinishedQV.value == false) {
      this.finishedDiaPlaceHolder =
        this.finishedDiaTypePlaceHolder + " Diameter mm";
    }

    //console.log(diameterType);
  }

  testReset() {
    //this.form.markAsPristine();
    //this.form.markAsUntouched();
    this.txtTreatmentMonth.clearValidators();
    this.txtTreatmentMonth.updateValueAndValidity();

    //this.form.updateValueAndValidity();
  }

  checkValidation(
    orderElement: HTMLDivElement,
    poleNumberElement: HTMLElement
  ) {
    const elementWithErrors = document.querySelectorAll(".ng-invalid");
    if (
      elementWithErrors != null &&
      elementWithErrors.length > 1 &&
      elementWithErrors[1] != null
    ) {
      //console.log("in invalid elements");
      elementWithErrors[1].scrollIntoView({ behavior: "smooth" });
      //return;
    }

    if (this.form.invalid == false) {
      this.scrollIntoView(orderElement);
      this.setFocus(poleNumberElement);
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.updateValueAndValidity();
      //this.form.reset();
    }

    // Might be useful for somthing.
    if (this.form.invalid == true) {
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      //console.log(invalid);
    }
  }

  scrollIntoView(el: HTMLElement) {
    this.formService.scrollIntoView(el);
  }

  setFocus(el: HTMLElement) {
    this.formService.setAutoFocusByElement(el);
  }

  testTreatmentMonth(el: HTMLInputElement) {
    //console.log(Date.now() + " value: " + el.value + ",  length: " + el.value.length);
    if (el.value == null || el.value == "") {
      return;
    }

    if (el.value.length == 2) {
      this.formService.setAutoFocusTimeout("txtTreatmentYear");
    }
  }

  testTreatmentYear(el: HTMLInputElement) {
    //console.log(Date.now() + " value: " + el.value + ",  length: " + el.value.length);
    if (el.value == null || el.value == "") {
      return;
    }

    if (el.value.length == 2 && el.value.toString()[0] == "0") {
      //console.log("first value is zero: " + el.value.toString()[0]);
    }
  }

  searchSpecies(code: string) {
    this.species = this.speciesOriginal;

    this.species = this.species.filter(
      (c) =>
        c.txtCode.toLowerCase().startsWith(code.toLowerCase()) ||
        (c.txtCodeAlias != null
          ? c.txtCodeAlias.toLowerCase().startsWith(code.toLowerCase())
          : false)
    );
  }

  async selectConsecutiveNumber() {
    if (
      this.form.controls["txtChargeNumber"].value == null ||
      this.form.controls["txtChargeNumber"].value == ""
    ) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Charge Number",
        "Please enter a charge number",
        "Ok"
      );
      return;
    }

    if (
      this.form.controls["txtTreatmentYear"].value == null ||
      this.form.controls["txtTreatmentYear"].value == ""
    ) {
      await this.alertService.openSnackBarCustomDefaultPromise(
        "Treatment Year",
        "Please enter a treatment year",
        "Ok"
      );
      return;
    }

    if (this.consecutiveNumberListDialogRef != null) {
      this.consecutiveNumberListDialogRef.close();
    }

    let chargeNo: number = this.form.controls["txtChargeNumber"].value;
    let chargeYear: number = this.form.controls["txtTreatmentYear"].value;

    this.consecutiveNumberListDialogRef = this.dialog.open(
      ConsecutiveNumberListComponent,
      {
        height: "auto",
        maxWidth: "95vw",
        data: { chargeNo: chargeNo, chargeYear: chargeYear },
      }
    );

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    // this.consecutiveNumberListDialogRef.backdropClick().subscribe(() => {
    //   this.consecutiveNumberListDialogRef.close();
    // });

    this.consecutiveNumberListDialogRef
      .afterClosed()
      .subscribe(
        async (data: { status: string; chargeItem: dtoTPChargeItem }) => {
          if (data == null || data == undefined) {
            this.alertService.openSnackBarError(
              "Something went wrong!\nAn email has been sent to the IT department.",
              "Close",
              "center",
              "bottom",
              4000,
              true,
              "Something went wrong with closing the FinalInspectionCreate dialog, null data."
            );
          }

          if (data.status != "Cancel") {
            //If we have used a consective number we will link this final item to the charge item apon saving.
            this.selectedTPChargeItem = null;

            console.log(data);
            if (data.chargeItem != null) {
              this.form.controls["txtConsecutiveNumber"].setValue(
                data.chargeItem.intConsecutiveNo.toString()
              );
              this.selectedTPChargeItem = data.chargeItem;
            }

            //this.transferredFinalInspectionItemEvent.emit(true);
          } else {
            // We have cancellled
            if (this.selectedTPChargeItem != null) {
              let msg: string =
                await this.alertService.openSnackBarCustomDefaultShowCancel(
                  "Clear Consecutive Number",
                  "You already have a consecutive number (" +
                    this.selectedTPChargeItem.intConsecutiveNo.toString() +
                    ") set.\nDo you want to clear it?",
                  "Yes",
                  "No"
                );
              if (msg == "Yes") {
                this.selectedTPChargeItem = null;
                this.form.controls["txtConsecutiveNumber"].setValue("");
              }
            }
          }
        }
      );
  }
}
