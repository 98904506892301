import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  ChangeDetectionStrategy,
  HostListener,
  Inject,
} from "@angular/core";
import { AppUser } from "../app-users/app-user-update/appuser.module";
import { AuthService } from "../account/auth.service";
import { NavigationService } from "../_shared/services/navigation.service";
import {
  BehaviorSubject,
  Observable,
  Observer,
  Subscription,
  timer,
} from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthenticationResult } from "@azure/msal-browser";
import { dtoKeyValue } from "../_shared/business-objects/dto-int-key-value.bo";
import { User } from "../account/user";
import { AlertService } from "../_shared/services/alert.service";
import { SharedService } from "../_shared/services/shared.service";
import { AppService } from "../_shared/services/app-services/app.service";
import { dtoAppUserPrinter } from "../_shared/business-objects/dto-app-user-printer.bo";
import { dtoAppUserPrinterService } from "../_shared/services/dto-app-user-printer.service";
import { SQLParamArray } from "../_shared/business-objects/sql-param-array";
import { Printer } from "../_shared/business-objects/printer.bo";
import { dtoAppUserPrinterDefault } from "../_shared/business-objects/dto-app-user-printer-default.bo";
import { dtoAppUserPrinterDefaultService } from "../_shared/services/dto-app-user-printer-default.service";
import { AppUserPrinterDefaultService } from "../_shared/services/app-user-printer-default.service";
import { AppUserPrinterDefault } from "../_shared/business-objects/app-user-printer-default.bo";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"], //,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;

  hideNav: boolean = false;

  user: AppUser;
  msUser: User;
  reckonUser: AppUser;
  _isAuthenticated$: Observable<boolean>;
  _isReckonAuthenticated$: Observable<boolean>;
  reckonAuthenticated: boolean;
  _isAdmin$: Observable<boolean>;
  _isAdmin: boolean;
  _isAuthenticated: boolean;
  _isAdminChecked: boolean;
  _isAuthenticatedChecked: boolean;
  isOffice365Authenticated: boolean;

  office365Authenticated$: Observable<boolean>;

  _tokenTime$: Observable<string>;

  printers: dtoAppUserPrinter[];
  defaultPrinter: dtoAppUserPrinterDefault;

  private _timerSubscription: Subscription;
  private _reckonTimerSubscription: Subscription;

  testDB: boolean = false;

  time: Date;
  timeRefreshTime: string;

  timerSubscribed: boolean;

  //itemSubscription: Subscription;

  authRes: AuthenticationResult;

  showPrinters: boolean = false;
  showInlineSpinner: boolean = false;
  showTick: boolean;

  @ViewChild("#navCollapseMenu", { static: true }) navCollapseMenu: ElementRef;
  @ViewChild("navMenu", { static: false }) navBar: ElementRef;

  //@ViewChild('myname', { static: true }) input;

  //

  //myBS$: BehaviorSubject<number>;
  //_myBS_Number: number;

  // =============================================================
  // OBSERVABLE OPERATOR EXAMPLE FOR FUTRUE REFERENCE.
  // =============================================================
  //myObs = new Observable((observer) => {

  //  console.log("observer starts");

  //  observer.next(1);
  //  observer.next(2);
  //  observer.next(3);
  //  observer.next(4);
  //  observer.next(5);
  //  observer.complete();
  //}).pipe(filter(data => data > 2),
  //  map((val) => { return val as number * 2 }),
  //  );

  // =============================================================

  constructor(
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private sharedService: SharedService,
    @Inject("BASE_URL") private baseUrl: string,
    private appService: AppService,
    private dtoAppUserPrinterService: dtoAppUserPrinterService,
    private dtoAppUserPrinterDefaultService: dtoAppUserPrinterDefaultService,
    private appUserPrinterDefaultService: AppUserPrinterDefaultService,
    private readonly el: ElementRef
  ) {
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    }

    this.user = new AppUser();
    this.user.txtFirstName = "";
    this.user.txtLastName = "";
    this.user.blnHasReckonAccount = false;

    this._isAdmin = false;

    this.isExpanded = false;

    this.timerSubscribed = false;

    this.isOffice365Authenticated = false;
  }

  ngOnInit() {
    this.showInlineSpinner = true;
    setTimeout(() => {
      let inlineSpinner = document.getElementById("inlineSpinnerId");
      if (inlineSpinner != null) {
        inlineSpinner.style.position = "absolute";
        inlineSpinner.style.top = "10px";
        inlineSpinner.style.left = "10px";
      }
    }, 500);

    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );

    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );

    this._isAuthenticated$ = this.authService.isAuthenticated;
    this._isAdmin$ = this.authService.isAdmin;

    //this.itemSubscription = this.authService.$visible.subscribe((item: number) => {
    //  console.log("number: " + item);
    //})

    this._isAuthenticated = false;
    this.isExpanded = false;

    this.timerSubscribe();
    //console.log("in nav menu: " + Date.now());

    let tokenTimer = timer(0, 1000 * 60 * 10); // every ten minutes.
    tokenTimer.subscribe((val) => {
      //console.log("in token timber");
      if (this.isAuthenticated() == true) {
        this.authService.isTokenValid(true);
        //console.log("refreshed");
      }
    });

    if (window.location.href.indexOf("stock-for-sale") > -1) {
      //console.log("stock-for-sale");
      if (this.navBar != null && this.navBar.nativeElement != null) {
        this.navBar.nativeElement.hidden = true;
      }
      this.hideNav = true;
    }

    this.route.queryParams.subscribe(async (params) => {
      if (params != null) {
        let hidenav: string = params["hidenav"];
        let location: string = window.location.href;

        if (
          (hidenav != null && hidenav.toLowerCase() == "yes") ||
          location.indexOf("reckon-identity") > -1
        ) {
          if (this.navBar != null && this.navBar.nativeElement != null) {
            this.navBar.nativeElement.hidden = true;
          }
          this.hideNav = true;
          //console.log("hideNav: ", this.hideNav);
          //console.log("hidden: ", this.navBar.nativeElement.hidden);
        }
      }
    });

    setTimeout(() => {
      this.user = this.authService.getAppUser();
      if (this.user != null) {
        this.user.txtSalt = "";

        if (this.user.blnHasReckonAccount == true) {
          this.getReckonSettings();
        }
      }

      //console.log("user: ", this.user);
    }, 1000);

    setTimeout(() => {
      //console.log("this.hideNav", this.hideNav);
      if (this.hideNav == false) {
        this.isTestDB();
      }
    }, 500);

    this.getPrinters();
    this.getDefaultPrinter();
  }

  @HostListener("document:click", ["$event"])
  closePrinterList(event: MouseEvent): void {
    if (this.showPrinters && !this.el.nativeElement.contains(event.target)) {
      this.showPrinters = false;
    }
  }

  async getPrinters() {
    setTimeout(async () => {
      if (this.user != null) {
        let aParamList: SQLParamArray[][] = [];
        let aParam: SQLParamArray[] = [];
        aParam.push(new SQLParamArray("guAppUserId", this.user.rowguid));
        aParamList.push(aParam);

        aParam = [];
        aParam.push(
          new SQLParamArray("intType", Printer.enType.Mobile.toString())
        );
        aParamList.push(aParam);

        this.printers =
          await this.dtoAppUserPrinterService.getdtoAppUserPrinterParamArrayPromise(
            aParamList
          );
        console.log(this.printers);
      }
    }, 1500);
  }

  async getDefaultPrinter() {
    setTimeout(async () => {
      if (this.user != null) {
        this.defaultPrinter =
          await this.dtoAppUserPrinterDefaultService.getdtoAppUserDefaultPrinter(
            this.user.rowguid,
            Printer.enType.Mobile
          );
        console.log("this.defaultPrinter: ", this.defaultPrinter);
      }
    }, 1500);
  }

  togglePrintersList() {
    this.showPrinters = !this.showPrinters;
  }

  async setDefaultMobilePrinter(
    printer: dtoAppUserPrinter,
    printerRow: HTMLDivElement
  ) {
    let defaultPrinter: AppUserPrinterDefault =
      await this.appUserPrinterDefaultService.getAppUserDefaultPrinter(
        this.user.rowguid,
        Printer.enType.Mobile
      );

    if (defaultPrinter != null && defaultPrinter.rowguid != printer.rowguid) {
      var offsets = printerRow.getBoundingClientRect();
      //var top = offsets.top + window.scrollY - 175;
      //var left = offsets.left + window.scrollX - 25;

      var top = offsets.top + 2;
      var left = offsets.left + 2;

      console.log("offsets", offsets);
      console.log("top: " + top + " - left: " + left);

      this.showAndPositionInlineSpinner(top, left);

      // Remove default printer.
      await this.appUserPrinterDefaultService.deleteAppUserPrinterDefaultPromise(
        defaultPrinter.rowguid
      );

      // Add default printer
      delete defaultPrinter.rowguid;
      defaultPrinter.dteDateCreated =
        this.sharedService.currentDatePlusTZOffset();
      defaultPrinter.guPrinterId = printer.rowguid;
      await this.appUserPrinterDefaultService.createAppUserPrinterDefaultPromise(
        defaultPrinter
      );
      await this.getDefaultPrinter();

      //this.hideInlineSpinner(false);
      setTimeout(() => {
        this.showInlineSpinner = false;
      }, 800);

      setTimeout(() => {
        this.showPrinters = false;
      }, 2000);
    }
  }

  showAndPositionInlineSpinner(top: number, left: number) {
    this.showInlineSpinner = true;
    setTimeout(() => {
      this.positionSpinner(top, left);
    }, 500);
  }

  hideInlineSpinner(showTick: boolean) {
    this.showInlineSpinner = false;

    if (showTick == true) {
      setTimeout(() => {
        this.showTick = true;
      }, 400);
      setTimeout(() => {
        this.showTick = false;
      }, 600);
    }
  }

  positionSpinner(top: number, left: number) {
    //setTimeout(() => {
    //}, 500);
    let navSpinner = document.getElementById("navSpinner");
    //navSpinner.style.display = "visible";

    let spinnerInlineContent: HTMLCollectionOf<HTMLDivElement> =
      document.getElementsByClassName(
        "spinner-inline-tick-content"
      ) as HTMLCollectionOf<HTMLDivElement>;
    let inlineSpinner = document.getElementById("inlineSpinnerId");
    if (inlineSpinner != null) {
      inlineSpinner.style.position = "absolute";
      inlineSpinner.style.top = top.toString() + "px";
      inlineSpinner.style.left = left.toString() + "px";
      inlineSpinner.style.padding = "0";
      inlineSpinner.style.zIndex = "30000";
    }

    //console.log("inlineSpinner.style.top", inlineSpinner.style.top);
    //console.log("inlineSpinner.style.left", inlineSpinner.style.left);

    //navSpinner.style.position = "absolute";
    //navSpinner.style.top = top.toString() + 'px';
    //navSpinner.style.left = left.toString() + 'px';
    navSpinner.style.visibility = "visible";
    navSpinner.style.zIndex = "30000";

    //inlineSpinner.style.opacity = "0.1"
    //inlineSpinner.style.backgroundColor = "#868686"
    inlineSpinner.style.backgroundColor = "transparent";

    if (spinnerInlineContent[0] != null) {
      //spinnerInlineContent[0].style.backgroundColor = "#868686"
      spinnerInlineContent[0].style.backgroundColor = "transparent";
      //console.log("spinnerInlineContent", spinnerInlineContent[0]);
    }

    //console.log("inlineSpinner", inlineSpinner);
    //console.log("spinnerInlineContent", spinnerInlineContent[0]);
  }

  receiveMessage(e) {
    //console.log("window message: ", e);

    if (e.data) {
      //Code for true
      //console.log("window message data true: ", e.data);
      this.reckonAuthenticated = true;
    } else {
      //Code for false
      //console.log("window message data false: ", e.data);
    }

    //window.onmessage = function (e) {
    //  if (e.data) {
    //    //Code for true
    //    console.log("window message data true: ", e.data);
    //    this.reckonAuthenticated = true;
    //  } else {
    //    //Code for false
    //    console.log("window message data false: ", e.data);
    //  }

    //};
  }

  async getReckonSettings() {
    //console.log("this.authService.ms_authenticated ", this.authService.ms_authenticated);

    //console.log("location.host", location.host);

    if (this.authService.ms_authenticated == true) {
      await this.authService.loadReckonSettings();

      this.authService.reckonAuth.subscribe((res) => {
        this.reckonAuthenticated = res;

        //console.log("reckonAuth.emit ", res);
        //console.log("location.href.indexOf(reckon-identity) ", location.href.indexOf("reckon-identity"));

        //Only go to reckon-identity if I'm not already on reckon-identity
        if (res == false && location.href.indexOf("reckon-identity") == -1) {
          const popupCenter = ({ url, title, w, h }) => {
            // Fixes dual-screen position                             Most browsers      Firefox
            const dualScreenLeft =
              window.screenLeft !== undefined
                ? window.screenLeft
                : window.screenX;
            const dualScreenTop =
              window.screenTop !== undefined
                ? window.screenTop
                : window.screenY;

            const width = window.innerWidth
              ? window.innerWidth
              : document.documentElement.clientWidth
              ? document.documentElement.clientWidth
              : screen.width;
            const height = window.innerHeight
              ? window.innerHeight
              : document.documentElement.clientHeight
              ? document.documentElement.clientHeight
              : screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft;
            const top = ((height - h) / 2 / systemZoom + dualScreenTop) / 2;
            const newWindow = window.open(
              url,
              title,
              `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
            );

            if (window.focus) newWindow.focus();
          };

          popupCenter({
            url: "https://" + location.host + "/reckon-identity?hidenav=yes",
            title: "mtech",
            w: 500,
            h: 500,
          });

          //console.log("reckonAuth: ", res);
          //console.log("locaton href: ", location.href);
          //window.open('https://localhost:44356/reckon-identity', 'mtech', 'popup,width=600,height=800,top=100,left=100');

          //this.router.navigate(['reckon-identity']);
        }
      });

      setTimeout(() => {
        //console.log("this.authService.isReckonTokenValid ");
        const source = timer(0, 600000); //Every 10 minutes.
        source.subscribe((res) => {
          if (this._isAuthenticated == true) {
            let refresh: boolean = this.authService.reckonAccessTokenExpired();
            //console.log("reckonAccessTokenExpired: ", this.authService.reckonAccessTokenExpired());
            this.authService.isReckonTokenValid(refresh);
            //console.log("timer res: ", res);
          }
        });
      }, 1000);
    }
    //this._isReckonAuthenticated$ = this.authService.isReckonAuthenticated;
  }

  async isTestDB() {
    this.testDB = await this.appService.isTestDatabase();
  }

  async isAuthenticatedSubscribe() {
    //let obs = this._isAuthenticated$.subscribe(async data => {

    //console.log("in test subscribe", data);

    //if (data == true) {
    //console.log("about to authenticate office 365");

    //console.log("isAuthenticatedSubscribe - this.msUser", this.msUser);
    //console.log("isAuthenticatedSubscribe - this.authService.user", this.authService.user);
    //console.log("isAuthenticatedSubscribe - this.isAuthenticated()", this.isAuthenticated());

    if (
      this.isAuthenticated() == true &&
      this.authService.user != null &&
      this.authService.user.blnHas365Account == true &&
      this.msUser == null
    ) {
      if (window.location.href != this.baseUrl) {
        //console.log("this.baseUrl: ", this.baseUrl);
        await this.alertService.openSnackBarCustomPromise(
          "Office 365",
          "Please navigate to home to logon to Office 365",
          "Ok",
          "",
          "center",
          "bottom",
          "",
          0,
          false
        );
        return;
      }

      if (this.msUser == null) {
        //console.log("_isAuthenticated$ - this.msUser : before ", this.msUser);
        await this.getOffice365Account();
        //console.log("_isAuthenticated$ - this.msUser : after ", this.msUser);
      }

      //obs.unsubscribe();
    }

    //}

    //});
  }

  async getOffice365Account() {
    if (this.isAuthenticated() == true && this.user.blnHas365Account == true) {
      this.isOffice365Authenticated =
        await this.authService.isOffice365LoggedIn();

      //console.log("this.isOffice365Authenticated", this.isOffice365Authenticated);

      if (this.isOffice365Authenticated == false) {
        //console.log("about to office365SignIn - ");
        await this.office365SignIn();
      }

      //console.log("this.isOffice365Authenticated", this.isOffice365Authenticated);

      if (this.isOffice365Authenticated == true) {
        //console.log("about to auth MSUser - ", this.authService.ms_user);
        await this.getMSUser();
      }
    }
  }

  async getMSUser() {
    this.msUser = await this.authService.getUser();
    //console.log("ms User", this.msUser);
  }

  ngOnDestroy(): void {
    this._timerSubscription.unsubscribe();
    //this.itemSubscription.unsubscribe();
  }

  timerSubscribe() {
    if (this.timerSubscribed == false) {
      const source = timer(0, 6000); //Every 1 minute.

      //console.log("timerSubscribe: " + Date.now());

      this._timerSubscription = source.subscribe((val) => {
        this.timeRefreshTime = this.authService.timeToRefreshToken();
        this.time = new Date();

        if (
          (this.timeRefreshTime != "" &&
            parseInt(this.timeRefreshTime) == 0 &&
            this._isAuthenticated == true) ||
          this._isAuthenticated == false
        ) {
          this.timerUnsubscribe();
          if (this._isAuthenticated == true) {
            this.onLogout();
          }
        }
      });

      this.timerSubscribed = true;
    }
  }

  timerUnsubscribe() {
    this.timerSubscribed = false;
    this._timerSubscription.unsubscribe();
  }

  //(click) = "toggle()"

  collapse() {
    this.isExpanded = false;
    //console.log("collapse: " + !this.isExpanded);
  }
  // [ngClass]="{ show: isExpanded }"
  //[hidden]="!isExpanded"

  toggle() {
    //console.log("toggle: " + !this.isExpanded);
    this.isExpanded = !this.isExpanded;
    //console.log("navCollapseMenu: " + this.navCollapseMenu);
  }

  async office365SignIn() {
    this.authRes = await this.authService.signIn("popup");
    //console.log("authRes:", this.authRes);

    if (this.authRes != null) {
      this.isOffice365Authenticated = this.authService.ms_authenticated;
      this.msUser = this.authService.ms_user;
      await this.authService.validateUser(this.authRes);
    }
  }

  async office365SignOut() {
    let msg: string = await this.alertService.openSnackBarCustomPromise(
      "Logoff",
      "Are you sure you want to logoff from Office 365?",
      "Yes",
      "No",
      "center",
      "top",
      "",
      0,
      true
    );

    if (msg != "Yes") {
      return;
    }

    await this.authService.signOut();
    this.isOffice365Authenticated = false;
    //console.log("authRes:", this.authRes);
  }

  reckonSignIn() {
    this.authService.reckonAuth.emit(false);

    //this.router.navigate(['reckon-identity'], { queryParams: { returnUrl: this.navigationService.getReturnURL() } });
    //this.router.navigate(['reckon-identity']);
  }

  reckonSignOut() {
    this.authService.setReckonLogoutSession();
    this.reckonAuthenticated = false;
  }

  onLogout() {
    this._isAuthenticated = false;
    this._isAdmin = false;
    this._isAdminChecked = false;
    this.authService.setLogoutSession();
    //this.authService.logout();

    //console.log("logout returnURL: " + this.navigationService.getReturnURL());
    this.router.navigate(["login"], {
      queryParams: { returnUrl: this.navigationService.getReturnURL() },
    });
  }

  isAdmin() {
    if (!this._isAdminChecked) {
      //this._isAdmin = true;
      //console.log("isAdmin: " + this._isAdmin);

      this._isAdmin$.pipe(shareReplay()).subscribe((data) => {
        this._isAdmin = false;
        //console.log("isAdmin data: " + data);
        if (data) {
          this._isAdmin = true;
        }
      });

      this._isAdminChecked = true;
    }
    return this._isAdmin;
  }

  isAuthenticated() {
    if (!this._isAuthenticatedChecked) {
      //this._isAuthenticated = false;
      this._isAuthenticated$.pipe(shareReplay()).subscribe((data) => {
        if (data) {
          this._isAuthenticated = true;
          this._isAdminChecked = false;
          if (this.user != null) {
            this.user.txtFirstName = localStorage.getItem("txtFirstName");
            this.user.txtLastName = localStorage.getItem("txtLastName");
          }
          //console.log("about to subscribe from isAuthenticated");
          this.timerSubscribe();
        }
      });
      this._isAuthenticatedChecked = true;
    }

    return this._isAuthenticated;
  }

  private isAuthenticatedCheck() {
    this._isAuthenticated$.subscribe((data) => {
      //console.log("isAuthenticatedCheck: " + data);
      if (!data) {
        this._isAdminChecked = false;
        this._isAdmin = false;
        this._isAuthenticated = false;
        this._isAuthenticatedChecked = false;
      }
    });
  }
}
