import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { CounterComponent } from "./counter/counter.component";
import { FetchDataComponent } from "./fetch-data/fetch-data.component";
import { BPStocktakeComponent } from "./bpstocktake/bpstocktake.component";
import { BoardTallyRecordComponent } from "./boardtallyrecord/boardtallyrecord.component";
import { LoginComponent } from "./login/login.component";

import { AuthService } from "./account/auth.service";
import { AuthInterceptor } from "./account/AuthInterceptor";
import { AuthGuard } from "./account/auth.guard";

//import { NgxOrgChartModule } from 'ngx-org-chart';
import { MatTooltipModule } from "@angular/material/tooltip";

//import { BoardTallyRecordService } from './boardtallyrecord/boardtallyrecord.service';
//import { BPStocktakeService } from './bpstocktake/bpstocktake.service';
//import { StockContainerService } from './stock-container/StockContainerService';
//import { PoleTrimmingItemService } from './_shared/services/pole-trimming-item.service';
//import { ProductTypeService } from './_shared/services/product-type.service';
//import { SpeciesService } from './_shared/services/species.service';
//import { TrimingItemReasonForChangeService } from './_shared/services/trimming-item-reason-for-change.service';
//import { PoleTrimingItemReasonForChangeService } from './_shared/services/pole-trimming-item-reason-for-change.service';
//import { PoleService } from './_shared/services/pole.service';
//import { IOService } from './_shared/services/io.service';
//import { NavigationService } from './_shared/services/navigation.service';
//import { SharedService } from './_shared/services/shared.service';
import { WOWService } from "./testWOW";

import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { MatDividerModule } from "@angular/material/divider";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RegisterComponent } from "./register/register.component";
import { AppUserUpdateComponent } from "./app-users/app-user-update/app-user-update.component";
import { LogoutComponent } from "./logout/logout.component";

//import { NgxMaskModule, IConfig } from 'ngx-mask';
//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { Chart } from "chart.js";

import { NgChartsModule } from "ng2-charts";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

import { PoleTrimmingItemComponent } from "./pole-trimming-item/pole-trimming-item.component";

import { SafeHtmlPipe } from "../pipes/safeHTML.pipe";
import { TruncateStringPipe } from "../pipes/truncateString.pipe";
import { IsNullPipe } from "../pipes/isNull.pipe";
import { IsZeroPipe } from "../pipes/isZero.pipe";
import { YesNoPipe } from "../pipes/yesNo.pipe";
import { CamelCasePipe } from "../pipes/camelCase.pipe";
import { PoleTrimmingItemAddComponent } from "./pole-trimming-item-add/pole-trimming-item-add.component";
import { PoleTrimmingItemUpdateComponent } from "./pole-trimming-item-update/pole-trimming-item-update.component";
import { ClassBuilderComponent } from "./class-builder/class-builder.component";
import { DeliveryDocketStockTransferComponent } from "./delivery-docket-stock-transfer/delivery-docket-stock-transfer.component";
import { StockTransferCreateComponent } from "./stock-transfer-create/stock-transfer-create.component";

import { CHHMaterialModule } from "./material.module";

import { CHHServiceModule } from "./chh-service.module";
import { AppuserGeostocklocationListComponent } from "./appuser-geostocklocation-list/appuser-geostocklocation-list.component";
import { AppuserGeostocklocationCreateComponent } from "./appuser-geostocklocation-create/appuser-geostocklocation-create.component";
import { PoleBarcodeComponent } from "./pole-barcode/pole-barcode.component";
import { PoleStockTakeComponent } from "./pole-stock-take/pole-stock-take.component";
import { CodeBuilderComponent } from "./code-builder/code-builder.component";
import { ProductCodeFavouriteListComponent } from "./product-code-favourite-list/product-code-favourite-list.component";
import { ProductCodeFavouriteCreateComponent } from "./product-code-favourite-create/product-code-favourite-create.component";
import { ProductCodeFavouriteUpdateComponent } from "./product-code-favourite-update/product-code-favourite-update.component";
import { ReportCentreComponent } from "./report-centre/report-centre.component";
import { report } from "process";
import { WeightCalculatorDialogComponent } from "./weight-calculator-dialog/weight-calculator-dialog.component";
import { BoardTallyRecordNotInStockComponent } from "./board-tally-record-not-in-stock/board-tally-record-not-in-stock.component";
import { DeliveryDocketListComponent } from "./delivery-docket/delivery-docket-list/delivery-docket-list.component";
import { SnackBarCustomComponent } from "./snack-bar-custom/snack-bar-custom.component";
import { StockTransferReceiveComponent } from "./stock-transfer-receive/stock-transfer-receive.component";
import { StockItemComponent } from "./stock-item/stock-item.component";
import { StockTransferStockItemComponent } from "./stock-transfer-stock-item/stock-transfer-stock-item.component";
import { StockItemCreateComponent } from "./stock-item-create/stock-item-create.component";
import { StockItemCreateByProductCodeComponent } from "./stock-item-create-by-product-code/stock-item-create-by-product-code.component";
import { StockItemListComponent } from "./stock-item-list/stock-item-list.component";
import { StockItemUpdateComponent } from "./stock-item-update/stock-item-update.component";
import { StockItemsComponent } from "./stock-items/stock-items.component";
import { OrgChartComponent } from "./org-chart/org-chart.component";
import { FinalInspectionListComponent } from "./final-inspection/final-inspection-list/final-inspection-list.component";
import { FinalInspectionCreateComponent } from "./final-inspection/final-inspection-create/final-inspection-create.component";
import { FinalInspectionUpdateComponent } from "./final-inspection/final-inspection-update/final-inspection-update.component";
import { FinalInspectionItemListComponent } from "./final-inspection-item/final-inspection-item-list/final-inspection-item-list.component";
import { FinalInspectionItemCreateComponent } from "./final-inspection-item/final-inspection-item-create/final-inspection-item-create.component";
import { FinalInspectionItemUpdateComponent } from "./final-inspection-item/final-inspection-item-update/final-inspection-item-update.component";
import { FinalInspectionSearchComponent } from "./final-inspection/final-inspection-search/final-inspection-search.component";
import { AppPageCreateComponent } from "./app-page/app-page-create/app-page-create.component";
import { AppPageListComponent } from "./app-page/app-page-list/app-page-list.component";
import { AppPageUpdateComponent } from "./app-page/app-page-update/app-page-update.component";
import { AppPageSearchComponent } from "./app-page/app-page-search/app-page-search.component";
import { AppUserColumnSearchComponent } from "./app-user-column/app-user-column-search/app-user-column-search.component";
import { AppUserColumnListComponent } from "./app-user-column/app-user-column-list/app-user-column-list.component";
import { AppUserColumnCreateComponent } from "./app-user-column/app-user-column-create/app-user-column-create.component";
import { AppUserColumnUpdateComponent } from "./app-user-column/app-user-column-update/app-user-column-update.component";
import { AppPageColumnSearchComponent } from "./app-page-column/app-page-column-search/app-page-column-search.component";
import { AppPageColumnListComponent } from "./app-page-column/app-page-column-list/app-page-column-list.component";
import { AppPageColumnCreateComponent } from "./app-page-column/app-page-column-create/app-page-column-create.component";
import { AppPageColumnUpdateComponent } from "./app-page-column/app-page-column-update/app-page-column-update.component";
import { AppUserColumnSetSearchComponent } from "./app-user-column-set/app-user-column-set-search/app-user-column-set-search.component";
import { AppUserColumnSetListComponent } from "./app-user-column-set/app-user-column-set-list/app-user-column-set-list.component";
import { AppUserColumnSetCreateComponent } from "./app-user-column-set/app-user-column-set-create/app-user-column-set-create.component";
import { AppUserColumnSetUpdateComponent } from "./app-user-column-set/app-user-column-set-update/app-user-column-set-update.component";
import { DeliveryDocketCreateComponent } from "./delivery-docket/delivery-docket-create/delivery-docket-create.component";
import { DeliveryDocketUpdateComponent } from "./delivery-docket/delivery-docket-update/delivery-docket-update.component";
import { DeliveryDocketSearchComponent } from "./delivery-docket/delivery-docket-search/delivery-docket-search.component";
import { DeliveryDocketAddItemsComponent } from "./delivery-docket/delivery-docket-add-items/delivery-docket-add-items.component";
import { DeliveryDocketFinalItemsAddComponent } from "./delivery-docket/delivery-docket-final-items-add/delivery-docket-final-items-add.component";
import { DeliveryDocketCreateDialogComponent } from "./delivery-docket/delivery-docket-create-dialog/delivery-docket-create-dialog.component";
import { DeliveryDocketOrderItemListComponent } from "./delivery-docket/delivery-docket-order-item-list/delivery-docket-order-item-list.component";
import { AppUserColumnSetBarComponent } from "./app-user-column-set/app-user-column-set-bar/app-user-column-set-bar.component";
import { FinalInspectionItemSummaryComponent } from "./final-inspection-item/final-inspection-item-summary/final-inspection-item-summary.component";
import { DeliveryDocketFinalItemsPreviewComponent } from "./delivery-docket/delivery-docket-final-items-preview/delivery-docket-final-items-preview.component";
import { DownloadsComponent } from "./downloads/downloads.component";
import { DeliveryDocketFinalItemsListComponent } from "./delivery-docket/delivery-docket-final-items-list/delivery-docket-final-items-list.component";
import { DeliveryDocketUpdateDialogComponent } from "./delivery-docket/delivery-docket-update-dialog/delivery-docket-update-dialog.component";
import { LoadingDocketCreateComponent } from "./loading-docket/loading-docket-create/loading-docket-create.component";
import { LoadingDocketUpdateComponent } from "./loading-docket/loading-docket-update/loading-docket-update.component";
import { LoadingDocketListComponent } from "./loading-docket/loading-docket-list/loading-docket-list.component";
import { LoadingDocketSearchComponent } from "./loading-docket/loading-docket-search/loading-docket-search.component";
import { DeliveryDocketOrderItemsAddComponent } from "./delivery-docket/delivery-docket-order-items-add/delivery-docket-order-items-add.component";
import { RoyaltyRateCreateComponent } from "./pole-costing/royalty-rates/royalty-rate-create/royalty-rate-create.component";
import { RoyaltyRateUpdateComponent } from "./pole-costing/royalty-rates/royalty-rate-update/royalty-rate-update.component";
import { RoyaltyRateListComponent } from "./pole-costing/royalty-rates/royalty-rate-list/royalty-rate-list.component";
import { RoyaltyRateSearchComponent } from "./pole-costing/royalty-rates/royalty-rate-search/royalty-rate-search.component";
import { DeliveryDocketDeliveryRateComponent } from "./delivery-docket/delivery-docket-delivery-rate/delivery-docket-delivery-rate.component";
import { ProductCodeBarcodePrintComponent } from "./product-codes/product-code-barcode/product-code-barcode-print/product-code-barcode-print.component";
import { BpFloorOrderSearchComponent } from "./board-plant-floor/bp-floor-order-search/bp-floor-order-search.component";
import { BpFloorOrderItemListComponent } from "./board-plant-floor/bp-floor-order-item-list/bp-floor-order-item-list.component";
import { DeliveryDocketOrderItemUpdateDialogComponent } from "./delivery-docket/delivery-docket-order-item-update-dialog/delivery-docket-order-item-update-dialog.component";
import { DeliveryDocketOrderItemUpdateComponent } from "./delivery-docket/delivery-docket-order-item-update/delivery-docket-order-item-update.component";
import { DeliveryDocketPrintComponent } from "./delivery-docket/delivery-docket-print/delivery-docket-print.component";
import { FinalInspectionItemTransferComponent } from "./final-inspection-item/final-inspection-item-transfer/final-inspection-item-transfer.component";
import { FinalInspectionItemTransferDialogComponent } from "./final-inspection-item/final-inspection-item-transfer-dialog/final-inspection-item-transfer-dialog.component";
import { PrinterSelectionComponent } from "./utilities/printers/printer-selection/printer-selection.component";
import { PrinterSearchComponent } from "./printers/printer-search/printer-search.component";
import { PrinterCreateComponent } from "./printers/printer-create/printer-create.component";
import { PrinterUpdateComponent } from "./printers/printer-update/printer-update.component";
import { PrinterListComponent } from "./printers/printer-list/printer-list.component";
import { PrinterLocationSearchComponent } from "./printers/printer-location-search/printer-location-search.component";
import { PrinterLocationCreateComponent } from "./printers/printer-location-create/printer-location-create.component";
import { PrinterLocationUpdateComponent } from "./printers/printer-location-update/printer-location-update.component";
import { PrinterLocationListComponent } from "./printers/printer-location-list/printer-location-list.component";
import { AppuserPrinterLocationSearchComponent } from "./printers/appuser-printer-location-search/appuser-printer-location-search.component";
import { AppuserPrinterLocationCreateComponent } from "./printers/appuser-printer-location-create/appuser-printer-location-create.component";
import { AppuserPrinterLocationUpdateComponent } from "./printers/appuser-printer-location-update/appuser-printer-location-update.component";
import { AppuserPrinterLocationListComponent } from "./printers/appuser-printer-location-list/appuser-printer-location-list.component";
import { AppuserPrinterDefaultSearchComponent } from "./printers/appuser-printer-default-search/appuser-printer-default-search.component";
import { AppuserPrinterDefaultCreateComponent } from "./printers/appuser-printer-default-create/appuser-printer-default-create.component";
import { AppuserPrinterDefaultUpdateComponent } from "./printers/appuser-printer-default-update/appuser-printer-default-update.component";
import { AppuserPrinterDefaultListComponent } from "./printers/appuser-printer-default-list/appuser-printer-default-list.component";
import { SpinnerComponent } from "./utilities/spinner/spinner.component";
import { OrderItemBarcodeStickerComponent } from "./report-centre/reports/order-item-barcode-sticker/order-item-barcode-sticker.component";
import { Code128autoBarcodeComponent } from "./utilities/barcode/code128auto-barcode/code128auto-barcode.component";
import { MachineRunCreateComponent } from "./machine-run/machine-run-create/machine-run-create.component";
import { MachineRunUpdateComponent } from "./machine-run/machine-run-update/machine-run-update.component";
import { MachineRunSearchComponent } from "./machine-run/machine-run-search/machine-run-search.component";
import { MachineRunListComponent } from "./machine-run/machine-run-list/machine-run-list.component";
import { MachineRecordCreateComponent } from "./machine-run/machine-record-create/machine-record-create.component";
import { MachineRecordUpdateComponent } from "./machine-run/machine-record-update/machine-record-update.component";
import { MachineRecordSearchComponent } from "./machine-run/machine-record-search/machine-record-search.component";
import { MachineRecordListComponent } from "./machine-run/machine-record-list/machine-record-list.component";
import { BpFloorOrderItemStockItemListComponent } from "./board-plant-floor/bp-floor-order-item-stock-item-list/bp-floor-order-item-stock-item-list.component";
import { BpFloorMachineRunDialogComponent } from "./board-plant-floor/bp-floor-machine-run-dialog/bp-floor-machine-run-dialog.component";
import { StockItemMultiplesComponent } from "./board-plant-floor/stock-item-multiples/stock-item-multiples.component";
import { MoulderListComponent } from "./moulder/moulder-list/moulder-list.component";
import { MoulderDialogComponent } from "./moulder/moulder-dialog/moulder-dialog.component";
import { MoulderCreateComponent } from "./moulder/moulder-create/moulder-create.component";
import { MoulderUpdateComponent } from "./moulder/moulder-update/moulder-update.component";
import { MoulderSearchComponent } from "./moulder/moulder-search/moulder-search.component";
import { CommonModule } from "@angular/common";
import { LoadingDocketViewComponent } from "./loading-docket/loading-docket-view/loading-docket-view.component";
import { LoadingDocketPrintComponent } from "./loading-docket/loading-docket-print/loading-docket-print.component";
import { TruckTrailerSelectionDialogComponent } from "./loading-docket/truck-trailer-selection-dialog/truck-trailer-selection-dialog.component";
import { MobilePlantEquipmentSelectionComponent } from "./mobile-plant/mobile-plant-equipment-selection/mobile-plant-equipment-selection.component";
import { MobilePlantEquipmentListComponent } from "./mobile-plant/mobile-plant-equipment-list/mobile-plant-equipment-list.component";
import { MobilePlantEquipmentCreateComponent } from "./mobile-plant/mobile-plant-equipment-create/mobile-plant-equipment-create.component";
import { MobilePlantEquipmentUpdateComponent } from "./mobile-plant/mobile-plant-equipment-update/mobile-plant-equipment-update.component";
import { MobilePlantEquipmentSearchComponent } from "./mobile-plant/mobile-plant-equipment-search/mobile-plant-equipment-search.component";
import { HaulingCompanySelectionComponent } from "./hauling/hauling-company-selection/hauling-company-selection.component";
import { DateSelectorComponent } from "./utilities/date-selector/date-selector.component";
import { BarcodeStickerComponent } from "./report-centre/reports/barcode-sticker/barcode-sticker.component";
import { HaulingCompanyDriverComponent } from "./hauling/hauling-company-driver/hauling-company-driver.component";
import { HaulingCompanyDriverSearchComponent } from "./hauling/hauling-company-driver-search/hauling-company-driver-search.component";
import { HaulingCompanyDriverCreateComponent } from "./hauling/hauling-company-driver-create/hauling-company-driver-create.component";
import { HaulingCompanyDriverUpdateComponent } from "./hauling/hauling-company-driver-update/hauling-company-driver-update.component";
import { HaulingCompanyDriverListComponent } from "./hauling/hauling-company-driver-list/hauling-company-driver-list.component";
import { HaulingCompanyDriverSelectionComponent } from "./hauling/hauling-company-driver-selection/hauling-company-driver-selection.component";
import { LoadingCheckListComponent } from "./loading-docket/loading-check-list/loading-check-list.component";
import { FinalInspectionItemPrintComponent } from "./final-inspection-item/final-inspection-item-print/final-inspection-item-print.component";
import { DeliveryDocketViewComponent } from "./delivery-docket/delivery-docket-view/delivery-docket-view.component";
import { DiscTemplateConfigCreateComponent } from "./disc-template-config/disc-template-config-create/disc-template-config-create.component";
import { DiscTemplateConfigUpdateComponent } from "./disc-template-config/disc-template-config-update/disc-template-config-update.component";
import { DiscTemplateConfigListComponent } from "./disc-template-config/disc-template-config-list/disc-template-config-list.component";
import { DiscTemplateConfigSearchComponent } from "./disc-template-config/disc-template-config-search/disc-template-config-search.component";
import { SignaturePadComponent } from "./utilities/signature-pad/signature-pad.component";
import { LinkExistingLoadingDocketComponent } from "./delivery-docket/link-existing-loading-docket/link-existing-loading-docket.component";
import { LinkExistingLoadingDocketDialogComponent } from "./delivery-docket/link-existing-loading-docket-dialog/link-existing-loading-docket-dialog.component";
import { LoadingDocketCreateDialogComponent } from "./loading-docket/loading-docket-create-dialog/loading-docket-create-dialog.component";
import { RoyaltyRateUtilityComponent } from "./pole-costing/royalty-rates/royalty-rate-utility/royalty-rate-utility.component";

import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
} from "@azure/msal-browser";

import { MsalModule, MsalService, MSAL_INSTANCE } from "@azure/msal-angular";
import { OAuthSettings } from "./account/oauth";
import { SendEmailComponent } from "./email/send-email/send-email.component";
import { ChhpoleStockComponent } from "./round-stock/chhpole-stock/chhpole-stock.component";
import { QuillModule } from "ngx-quill";
import { TextAreaAutoSizeDirective } from "./_shared/directives/text-inputs/text-area-auto-size.directive";
import { TransformTextPipe } from "./_shared/pipes/transform-text.pipe";
import { CustomerContactCreateComponent } from "./customer-contact/customer-contact-create/customer-contact-create.component";
import { CustomerContactCreateDialogComponent } from "./customer-contact/customer-contact-create-dialog/customer-contact-create-dialog.component";
import { CustomerDeliveryAddressDialogComponent } from "./customer-delivery-address/customer-delivery-address-dialog/customer-delivery-address-dialog.component";
import { CustomerDeliveryAddressSelectionComponent } from "./customer-delivery-address/customer-delivery-address-selection/customer-delivery-address-selection.component";
import { CustomerDeliveryAddressCreateComponent } from "./customer-delivery-address/customer-delivery-address-create/customer-delivery-address-create.component";
import { SpinnerInlineTickComponent } from "./utilities/spinner-inline-tick/spinner-inline-tick.component";
import { CustomerDeliveryAddressUpdateComponent } from "./customer-delivery-address/customer-delivery-address-update/customer-delivery-address-update.component";
import { CustomerContactUpdateDialogComponent } from "./customer-contact/customer-contact-update-dialog/customer-contact-update-dialog.component";
import { CustomerContactUpdateComponent } from "./customer-contact/customer-contact-update/customer-contact-update.component";
import { SendMailDialogComponent } from "./email/send-mail-dialog/send-mail-dialog.component";
import { AppUserListComponent } from "./app-users/app-user-list/app-user-list.component";
import { AppUserSearchComponent } from "./app-users/app-user-search/app-user-search.component";
import { TermsAndConditionsComponent } from "./utilities/terms-and-conditions/terms-and-conditions.component";
import { QuoteListComponent } from "./orders-quotes/quotes/quote-list/quote-list.component";
import { QuoteCreateComponent } from "./orders-quotes/quotes/quote-create/quote-create.component";
import { QuoteUpdateComponent } from "./orders-quotes/quotes/quote-update/quote-update.component";
import { QuoteSearchComponent } from "./orders-quotes/quotes/quote-search/quote-search.component";
import { OrderSearchComponent } from "./orders-quotes/orders/order-search/order-search.component";
import { OrderListComponent } from "./orders-quotes/orders/order-list/order-list.component";
import { OrderCreateComponent } from "./orders-quotes/orders/order-create/order-create.component";
import { OrderUpdateComponent } from "./orders-quotes/orders/order-update/order-update.component";
import { PhotoViewerComponent } from "./utilities/photo-viewer/photo-viewer.component";
import { PhotoViewerDialogComponent } from "./utilities/photo-viewer-dialog/photo-viewer-dialog.component";
import { SafeURLPipe } from "../pipes/safeURL.pipe";
import { DropboxEmailsComponent } from "./utilities/dropbox-emails/dropbox-emails.component";
import { QuotePrintComponent } from "./orders-quotes/quotes/quote-print/quote-print.component";
import { BoardTallyRecordHistoryListComponent } from "./board-tally-record-history/board-tally-record-history-list/board-tally-record-history-list.component";
import { BarcodeStickerLargeComponent } from "./report-centre/reports/barcode-sticker-large/barcode-sticker-large.component";
import { QuoteItemListComponent } from "./orders-quotes/quotes/quote-item-list/quote-item-list.component";
import { QuoteItemCreateComponent } from "./orders-quotes/quotes/quote-item-create/quote-item-create.component";
import { QuoteItemUpdateComponent } from "./orders-quotes/quotes/quote-item-update/quote-item-update.component";
import { TruncateStringComponent } from "./utilities/truncate-string/truncate-string.component";
import { QuoteItemViewComponent } from "./orders-quotes/quotes/quote-item-view/quote-item-view.component";
import { CustomerCreateComponent } from "./customer/customer-create/customer-create.component";
import { CustomerUpdateComponent } from "./customer/customer-update/customer-update.component";
import { CustomerListComponent } from "./customer/customer-list/customer-list.component";
import { CustomerSearchComponent } from "./customer/customer-search/customer-search.component";
import { CustomerSelectComponent } from "./customer/customer-select/customer-select.component";
import { CustomerUpdateDialogComponent } from "./customer/customer-update-dialog/customer-update-dialog.component";
import { ProductTypeSelectComponent } from "./product-type/product-type-select/product-type-select.component";
import { ProductTypeCreateComponent } from "./product-type/product-type-create/product-type-create.component";
import { ProductTypeUpdateComponent } from "./product-type/product-type-update/product-type-update.component";
import { ProductTypeListComponent } from "./product-type/product-type-list/product-type-list.component";
import { ProductTypeSearchComponent } from "./product-type/product-type-search/product-type-search.component";
import { ProductTypeUpdateDialogComponent } from "./product-type/product-type-update-dialog/product-type-update-dialog.component";
import { SpeciesSelectComponent } from "./species/species-select/species-select.component";
import { SpeciesCreateComponent } from "./species/species-create/species-create.component";
import { SpeciesUpdateComponent } from "./species/species-update/species-update.component";
import { SpeciesListComponent } from "./species/species-list/species-list.component";
import { SpeciesSearchComponent } from "./species/species-search/species-search.component";
import { GradeSelectComponent } from "./grade/grade-select/grade-select.component";
import { GradeCreateComponent } from "./grade/grade-create/grade-create.component";
import { GradeUpdateComponent } from "./grade/grade-update/grade-update.component";
import { GradeListComponent } from "./grade/grade-list/grade-list.component";
import { GradeSearchComponent } from "./grade/grade-search/grade-search.component";
import { GradeUpdateDialogComponent } from "./grade/grade-update-dialog/grade-update-dialog.component";
import { SpeciesUpdateDialogComponent } from "./species/species-update-dialog/species-update-dialog.component";
import { ProductProfileSelectComponent } from "./product-profile/product-profile-select/product-profile-select.component";
import { ProductProfileCreateComponent } from "./product-profile/product-profile-create/product-profile-create.component";
import { ProductProfileUpdateComponent } from "./product-profile/product-profile-update/product-profile-update.component";
import { ProductProfileUpdateDialogComponent } from "./product-profile/product-profile-update-dialog/product-profile-update-dialog.component";
import { ProductProfileListComponent } from "./product-profile/product-profile-list/product-profile-list.component";
import { ProductProfileSearchComponent } from "./product-profile/product-profile-search/product-profile-search.component";
import { ProductCodeSearchComponent } from "./product-codes/product-code-search/product-code-search.component";
import { ProductCodeCreateComponent } from "./product-codes/product-code-create/product-code-create.component";
import { ProductCodeUpdateComponent } from "./product-codes/product-code-update/product-code-update.component";
import { ProductCodeBuilderComponent } from "./product-codes/product-code-builder/product-code-builder.component";
import { ProductCodeBuilderDialogComponent } from "./product-codes/product-code-builder-dialog/product-code-builder-dialog.component";
import { ProductCodeSelectComponent } from "./product-codes/product-code-select/product-code-select.component";
import { ReckonIdentityComponent } from "./reckon/reckon-identity/reckon-identity.component";
import { UserFilesListComponent } from "./reckon/user-files-list/user-files-list.component";
import { CustomerCreateDialogComponent } from "./customer/customer-create-dialog/customer-create-dialog.component";
import { ReckonInvoiceListComponent } from "./reckon/reckon-invoice-list/reckon-invoice-list.component";
import { AppPageDevelopmentComponent } from "./app-page-development/app-page-development.component";
import { ConsecutiveNumberListComponent } from "./final-inspection-item/consecutive-number-list/consecutive-number-list.component";
import { CcaChargeSearchComponent } from "./cca-charge/cca-charge-search/cca-charge-search.component";
import { CcaChargeListComponent } from "./cca-charge/cca-charge-list/cca-charge-list.component";
import { CcaChargeCreateComponent } from "./cca-charge/cca-charge-create/cca-charge-create.component";
import { CcaChargeUpdateComponent } from "./cca-charge/cca-charge-update/cca-charge-update.component";
import { CcaChargeItemListComponent } from "./cca-charge/cca-charge-item-list/cca-charge-item-list.component";
import { CcaChargeItemCreateComponent } from "./cca-charge/cca-charge-item-create/cca-charge-item-create.component";
import { CcaChargeItemUpdateComponent } from "./cca-charge/cca-charge-item-update/cca-charge-item-update.component";
import { CcaChargeItemViewComponent } from "./cca-charge/cca-charge-item-view/cca-charge-item-view.component";
import { CcaChargePrintComponent } from "./cca-charge/cca-charge-print/cca-charge-print.component";
import { CustomerContactSelectComponent } from "./customer-contact/customer-contact-select/customer-contact-select.component";
import { PosSalesComponent } from "./report-centre/reports/pos-sales/pos-sales.component";
import { ChhChartComponent } from "./report-centre/reports/chh-chart/chh-chart.component";
import { MonthlyReportsComponent } from "./report-centre/reports/monthly-reports/monthly-reports.component";
import { PosProductSummaryComponent } from "./report-centre/reports/pos-product-summary/pos-product-summary.component";
import { LoginMsgraphComponent } from "./login-msgraph/login-msgraph.component";
import { DeliveredSalesChartComponent } from "./report-centre/reports/delivered-sales/delivered-sales-chart/delivered-sales-chart.component";
import { DeliveredSalesSummaryComponent } from "./report-centre/reports/delivered-sales/delivered-sales-summary/delivered-sales-summary.component";
import { PickupLocationSelectComponent } from "./pickup-location/pickup-location-select/pickup-location-select.component";
import { PoleTagComponent } from "./report-centre/reports/pole-tag/pole-tag.component";
import { PoleInspectionSearchComponent } from "./log-yard/pole-inspection-search/pole-inspection-search.component";
import { PoleInspectionCreateComponent } from "./log-yard/pole-inspection-create/pole-inspection-create.component";
import { PoleInspectionUpdateComponent } from "./log-yard/pole-inspection-update/pole-inspection-update.component";
import { PoleInspectionItemCreateComponent } from "./log-yard/pole-inspection-item-create/pole-inspection-item-create.component";
import { PoleInspectionItemUpdateComponent } from "./log-yard/pole-inspection-item-update/pole-inspection-item-update.component";
import { PoleInspectionItemDialogComponent } from "./log-yard/pole-inspection-item-dialog/pole-inspection-item-dialog.component";
import { InspectionPoleBarcodeComponent } from "./log-yard/inspection-pole-barcode/inspection-pole-barcode.component";
import { StockForSaleComponent } from "./sales/stock-for-sale/stock-for-sale.component";
import { ProductTileComponent } from "./sales/product-tile/product-tile.component";
import { ProductDetailViewComponent } from "./sales/product-detail-view/product-detail-view.component";
import { PhotoViewerZoomComponent } from "./utilities/photo-viewer-zoom/photo-viewer-zoom.component";
import { ProductTypeCategorySelectComponent } from "./product-type-category/product-type-category-select/product-type-category-select.component";

let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance =
    msalInstance ??
    new PublicClientApplication({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
        postLogoutRedirectUri: OAuthSettings.redirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
    });

  return msalInstance;
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button

    ["link", "image", "video"], // link and image, video
  ],
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    BoardTallyRecordComponent,
    BPStocktakeComponent,
    RegisterComponent,
    AppUserUpdateComponent,
    PoleTrimmingItemComponent,
    SafeHtmlPipe,
    TruncateStringPipe,
    IsNullPipe,
    IsZeroPipe,
    YesNoPipe,
    CamelCasePipe,
    SafeURLPipe,
    TransformTextPipe,
    PoleTrimmingItemAddComponent,
    PoleTrimmingItemUpdateComponent,
    ClassBuilderComponent,
    DeliveryDocketStockTransferComponent,
    StockTransferCreateComponent,
    AppuserGeostocklocationListComponent,
    AppuserGeostocklocationCreateComponent,
    PoleBarcodeComponent,
    PoleStockTakeComponent,
    CodeBuilderComponent,
    ProductCodeFavouriteListComponent,
    ProductCodeFavouriteCreateComponent,
    ProductCodeFavouriteUpdateComponent,
    ReportCentreComponent,
    WeightCalculatorDialogComponent,
    BoardTallyRecordNotInStockComponent,
    DeliveryDocketListComponent,
    SnackBarCustomComponent,
    StockTransferReceiveComponent,
    StockItemComponent,
    StockTransferStockItemComponent,
    StockItemCreateComponent,
    StockItemCreateByProductCodeComponent,
    StockItemListComponent,
    StockItemUpdateComponent,
    StockItemsComponent,
    OrgChartComponent,
    FinalInspectionListComponent,
    FinalInspectionCreateComponent,
    FinalInspectionUpdateComponent,
    FinalInspectionItemListComponent,
    FinalInspectionItemCreateComponent,
    FinalInspectionItemUpdateComponent,
    FinalInspectionSearchComponent,
    AppPageCreateComponent,
    AppPageListComponent,
    AppPageUpdateComponent,
    AppPageSearchComponent,
    AppUserColumnSearchComponent,
    AppUserColumnListComponent,
    AppUserColumnCreateComponent,
    AppUserColumnUpdateComponent,
    AppPageColumnSearchComponent,
    AppPageColumnListComponent,
    AppPageColumnCreateComponent,
    AppPageColumnUpdateComponent,
    AppUserColumnSetSearchComponent,
    AppUserColumnSetListComponent,
    AppUserColumnSetCreateComponent,
    AppUserColumnSetUpdateComponent,
    DeliveryDocketCreateComponent,
    DeliveryDocketUpdateComponent,
    DeliveryDocketSearchComponent,
    DeliveryDocketAddItemsComponent,
    DeliveryDocketFinalItemsAddComponent,
    DeliveryDocketCreateDialogComponent,
    DeliveryDocketOrderItemListComponent,
    AppUserColumnSetBarComponent,
    FinalInspectionItemSummaryComponent,
    DeliveryDocketFinalItemsPreviewComponent,
    DownloadsComponent,
    DeliveryDocketFinalItemsListComponent,
    DeliveryDocketUpdateDialogComponent,
    LoadingDocketCreateComponent,
    LoadingDocketUpdateComponent,
    LoadingDocketListComponent,
    LoadingDocketSearchComponent,
    DeliveryDocketOrderItemsAddComponent,
    RoyaltyRateCreateComponent,
    RoyaltyRateUpdateComponent,
    RoyaltyRateListComponent,
    RoyaltyRateSearchComponent,
    DeliveryDocketDeliveryRateComponent,
    ProductCodeBarcodePrintComponent,
    BpFloorOrderSearchComponent,
    BpFloorOrderItemListComponent,
    DeliveryDocketOrderItemUpdateDialogComponent,
    DeliveryDocketOrderItemUpdateComponent,
    DeliveryDocketPrintComponent,
    FinalInspectionItemTransferComponent,
    FinalInspectionItemTransferDialogComponent,
    PrinterSelectionComponent,
    PrinterSearchComponent,
    PrinterCreateComponent,
    PrinterUpdateComponent,
    PrinterListComponent,
    PrinterLocationSearchComponent,
    PrinterLocationCreateComponent,
    PrinterLocationUpdateComponent,
    PrinterLocationListComponent,
    AppuserPrinterLocationSearchComponent,
    AppuserPrinterLocationCreateComponent,
    AppuserPrinterLocationUpdateComponent,
    AppuserPrinterLocationListComponent,
    AppuserPrinterDefaultSearchComponent,
    AppuserPrinterDefaultCreateComponent,
    AppuserPrinterDefaultUpdateComponent,
    AppuserPrinterDefaultListComponent,
    SpinnerComponent,
    OrderItemBarcodeStickerComponent,
    Code128autoBarcodeComponent,
    MachineRunCreateComponent,
    MachineRunUpdateComponent,
    MachineRunSearchComponent,
    MachineRunListComponent,
    MachineRecordCreateComponent,
    MachineRecordUpdateComponent,
    MachineRecordSearchComponent,
    MachineRecordListComponent,
    BpFloorOrderItemStockItemListComponent,
    BpFloorMachineRunDialogComponent,
    StockItemMultiplesComponent,
    MoulderListComponent,
    MoulderDialogComponent,
    MoulderCreateComponent,
    MoulderUpdateComponent,
    MoulderSearchComponent,
    LoadingDocketViewComponent,
    LoadingDocketPrintComponent,
    TruckTrailerSelectionDialogComponent,
    MobilePlantEquipmentSelectionComponent,
    MobilePlantEquipmentListComponent,
    MobilePlantEquipmentCreateComponent,
    MobilePlantEquipmentUpdateComponent,
    MobilePlantEquipmentSearchComponent,
    HaulingCompanySelectionComponent,
    DateSelectorComponent,
    BarcodeStickerComponent,
    HaulingCompanyDriverComponent,
    HaulingCompanyDriverSearchComponent,
    HaulingCompanyDriverCreateComponent,
    HaulingCompanyDriverUpdateComponent,
    HaulingCompanyDriverListComponent,
    HaulingCompanyDriverSelectionComponent,
    LoadingCheckListComponent,
    FinalInspectionItemPrintComponent,
    DeliveryDocketViewComponent,
    DiscTemplateConfigCreateComponent,
    DiscTemplateConfigUpdateComponent,
    DiscTemplateConfigListComponent,
    DiscTemplateConfigSearchComponent,
    SignaturePadComponent,
    LinkExistingLoadingDocketComponent,
    LinkExistingLoadingDocketDialogComponent,
    LoadingDocketCreateDialogComponent,
    RoyaltyRateUtilityComponent,
    SendEmailComponent,
    ChhpoleStockComponent,
    TextAreaAutoSizeDirective,
    CustomerContactCreateComponent,
    CustomerContactCreateDialogComponent,
    CustomerContactUpdateComponent,
    CustomerDeliveryAddressDialogComponent,
    CustomerDeliveryAddressSelectionComponent,
    CustomerDeliveryAddressCreateComponent,
    SpinnerInlineTickComponent,
    CustomerDeliveryAddressUpdateComponent,
    CustomerContactUpdateDialogComponent,
    SendMailDialogComponent,
    AppUserListComponent,
    AppUserSearchComponent,
    TermsAndConditionsComponent,
    QuoteListComponent,
    QuoteCreateComponent,
    QuoteUpdateComponent,
    QuoteSearchComponent,
    OrderSearchComponent,
    OrderListComponent,
    OrderCreateComponent,
    OrderUpdateComponent,
    PhotoViewerComponent,
    PhotoViewerDialogComponent,
    DropboxEmailsComponent,
    QuotePrintComponent,
    BoardTallyRecordHistoryListComponent,
    BarcodeStickerLargeComponent,
    QuoteItemListComponent,
    QuoteItemCreateComponent,
    QuoteItemUpdateComponent,
    TruncateStringComponent,
    QuoteItemViewComponent,
    CustomerCreateComponent,
    CustomerUpdateComponent,
    CustomerListComponent,
    CustomerSearchComponent,
    CustomerSelectComponent,
    CustomerUpdateDialogComponent,
    ProductTypeSelectComponent,
    ProductTypeCreateComponent,
    ProductTypeUpdateComponent,
    ProductTypeListComponent,
    ProductTypeSearchComponent,
    ProductTypeUpdateDialogComponent,
    SpeciesSelectComponent,
    SpeciesCreateComponent,
    SpeciesUpdateComponent,
    SpeciesListComponent,
    SpeciesSearchComponent,
    GradeSelectComponent,
    GradeCreateComponent,
    GradeUpdateComponent,
    GradeListComponent,
    GradeSearchComponent,
    GradeUpdateDialogComponent,
    SpeciesUpdateDialogComponent,
    ProductProfileSelectComponent,
    ProductProfileCreateComponent,
    ProductProfileUpdateComponent,
    ProductProfileUpdateDialogComponent,
    ProductProfileListComponent,
    ProductProfileSearchComponent,
    ProductCodeSearchComponent,
    ProductCodeCreateComponent,
    ProductCodeUpdateComponent,
    ProductCodeBuilderComponent,
    ProductCodeBuilderDialogComponent,
    ProductCodeSelectComponent,
    ReckonIdentityComponent,
    UserFilesListComponent,
    CustomerCreateDialogComponent,
    ReckonInvoiceListComponent,
    AppPageDevelopmentComponent,
    ConsecutiveNumberListComponent,
    CcaChargeSearchComponent,
    CcaChargeListComponent,
    CcaChargeCreateComponent,
    CcaChargeUpdateComponent,
    CcaChargeItemListComponent,
    CcaChargeItemCreateComponent,
    CcaChargeItemUpdateComponent,
    CcaChargeItemViewComponent,
    CcaChargePrintComponent,
    CustomerContactSelectComponent,
    PosSalesComponent,
    ChhChartComponent,
    MonthlyReportsComponent,
    PosProductSummaryComponent,
    LoginMsgraphComponent,
    DeliveredSalesChartComponent,
    DeliveredSalesSummaryComponent,
    PickupLocationSelectComponent,
    PoleTagComponent,
    PoleInspectionSearchComponent,
    PoleInspectionCreateComponent,
    PoleInspectionUpdateComponent,
    PoleInspectionItemCreateComponent,
    PoleInspectionItemUpdateComponent,
    PoleInspectionItemDialogComponent,
    InspectionPoleBarcodeComponent,
    StockForSaleComponent,
    ProductTileComponent,
    ProductDetailViewComponent,
    PhotoViewerZoomComponent,
    ProductTypeCategorySelectComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    MsalModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      [
        {
          path: "",
          component: HomeComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
        { path: "login", component: LoginComponent },
        { path: "counter", component: CounterComponent },
        { path: "fetch-data", component: FetchDataComponent },
        {
          path: "boardtallyrecord",
          component: BoardTallyRecordComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "bpstocktake",
          component: BPStocktakeComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "bp-floor-order-search",
          component: BpFloorOrderSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "machine-run-search",
          component: MachineRunSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "stock-transfer-create",
          component: StockTransferCreateComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "stock-items",
          component: StockItemsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "stock-transfer-receive",
          component: StockTransferReceiveComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "appuser-geostocklocation-list",
          component: AppuserGeostocklocationListComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "appuser-geostocklocation-create",
          component: AppuserGeostocklocationCreateComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "org-chart",
          component: OrgChartComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "pole-barcode",
          component: PoleBarcodeComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "product-code-barcode-print",
          component: ProductCodeBarcodePrintComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "final-inspection-search",
          component: FinalInspectionSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "final-inspection-item-print",
          component: FinalInspectionItemPrintComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "inspection-pole-barcode",
          component: InspectionPoleBarcodeComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "app-page-search",
          component: AppPageSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "app-page-column-search",
          component: AppPageColumnSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "app-user-column-search",
          component: AppUserColumnSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "app-user-column-set-search",
          component: AppUserColumnSetSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "pole-stock-take",
          component: PoleStockTakeComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "pole-trimming-item",
          component: PoleTrimmingItemComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "pole-trimming-item-add",
          component: PoleTrimmingItemAddComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "pole-trimming-item-update/:poleTrimmingItemGuid",
          component: PoleTrimmingItemUpdateComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "class-builder",
          component: ClassBuilderComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "code-builder",
          component: CodeBuilderComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "report-centre",
          component: ReportCentreComponent,
          canActivate: [AuthGuard],
        },
        { path: "pos-sales", component: PosSalesComponent }, //, canActivate: [AuthGuard]
        { path: "monthly-reports", component: MonthlyReportsComponent }, //, canActivate: [AuthGuard]
        {
          path: "order-item-barcode-sticker",
          component: OrderItemBarcodeStickerComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "barcode-sticker",
          component: BarcodeStickerComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "barcode-sticker-large",
          component: BarcodeStickerLargeComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "board-tally-record-not-in-stock",
          component: BoardTallyRecordNotInStockComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "delivery-docket-search",
          component: DeliveryDocketSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "delivery-docket-print",
          component: DeliveryDocketPrintComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "loading-docket-search",
          component: LoadingDocketSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "loading-docket-view",
          component: LoadingDocketViewComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "delivery-docket-view",
          component: DeliveryDocketViewComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "loading-docket-print",
          component: LoadingDocketPrintComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "hauling-company-driver-search",
          component: HaulingCompanyDriverSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "disc-template-config-search",
          component: DiscTemplateConfigSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "stock-item",
          component: StockItemComponent,
          canActivate: [AuthGuard],
        },
        { path: "logout", component: LogoutComponent },
        { path: "login-msgraph", component: LoginMsgraphComponent },
        {
          path: "register",
          component: RegisterComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "app-user-search",
          component: AppUserSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "app-page-development",
          component: AppPageDevelopmentComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "downloads",
          component: DownloadsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "royalty-rate-search",
          component: RoyaltyRateSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "printer-search",
          component: PrinterSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "printer-location-search",
          component: PrinterLocationSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "send-mail",
          component: SendEmailComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "dropbox-email",
          component: DropboxEmailsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "terms-and-conditions",
          component: TermsAndConditionsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "quote-search",
          component: QuoteSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "order-search",
          component: OrderSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "cca-charge-search",
          component: CcaChargeSearchComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "cca-charge-print",
          component: CcaChargePrintComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "reckon-identity",
          component: ReckonIdentityComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "reckon-invoice-list",
          component: ReckonInvoiceListComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "user-files-list",
          component: UserFilesListComponent,
          canActivate: [AuthGuard],
        },
        { path: "stock-for-sale", component: StockForSaleComponent },
        {
          path: "preview",
          loadChildren: () =>
            import("./preview/preview.routes").then((m) => m.previewRoutes),
        },
        { path: "**", component: HomeComponent, canActivate: [AuthGuard] },
      ],
      {}
    ),
    BrowserAnimationsModule,
    MatTooltipModule,
    CHHMaterialModule,
    CHHServiceModule,
    NgChartsModule,
    DragDropModule,
    QuillModule.forRoot(),
    //,NgxMaskModule.forRoot()
  ],
  exports: [
    SafeHtmlPipe,
    SafeURLPipe,
    TruncateStringPipe,
    YesNoPipe,
    CamelCasePipe,
    IsNullPipe,
    IsZeroPipe,
  ],
  providers: [
    WOWService,
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    SafeHtmlPipe,
    SafeURLPipe,
    TruncateStringPipe,
    YesNoPipe,
    CamelCasePipe,
    IsNullPipe,
    IsZeroPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MsalService,
  ],
  bootstrap: [AppComponent],
})

//, BrowserAnimationsModule, MatSliderModule, MatFormFieldModule, MatInputModule, MatDividerModule, MatTableModule, MatCheckboxModule, MatCardModule, MatRadioModule,
//MatButtonModule, MatMenuModule, MatIconModule, MatSelectModule, MatButtonToggleModule, MatDatepickerModule, MatNativeDateModule,
//NgxMatSelectSearchModule, MatListModule, MatSnackBarModule,
export class AppModule {}
